import React from 'react';

export const KPI = ({ title, subtitle, value, color }) => (
  <div className="kpi">
    <span className="kpi-title">{title}</span>
    <div
      style={{
        whiteSpace: `nowrap`,
        fontSize: '4rem',
        color: color ? color : 'black',
        fontWeight: 'lighter'
      }}
      className="value text-center "
    >
      {value}
    </div>
    <p className="w-100 subtitle">
      <small>{subtitle}</small>
    </p>
  </div>
);

export const ThinKPI = ({ title, subtitle, value, color }) => (
  <div className="text-center ">
    {title && <h4 className="title">{title}</h4>}
    <span
      style={{
        whiteSpace: `nowrap`,
        fontSize: '100px',
        color: color ? color : 'black',
        fontWeight: 'lighter',
        lineHeight: '200px'
      }}
      className="value"
    >
      {value}
    </span>
    {subtitle && (
      <p className="subtitle">
        <small>{subtitle}</small>
      </p>
    )}
  </div>
);
