import { propertyConstants } from '../_constants/propertyConstants';
export const rentedPropertyActions = {
  hideModals,
  changeVisualizationMode,
  updateModals
};

function updateModals(body) {
  return dispatch => {
    dispatch(request(body));
  };
  function request(body) {
    return { type: propertyConstants.UPDATE_MODALS, body };
  }
}

function hideModals() {
  return dispatch => {
    dispatch(request());
  };
  function request(body) {
    return { type: propertyConstants.HIDE_MODALS, body };
  }
}

function changeVisualizationMode(currentVisualizationMode) {
  return dispatch => {
    dispatch(request(currentVisualizationMode));
  };
  function request(currentVisualizationMode) {
    return {
      type: propertyConstants.CHANGE_VIEW_MODE,
      currentVisualizationMode
    };
  }
}
