import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Row, Col, Image, Breadcrumb } from 'react-bootstrap';
import RentedPropertyForm from '../../_components/RentedPropertyForm/RentedPropertyForm';
import { propertyActions } from '../../_actions/propertyActions';
import { mapPropertyToEdit } from '../../_helpers/mappers';
import PageTitle from '../../_components/Shared/PageTitle';
class RentedPropertyNewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showBillingModeModal: true,
      payment_method: ''
    };
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  handleModeSelected = mode => {
    this.setState({ payment_method: mode, showBillingModeModal: false });
  };
  handleHide = () => {
    if (!this.state.payment_method) {
      window.history.go(-1);
    }
  };
  submit = values => {
    this.setState({ showModal: false });
    this.props.saveNewProperty(mapPropertyToEdit(values));
  };

  render() {
    const BillingModeModal = ({ onModeSelected }) => {
      return (
        <Modal
          className="form-modal"
          show={this.state.showBillingModeModal}
          onHide={this.handleHide}
          bsSize="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Escolha o modo de recebimento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ textAlign: 'center' }}>
              <Col xs={12} md={6} onClick={() => onModeSelected('bank_slip')}>
                <div className="box" tabIndex={1}>
                  <Image
                    style={{ maxHeight: '60px', height: '60px' }}
                    src={'/images/icon_boleto.svg'}
                  />
                  <h4 style={{ whiteSpace: 'wrap' }}>Boleto Bancário</h4>
                </div>
              </Col>
              <Col xs={12} md={6} onClick={() => onModeSelected('manual')}>
                <div className="box" tabIndex={2}>
                  <Image
                    style={{ maxHeight: '60px', height: '60px' }}
                    src={'/images/icon_money_bag.svg'}
                  />
                  <h4 style={{ whiteSpace: 'wrap' }}>Receber Manualmente</h4>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      );
    };

    const DraftConfirmationModal = () => {
      return (
        <Modal
          className="form-modal"
          show={this.state.showModal}
          onHide={this.toggleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Cadastrar novo imóvel alugado</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            Você ainda não preencheu todos os campos necessários. Deseja salvar
            um rascunho e finalizar o cadastro posteriormente?
          </Modal.Body>

          <Modal.Footer>
            <Button
              bsStyle="primary"
              className="btn-primary__cancel"
              onClick={this.toggleModal}
            >
              Cancelar
            </Button>
            <Button
              bsStyle="primary"
              className="btn_primary__confirm"
              onClick={this.submit}
            >
              Salvar rascunho
            </Button>
          </Modal.Footer>
        </Modal>
      );
    };
    return (
      <Col md={12}>
        <PageTitle>Novo Imóvel Alugado</PageTitle>
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard">Meus Imóveis</Breadcrumb.Item>
          <Breadcrumb.Item href="/properties">Imóveis Alugados</Breadcrumb.Item>
          <Breadcrumb.Item active>Novo Imóvel Alugado</Breadcrumb.Item>
        </Breadcrumb>
        {this.state.payment_method && (
          <RentedPropertyForm
            onSubmit={this.submit}
            isNew={true}
            onDraftSubmit={this.toggleModal}
            payment_method={this.state.payment_method}
          />
        )}
        <BillingModeModal onModeSelected={this.handleModeSelected} />
        <DraftConfirmationModal />
      </Col>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveNewProperty: values => dispatch(propertyActions.register(values))
});

export default connect(
  null,
  mapDispatchToProps
)(RentedPropertyNewPage);
