import { authHeader } from '../_helpers/authHeader';
import config from '../_helpers/config';

export const bankAccountService = {
  getAll
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${config.ZIMOBI_API_BASE}/bank_accounts?qnty=20000`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  if (response.status === 204) {
    return;
  }

  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('user');
        window.location.reload(true);
      }

      const error = data || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
