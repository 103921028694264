import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
export const TooltipWrapper = ({ id, children, tooltip, bsClass }) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip
          id={
            id
              ? id
              : Math.random()
                  .toString(36)
                  .substring(2, 15)
          }
        >
          {tooltip}
        </Tooltip>
      }
      placement="top"
      delayShow={100}
      delayHide={500}
    >
      {children}
    </OverlayTrigger>
  );
};
