export const searchAddressFromPostalCode = postalCode => {
  return fetch(`//viacep.com.br/ws/${postalCode}/json/ `, {
    method: 'GET'
  })
    .then(response => {
      return response.json().then(data => {
        if (response.ok) {
          if (data.erro) {
            return null;
          }
          return data;
        }
        return null;
      });
    })
    .catch(error => null);
};
