import { validateCPF } from '../../../_helpers/cpfValidator';

const validate = values => {
  const errors = {
    doubt_form_attributes: {},
    schedule_form_attributes: {}
  };

  const requiredMsg = 'Campo obrigatório';

  //Have Doubt
  const doubt_form_attributes = values.doubt_form_attributes || {};
  if (!doubt_form_attributes.full_name) {
    errors.doubt_form_attributes.full_name = requiredMsg;
  }
  if (!doubt_form_attributes.mobile) {
    errors.doubt_form_attributes.mobile = requiredMsg;
  }
  if (!doubt_form_attributes.email) {
    errors.doubt_form_attributes.email = requiredMsg;
  }
  if (!doubt_form_attributes.doubt_text) {
    errors.doubt_form_attributes.doubt_text = requiredMsg;
  }
  if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      doubt_form_attributes.email
    )
  ) {
    errors.doubt_form_attributes.email = 'Insira um email válido.';
  }

  //Schedule Visit
  const schedule_form_attributes = values.schedule_form_attributes || {};
  if (!schedule_form_attributes.full_name) {
    errors.schedule_form_attributes.full_name = requiredMsg;
  }
  if (!schedule_form_attributes.mobile) {
    errors.schedule_form_attributes.mobile = requiredMsg;
  }
  if (!schedule_form_attributes.email) {
    errors.schedule_form_attributes.email = requiredMsg;
  }
  if (!schedule_form_attributes.availability) {
    errors.schedule_form_attributes.availability = requiredMsg;
  }
  if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      schedule_form_attributes.email
    )
  ) {
    errors.schedule_form_attributes.email = 'Insira um email válido.';
  }
  if (!schedule_form_attributes.cpf) {
    errors.schedule_form_attributes.cpf = requiredMsg;
  }
  if (values.cpf && !validateCPF(values.cpf)) {
    errors.schedule_form_attributes.cpf = 'CPF inválido';
  }
  return errors;
};

export default validate;
