import React from 'react';
import { toCurrenry } from '../_helpers/utils';
import { Donut } from './AppCharts';

export const DonutChartKPI = ({ summaryData, padding }) => {
  let overdue_amount_in_cents = 0;
  let pending_amount_in_cents = 0;
  let completed_amount_in_cents = 0;
  let properties = 0;
  if (summaryData) {
    overdue_amount_in_cents = summaryData.overdue_amount_in_cents
      ? summaryData.overdue_amount_in_cents
      : 0;
    pending_amount_in_cents = summaryData.pending_amount_in_cents
      ? summaryData.pending_amount_in_cents
      : 0;
    completed_amount_in_cents = summaryData.completed_amount_in_cents
      ? summaryData.completed_amount_in_cents
      : 0;
    properties = summaryData.properties_count
      ? summaryData.properties_count
      : 0;
  }
  const _data = {
    labels: [
      toCurrenry(overdue_amount_in_cents / 100) + ' Atrasado',
      toCurrenry(pending_amount_in_cents / 100) + ' A Receber',
      toCurrenry(completed_amount_in_cents / 100) + ' Recebido'
    ],
    datasets: [
      {
        data: [
          overdue_amount_in_cents / 100,
          pending_amount_in_cents / 100,
          completed_amount_in_cents / 100
        ],
        backgroundColor: ['#ED1C24', '#f69322', '#2DEF56'],
        hoverBackgroundColor: ['#ED1C24', '#f69300', '#2DEF56']
      }
    ]
  };
  return properties > 0 ? (
    <Donut
      height={'100px'}
      key={1}
      padding={padding}
      labels={_data.labels}
      datasets={_data.datasets}
    />
  ) : (
    <div className="w-100 text-center" style={{ lineHeight: '7' }}>
      <span>Sem dados para o período selecionado</span>
    </div>
  );
};
