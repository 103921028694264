import moment from "moment";
import React from "react";
import {
  Button,
  Col,
  Glyphicon,
  Grid,
  Modal,
  Row,
  Breadcrumb,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { billingActions } from "../../_actions/billingActions";
import { propertyActions } from "../../_actions/propertyActions";
import MarkAsPaidForm from "./Forms/MarkAsPaidForm";
import NewBoletoForm from "./Forms/NewBoletoForm";
import { KPI } from "../../_components/KPI";
import { DonutChartKPI } from "../../_components/ChartKPI";
import TogglePanel from "../../_components/TogglePanel";
import { SimplePanel } from "../../_components/SimplePanel";
import { PanelWithTitle } from "../../_components/PanelWithTitle";
import {
  toCurrenry,
  zeroPad,
  getPaymentStatusFromBilling
} from "../../_helpers/utils";
import { PropertyViewDialog } from "./Dialogs/PropertyViewDialog";
import { rentedPropertyActions } from "../../_actions/rentedPropertyActions";
import PageTitle from "../../_components/Shared/PageTitle";
import LoadingMessage from "../../_components/Shared/LoadingMessage";

export class BillingPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panelOpen: false,
      eventkey: void 0,
      boletoModalOpen: false,
      markAsPaidModalOpen: false
    };
  }
  componentDidMount() {
    const { dispatch, currentDateSummary } = this.props;
    dispatch(propertyActions.getSummary(currentDateSummary, 0));
    dispatch(billingActions.findAllPayments());
  }

  toogleOpenPanel = index => {
    this.setState({ panelOpen: !this.state.panelOpen, eventkey: index });
  };

  pendingActionsFormatter = (cellContent, row) => {
    return (
      <div style={{ display: "inline-flex" }}>
        <Button
          bsSize="sm"
          className="btn-primary-color"
          bsStyle={"primary"}
          onClick={() =>
            this.props.dispatch(billingActions.showNewBoletoModal(row.id))
          }
        >
          Solicitar Novo Boleto
        </Button>
        <Button
          bsSize="sm"
          bsStyle={"default"}
          onClick={() =>
            this.props.dispatch(billingActions.showMarkAsPaidModal(row.id))
          }
        >
          Marcar como Recebido
        </Button>
      </div>
    );
  };
  handleHide = () => {
    this.props.dispatch(propertyActions.hideModals());
  };
  handleMonthSummaryChange = e => {
    const { dispatch, currentDateSelected } = this.props;
    let key = e.target.attributes.eventkey.value;
    switch (key) {
      case "prev":
        dispatch(propertyActions.getSummary(currentDateSelected, -1));
        break;
      case "next":
        dispatch(propertyActions.getSummary(currentDateSelected, 1));
        break;
      default:
        break;
    }
  };
  handleModalHideRequest = () => {
    this.props.dispatch(rentedPropertyActions.hideModals());
  };
  onMarkAsPaidSubmit = e => {
    e.preventDefault();
    let newPayment = this.props.markAsPaidForm.values.payment;
    let oldPayment = this.props.selectedPayment;
    this.props.dispatch(
      propertyActions.updatePayment(newPayment, oldPayment, true)
    );
  };
  onNewBoletoSubmit = e => {
    e.preventDefault();
    const { addings, discounts, _payment } = this.props.newBoletoForm.values;
    let newPaymentTransactions = [...discounts, ...addings];
    _payment.transactions = newPaymentTransactions;
    this.props.dispatch(
      propertyActions.updatePayment(
        this.props.newBoletoForm.values._payment,
        this.props.selectedPayment,
        false
      )
    );
  };
  handleAddition = event => {
    console.info(event);
  };
  // handlePropertyBillingStateChange = values => {
  //   const { dispatch, selectedProperty } = this.props;
  //   dispatch(
  //     billingActions.changeContractBillingType(
  //       values.payment_method,
  //       values.id,
  //       selectedProperty.id
  //     )
  //   );
  // };
  handleTransactionRemoval = ({ transactionId, paymentId }) => {
    this.props.dispatch(
      propertyActions.deleteTransaction(transactionId, paymentId)
    );
  };
  shouldRenderEmpty = () => {
    return this.props.payments && this.props.payments.length === 0;
  };
  handleDiscount = event => {
    console.info(event);
  };
  render() {
    const {
      summaryData,
      currentDateSelected,
      selectedPayment,
      payments,
      propertyViewModalOpen,
      selectedProperty
    } = this.props;
    const commonColumns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true
      },
      {
        dataField: "status",
        text: "Status",
        formatter: getPaymentStatusFromBilling
      },
      {
        dataField: "df2",
        isDummyField: true,
        text: "",
        formatter: (cellContent, row) => {
          return (
            <table>
              <tbody>
                <tr>
                  <td style={{ paddingRight: "1rem" }}>Valor:</td>
                  <td>
                    {toCurrenry(row.total_amount_in_cents / 100)}
                    {row.is_completed}
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingRight: "1rem" }}>Dia Vencimento:</td>
                  <td>{moment(row.due_date).format("DD/MM/YY")}</td>
                </tr>
                <tr>
                  <td style={{ paddingRight: "1rem" }}>Forma Pgto:</td>
                  <td>{row.payment_method.label}</td>
                </tr>
              </tbody>
            </table>
          );
        }
      },
      {
        dataField: "address",
        isDummyField: false,
        text: "Imóvel",
        formatter: (cellContent, row) => {
          return (
            row.contract &&
            row.contract.property && (
              <div>
                <span className="w-100">
                  <div
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                    onClick={() =>
                      this.props.dispatch(
                        billingActions.showPropertyViewModal(
                          row.contract.property.id
                        )
                      )
                    }
                  >
                    <span className="primary-color-variation">
                      {zeroPad(row.contract.property.code)}{" "}
                    </span>

                    <small style={{ textAlign: "center" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;ref: {row.contract.property.id}
                    </small>
                  </div>
                </span>
                <table>
                  <tbody>
                    {row._property_address && (
                      <tr>
                        <td style={{ paddingRight: "1rem" }}>Endereço:</td>
                        <td>{row._property_address}</td>
                      </tr>
                    )}
                    {row.contract.tenant && (
                      <tr>
                        <td style={{ paddingRight: "1rem" }}>Inquilino:</td>
                        <td>{row.contract.tenant.full_name}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )
          );
        }
      }
    ];
    const delayedPaymentColumns = [
      ...commonColumns,
      {
        dataField: "actions",
        text: "Ações",
        isDummyField: true,
        formatter: (cellContent, payment) => {
          return (
            <div>
              <Button
                bsSize="sm"
                className="btn-primary-color"
                bsStyle={"primary"}
                onClick={() =>
                  this.props.dispatch(
                    billingActions.showNewBoletoModal(payment.id)
                  )
                }
              >
                Solicitar Novo Boleto
              </Button>
              <Button
                bsSize="sm"
                bsStyle={"default"}
                onClick={() =>
                  this.props.dispatch(
                    billingActions.showMarkAsPaidModal(payment.id)
                  )
                }
              >
                Marcar como Recebido
              </Button>
            </div>
          );
        }
      }
    ];
    const receivedPaymentColumns = [...commonColumns];
    const pendingPaymentColumns = [
      ...commonColumns,
      {
        dataField: "actions",
        text: "Ações",
        isDummyField: true,
        formatter: this.pendingActionsFormatter
      }
    ];
    const BoletoModal = (
      <Modal
        bsSize="lg"
        show={this.props.boletoModalOpen}
        container={this}
        onHide={this.handleHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Solicitar Novo Boleto{" "}
            {selectedPayment && selectedPayment.contract && (
              <Link
                className="primary-color-variation"
                to={"/properties/edit/" + selectedPayment.contract.property.id}
              >
                {zeroPad(selectedPayment.contract.property.code)}
              </Link>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewBoletoForm
            handleSubmit={this.onNewBoletoSubmit}
            onCancel={this.handleHide}
            onAddition={this.handleAddition}
            onTrasactionDeleteRequest={this.handleTransactionRemoval}
            payment={selectedPayment}
            onDiscount={this.handleDiscount}
          />
        </Modal.Body>
      </Modal>
    );

    const MarkAsPaidModal = (
      <Modal
        show={this.props.markAsPaidModalOpen}
        onHide={this.handleHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Marcar como Recebido{" "}
            {selectedPayment && selectedPayment.contract && (
              <Link
                className="primary-color-variation"
                to={"/properties/edit/" + selectedPayment.contract.property.id}
              >
                {zeroPad(selectedPayment.contract.property.code)}
              </Link>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MarkAsPaidForm
            onCancel={this.handleHide}
            payment={selectedPayment}
            handleSubmit={this.onMarkAsPaidSubmit}
          />
        </Modal.Body>
      </Modal>
    );
    const getPayments = (value, payments, date) => {
      if (payments) {
        return payments.filter(p => p.status && p.status.value === value);
      } else {
        return null;
      }
    };
    const getOverduePayments = getPayments(
      "overdue",
      payments,
      currentDateSelected
    );
    const getPendingPayments = getPayments(
      "pending",
      payments,
      currentDateSelected
    );
    const getCompletedPayments = getPayments(
      "completed",
      payments,
      currentDateSelected
    );

    let panels = [
      {
        thinBody: true,
        body: (
          <div>
            <PageTitle>Cobranças</PageTitle>
          </div>
        )
      },
      {
        thinBody: true,
        body: (
          <Breadcrumb>
            <Breadcrumb.Item href="/dashboard">Meus Imóveis</Breadcrumb.Item>
            <Breadcrumb.Item active href="/billings">
              Cobranças
            </Breadcrumb.Item>
          </Breadcrumb>
        )
      },
      {
        thinBody: true,
        hidden: this.props.shouldRenderEmpty,
        body: (
          <Col
            md={6}
            mdPush={3}
            style={{ marginTop: "10rem" }}
            className=" animated fadeInDown"
          >
            <PanelWithTitle
              iconPath={"/images/icon_money_bag.svg"}
              to={"/properties/new"}
              subtitle={"Comece a receber com controle agora"}
              callToAction={"Gerar Cobrança"}
              callToActionSize={"small"}
              title={"Cobranças"}
              body={"Você ainda não cadastrou nenhuma cobrança"}
            />
          </Col>
        )
      },
      {
        hidden: !this.props.shouldRenderEmpty,
        body: (
          <div>
            <Col md={3} sm={12}>
              <KPI
                title={"Total Mensal"}
                value={
                  summaryData && summaryData.completed_amount_in_cents
                    ? toCurrenry(summaryData.completed_amount_in_cents / 100)
                    : toCurrenry(0)
                }
                subtitle={"Recebido"}
                color={"#2DEF56"}
              />
            </Col>
            <Col
              md={5}
              sm={12}
              xsHidden={false}
              smHidden={true}
              mdHidden={true}
              lgHidden={true}
            >
              <DonutChartKPI summaryData={summaryData} padding={{ right: 0 }} />{" "}
            </Col>
            <Col
              md={5}
              sm={12}
              xsHidden={true}
              smHidden={false}
              mdHidden={false}
              lgHidden={false}
            >
              <DonutChartKPI summaryData={summaryData} padding={{ right: 0 }} />{" "}
            </Col>
            <Col md={4} sm={12}>
              <KPI
                title={"Total Previsto"}
                value={
                  summaryData && summaryData.total_amount_in_cents
                    ? toCurrenry(summaryData.total_amount_in_cents / 100)
                    : toCurrenry(0)
                }
                subtitle={`Mensais de ${
                  summaryData ? summaryData.properties_count : 0
                } imóveis`}
                color={"#5EC7CE"}
              />
            </Col>
          </div>
        ),
        toogleable: false,
        title: "Resumo das cobranças",
        customComponent: (
          <div style={{ minWidth: "100px" }} className="title">
            {" "}
            <Glyphicon
              eventkey={"prev"}
              onClick={this.handleMonthSummaryChange}
              style={{ marginRight: "2rem", cursor: "pointer" }}
              glyph="glyphicon glyphicon-menu-left"
            />{" "}
            <span>
              {currentDateSelected &&
                moment.monthsShort()[currentDateSelected.month()]}
              /{currentDateSelected && currentDateSelected.year()}
            </span>{" "}
            <Glyphicon
              eventkey={"next"}
              onClick={this.handleMonthSummaryChange}
              style={{ marginLeft: "2rem", cursor: "pointer" }}
              glyph="glyphicon glyphicon-menu-right"
            />
          </div>
        )
      },
      {
        hidden: !this.props.shouldRenderEmpty,
        body: (
          <div style={{ overflowX: "auto" }}>
            <BootstrapTable
              striped
              condensed
              bordered={false}
              keyField="id"
              noDataIndication="Sem dados"
              data={payments ? getOverduePayments : []}
              columns={delayedPaymentColumns}
              headerClasses={"content-table"}
              rowClasses={"content-table"}
            />
          </div>
        ),
        title2:
          "Número de pagamentos: " + (payments ? getOverduePayments.length : 0),
        initialValues: { amount: 3 },
        title: "Atrasados",
        toogleable: true
      },
      {
        hidden: !this.props.shouldRenderEmpty,
        body: (
          <div style={{ overflowX: "auto" }}>
            <BootstrapTable
              striped
              condensed
              bordered={false}
              keyField="id"
              noDataIndication="Sem dados"
              data={payments ? getPendingPayments : []}
              columns={pendingPaymentColumns}
              headerClasses={"content-table"}
              rowClasses={"content-table"}
            />
          </div>
        ),
        title2:
          "Número de pagamentos: " + (payments ? getPendingPayments.length : 0),
        initialValues: { amount: 8 },
        title: "A Receber",
        toogleable: true
      },
      {
        hidden: !this.props.shouldRenderEmpty,
        body: (
          <div style={{ overflowX: "auto" }}>
            <BootstrapTable
              striped
              condensed
              bordered={false}
              keyField="id"
              noDataIndication="Sem dados"
              data={payments ? getCompletedPayments : []}
              columns={receivedPaymentColumns}
              headerClasses={"content-table"}
              rowClasses={"content-table"}
            />
          </div>
        ),
        title2:
          "Número de pagamentos: " +
          (payments ? getCompletedPayments.length : 0),
        initialValues: { amount: 8 },
        title: "Recebidos",
        toogleable: true
      }
    ];
    return (
      <div>
        <Grid fluid={true}>
          {selectedProperty && (
            <PropertyViewDialog
              onHide={this.handleModalHideRequest}
              container={this}
              loading={this.props.billingChangeInProgress}
              // handleSubmit={this.handlePropertyBillingStateChange}
              property={selectedProperty}
              show={propertyViewModalOpen}
            />
          )}
          {selectedPayment && selectedPayment.contract && BoletoModal}
          {selectedPayment && MarkAsPaidModal}
          <Row>
            <Col md={12} className="main animated fadeInDown">
              <Row>
                {this.props.loading ? (
                  <SimplePanel>
                    <LoadingMessage title="Carregando Pagamentos..." />
                  </SimplePanel>
                ) : (
                  panels.map((panel, index) => {
                    return (
                      !panel.hidden && (
                        <TogglePanel
                          key={index}
                          title2={panel.title2}
                          body={panel.body}
                          eventkey={index}
                          form={this.props.form}
                          handleValidationChange={
                            this.handlePanelValidationChange
                          }
                          initialValues={panel.initialValues}
                          isExpanded={
                            this.state.panelOpen &&
                            this.state.eventkey === index
                          }
                          onBackButtonClick={this.goToPreviousPanel}
                          onSubmit={this.goToNextPanel}
                          showNextButton
                          thinBody={panel.thinBody}
                          title={panel.title}
                          toogleOpenPanel={() => this.toogleOpenPanel(index)}
                          validate={this.props.validate}
                          toogleable={panel.toogleable}
                          size={panel.size}
                          customComponent={panel.customComponent}
                        />
                      )
                    );
                  })
                )}
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { markAsPaidForm, newBoletoForm } = state.form;
  return {
    summaryData: state.properties.summaryData,
    selectedPayment: state.payments.selectedPayment,
    boletoModalOpen: state.payments.boletoModalOpen,
    markAsPaidModalOpen: state.payments.markAsPaidModalOpen,
    payments: state.payments.items,
    currentDateSelected: state.properties.currentDateSummary || moment(),
    markAsPaidForm,
    loading: state.payments.loading,
    newBoletoForm,
    billingChangeInProgress: state.properties.billingChangeInProgress,
    selectedProperty: state.properties.selectedProperty || {},
    propertyViewModalOpen: state.properties.propertyViewModalOpen,
    shouldRenderEmpty: state.payments.items && state.payments.items.length !== 0
  };
};

export default connect(mapStateToProps)(BillingPageContainer);
