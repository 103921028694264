import React from "react";
import { connect } from "react-redux";
import { Col, Grid, Image, Panel, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { userActions } from "../../_actions/userActions";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import GoogleLogin from "react-google-login";
import LoadingMessage from "../../_components/Shared/LoadingMessage";
import LoginForm from "./components/LoginForm";
// import { Button } from "react-md";

export class LoginContainer extends React.Component {
  componentDidMount() {
    document.body.classList.add("guest-user");
  }

  submit = values => {
    const { dispatch } = this.props;
    dispatch(userActions.login(values.email, values.password));
  };

  responseFacebook = response => {
    const { dispatch } = this.props;
    dispatch(userActions.facebookLogin(response));
  };

  responseGoogle = response => {
    const { dispatch } = this.props;
    dispatch(userActions.googleLogin(response));
  };

  render() {
    return (
      <Grid className="login-area">
        <Row>
          <Col sm={6} smOffset={3} xs={12}>
            <Panel>
              <Panel.Body className="panel-body__login">
                <Row>
                  <Col sm={8} smOffset={2} xs={10} xsOffset={1}>
                    <Row>
                      <Col className="text-center">
                        <Image src="/images/logo.png" alt="logo" />
                      </Col>
                    </Row>
                    <Row className="padding-top-30">
                      <Col md={6} xs={12} className="no-padding">
                        {/* <FacebookLogin
                          appId="273877160103707"
                          fields="name,email,picture"
                          callback={this.responseFacebook}
                          render={renderProps => (
                            <Button
                              bsStyle="primary"
                              className="reset-google-btn btn-default btn-block btn-primary__facebook"
                              onClick={renderProps.onClick}
                              // className="btn-block btn-primary__facebook"
                            >
                              Entrar com Facebook
                            </Button>
                          )}
                        /> */}
                      </Col>
                      <Col md={6} xs={12} className="no-padding">
                        {/* <GoogleLogin
                          clientId="780640256949-eohbceej8t05r14gk5iqqadsmr6r69k3.apps.googleusercontent.com"
                          buttonText="Entrar com Google"
                          className="reset-google-btn btn-default btn-block btn-primary__google btn btn-default"
                          onSuccess={this.responseGoogle}
                        /> */}
                      </Col>
                    </Row>
                    <Row className="padding-top-30" hidden>
                      <Col xs={12} className="text-center background-line">
                        <span>OU</span>
                      </Col>
                    </Row>
                    {this.props.loading ? (
                      <LoadingMessage title="Autenticando" />
                    ) : (
                      <LoginForm onSubmit={this.submit} {...this.props} />
                    )}
                    <Row>
                      <Col xs={6} xsOffset={3} className="text-center">
                        <hr />
                      </Col>
                    </Row>
                    <Row className="padding-top-5">
                      <Col className="text-center">
                        <p>
                          Novo por aqui? <Link to="/signup">Cadastre-se!</Link>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Panel.Body>
            </Panel>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export const mapStateToProps = state => {
  return {
    loading: state.authentication.loading
  };
};

export default connect(mapStateToProps)(LoginContainer);
