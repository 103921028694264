import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import { change, Field, arrayRemove, arrayPush } from 'redux-form';
import { TextField } from '../../FormFields';
import { formatPhone } from '../../../_helpers/reduxFormFormatters';
import { normalizeNumericValue } from '../../../_helpers/reduxFormNormalizers';

export class ListingOwnerData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      btnBaseClass: 'btn-primary btn-primary__person-type',
      personType: 'individual',
      action: props.selectedAction
    };

   
  }

  // componentDidMount(){
  //   this.props.dispatch(
  //     change(
  //       this.props.form,
  //       'new_user_attributes.person_type',
  //       this.state.personType
  //     )
  //   );
  // }

  selectPersonType(type) {
    this.setState({ personType: type });
    this.props.dispatch(
      change(this.props.form, 'new_user_attributes.person_type', type)
    );
  }

  selectActionType(type) {
    this.setState({ action: type });
    this.props.dispatch(
      change(this.props.form, 'new_user_attributes.action', type)
    );
  }

  renderUserLogin = () => {
    return (
      <div>
        <Row>
          <Col sm={6}>
            <Field
              id="email"
              component={TextField}
              controlClassName="form-control__white"
              label="Email"
              labelClassName="label__grey"
              name="new_user_attributes.email"
              type="text"
            />
          </Col>
          <Col sm={6}>
            <Field
              id="password"
              component={TextField}
              controlClassName="form-control__white"
              label="Senha"
              labelClassName="label__grey"
              name="new_user_attributes.password"
              type="password"
            />
          </Col>
        </Row>
        <Row>
          <p className="text_small">
            Novo por aqui?{' '}
            <span onClick={() => this.selectActionType('create_account')}>
              Cadastre-se!
            </span>
          </p>
        </Row>
      </div>
    );
  };

  renderUserRegister = () => {
    return (
      <div>
        <Row>
          <Col sm={12}>
            <Button
              className={
                this.state.personType === 'individual'
                  ? this.state.btnBaseClass + ' active'
                  : this.state.btnBaseClass
              }
              onClick={() => this.selectPersonType('individual')}
            >
              Pessoa Física
            </Button>
            <Button
              className={
                this.state.personType === 'company'
                  ? this.state.btnBaseClass + ' active'
                  : this.state.btnBaseClass
              }
              onClick={() => this.selectPersonType('company')}
            >
              Pessoa Jurídica
            </Button>
          </Col>
        </Row>
        {this.state.personType === 'individual'
          ? this.renderIndividualFields()
          : this.renderCompanyFields()}
        <Row>
          <p className="text_small">
            Já tem uma conta?{' '}
            <span onClick={() => this.selectActionType('login')}>
              Faça seu login.
            </span>
          </p>
        </Row>
      </div>
    );
  };

  renderIndividualFields = () => {
    return (
      <div>
        <Row>
          <Col sm={6}>
            <Field
              id="full_name"
              component={TextField}
              controlClassName="form-control__white"
              label="Nome completo"
              labelClassName="label__grey"
              name="new_user_attributes.full_name"
            />
          </Col>
          <Col sm={6}>
            <Field
              id="phone"
              component={TextField}
              controlClassName="form-control__white"
              format={formatPhone}
              label="Telefone"
              labelClassName="label__grey"
              name="new_user_attributes.phone"
              normalize={normalizeNumericValue}
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              id="email"
              component={TextField}
              controlClassName="form-control__white"
              label="Email"
              labelClassName="label__grey"
              name="new_user_attributes.email"
              type="text"
            />
          </Col>
          <Col sm={6}>
            <Field
              id="email_confirmation"
              component={TextField}
              controlClassName="form-control__white"
              label="Confirme seu email"
              labelClassName="label__grey"
              name="new_user_attributes.email_confirmation"
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              id="password"
              component={TextField}
              controlClassName="form-control__white"
              label="Senha"
              labelClassName="label__grey"
              name="new_user_attributes.password"
              type="password"
            />
          </Col>
          <Col sm={6}>
            <Field
              id="password_confirmation"
              component={TextField}
              controlClassName="form-control__white"
              label="Confirme sua senha"
              labelClassName="label__grey"
              name="new_user_attributes.password_confirmation"
              type="password"
            />
          </Col>
        </Row>
      </div>
    );
  };

  renderCompanyFields = () => {
    return (
      <div>
        <Row>
          <Col sm={12}>
            <Field
              id="new_user_attributes.company_name"
              component={TextField}
              controlClassName="form-control__white"
              label="Razão Social"
              labelClassName="label__grey"
              name="new_user_attributes.company_name"
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              id="full_name"
              component={TextField}
              controlClassName="form-control__white"
              label="Nome do Responsável"
              labelClassName="label__grey"
              name="new_user_attributes.full_name"
            />
          </Col>
          <Col sm={6}>
            <Field
              id="phone"
              component={TextField}
              controlClassName="form-control__white"
              format={formatPhone}
              label="Telefone"
              labelClassName="label__grey"
              name="new_user_attributes.phone"
              normalize={normalizeNumericValue}
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              id="email"
              component={TextField}
              controlClassName="form-control__white"
              label="Email"
              labelClassName="label__grey"
              name="new_user_attributes.email"
              type="text"
            />
          </Col>
          <Col sm={6}>
            <Field
              id="email_confirmation"
              component={TextField}
              controlClassName="form-control__white"
              label="Confirme seu email"
              labelClassName="label__grey"
              name="new_user_attributes.email_confirmation"
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              id="password"
              component={TextField}
              controlClassName="form-control__white"
              label="Senha"
              labelClassName="label__grey"
              name="new_user_attributes.password"
              type="password"
            />
          </Col>
          <Col sm={6}>
            <Field
              id="password_confirmation"
              component={TextField}
              controlClassName="form-control__white"
              label="Confirme sua senha"
              labelClassName="label__grey"
              name="new_user_attributes.password_confirmation"
              type="password"
            />
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div className="owner-panel">
        {!this.state.action && (
          <Row>
            <Col sm={12} className="text-center">
              <Button
                className="account-selector-button"
                onClick={() => this.selectActionType('login')}
              >
                Eu já tenho uma conta
              </Button>
            </Col>
            <Col sm={12} className="text-center">
              <Button
                className="account-selector-button"
                onClick={() => this.selectActionType('create_account')}
              >
                Criar uma nova conta
              </Button>
            </Col>
            <Row>
              <Col sm={12} className="text-center">
                <Field
                  id="action"
                  component={TextField}
                  name="new_user_attributes.action"
                  type="text"
                  style={{ display: 'none' }}
                />
              </Col>
            </Row>
          </Row>
        )}

        {this.state.action === 'login' && this.renderUserLogin()}

        {this.state.action === 'create_account' && this.renderUserRegister()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  arrayPush: (formName, attribute, value) =>
    dispatch(arrayPush(formName, attribute, value)),
  changeFormField: (formName, attribute, value) =>
    dispatch(change(formName, attribute, value)),
  formArrayRemove: (formName, attribute, index) =>
    dispatch(arrayRemove(formName, attribute, index))
});
export default connect(
  null,
  mapDispatchToProps
)(ListingOwnerData);
