import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { reducer as formReducer } from "redux-form";
import alert from "../_reducers/alert";
import authentication from "../_reducers/authentication";
import registration from "../_reducers/registration";
import properties from "../_reducers/properties";
import bankAccounts from "../_reducers/bankAccounts";
import landlords from "../_reducers/landlords";
import payments from "../_reducers/payments";
import { createLogger } from "redux-logger";
import { alertActions } from "../_actions/alertActions";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const FetchInterceptor = require("fetch-interceptor");
const reduxLogger = createLogger({
  diff: true,
  collapsed: true
});
// eslint-disable-next-line
const interceptor = FetchInterceptor.register({
  onBeforeRequest(request, controller) {
    // Hook before request
  },
  onRequestSuccess(response, request, controller) {
    // Hook on response success
  },
  onRequestFailure(response, request, controller) {
    // Hook on response failure
    if (response.status === 401 || response.status === 403) {
      alertActions.error("Acesso Negado");
      // userActions.logout();
    }
  }
});

export default () => {
  const rootReducer = combineReducers({
    alert,
    authentication,
    bankAccounts,
    landlords,
    registration,
    properties,
    // listings,
    payments,
    form: formReducer
  });

  const buildStore = () => {
    if (process.env.NODE_ENV !== "production") {
      if (module.hot) {
        return createStore(
          rootReducer,
          composeEnhancers(applyMiddleware(thunk, reduxLogger))
        );
      }
    } else {
      return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
    }
  };

  const store = buildStore();

  return store;
};
