import { landlordConstants } from '../_constants/landlordConstants';
import { landlordService } from '../_services/landlordService';

export const landlordActions = {
  getAll
};

function getAll() {
  return dispatch => {
    dispatch(request());
    landlordService
      .getAll()
      .then(
        response => dispatch(success(response.data)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return { type: landlordConstants.GETALL_REQUEST };
  }
  function success(landlords) {
    return { type: landlordConstants.GETALL_SUCCESS, landlords };
  }
  function failure(error) {
    return { type: landlordConstants.GETALL_FAILURE, error };
  }
}
