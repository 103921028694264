import React from 'react';
import { Button, Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { change, Field, FieldArray, arrayPush, arrayRemove } from 'redux-form';
import {
  FileUploadField,
  TextField,
  GenderRadioGroup,
  DropZoneField
} from '../../FormFields';
import {
  formatCNPJ,
  formatCPF,
  formatPhone
} from '../../../_helpers/reduxFormFormatters';
import { normalizeNumericValue } from '../../../_helpers/reduxFormNormalizers';
export const validateImage = allValues => {};
class TenantData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      btnBaseClass: 'btn-primary btn-primary__person-type',
      personType: (props.tenant  && props.tenant.person_type && props.tenant.person_type.value)  || 'individual',
      showIdFileBackInput: false,
      idFileCustomButtonAddText: 'Adicionar foto verso',
      idFileCustomButtonRemoveText: 'Remover foto verso'
    };
    this.selectPersonType(this.state.personType)
    this.props.changeFormField(
      this.props.form,
      'active_contract.tenant.gender',
      (props.tenant  && props.tenant.gender && props.tenant.gender.value) ||  'male'
    );
  }

  handleFileUploadSuccess = (file, files, identifier) => {
    this.props.changeFormField(this.props.form, identifier, files);
  };

  handleRemovedFile = (file, files, identifier) => {
    this.props.changeFormField(this.props.form, identifier, files);
  };

  selectPersonType(type) {
    this.setState({ personType: type });
    this.props.changeFormField(
      this.props.form,
      'active_contract.tenant.person_type',
      type
    );
  }

  renderStatements = ({ addButtonLabel, label, fields, meta: { error } }) => (
    <FormGroup className="contract-panel-expenses">
      <ControlLabel className={'label__grey'}>{label}</ControlLabel>
      {fields.map((statement, index) => (
        <Row key={index}>
          <Col sm={3}>
            <Field
              component={TextField}
              controlClassName="form-control__white "
              name={`active_contract.tenant.statements_attributes[${index}].description`}
              placeholder="Descrição"
              showLabel={false}
            />
          </Col>
          <Col sm={9}>
            <FileUploadField
              component={TextField}
              controlClassName="form-control__white file_upload_input"
              deleteCallback={() => fields.remove(index)}
              name={`active_contract.tenant.statements_attributes[${index}].file_url`}
              type="text"
              showLabel={false}
              onFileUploadFieldChange={this.onFileUploadFieldChange}
            />
          </Col>
        </Row>
      ))}
      <Row>
        <Col sm={12}>
          <Button
            className="btn-expense btn-add-expense"
            onClick={() => fields.push({})}
          >
            {addButtonLabel}
          </Button>
        </Col>
      </Row>
    </FormGroup>
  );

  renderIndividualFields = () => {
    return (
      <div>
        <Row>
          <Col md={12}>
            <h4 className="title" style={{ textTransform: 'uppercase' }}>
              {' '}
              Dados do inquilino{' '}
            </h4>
            <hr />
          </Col>
          <Col md={5}>
            <Field
              id="full_name"
              component={TextField}
              controlClassName="form-control__white"
              label="Nome Completo"
              labelClassName="label__grey"
              name="active_contract.tenant.full_name"
              type="text"
            />
          </Col>
          <Col md={3}>
            <GenderRadioGroup name="active_contract.tenant.gender" />
          </Col>
          <Col md={4}>
            <Field
              id="rg"
              component={TextField}
              controlClassName="form-control__white"
              label="CPF"
              labelClassName="label__grey"
              normalize={normalizeNumericValue}
              name="active_contract.tenant.cpf"
              format={formatCPF}
              type="text"
            />
          </Col>
          <Col md={4}>
            <Field
              id="cpf"
              component={TextField}
              controlClassName="form-control__white"
              label="RG"
              labelClassName="label__grey"
              normalize={normalizeNumericValue}
              name="active_contract.tenant.rg"
              type="text"
            />
          </Col>
          <Col md={4}>
            <Field
              id="email"
              component={TextField}
              controlClassName="form-control__white"
              label="Telefone"
              labelClassName="label__grey"
              name="active_contract.tenant.phone"
              normalize={normalizeNumericValue}
              format={formatPhone}
              type="text"
            />
          </Col>
          <Col md={4}>
            <Field
              id="phone"
              component={TextField}
              controlClassName="form-control__white"
              label="E-mail"
              labelClassName="label__grey"
              name="active_contract.tenant.email"
              type="text"
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <h4 className="title" style={{ textTransform: 'uppercase' }}>
              {' '}
              Documentos do inquilino{' '}
            </h4>
            <hr />
          </Col>
          <Col md={6}>
            <Field
              label={
                <span>
                  Fotos do RG - <small>Frente e Verso</small>
                </span>
              }
              showErrorBesideLabel={true}
              name="active_contract.tenant.rg_images_validator"
              onFileRemoved={(file, files) =>
                this.handleRemovedFile(
                  file,
                  files,
                  'active_contract.tenant.rg_images'
                )
              }
              onFileAdded={(file, files) =>
                this.handleFileUploadSuccess(
                  file,
                  files,
                  'active_contract.tenant.rg_images'
                )
              }
              images={this.props.tenant && this.props.tenant.rg_images}
              maxFiles={2}
              component={DropZoneField}
            />
          </Col>
          <Col md={6}>
            <Field
              label={
                <span>
                  Fotos do CPF - <small>Frente e Verso</small>
                </span>
              }
              showErrorBesideLabel={true}
              name="active_contract.tenant.cpf_images_validator"
              onFileRemoved={(file, files) =>
                this.handleRemovedFile(
                  file,
                  files,
                  'active_contract.tenant.cpf_images'
                )
              }
              onFileAdded={(file, files) =>
                this.handleFileUploadSuccess(
                  file,
                  files,
                  'active_contract.tenant.cpf_images'
                )
              }
              images={this.props.tenant && this.props.tenant.cpf_images}
              maxFiles={2}
              component={DropZoneField}
            />
          </Col>

          <Col md={6}>
            <Field
              label={<span>Comprovante de Endereço</span>}
              name="active_contract.tenant.address_images_validator"
              showErrorBesideLabel={true}
              onFileRemoved={(file, files) =>
                this.handleRemovedFile(
                  file,
                  files,
                  'active_contract.tenant.address_images'
                )
              }
              onFileAdded={(file, files) =>
                this.handleFileUploadSuccess(
                  file,
                  files,
                  'active_contract.tenant.address_images'
                )
              }
              images={this.props.tenant && this.props.tenant.address_images}
              maxFiles={4}
              component={DropZoneField}
            />
          </Col>

          <Col md={6}>
            <Field
              label={<span> Comprovante de Renda</span>}
              showErrorBesideLabel={true}
              name="active_contract.tenant.statements_images_validator"
              onFileRemoved={(file, files) =>
                this.handleRemovedFile(
                  file,
                  files,
                  'active_contract.tenant.statements_images'
                )
              }
              onFileAdded={(file, files) =>
                this.handleFileUploadSuccess(
                  file,
                  files,
                  'active_contract.tenant.statements_images'
                )
              }
              images={this.props.tenant && this.props.tenant.statements_images}
              maxFiles={2}
              component={DropZoneField}
            />
          </Col>
          {/* {this.props.values &&
            this.props.values.active_contract &&
            this.props.values.active_contract.rental_guarantee_type ===
              'bond_insurance' && (
              <section>
                <Col md={12}>
                  <h4 className="title" style={{ textTransform: 'uppercase' }}>
                    {' '}
                    Seguro Fiança{' '}
                  </h4>
                  <hr />
                </Col>

                <Col md={3}>
                  <Field
                    id="security_firm"
                    component={TextField}
                    controlClassName="form-control__white"
                    label="Seguradora"
                    labelClassName="label__grey"
                    name="active_contract.tenant.security_firm"
                    type="text"
                  />
                </Col>
                <Col md={3}>
                  <Field
                    id="security_number"
                    component={TextField}
                    controlClassName="form-control__white"
                    label="Número da Apólice"
                    labelClassName="label__grey"
                    name="active_contract.tenant.security_number"
                    type="text"
                  />
                </Col>
                <Col md={6}>
                  <ControlLabel className="label__grey">
                    Documento da Apólice
                  </ControlLabel>
                  <Field
                    onRemovedFile={file =>
                      this.handleRemovedFile(file, 'apolice_images')
                    }
                    onSuccess={(file, response) =>
                      this.handleFileUploadSuccess(
                        file,
                        response,
                        'apolice_images'
                      )
                    }
                    onAddedFile={(file, response) =>
                      this.handleAddedFile(
                        file,
                        response,
                        'active_contract.tenant.apolice_images'
                      )
                    }
                    images={
                      this.props.values.active_contract &&
                      this.props.values.active_contract.tenant &&
                      this.props.values.active_contract.tenant.apolice_images
                    }
                    name="active_contract.tenant.apolice_images"
                    component={PhotosDropzone}
                  />
                </Col>
              </section>
            )} */}
        </Row>
        {/* {this.props.values &&
          this.props.values.active_contract &&
          this.props.values.active_contract.rental_guarantee_type ===
            'deposit' && (
            <section>
              {' '}
              <Row>
                <Col md={12}>
                  <h4 className="title" style={{ textTransform: 'uppercase' }}>
                    Caução{' '}
                  </h4>
                  <hr />
                </Col>

                <Col md={3}>
                  <Field
                    id="deposit_amount"
                    component={TextField}
                    controlClassName="form-control__white"
                    label="Valor"
                    labelClassName="label__grey"
                    name="active_contract.tenant.deposit_amount"
                    normalize={normalizeNumericValue}
                    format={formatReais}
                  />
                </Col>
                <Col md={3}>
                  <Field
                    id="deposit_date"
                    component={TextField}
                    controlClassName="form-control__white"
                    label="Data do Depósito"
                    labelClassName="label__grey"
                    name="active_contract.tenant.deposit_date"
                    type="date"
                  />
                </Col>
                <Col md={6}>
                  <ControlLabel className="label__grey">
                    Comprovante de Depósito
                  </ControlLabel>
                  <Field
                    onRemovedFile={file =>
                      this.handleRemovedFile(file, 'deposit_statements')
                    }
                    onSuccess={(file, response) =>
                      this.handleFileUploadSuccess(
                        file,
                        response,
                        'deposit_statements'
                      )
                    }
                    onAddedFile={(file, response) =>
                      this.handleAddedFile(
                        file,
                        response,
                        'active_contract.tenant.deposit_statements'
                      )
                    }
                    images={
                      this.props.values.active_contract &&
                      this.props.values.active_contract.tenant &&
                      this.props.values.active_contract.tenant
                        .deposit_statements
                    }
                    name="active_contract.tenant.deposit_statements"
                    component={PhotosDropzone}
                  />
                </Col>
              </Row>
            </section>
          )} */}
      </div>
    );
  };

  renderCompanyFields = () => {
    return (
      <div>
        <Row>
          <Col md={12}>
            <h4 className="title" style={{ textTransform: 'uppercase' }}>
              {' '}
              Dados da empresa{' '}
            </h4>
            <hr />
          </Col>
          <Col md={4}>
            <Field
              id="company_name"
              component={TextField}
              controlClassName="form-control__white"
              label="Razão Social"
              labelClassName="label__grey"
              name="active_contract.tenant.company_name"
              type="text"
            />
          </Col>
          <Col md={4}>
            <Field
              id="full_name"
              component={TextField}
              controlClassName="form-control__white"
              label="Nome do Responsável"
              labelClassName="label__grey"
              name="active_contract.tenant.full_name"
              type="text"
            />
          </Col>
          <Col md={4}>
            <Field
              id="cnpj"
              component={TextField}
              controlClassName="form-control__white"
              label="CNPJ"
              labelClassName="label__grey"
              name="active_contract.tenant.cnpj"
              format={formatCNPJ}
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Field
              id="state_inscription"
              component={TextField}
              controlClassName="form-control__white"
              label="Inscrição Estadual"
              labelClassName="label__grey"
              name="active_contract.tenant.state_inscription"
              type="text"
            />
          </Col>
          <Col md={4}>
            <Field
              id="email"
              component={TextField}
              controlClassName="form-control__white"
              label="E-mail"
              labelClassName="label__grey"
              name="active_contract.tenant.email"
              type="text"
            />
          </Col>
          <Col md={3}>
            <Field
              id="phone"
              component={TextField}
              controlClassName="form-control__white"
              label="Telefone"
              labelClassName="label__grey"
              name="active_contract.tenant.phone"
              format={formatPhone}
              normalize={normalizeNumericValue}
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FileUploadField
              id="social_contract_file_url"
              component={TextField}
              controlClassName="form-control__white"
              label="Contrato Social"
              labelClassName="label__grey"
              name="active_contract.tenant.social_contract_file_url"
              type="text"
              onFileUploadFieldChange={this.props.onFileUploadFieldChange}
            />
            {/* <Field
              component={TextField}
              controlClassName="form-control__white"
              labelClassName="label__grey"
              name="social_contract_file_url_validator"
              showLabel={false}
            /> */}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FieldArray
              name="active_contract.tenant.statements"
              component={this.renderStatements}
              addButtonLabel="Adicionar documento"
              label="Documentos dos proprietários da empresa"
            />
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div className="tenant-panel">
        <Row>
          <Col sm={12}>
            <Button
              className={
                this.state.personType === 'individual'
                  ? this.state.btnBaseClass + ' active'
                  : this.state.btnBaseClass
              }
              onClick={() => this.selectPersonType('individual')}
            >
              Pessoa Física
            </Button>
            <Button
              className={
                this.state.personType === 'company'
                  ? this.state.btnBaseClass + ' active'
                  : this.state.btnBaseClass
              }
              onClick={() => this.selectPersonType('company')}
            >
              Pessoa Jurídica
            </Button>
          </Col>
        </Row>
        {this.state.personType === 'individual'
          ? this.renderIndividualFields()
          : this.renderCompanyFields()}
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  arrayPush: (formName, attribute, value) =>
    dispatch(arrayPush(formName, attribute, value)),
  changeFormField: (formName, attribute, value) =>
    dispatch(change(formName, attribute, value)),
  formArrayRemove: (formName, attribute, index) =>
    dispatch(arrayRemove(formName, attribute, index))
});

const mapStateToProps = (state, ownProps) => ({
  initialValues: ownProps.tenant
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TenantData);
