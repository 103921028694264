export const paymentConstants = {
  UPDATE_CONTRACT_BILLING_REQUEST: 'UPDATE_CONTRACT_BILLING_REQUEST',
  UPDATE_CONTRACT_BILLING_SUCCESS: 'UPDATE_CONTRACT_BILLING_SUCCESS',
  UPDATE_CONTRACT_BILLING_ERROR: 'UPDATE_CONTRACT_BILLING_ERROR',

  GET_BILLING_SUMMARY_REQUEST: 'GET_BILLING_SUMMARY_REQUEST',
  GET_SUMMARY_SUCCESS: 'GET_SUMMARY_SUCCESS',
  GET_SUMMARY_FAILURE: 'GET_SUMMARY_FAILURE',

  UPDATE_PAYMENT_REQUEST: 'UPDATE_PAYMENT_REQUEST',
  UPDATE_PAYMENT_SUCCESS: 'UPDATE_PAYMENT_SUCCESS',
  UPDATE_PAYMENT_ERROR: 'UPDATE_PAYMENT_ERROR',

  GETALL_PAYMENT_REQUEST: 'GETALL_PAYMENT_REQUEST',
  GETALL_PAYMENT_SUCCESS: 'GETALL_PAYMENT_SUCCESS',
  GETALL_PAYMENT_FAILURE: 'GETALL_PAYMENT_FAILURE',

  CREATE_TRANSACTION_REQUEST: 'CREATE_TRANSACTION_REQUEST',
  CREATE_TRANSACTION_SUCCESS: 'CREATE_TRANSACTION_SUCCESS',
  CREATE_TRANSACTION_ERROR: 'CREATE_TRANSACTION_ERROR',

  UPDATE_TRANSACTION_REQUEST: 'UPDATE_TRANSACTION_REQUEST',
  UPDATE_TRANSACTION_SUCCESS: 'UPDATE_TRANSACTION_SUCCESS',
  UPDATE_TRANSACTION_ERROR: 'UPDATE_TRANSACTION_ERROR',

  DELETE_TRANSACTION_REQUEST: 'DELETE_TRANSACTION_REQUEST',
  DELETE_TRANSACTION_SUCCESS: 'DELETE_TRANSACTION_SUCCESS',
  DELETE_TRANSACTION_ERROR: 'DELETE_TRANSACTION_ERROR',

  NEW_BOLETO_NOTIFICATION_REQUEST: 'NEW_BOLETO_NOTIFICATION_REQUEST',
  NEW_BOLETO_NOTIFICATION_SUCCESS: 'NEW_BOLETO_NOTIFICATION_SUCCESS',
  NEW_BOLETO_NOTIFICATION_ERROR: 'NEW_BOLETO_NOTIFICATION_ERROR'
};
