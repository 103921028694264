export const billingConstants = {
  NEW_BOLETO_DIALOG: 'NEW_BOLETO_DIALOG',
  SHOW_SHARING_DIALOG: 'SHOW_SHARING_DIALOG',
  SHOW_NEW_BOLETO_DIALOG: 'SHOW_NEW_BOLETO_DIALOG',
  SHOW_MARK_AS_PAID_DIALOG: 'SHOW_MARK_AS_PAID_DIALOG',
  NEW_BOLETO_FAILURE: 'NEW_BOLETO_FAILURE',
  SHOW_PROPERTY_VIEW_DIALOG: 'SHOW_PROPERTY_VIEW_DIALOG',
  PROPERTY_APPROVAL_DIALOG_SUCCESS: 'PROPERTY_APPROVAL_DIALOG_SUCCESS',
  PROPERTY_APPROVAL_DIALOG_ERROR: 'PROPERTY_APPROVAL_DIALOG_ERROR',
  PROPERTY_PAYMENT_APPROVAL_DIALOG_SUCCESS:
    'PROPERTY_PAYMENT_APPROVAL_DIALOG_SUCCESS',
  PROPERTY_PAYMENT_APPROVAL_DIALOG_ERROR:
    'PROPERTY_PAYMENT_APPROVAL_DIALOG_ERROR'
};
