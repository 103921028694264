import React from "react";
import { connect } from "react-redux";
import { propertyActions } from "../../_actions/propertyActions";
import TogglePanel from "../../_components/TogglePanel";
import { PanelWithTitle } from "../../_components/PanelWithTitle";
import BootstrapTable from "react-bootstrap-table-next";
import { Col, Grid, Row, Breadcrumb } from "react-bootstrap";
import { toCurrenry, getPaymentStatusFromProperty } from "../../_helpers/utils";
import { formatPhone } from "../../_helpers/reduxFormFormatters";
import PageTitle from "../../_components/Shared/PageTitle";

class ContractsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panelOpen: false,
      eventkey: void 0
    };
  }
  componentWillMount() {
    this.props.dispatch(propertyActions.findAll());
  }
  toogleOpenPanel = index => {
    this.setState({ panelOpen: !this.state.panelOpen, eventkey: index });
  };

  handleHide = () => {
    this.props.dispatch(propertyActions.hideModals());
  };
  handleMonthSummaryChange = e => {
    const { dispatch, currentDateSelected } = this.props;
    let key = e.target.attributes.eventkey.value;
    switch (key) {
      case "prev":
        dispatch(propertyActions.getSummary(currentDateSelected, -1));
        break;
      case "next":
        dispatch(propertyActions.getSummary(currentDateSelected, 1));
        break;
      default:
        break;
    }
  };

  shouldRenderEmpty = () => {
    return this.props.contracts && this.props.contracts.length === 0;
  };

  render() {
    const delayedPaymentColumns = [
      {
        dataField: "tenant.full_name",
        text: "Nome"
      },
      {
        dataField: "tenant.email",
        text: "Email"
      },
      {
        dataField: "tenant.phone",
        text: "Telefone",
        formatter: (cell, row) => {
          return formatPhone(cell);
        }
      },
      {
        dataField: "payment_status",
        text: "Status Último Pagamento",
        formatter: getPaymentStatusFromProperty
      },
      {
        dataField: "duration_in_months",
        text: "Duração Contrato (meses)"
      },
      {
        dataField: "total_price_in_cents",
        text: "Total do Contrato",
        formatter: (cell, row) => {
          return toCurrenry(cell);
        }
      }
    ];
    let panels = [
      {
        thinBody: true,
        hidden: false,
        body: (
          <div>
            <PageTitle>Meus Contratos</PageTitle>
            <Breadcrumb>
              <Breadcrumb.Item href="/contracts" active>
                Meus Contratos
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        )
      },
      {
        thinBody: true,
        hidden: this.shouldRenderEmpty,
        body: (
          <Col
            md={6}
            mdPush={3}
            style={{ marginTop: "10rem" }}
            className=" animated fadeInDown"
          >
            <PanelWithTitle
              iconPath={"/images/si-glyph-document-bullet-list.svg"}
              to={"/contracts/new"}
              subtitle={"Comece a receber com controle agora"}
              callToAction={"Criar Contrato"}
              callToActionSize={"small"}
              title={"Contratos"}
              body={"Você ainda não cadastrou nenhum contrato"}
            />
          </Col>
        )
      },
      {
        hidden: !this.shouldRenderEmpty,
        body: (
          <div name="contractsWrapper" style={{ overflowX: "auto" }}>
            <BootstrapTable
              striped
              condensed
              bordered={false}
              keyField="id"
              noDataIndication="Sem dados"
              data={this.props.contracts}
              columns={delayedPaymentColumns}
              headerClasses={"content-table"}
              rowClasses={"content-table"}
            />
          </div>
        )
      }
    ];
    return (
      <div>
        <Grid fluid={true}>
          {/* <Header isAuthenticated className="container-full" /> */}
          <Row>
            {/* <SideNav /> */}
            <Col md={12} className="main animated fadeInDown">
              <Row>
                {panels.map((panel, index) => {
                  return (
                    !panel.hidden && (
                      <TogglePanel
                        key={index}
                        title2={panel.title2}
                        body={panel.body}
                        eventkey={index}
                        form={this.props.form}
                        handleValidationChange={
                          this.handlePanelValidationChange
                        }
                        initialValues={panel.initialValues}
                        isExpanded={
                          this.state.panelOpen && this.state.eventkey === index
                        }
                        onBackButtonClick={this.goToPreviousPanel}
                        onSubmit={this.goToNextPanel}
                        showNextButton
                        thinBody={panel.thinBody}
                        title={panel.title}
                        toogleOpenPanel={() => this.toogleOpenPanel(index)}
                        validate={this.props.validate}
                        toogleable={panel.toogleable}
                        size={panel.size}
                        customComponent={panel.customComponent}
                      />
                    )
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  contracts: (state.properties && state.properties.contracts) || []
});

export default connect(mapStateToProps)(ContractsContainer);
