import Swal from 'sweetalert2';
import { listingActions } from '../../../_actions/listingActions';
import { propertyActions } from '../../../_actions/propertyActions';
export function showPublishingStatusChangeDialog(dispatch, checked, listingId) {
  Swal.fire({
    title: 'Você está alterando a publicação do anúncio.',
    html: 'Você tem certeza que deseja ' + (checked ? '<b>Ativar</b>' : '<b>Desativar</b>' )+ ' este anúncio?',
    type: 'question',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: '#5EC7CE',
    cancelButtonColor: 'f0f0f0',
    confirmButtonText: 'Sim quero ' + (checked ? '<b>Ativar</b>' : '<b>Desativar</b>' ),
    cancelButtonText: 'Cancelar',
    confirmButtonClass: 'btn btn-primary',
    cancelButtonClass: 'btn btn-secondary',
    focusConfirm: false,
    buttonsStyling: false,
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading()
  }).then(result => {
    if (result.value) {
      if (listingId) {
        if (checked) {
          dispatch(propertyActions.enableListing(listingId));
        } else {
          dispatch(propertyActions.disableListing(listingId));
        }
      }
    }
  });
}
export function showDeleteConfirmationDialog(dispatch, listingId) {
  Swal.fire({
    title: 'Você está excluindo um anúncio.',
    text: 'Lembre-se, você também pode pausá-lo no botão verde em "Publicação". Assim é possível ativar novamente quando quiser.',
    type: 'warning',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: '#5EC7CE',
    cancelButtonColor: 'f0f0f0',
    confirmButtonText: 'Sim quero deletar',
    cancelButtonText: 'Cancelar',
    confirmButtonClass: 'btn btn-primary',
    cancelButtonClass: 'btn btn-secondary',
    focusConfirm: false,
    buttonsStyling: false,
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading()
  }).then(result => {
    if (result.value) {
      dispatch(listingActions.deleteListingById(listingId))
    }
  });
}
export function showFoundTenantDialog(dispatch,propertyId) {
  Swal.fire({
    title: 'Encontrou seu inquilino!?',
    type: 'question',
    html:
      'Ficamos muito felizes em ajudar nesse processo.<br/>Agora você pode emitir um contrato através da nossa plataforma e administrar seu imóvel com muito mais controle.',
    showCloseButton: true,
    width: 600,
    showCancelButton: true,
    confirmButtonColor: '#5EC7CE',
    cancelButtonColor: '#5EC7CE',
    confirmButtonText: 'Emitir Contrato',
    cancelButtonText: 'Emitir Boletos',
    showLoaderOnConfirm: true,
    confirmButtonClass: 'btn btn-primary',
    cancelButtonClass: 'btn btn-primary',
    focusConfirm: false,
    buttonsStyling: false,
    allowOutsideClick: () => !Swal.isLoading()
  }).then(result => {
    if (result.value) {
      dispatch(listingActions.sendContractProcessNotification(propertyId))
      // listingService
      //   .sendContractProcessNotification(propertyId)
      //   .then(response => {
      //     if (response.ok) {
      //       Swal.fire(
      //         'Entendido!',
      //         'Chamando Emissão de contratos! Em breve entraremos em contato',
      //         'success'
      //       );
      //     }
      //   })
      //   .catch(error => {
      //     Swal.fire(
      //       'Opa!',
      //       'Erro ao emitir contrato para o anúncio ',
      //       'error'
      //     );
      //   });
    } else if (
      result.dismiss === Swal.DismissReason.cancel
    ) {
      dispatch(listingActions.sendPaymentManagementNotification(propertyId))
      // listingService
      //   .sendPaymentManagementNotification(propertyId)
      //   .then(response => {
      //     if (response.ok) {
      //       Swal.fire(
      //         'Entendido!',
      //         'Vamos administrar seus pagamentos via boleto então, logo entraremos em contato :)',
      //         'success'
      //       );
      //     }
      //   })
      //   .catch(error => {
      //     Swal.fire(
      //       'Opa!',
      //       'Erro ao solicitar emissão de boletos para o anúncio ',
      //       'error'
      //     );
      //   });
    }
  });
}
