import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApprovalDataForm from '../Forms/ApprovalDataForm';
import { getListingStatusColor } from '../../../_helpers/utils';
import { Button } from 'react-bootstrap';

export const ListingApprovalDialog = ({
  container,
  property,
  user,
  open,
  onHide,
  onSubmit
}) => (
  <Modal bsSize="lg" show={open} container={container} onHide={onHide}>
    <Modal.Header
      closeButton
      style={{
        borderLeft: '10px solid',
        borderLeftColor: property.listing
          ? getListingStatusColor(property.listing.status)
          : 'gray',
        borderTopLeftRadius: '5px'
      }}
    >
      <Modal.Title
        style={{
          color: getListingStatusColor(
            property.listing && property.listing.status
          )
        }}
      >
        {`Aprovação - Resumo do Anúncio - ${property.code}`}
        <Link
          className="primary-color-variation"
          to={'/listings/edit/' + property.id}
        >
          {' '}
          Mais detalhes
        </Link>
        <small> Ref Zimobi: {property.id}</small>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ApprovalDataForm
        property={property}
        user={user}
        onSubmit={onSubmit}
        initialValues={property}
      />
    </Modal.Body>
  </Modal>
);
export const ListingRemovalDialog = ({ container, open, onHide, onSubmit }) => (
  <Modal bsSize="medium" show={open} container={container} onHide={onHide}>
    <Modal.Header closeButton>
      <div style={{ padding: '2rem' }} className="text-center">
        <strong>
          <h4>
            <p style={{ padding: '1rem', fontWeight: 'bold' }}>
              Você está excluindo um anúncio.
            </p>
          </h4>
        </strong>
        <p>
          Se você está deletando porque encontrou um inquilino, nós podemos
          emitir seu contrato e emitir seus boletos com muita praticidade.
        </p>
        <div style={{ paddingTop: '1rem' }}>
          <Button
            bsSize={'medium'}
            bsStyle={'info'}
            style={{ marginRight: '1rem' }}
          >
            Emitir Contrato
          </Button>
          <Button bsSize={'medium'} bsStyle={'info'}>
            Emitir Boletos
          </Button>
        </div>
      </div>
    </Modal.Header>
    <Modal.Body>
      <div className="text-center" style={{ padding: '2rem' }}>
        <p style={{ marginLeft: '12rem', marginRight: '12rem' }}>
          {' '}
          Se você deseja somente deletar o anúncio, por favor confirme no botão
          abaixo.{' '}
        </p>
        <div style={{ paddingTop: '1rem' }}>
          <Button bsSize={'medium'} bsStyle={'variant'}>
            Excluir anúncio
          </Button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
);
