import styled from 'styled-components';

const PageTitle = styled.span`
  font-size: 2.5rem;
  color: #5ec7ce;
  margin-right: 1.8rem;
  display: inline;
  vertical-align: sub;
`;

export default PageTitle;
