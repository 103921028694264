import React from 'react';
import Sidebar from 'react-sidebar';
import NewToolBar from '../NewToolBar';
import NewSideMenu from '../NewSideNav';
import styled from 'styled-components';
import { userActions } from '../../_actions/userActions';
import { history } from '../../_helpers/history';
const Wrapper = styled.div`
  padding: 2.5em;
`;

function getMQL(){
  return  window.matchMedia(`(min-width: 800px)`);
}
class SideNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked:  getMQL().matches,
      sidebarOpen: false
    };
  }
  
  componentWillMount() {
    getMQL().addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    getMQL().removeListener(this.mediaQueryChanged);
  }

  onSetSidebarOpen = open => {
    this.setState({ sidebarOpen: open });
  };

  mediaQueryChanged = () => {
    this.setState({ sidebarDocked: getMQL().matches, sidebarOpen: false });
  };

  handleToggle = () => {
    this.setState({
      sidebarDocked:  getMQL().matches,
      sidebarOpen: !this.state.sidebarOpen
    });
  };
  handleExit = () => {
     userActions.logout()
  };
  handleProfileRequest = () => {
    history.push('/profile')
  };
  render() {
    const menuData = [
      {
        to: '/dashboard',
        iconName: 'stats',
        iconPath: '/images/icon_money_bag.svg',
        label: 'Meus Imóveis',
        hidden: false
      },
      {
        to: '/properties',
        iconName: 'home',
        iconPath: '/images/icon_home.svg',
        label: 'Imóveis Alugados',
        hidden: false,
        subitems: [
          {
            to: '/properties/new',
            label: 'Cadastrar Imóvel Alugado',
            hidden: false
          }
        ]
      },
      {
        to: '/listings',
        iconName: 'home',
        iconPath: '/images/icon_home.svg',
        label: 'Anúncios',
        hidden: false,
        subitems: [
          {
            to: '/listings/new',
            label: 'Cadastrar Anúncio',
            hidden: false
          }
        ]
      },
      {
        to: '/contracts',
        iconName: 'file',
        label: 'Contratos',
        hidden: true,
        subitems: [
          {
            to: '/contracts/new',
            label: 'Novo Contrato',
            hidden: false
          }
        ]
      },
      {
        to: '/billing',
        iconName: 'usd',
        label: 'Cobranças',
        hidden: !this.props.showPayments
      },
      { to: '#', iconName: 'comment', label: 'Chamados', hidden: true },
      { to: '#', iconName: 'tasks', label: 'Meu Plano', hidden: true },
      { to: '/profile', iconName: 'user', label: 'Perfil', hidden: false }
    ];
    return (
      <Sidebar
        sidebar={
          <NewSideMenu
            currentLocation={this.props.currentLocation}
            menuData={menuData}
          />
        }
        open={this.state.sidebarOpen}
        docked={this.state.sidebarDocked}
        onSetOpen={this.onSetSidebarOpen}
        sidebarClassName={'sidecontent'}
        rootId="page-wrap"
        transitions={true}
      >
        <NewToolBar
          showToggleButton={!this.state.sidebarDocked}
          onToggle={this.handleToggle}
          isAuthenticated
          user={this.props.user}
          profileLoading={this.props.profileLoading}
          loggedIn={this.props.loggedIn}
          onExitRequest={this.handleExit}
          onProfileRequest={this.handleProfileRequest}
        />
        <Wrapper>{this.props.children}</Wrapper>
      </Sidebar>
    );
  }
}

export default SideNavigation;
