import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button, Col, Panel, Row, Image } from "react-bootstrap";
import { TextField } from "../FormFields";
import { Link } from "react-router-dom";
import { formatPhone } from "../../_helpers/reduxFormFormatters";
import validate from "./Validations";
import { normalizeNumericValue } from "../../_helpers/reduxFormNormalizers";
import LoadingMessage from "../Shared/LoadingMessage";

class UserDataFromSimplified extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btnBaseClass: "btn-primary pull-right btn-primary__person-type"
    };
  }

  render() {
    const { handleSubmit, invalid, submitting, pristine } = this.props;
    return (
      <Panel>
        <Panel.Body style={{ padding: "20px" }}>
          <Row>
            <Col className="text-center">
              <Image
                src="/images/logo.png"
                style={{ paddingBottom: "2rem" }}
                alt="logo"
              />
            </Col>
          </Row>
          {this.props.loading ? (
            <LoadingMessage title="Enviando..." />
          ) : (
            <form className="form" onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <Field
                    autoFocus
                    id="full_name"
                    component={TextField}
                    controlClassName="form-control__white"
                    label="Nome Completo"
                    labelClassName="label__grey"
                    name="full_name"
                    type="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Field
                    id="phone"
                    component={TextField}
                    controlClassName="form-control__white"
                    label="Telefone"
                    labelClassName="label__grey"
                    name="phone"
                    normalize={normalizeNumericValue}
                    format={formatPhone}
                    type="text"
                  />
                </Col>
              </Row>

              <div>
                <Row>
                  <Col md={6}>
                    <Field
                      id="email"
                      component={TextField}
                      controlClassName="form-control__white"
                      label="Email"
                      labelClassName="label__grey"
                      name="email"
                      type="text"
                    />
                  </Col>
                  <Col md={6}>
                    <Field
                      id="email_confirmation"
                      component={TextField}
                      controlClassName="form-control__white"
                      label="Confirme seu email"
                      labelClassName="label__grey"
                      name="email_confirmation"
                      type="text"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Field
                      id="password"
                      component={TextField}
                      controlClassName="form-control__white"
                      label="Senha"
                      labelClassName="label__grey"
                      name="password"
                      type="password"
                    />
                  </Col>
                  <Col md={6}>
                    <Field
                      id="password_confirmation"
                      component={TextField}
                      controlClassName="form-control__white"
                      label="Confirme sua senha"
                      labelClassName="label__grey"
                      name="password_confirmation"
                      type="password"
                    />
                  </Col>
                </Row>
              </div>

              <Row>
                <Col md={12} style={{ textAlign: "center", marginTop: "2rem" }}>
                  <Button
                    disabled={invalid || submitting || pristine}
                    bsStyle="primary"
                    className="btn-primary__login"
                    type={"submit"}
                  >
                    CADASTRAR
                  </Button>
                  <p style={{ marginTop: "0.5rem" }}>
                    <Link to="/login"> Ja possuo uma conta</Link>
                  </p>
                </Col>
              </Row>
            </form>
          )}
        </Panel.Body>
      </Panel>
    );
  }
}

const form = reduxForm({
  form: "UserDataFromSimplified",
  validate,
  destroyOnUnmount: true
})(UserDataFromSimplified);

export default connect(state => ({}))(form);
