import React from "react";
import { connect } from "react-redux";
import { Button, Col, ControlLabel, FormGroup, Row } from "react-bootstrap";
import {
  arrayRemove,
  change,
  Field,
  FieldArray,
  getFormValues
} from "redux-form";
import { SelectField, TextField } from "../../FormFields";
import { formatReais } from "../../../_helpers/reduxFormFormatters";
import { normalizeNumericValue } from "../../../_helpers/reduxFormNormalizers";
import {
  getNumericOptions,
  propertyTypes,
  rentalTypes
} from "../../../_helpers/optionsArrays";
import { calculateTotalRentPriceInCents } from "./business/propertyCalculations";

export class PropertyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { changeFormField, listing, form } = this.props;
    if (listing && listing.property) {
      changeFormField(
        form,
        "property.property_type",
        listing.property.property_type.value
      );
      changeFormField(
        form,
        "property.rental_type",
        listing.property.rental_type.value
      );
    }
  }
  setMonetaryAmountInLocalState(changedKey, changedValue) {
    const intValue = parseInt(changedValue.replace(/[^\d]/g, ""), 10);
    this.props.changeFormField(this.props.form, changedKey, intValue);
  }
  removeExpense = index => {
    this.props.formArrayRemove(this.props.form, "expenses", index);
  };

  renderExpenses = ({ fields, meta: { error } }) => (
    <FormGroup className="contract-panel-expenses">
      <ControlLabel className={"label__grey"}>Outras despesas </ControlLabel>
      {fields.map((expense, index) => (
        <Row key={index}>
          <Col sm={3}>
            <Field
              component={TextField}
              controlClassName="form-control__white"
              name={`expenses[${index}].amount_in_cents`}
              normalize={normalizeNumericValue}
              format={formatReais}
              placeholder="Valor"
              showLabel={false}
            />
          </Col>
          <Col sm={3}>
            <Field
              component={TextField}
              controlClassName="form-control__white"
              name={`expenses[${index}].name`}
              placeholder="Descrição"
              showLabel={false}
            />
          </Col>

          <Col sm={3}>
            <Button
              className="btn-expense btn-remove-expense"
              onClick={() => {
                fields.remove(index);
                this.removeExpense(index);
              }}
            >
              Remover despesa
            </Button>
          </Col>
        </Row>
      ))}
      <Row>
        <Col sm={12}>
          <Button
            className="btn-expense btn-add-expense"
            onClick={() => {
              fields.push({ amount_in_cents: 0 });
            }}
          >
            Adicionar despesa
          </Button>
        </Col>
      </Row>
    </FormGroup>
  );

  render() {
    const property = this.props.values && this.props.values.property;
    return (
      <div>
        <Row>
          <Col sm={3}>
            <Field
              id="rental_price_in_cents"
              component={TextField}
              controlClassName="form-control__white"
              label="Valor do Aluguel"
              labelClassName="label__grey"
              name="rental_price_in_cents"
              normalize={normalizeNumericValue}
              format={formatReais}
            />
          </Col>
          <Col sm={3}>
            <Field
              id="condo_fee_in_cents"
              component={TextField}
              controlClassName="form-control__white"
              label="Condomínio"
              labelClassName="label__grey"
              name="condo_fee_in_cents"
              normalize={normalizeNumericValue}
              format={formatReais}
            />
          </Col>
          <Col sm={3}>
            <Field
              id="iptu_price_in_cents"
              component={TextField}
              controlClassName="form-control__white"
              label="Valor do IPTU (mensal)"
              labelClassName="label__grey"
              name="iptu_price_in_cents"
              normalize={normalizeNumericValue}
              format={formatReais}
            />
          </Col>
          <Col sm={3}>
            <Field
              id="total_price_in_cents"
              component={TextField}
              controlClassName="form-control__white"
              disabled
              label="Total Mensal"
              labelClassName="label__grey"
              name="total_price_in_cents"
              normalize={normalizeNumericValue}
              format={formatReais}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} hidden>
            <FieldArray name="expenses" component={this.renderExpenses} />
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              label="Tipo de Imóvel"
              labelClassName="label__grey"
              name="property.property_type"
              touchOnBlur={false}
              options={propertyTypes}
            />
          </Col>
          <Col sm={6}>
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              label="Tipo do Aluguel"
              labelClassName="label__grey"
              name="property.rental_type"
              options={rentalTypes}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Field
              id="usable_floor_area_in_square_meters"
              component={TextField}
              controlClassName="form-control__white"
              label="Área Útil (m²)"
              labelClassName="label__grey"
              name="property.usable_floor_area_in_square_meters"
              normalize={normalizeNumericValue}
            />
          </Col>
          <Col sm={4}>
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              label={
                property && property.rental_type === "commercial"
                  ? "Ambientes"
                  : "Quartos"
              }
              labelClassName="label__grey"
              name="property.number_of_rooms"
              options={getNumericOptions(10)}
            />
          </Col>
          <Col sm={4}>
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              label="Banheiros"
              labelClassName="label__grey"
              name="property.number_of_bathrooms"
              options={getNumericOptions(10)}
            />
          </Col>
        </Row>
        <Row>
          <Col
            sm={4}
            style={{
              display:
                property && property.rental_type === "commercial"
                  ? "none"
                  : "inherit"
            }}
          >
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              label="Suítes"
              labelClassName="label__grey"
              name="property.number_of_suites"
              options={getNumericOptions(5, false)}
            />
          </Col>
          <Col sm={4}>
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              label="Vagas de Garagem"
              labelClassName="label__grey"
              name="property.number_of_parking_spots"
              options={getNumericOptions(30, false)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeFormField: (formName, attribute, value) =>
    dispatch(change(formName, attribute, value)),
  formArrayRemove: (formName, attribute, index) =>
    dispatch(arrayRemove(formName, attribute, index))
});
const mapStateToProps = (state, props) => {
  let values = getFormValues(props.form)(state);
  if (values) {
    values.total_price_in_cents = calculateTotalRentPriceInCents(
      values.expenses,
      values.rental_price_in_cents,
      values.condo_fee_in_cents,
      values.iptu_price_in_cents
    );
  }
  // console.log(state.properties.selectedListing);

  return {
    values: values
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyDetails);
