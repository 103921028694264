const validate = (values, registered_fields) => {
  const errors = {
    listing: {}
  };

  const requiredMsg = 'Campo obrigatório';
  if (values.listing) {
    if (values.reviewNotesRequired && !values.listing.notes) {
      errors.listing.notes = requiredMsg;
    }

    if (!values.reviewNotesRequired && !values.listing.external_title) {
      errors.listing.external_title = requiredMsg;
    }
    if (!values.reviewNotesRequired && !values.listing.external_description) {
      errors.listing.external_description = requiredMsg;
    }
  }

  if (!values.payment_method_notes) {
    errors.payment_method_notes = requiredMsg;
  }

  return errors;
};

export default validate;
