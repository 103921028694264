import React from 'react';
import { Image } from 'react-bootstrap';
const LoadingMessage = ({ title, subtitle, imagePath }) => (
  <div
    style={{
      padding: '1rem',
      backgroundColor: 'white',
      position: 'relative',
      textAlign: 'center',
      color: '#9c9c9c',
      height: 'auto',
      margin: '3rem'
    }}
  >
    <Image
      style={{ maxHeight: '100px' }}
      src={imagePath ? imagePath : '/images/building_loader.gif'}
    />
    {title ? <h3>{title}</h3> : <h3>Carregando</h3>}
    {subtitle && <h5>{subtitle}</h5>}
  </div>
);

export default LoadingMessage;
