import React from "react";
import { connect } from "react-redux";
import { reduxForm, change } from "redux-form";
import { Col, Row, Button } from "react-bootstrap";
import { TextAreaField, TextField } from "../../../_components/FormFields";
import { Field } from "redux-form";
import validate from "./Validations";
import {
  // renderRentType,
  renderRentalGuarantees,
  renderAddressFormatter,
  toCurrenry
} from "../../../_helpers/utils";
import RenderRow from "../../../_components/RenderRow";

const formName = "ApprovalDataForm";
class ApprovalDataForm extends React.Component {
  render() {
    const {
      address,
      property_type,
      listing,
      rental_type,
      usable_floor_area_in_square_meters,
      number_of_bathrooms,
      number_of_parking_spots,
      number_of_rooms,
      number_of_suites,
      landlord
    } = this.props.property;

    const { handleSubmit, user } = this.props;
    const RenderPicThumb = ({ src }) => {
      return (
        <a href={src ? src : "#"} target="blank">
          <img
            className="img-fluid d-inline"
            style={{
              marginBottom: "15px",
              borderRadius: "10px",
              maxWidth: "110px",
              opacity: src ? 1 : 0.5,
              objectFit: "cover"
            }}
            alt="MINI"
            width={"100%"}
            height={80}
            src={src ? src : "https://via.placeholder.com/120x100"}
          />
        </a>
      );
    };

    return (
      <Row>
        <form className="form" onSubmit={handleSubmit}>
          <Col md={12}>
            {listing && (
              <Col md={6}>
                {/* <SectionTitle label="TITLE"/> */}
                <RenderRow
                  label="Valor aluguel"
                  value={toCurrenry(listing.rental_price_in_cents / 100 || 0)}
                />
                <RenderRow
                  label="Condomínio"
                  value={toCurrenry(listing.condo_fee_in_cents / 100 || 0)}
                />
                <RenderRow
                  label="IPTU"
                  value={toCurrenry(listing.iptu_price_in_cents / 100 || 0)}
                />
                <RenderRow
                  label="Outras Desp."
                  value={
                    listing.expenses &&
                    toCurrenry(
                      listing.expenses
                        .map(e => parseInt(e.amount_in_cents))
                        .reduce((acc, item) => acc + item, 0) / 100
                    )
                  }
                />
                <RenderRow
                  label="Total"
                  value={toCurrenry(listing.total_price_in_cents / 100 || 0)}
                />
                <RenderRow label="CEP" value={address.postal_code} />
                <RenderRow
                  label="Endereço"
                  value={renderAddressFormatter(address)}
                />
                <Field
                  id="street"
                  component={TextField}
                  controlClassName="form-control__white"
                  label="Título do Anúncio Revisado"
                  labelClassName="label__grey"
                  name="listing.external_title"
                />
                <Field
                  component={TextAreaField}
                  rows={11}
                  controlClassName="form-control__white listing-description"
                  label="Descrição Revisada"
                  labelClassName="label__grey"
                  name="listing.external_description"
                />
              </Col>
            )}
            {landlord && (
              <Col md={6}>
                <RenderRow label="Proprietário" value={landlord.full_name} />
                <RenderRow label="Email" value={landlord.email} />
                <RenderRow label="Contato" value={landlord.phone} />
                <hr />
              </Col>
            )}

            <Col md={6}>
              {user && (
                <section>
                  <RenderRow label="Usuário" value={user.full_name} />
                  <RenderRow label="Email" value={user.email} />
                  <RenderRow label="Contato" value={user.phone} />
                  <RenderRow label="Empresa" value={user.company_name} />
                  <hr />
                </section>
              )}
              <RenderRow label="Tipo do imóvel" value={property_type.label} />
              <RenderRow
                label="Tipo aluguel"
                value={rental_type && rental_type.label}
              />
              <RenderRow
                label="Área"
                value={usable_floor_area_in_square_meters || 0 + "m²"}
              />
              <RenderRow
                label="Quartos/Ambientes"
                value={number_of_rooms || 0}
              />
              <RenderRow label="Banheiros" value={number_of_bathrooms || 0} />
              <RenderRow label="Suítes" value={number_of_suites || 0} />
              <RenderRow
                label="Vagas Garagem"
                value={number_of_parking_spots || 0}
              />
              {listing && (
                <section>
                  <RenderRow
                    label="Garantias"
                    value={renderRentalGuarantees(
                      listing.deposit_amount_in_cents,
                      listing.rental_guarantee
                    )}
                  />
                  <RenderRow
                    label="Observação"
                    value={listing.guarantee_description}
                  />
                  <Field
                    component={TextAreaField}
                    controlClassName="form-control__white listing-description"
                    label="Notas de Revisão"
                    labelClassName="label__grey"
                    name="listing.notes"
                    required={this.props.form.reviewNotesRequired}
                  />
                  <Col md={12} className="text-center">
                    <Col md={6} xs={6}>
                      <Button
                        bsStyle={"primary"}
                        style={{ fontWeight: "bold" }}
                        className="btn-invalid "
                        type="submit"
                        onMouseDown={() =>
                          this.props.changeFormField(
                            formName,
                            "reviewNotesRequired",
                            true
                          )
                        }
                        onClick={handleSubmit(values => {
                          this.props.onSubmit({
                            ...values,
                            operation: "reprove"
                          });
                        })}
                      >
                        REPROVAR
                      </Button>
                    </Col>
                    <Col md={6} xs={6} style={{ marginBottom: "3rem" }}>
                      <Button
                        bsStyle={"primary"}
                        style={{ fontWeight: "bold" }}
                        className="btn-valid "
                        type="submit"
                        onMouseDown={() =>
                          this.props.changeFormField(
                            formName,
                            "reviewNotesRequired",
                            false
                          )
                        }
                        onClick={handleSubmit(values => {
                          this.props.onSubmit({
                            ...values,
                            operation: "approve"
                          });
                        })}
                      >
                        APROVAR
                      </Button>
                    </Col>
                  </Col>
                </section>
              )}
            </Col>

            <Col md={12}>
              <hr />
              {listing &&
                listing.images.map((p, index) => (
                  <Col
                    key={index}
                    xs={listing.images.length < 3 ? 6 : 4}
                    md={listing.images.length < 3 ? 6 : 3}
                    style={{ textAlign: "center" }}
                  >
                    <RenderPicThumb
                      key={index}
                      src={
                        listing.images[index]
                          ? listing.images[index].url
                          : undefined
                      }
                    />
                  </Col>
                ))}
            </Col>
          </Col>
        </form>
      </Row>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  changeFormField: (formName, attribute, value) =>
    dispatch(change(formName, attribute, value))
});
ApprovalDataForm = reduxForm({
  form: formName,
  destroyOnUnmount: true,
  initialValues: {},
  validate
})(ApprovalDataForm);

export default connect(
  null,
  mapDispatchToProps
)(ApprovalDataForm);
