import { alertConstants } from '../_constants/alertConstants';
export const alertActions = {
  success,
  error,
  clear,
  showMessageDialog
};
function showMessageDialog(title,message,style) {
  return {type: alertConstants.MESSAGE_DIALOG, title, message, style  };
}

function success(message) {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });

  return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });

  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}
