import React from "react";
import { connect } from "react-redux";
import { arrayPush, change, getFormValues } from "redux-form";
import { Col, Row } from "react-bootstrap";
import {
  SelectField,
  TextAreaField,
  TextField,
  DropZoneField,
  CheckBoxField
} from "../../FormFields";
import { Field } from "redux-form";
import { normalizeNumericValue } from "../../../_helpers/reduxFormNormalizers";
import { formatReais } from "../../../_helpers/reduxFormFormatters";
import {
  furnishedTypes,
  guaranteesList
} from "../../../_helpers/optionsArrays";
import Swal from "sweetalert2";
class ListingData extends React.Component {
  constructor(props) {
    super(props);
    const { listing } = props;
    this.state = {
      images: (listing && listing.images) || []
    };
  }

  handleFileUploadSuccess = (file, files, identifier) => {
    this.props.changeFormField(this.props.form, identifier, files);

    if (!this.props.values.loading_images && file != null) {
      this.props.changeFormField(this.props.form, "loading_images", true);
      Swal.fire({
        title: "Upload em progresso",
        allowOutsideClick: false,
        timer: 2000,
        html:
          "Aguarde até que todas as imagens sejam carregadas antes de prosseguir.",
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      }).then(result => {
        if (result.dismiss === this.props.values.loading_images) {
          console.log("I was closed by the timer");
        }
      });
    }
  };

  handleRemovedFile = (file, files, identifier) => {
    this.props.changeFormField(this.props.form, identifier, files);
  };
  handleQueueCompleted = () => {
    this.props.changeFormField(this.props.form, "loading_images", false);
  };
  componentDidMount() {
    this.props.changeFormField(
      this.props.form,
      "rental_guarantee",
      this.props.values &&
        this.props.values.rental_guarantee &&
        this.props.values.rental_guarantee.map(r => r.value)
    );
  }
  render() {
    return (
      <div>
        <Row>
          <Col sm={12}>
            <Field
              observation="Você pode selecionar mais de uma garantia"
              label={"Garantia"}
              name="rental_guarantee"
              component={CheckBoxField}
              options={guaranteesList}
            />
          </Col>
        </Row>

        <Row>
          {this.props.showDepositAmountField && (
            <Col sm={6}>
              <Field
                id="deposit_amount_in_cents"
                component={TextField}
                controlClassName="form-control__white"
                label="Valor do Depósito"
                labelClassName="label__grey"
                tooltiplabel="É o valor que você vai aceitar como depósito de segurança. O valor máximo segundo a Lei do Inquilinato é equivalente a 3 aluguéis"
                name="deposit_amount_in_cents"
                normalize={normalizeNumericValue}
                format={formatReais}
              />
            </Col>
          )}
          <Col sm={6}>
            <Field
              id="guarantee_description"
              component={TextField}
              controlClassName="form-control__white"
              label="Observação da Garantia"
              observation="*Opcional"
              labelClassName="label__grey"
              name="guarantee_description"
            />
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <Field
              id="street"
              component={TextField}
              controlClassName="form-control__white"
              label="Título do Anúncio"
              tooltiplabel="Esse é o título que o anúncio aparecerá para os interessados em imóveis na plataforma Zimobi!"
              labelClassName="label__grey"
              name="title"
            />
          </Col>
          <Col sm={6}>
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              label="Mobiliado"
              labelClassName="label__grey"
              name="furnish_type"
              options={furnishedTypes}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field
              component={TextAreaField}
              controlClassName="form-control__white listing-description"
              label="Descrição do Anúncio"
              observation="Escreva as peculiaridades do imóvel, comodidades próximas, mas não coloque informações pessoais, o contato é feito através da zimobi."
              labelClassName="label__grey"
              name="description"
              placeholder="Escreva aqui o texto do seu anúncio."
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {/* <div style={{ display: 'flex' }}>
              <ControlLabel className="label__grey">Fotos </ControlLabel>
              <Field
                id="images_attributes_validator"
                component={TextField}
                controlClassName="form-control__white"
                labelClassName="label__grey"
              
               
              
                showLabel={true}
              />
            </div> */}
            <Field
              label={"Fotos"}
              showLabel={true}
              showErrorBesideLabel={true}
              replaceSubtitleByError={true}
              placeholderMessage={
                "Você deve colocar no mínimo 3 fotos para seu anúncio - Você pode ordená-las clicando e arrastando para a posição desejada."
              }
              name="images_attributes_validator"
              onFileRemoved={(file, files) =>
                this.handleRemovedFile(file, files, "images")
              }
              onUploadDone={this.handleQueueCompleted}
              onFileAdded={(file, files) =>
                this.handleFileUploadSuccess(file, files, "images")
              }
              images={this.state.images}
              component={DropZoneField}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  arrayPush: (formName, attribute, value) =>
    dispatch(arrayPush(formName, attribute, value)),
  changeFormField: (formName, attribute, value) =>
    dispatch(change(formName, attribute, value))
});
const mapStateToProps = (state, ownProps) => {
  let values = getFormValues(ownProps.form)(state);

  if (values) {
    let showDepositAmountField =
      values.rental_guarantee &&
      values.rental_guarantee.indexOf("deposit") > -1;
    return {
      values,
      showDepositAmountField,
      hasErrorOnPictures: values.images_attributes_validator ? true : false
    };
  }
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingData);
