import React from 'react';
import { connect } from 'react-redux';
import { mapPropertyToEdit } from '../../_helpers/mappers';
import RentedPropertyForm from '../../_components/RentedPropertyForm/RentedPropertyForm';
import { propertyActions } from '../../_actions/propertyActions';
import { Breadcrumb } from 'react-bootstrap';
import PageTitle from '../../_components/Shared/PageTitle';
class RentedPropertyEditPage extends React.Component {
  componentWillMount() {
    this.props.dispatch(
      propertyActions.findById(this.props.match.params.propertyId)
    );
  }

  handleSubmit = values => {
    delete values['listing'];
    this.props.dispatch(propertyActions.update(mapPropertyToEdit(values)));
  };

  render() {
    return (
      <div>
        <PageTitle>Edição Imóvel Alugado</PageTitle>
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard">Meus Imóveis</Breadcrumb.Item>
          <Breadcrumb.Item href="/properties">Imóveis Alugados</Breadcrumb.Item>
          <Breadcrumb.Item active>Edição Imóvel Alugado</Breadcrumb.Item>
        </Breadcrumb>
        {this.props.selectedProperty && (
          <RentedPropertyForm
            activeSessionName={this.props.match.params.section}
            onSubmit={this.handleSubmit}
            property={this.props.selectedProperty}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    selectedProperty: state.properties.selectedProperty
  };
};

export default connect(mapStateToProps)(RentedPropertyEditPage);
