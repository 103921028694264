import React from 'react';
import { Glyphicon } from 'react-bootstrap';
export const SearchBox = ({ onSearchChanged, value, onClearSearch, props }) => (
  <div className="inner-addon right-addon " style={{ minWidth: '40%' }}>
    <Glyphicon
      glyph={
        value ? 'glyphicon glyphicon-remove' : 'glyphicon glyphicon-search'
      }
      onClick={onClearSearch}
    />
    <input
      type="text"
      className="form-control"
      placeholder="Buscar por id da propriedade exemplo: 1234"
      value={value}
      onChange={onSearchChanged}
    />
  </div>
);
