import { bankAccountConstants } from '../_constants/bankAccountConstants';
import { bankAccountService } from '../_services/bankAccountService';

export const bankAccountActions = {
  getAll
};

function getAll() {
  return dispatch => {
    dispatch(request());
    bankAccountService
      .getAll()
      .then(
        response => dispatch(success(response.data)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return { type: bankAccountConstants.GETALL_REQUEST };
  }
  function success(bankAccounts) {
    return { type: bankAccountConstants.GETALL_SUCCESS, bankAccounts };
  }
  function failure(error) {
    return { type: bankAccountConstants.GETALL_FAILURE, error };
  }
}
