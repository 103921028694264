import React from 'react';

export const SectionTitle = props => {
  return (
    <div>
      <h5 className="title" style={{ textTransform: 'uppercase' }}>
        {props.label}
      </h5>
      <hr />
    </div>
  );
};
