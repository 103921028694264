import { alertConstants } from '../_constants/alertConstants';
import { toast } from 'react-toastify';
import swal from 'sweetalert2';

export default function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.MESSAGE_DIALOG:
      swal.fire(action.title, action.message,action.style);
      return { ...state };
    case alertConstants.SUCCESS:
      toast.success(action.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return { ...state, type: 'alert-success', message: action.message };
    case alertConstants.ERROR:
      toast.error(action.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return { ...state, type: 'alert-danger', message: action.message };
    case alertConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
