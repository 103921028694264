import React from "react";
import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup
} from "react-bootstrap";
import { Field } from "redux-form";
import ReactS3Uploader from "react-s3-uploader";
import { authHeader } from "../_helpers/authHeader";
import config from "../_helpers/config";
import { renderToolTipIcon } from "../_helpers/utils";
import PhotosDropzone from "./PhotosDropzone";
import CheckboxGroup from "./CheckboxGroup";
import RenderPicThumb from "./RenderPicThumb";
const PlaceholderMessage = ({ replaceSubtitleByError, placeholderMessage }) => {
  return (
    (replaceSubtitleByError && (
      <p className="field-message">{placeholderMessage}</p>
    )) ||
    null
  );
};
export const TextField = ({
  id,
  label,
  labelClassName,
  controlClassName,
  input,
  type,
  replaceSubtitleByError,
  placeholderMessage,
  showLabel,
  showErrorBesideLabel,
  value,
  meta: { touched, error },
  ...props
}) => {
  var hasError = false;
  if (error !== undefined) {
    hasError = true;
  }

  if (showLabel === undefined) {
    showLabel = true;
  }

  return (
    <FormGroup
      controlId={id}
      validationState={hasError && touched ? "error" : null}
    >
      {showLabel && (
        <div style={{ display: "flex" }}>
          {label && (
            <ControlLabel className={labelClassName}>
              {label}
              {props.tooltiplabel && renderToolTipIcon(props.tooltiplabel)}
            </ControlLabel>
          )}{" "}
          {showErrorBesideLabel ? (
            hasError && touched ? (
              <p className="field-error">{error}</p>
            ) : (
              <PlaceholderMessage {...props} />
            )
          ) : null}
          <Observation {...props} />
        </div>
      )}
      <FormControl
        type={type}
        className={controlClassName}
        {...input}
        {...props}
      />
      {!showErrorBesideLabel ? (
        hasError && touched ? (
          <p className="field-error">{error}</p>
        ) : (
          <PlaceholderMessage {...props} />
        )
      ) : null}
    </FormGroup>
  );
};

export const DropZoneField = ({
  id,
  label,
  labelClassName,
  controlClassName,
  input,
  type,
  showLabel,
  value,
  showErrorBesideLabel,
  meta: { touched, error },
  ...props
}) => {
  var hasError = false;
  if (error !== undefined) {
    hasError = true;
  }
  if (showLabel === undefined) {
    showLabel = true;
  }
  return (
    <FormGroup
      controlId={id}
      validationState={hasError && touched ? "error" : null}
    >
      {showLabel && (
        <div style={{ display: "flex" }}>
          {label && (
            <ControlLabel className={labelClassName || "label__grey"}>
              {label}
              {props.tooltiplabel && renderToolTipIcon(props.tooltiplabel)}
            </ControlLabel>
          )}
          {showErrorBesideLabel ? (
            hasError && touched ? (
              <p className="field-error">{error}</p>
            ) : (
              <PlaceholderMessage {...props} />
            )
          ) : null}
          <Observation {...props} />
        </div>
      )}
      {props.disabled ? (
        <div class="photos-dropzone">
          <div class="filepicker dropzone">
            {props.images && props.images.length > 0 ? (
              <div style={{ margin: "2rem" }}>
                {props.images.map((image, index) => (
                  <RenderPicThumb key={index} src={image.url} />
                ))}
              </div>
            ) : (
              <div class="dz-default dz-message">
                <img
                  height="83"
                  alt="place holder for dropzone"
                  src="/images/image_icon2.png"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <PhotosDropzone
            id={id}
            className={controlClassName}
            {...input}
            {...props}
          />
          {!showErrorBesideLabel ? (
            hasError && touched ? (
              <p className="field-error">{error}</p>
            ) : (
              <PlaceholderMessage {...props} />
            )
          ) : null}
          <div
            style={{
              textAlign: "center",
              width: "100%",
              color: "#9c9c9c",
              padding: "1rem"
            }}
          >
            <span>Clique ou arraste para adicionar fotos</span>
          </div>
        </div>
      )}
    </FormGroup>
  );
};
export const TextLabel = ({
  id,
  label,
  labelClassName,
  controlClassName,
  input,
  type,
  showLabel,
  meta: { touched, error },
  ...props
}) => {
  return (
    <FormGroup controlId={id}>
      {showLabel && (
        <div style={{ display: "flex" }}>
          <ControlLabel className={labelClassName}>
            {label}{" "}
            {props.tooltiplabel && renderToolTipIcon(props.tooltiplabel)}
          </ControlLabel>
        </div>
      )}

      <FormControl
        type={type}
        className={controlClassName}
        {...input}
        {...props}
      />
    </FormGroup>
  );
};
export const TextAreaField = ({
  id,
  label,
  labelClassName,
  controlClassName,
  input,
  type,
  showLabel,
  meta: { touched, error },
  ...props
}) => {
  var hasError = false;
  if (error !== undefined) {
    hasError = true;
  }

  if (showLabel === undefined) {
    showLabel = true;
  }

  return (
    <FormGroup
      controlId={id}
      validationState={hasError && touched ? "error" : null}
    >
      {showLabel && (
        <div style={{ display: "flex" }}>
          <ControlLabel className={labelClassName}>
            {label}{" "}
            {props.tooltiplabel && renderToolTipIcon(props.tooltiplabel)}
          </ControlLabel>{" "}
          <Observation {...props} />
        </div>
      )}
      <FormControl
        componentClass="textarea"
        className={controlClassName}
        {...input}
        {...props}
      />
      {hasError && touched ? <p className="field-error">{error}</p> : null}
    </FormGroup>
  );
};

export const SelectField = ({
  label,
  labelClassName,
  controlClassName,
  input,
  options,
  meta: { touched, error },
  ...props
}) => {
  var hasError = false;
  if (error !== undefined) {
    hasError = true;
  }
 
  return (
    <FormGroup validationState={hasError && touched ? "error" : null}>
      <ControlLabel className={labelClassName}>{label}</ControlLabel>
      <Field
        className={`form-control ${controlClassName} `}
        component="select"
        {...input}
        {...props}
      >
        {options &&
          options.length > 0 &&
          options.map(option => (
            <option
              value={option.value}
              key={option.value}
              selected={option.selected}
            >
              {option.label}
            </option>
          ))}
      </Field>
      {hasError && touched ? <p className="field-error">{error}</p> : null}
    </FormGroup>
  );
};

export const GenderRadioGroup = ({ ...props }) => {
  return (
    <FormGroup>
      <ControlLabel className="label__grey">Gênero</ControlLabel>
      <div className="radio-group">
        <div>
          <label className="radio-option">
            <Field component="input" type="radio" value="male" {...props} />
            Masculino
          </label>
        </div>
        <div>
          <label className="radio-option">
            <Field component="input" type="radio" value="female" {...props} />
            Feminino
          </label>
        </div>
      </div>
    </FormGroup>
  );
};

const Observation = ({ observation }) => {
  return (
    (observation && <p className="field-message">{observation}</p>) || null
  );
};
export const CheckBoxField = ({
  labelClassName,
  options,
  label,
  observation,
  ...props
}) => {
  return (
    <FormGroup>
      <div style={{ display: "inline-flex" }}>
        <ControlLabel className={labelClassName || "label__grey"}>
          {label}
        </ControlLabel>{" "}
        <Observation observation={observation} />
      </div>
      <Field
        name="rental_guarantee"
        component={CheckboxGroup}
        options={options}
      />
    </FormGroup>
  );
};

export class FileUploadField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFileSended: false,
      uploading: false,
      fileOriginalName: ""
    };
  }

  render() {
    let { showLabel } = this.props;

    if (showLabel === undefined) {
      showLabel = true;
    }

    return (
      <div className="file-upload-field">
        <ReactS3Uploader
          className={"hidden"}
          signingUrl={`${config.ZIMOBI_API_BASE}/files/s3/sign`}
          signingUrlMethod="GET"
          onProgress={() => this.setState({ uploading: true })}
          //onError={this.onUploadError}
          onFinish={response => {
            this.setState({ uploading: false, isFileSended: true });
            if (typeof this.props.onFileUploadFieldChange === "function") {
              this.props.onFileUploadFieldChange(
                this.props.name,
                response.signedUrl.split("?")[0]
              );
            }
          }}
          signingUrlHeaders={authHeader()}
          scrubFilename={filename => filename.replace(/[^\w\d_\-.]+/gi, "")}
          ref={uploader => {
            this.uploader = uploader;
          }}
          inputRef={cmp => (this.uploadInput = cmp)}
          autoUpload={true}
          preprocess={(file, next) => {
            this.setState({ fileOriginalName: this.uploadInput.files[0].name });
            next(file);
          }}
        />

        {showLabel && (
          <ControlLabel className={this.props.labelClassName}>
            {this.props.label}
            <small>{this.props.note}</small>
          </ControlLabel>
        )}
        <FormGroup
          controlId={this.props.id}
          className="clearfix"
          validationState={
            this.props.hasError && this.props.touched ? "error" : null
          }
        >
          <Col sm={5} xs={12} className="file-upload-column">
            <Field
              component={TextField}
              controlClassName={`form-control ${this.props.controlClassName}`}
              labelClassName="label__grey"
              name={this.props.name + "_validator"}
              showLabel={false}
              placeholder={this.state.fileOriginalName}
              disabled
            />
            {this.props.hasError && this.props.touched ? (
              <p className="field-error">{this.props.error}</p>
            ) : null}

            {!this.state.isFileSended && !this.state.uploading ? (
              <Button
                className="file-upload-btn file-upload-btn-select-file"
                disabled={this.props.disabled}
                onClick={() => this.uploadInput.click()}
              >
                Selecionar arquivo
              </Button>
            ) : (
              <Button
                className={
                  this.state.isFileSended
                    ? "file-upload-btn file-upload-btn-send-file sent"
                    : "file-upload-btn file-upload-btn-send-file"
                }
                disabled={this.state.isFileSended || this.props.disabled}
                onClick={() => this.uploader.uploadFile()}
              >
                {this.state.uploading
                  ? "Enviando..."
                  : this.state.isFileSended
                  ? "Enviado"
                  : "Enviar"}
              </Button>
            )}
          </Col>
          <Col
            sm={7}
            xs={12}
            className="file-upload-column file-upload-btn-column"
          >
            <Button
              className="file-upload-btn file-upload-btn-delete-file"
              disabled={this.props.disabled}
              onClick={() => {
                this.setState({ fileOriginalName: "", isFileSended: false });
                this.uploader.clear();
                if (typeof this.props.onFileUploadFieldChange === "function") {
                  this.props.onFileUploadFieldChange(this.props.name, "");
                }
                if (typeof this.props.deleteCallback === "function") {
                  this.props.deleteCallback();
                }
              }}
            >
              Deletar
            </Button>
            {this.props.showCustomButton && (
              <Button
                className="file-upload-btn file-upload-btn-custom"
                disabled={this.props.disabled}
                onClick={this.props.customButtonOnClick}
              >
                {this.props.customButtonText}
              </Button>
            )}
          </Col>
        </FormGroup>
      </div>
    );
  }
}
