import React from 'react';
import { Panel, Col } from 'react-bootstrap';
export const SimplePanel = props => (
  <Col md={6} mdPush={3}>
    <Panel style={{ marginTop: '10rem' }}>
      <Panel.Body
        className="text-center card-text-color"
        style={{ overflow: 'auto' }}
      >
        {props.children}
      </Panel.Body>
    </Panel>
  </Col>
);
