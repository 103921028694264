import React from 'react';
import { Panel } from 'react-bootstrap';
import EmptyMessageBlock from './EmptyMessageComponent';
export const PanelWithTitle = ({
  title,
  body,
  callToAction,
  bsStyle,
  to,
  subtitle,
  iconPath,
  callToActionSize
}) => (
  <Panel>
    <Panel.Heading>
      <Panel.Title>
        <h4 className="title" style={{ textAlign: 'center' }}>
          {title}
        </h4>
        <hr />
      </Panel.Title>
    </Panel.Heading>
    <Panel.Body
      className="text-center card-text-color"
      style={{ overflow: 'auto' }}
    >
      <EmptyMessageBlock
        title={body}
        subtitle={subtitle}
        callToActionSize={callToActionSize}
        callToActionLabel={callToAction}
        imagePath={iconPath}
        bsStyle={bsStyle}
        linkTo={to}
      />
    </Panel.Body>
  </Panel>
);
