const validate = values => {
  const errors = {
    property: { address: {} },
    active_contract: { tenant: {} },
    landlord: {},
    new_user_attributes: {}
  };

  const requiredMsg = "Campo obrigatório";

  // Address

  const address = (values.property && values.property.address) || {};

  if (!address.street) {
    errors.property.address.street = requiredMsg;
  }

  if (!address.number) {
    errors.property.address.number = requiredMsg;
  }

  if (!address.postal_code) {
    errors.property.address.postal_code = requiredMsg;
  }

  if (address.cep_error) {
    errors.property.address.postal_code = address.cep_error;
  }

  if (!address.neighbourhood) {
    errors.property.address.neighbourhood = requiredMsg;
  }

  if (!address.city) {
    errors.property.address.city = requiredMsg;
  }

  if (!address.state) {
    errors.property.address.state = requiredMsg;
  }

  // Property Details
  const property = values.property || {};
  if (property) {
    if (!property.code) {
      errors.property.code = requiredMsg;
    }
    if (
      !property.property_type ||
      (property.property_type && typeof property.property_type !== "string")
    ) {
      errors.property.property_type = requiredMsg;
    }

    if (
      !property.rental_type ||
      (property.rental_type && typeof property.rental_type !== "string")
    ) {
      errors.property.rental_type = requiredMsg;
    }

    if (!property.usable_floor_area_in_square_meters) {
      errors.property.usable_floor_area_in_square_meters = requiredMsg;
    }

    if (
      !property.number_of_rooms ||
      (property.number_of_rooms && typeof property.number_of_rooms === "object")
    ) {
      errors.property.number_of_rooms = requiredMsg;
    }

    if (
      !property.number_of_bathrooms ||
      (property.number_of_bathrooms &&
        typeof property.number_of_bathrooms === "object")
    ) {
      errors.property.number_of_bathrooms = requiredMsg;
    }
  }

  const listing = values || {};
  if (
    !listing.rental_price_in_cents ||
    (listing.rental_price_in_cents === 0 ||
      parseInt(listing.rental_price_in_cents) === 0)
  ) {
    errors.rental_price_in_cents = requiredMsg;
  }

  // if (!listing.total_price_in_cents) {
  //   errors.listing.total_price_in_cents = requiredMsg;
  // }

  // Listing

  if (!listing.rental_guarantee || listing.rental_guarantee.length <= 0) {
    errors.rental_guarantee = "Selecione uma forma de garantia.";
  }

  if (
    listing.rental_guarantee &&
    listing.rental_guarantee.includes("deposit") &&
    !listing.deposit_amount_in_cents
  ) {
    errors.deposit_amount_in_cents = requiredMsg;
  }

  if (
    !listing.deposit_amount_in_cents ||
    parseInt(listing.deposit_amount_in_cents) === 0
  ) {
    errors.deposit_amount_in_cents = requiredMsg;
  }

  if (!listing.title) {
    errors.title = requiredMsg;
  }

  if (!listing.description) {
    errors.description = requiredMsg;
  }
  if (listing.loading_images === "true") {
    errors.loading_images = "Aguarde, carregamento de imagens em curso";
  } else {
    delete errors.loading_images;
  }
  if (!listing.images || listing.images.length < 3) {
    errors.images_attributes_validator =
      "Você deve colocar no mínimo 3 fotos para seu anúncio.";
  }

  // New User
  if (values.new_user_attributes) {
    const new_user_attributes = values.new_user_attributes || {};

    if (!new_user_attributes.action) {
      errors.new_user_attributes.action = "Selecione uma das opções acima.";
    }

    if (!new_user_attributes.full_name) {
      errors.new_user_attributes.full_name = requiredMsg;
    }

    if (!new_user_attributes.phone) {
      errors.new_user_attributes.phone = requiredMsg;
    }

    if (!new_user_attributes.email) {
      errors.new_user_attributes.email = requiredMsg;
    }

    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        new_user_attributes.email
      )
    ) {
      errors.new_user_attributes.email = "Insira um email válido.";
    }

    if (!new_user_attributes.email_confirmation) {
      errors.new_user_attributes.email_confirmation = requiredMsg;
    }

    if (new_user_attributes.email !== new_user_attributes.email_confirmation) {
      errors.new_user_attributes.email_confirmation =
        "O email e a confirmação de email estão diferentes.";
    }

    if (
      new_user_attributes.email &&
      new_user_attributes.email.indexOf(" ") !== -1
    ) {
      errors.new_user_attributes.email = "Email não pode conter espaços!";
    }

    if (!new_user_attributes.password) {
      errors.new_user_attributes.password = requiredMsg;
    }

    if (!new_user_attributes.password_confirmation) {
      errors.new_user_attributes.password_confirmation = requiredMsg;
    }

    if (!new_user_attributes.password) {
      errors.password = requiredMsg;
    } else if (new_user_attributes.password.length < 6) {
      errors.new_user_attributes.password =
        "A senha deve ter pelo menos 6 caracteres.";
    }

    if (
      new_user_attributes.password !== new_user_attributes.password_confirmation
    ) {
      errors.new_user_attributes.password_confirmation =
        "A senha e a confirmação de senha estão diferentes.";
    }
  }

  return errors;
};

export default validate;
