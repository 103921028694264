import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getBillingMethodStatusColor } from '../../../_helpers/utils';
import ApprovalPaymentMethodForm from '../Forms/ApprovalPaymentMethodForm';

export const PaymentMethodApprovalDialog = ({
  container,
  property,
  user,
  open,
  onHide,
  onSubmit
}) => (
  <Modal bsSize="lg" show={open} container={container} onHide={onHide}>
    <Modal.Header
      closeButton
      style={{
        borderLeft: '10px solid',
        borderLeftColor: getBillingMethodStatusColor(property),
        borderTopLeftRadius: '5px'
      }}
    >
      <Modal.Title
        style={{
          color: getBillingMethodStatusColor(property)
        }}
      >
        {`Aprovação - Método de Cobrança - ${property.code}`}
        <Link
          className="primary-color-variation"
          to={'/properties/edit/' + property.id}
        >
          {' '}
          Mais detalhes
        </Link>
        <small> Ref Zimobi: {property.id}</small>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ApprovalPaymentMethodForm
        property={property}
        user={user}
        onSubmit={onSubmit}
      />
    </Modal.Body>
  </Modal>
);
