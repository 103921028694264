import React from "react";
import { Col, Row, ControlLabel, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import {
  change,
  Field,
  getFormValues,
  isValid,
  unregisterField
} from "redux-form";
import { SelectField, TextField } from "../../FormFields";
import {
  // accountTypes,
  banks,
  receivingMethodTypes
} from "../../../_helpers/optionsArrays";
import { formatCPF, formatCNPJ } from "../../../_helpers/reduxFormFormatters";
import { normalizeNumericValue } from "../../../_helpers/reduxFormNormalizers";

class ReceivingAccountData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewBankAccount: props.bank_account ? false : true,
      personType: "individual"
    };
  }
  componentDidMount() {
    this.props.changeFormField(
      this.props.form,
      "bank_account.person_type",
      "individual"
    );
    this.props.changeFormField(
      this.props.form,
      "bank_account.account_type",
      "current"
    );
    this.props.changeFormField(
      this.props.form,
      'active_contract.payment_method',
      this.props.payment_method || 'manual'
    );
  }
  getSelectOptions = () => {
    const { bankAccounts, bank_account } = this.props;
    let options = [];
    let selectedBankId = bank_account && bank_account.id;
    options.push({ value: "", label: "Cadastrar nova conta bancária" });
    if (bankAccounts && bankAccounts.length > 0) {
      bankAccounts.forEach(bankAccount =>
        options.push({
          value: bankAccount.id,
          selected: bankAccount.id === selectedBankId,
          label:
            bankAccount.full_name +
            " | " +
            (bankAccount.bank && bankAccount.bank.name) +
            " | " +
            bankAccount.agency +
            " | " +
            bankAccount.account_number
        })
      );
    }
    return options;
  };

  onChangeBillingMode(e) {
    console.log(e.target.value);
    this.props.changeFormField(
      this.props.form,
      "active_contract.payment_method",
      e.target.value
    );
    this.setState({
      isNewBankAccount: this.props.bank_account ? false : true
    });
  }
  onChangeAccount(e) {
    if (this.props.bankAccounts && this.props.bankAccounts.length > 0) {
      const objIndex = e.target.selectedIndex;
      let selectedBankAccount = this.props.bankAccounts[objIndex - 1];
      if (objIndex === 0) {
        this.setState({ isNewBankAccount: true });
        this.props.changeFormField(this.props.form, "bank_account", {});
        this.props.changeFormField(
          this.props.form,
          "bank_account.account_type",
          "current"
        );
        // unregisterField(this.props.form, 'bank_account.id');
        return;
      } else {
        this.setState({ isNewBankAccount: false });
        const fields = Object.keys(selectedBankAccount);
        fields.map(field => {
          if (field === "id") {
            this.props.changeFormField(
              this.props.form,
              "bank_account." + field,
              parseInt(selectedBankAccount[field])
            );
          } else {
            this.props.changeFormField(
              this.props.form,
              "bank_account." + field,
              selectedBankAccount[field]
            );
          }
          return null;
        });
        this.props.changeFormField(
          this.props.form,
          "bank_account.person_type",
          selectedBankAccount &&
            selectedBankAccount.person_type &&
            selectedBankAccount.person_type.value
        );
      }
    }
  }

  handlePersonTypeRadioChange = e => {
    this.setState({ personType: e.target.value });
    this.props.changeFormField(
      this.props.form,
      "bank_account.person_type",
      e.target.value
    );
    this.props.changeFormField(
      this.props.form,
      "bank_account.cpf_or_cnpj",
      null
    );
  };

  renderPersonTypeOptions = () => {
    return (
      <FormGroup>
        <ControlLabel className="label__grey" />
        <Row>
          <Col sm={12} className="radio-group">
            <div>
              <label className="radio-option">
                <Field
                  component="input"
                  disabled={!this.state.isNewBankAccount}
                  name="bank_account.person_type"
                  type="radio"
                  value="individual"
                  onChange={this.handlePersonTypeRadioChange}
                />
                Pessoa Física
              </label>
            </div>
            <div>
              <label className="radio-option">
                <Field
                  component="input"
                  disabled={!this.state.isNewBankAccount}
                  name="bank_account.person_type"
                  type="radio"
                  value="company"
                  onChange={this.handlePersonTypeRadioChange}
                />
                Pessoa Jurídica
              </label>
            </div>
          </Col>
        </Row>
      </FormGroup>
    );
  };

  render() {
    const { formValues } = this.props;
    return (
      <div>
        <Row>
          <Col sm={6}>
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              label="Forma de Recebimento"
              labelClassName="label__grey"
              name="active_contract.payment_method"
              options={receivingMethodTypes}
              onChange={e => this.onChangeBillingMode(e)}
            />
          </Col>
          {formValues &&
            formValues.active_contract &&
            formValues.active_contract.payment_method !== "manual" && (
              <section>
                <Col sm={6}>
                  <Field
                    component={SelectField}
                    controlClassName="form-control__white"
                    label="Conta"
                    type={"number"}
                    labelClassName="label__grey"
                    name="bank_account.id"
                    options={this.getSelectOptions()}
                    onChange={e => this.onChangeAccount(e)}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    component={SelectField}
                    controlClassName="form-control__white"
                    disabled={!this.state.isNewBankAccount}
                    label="Banco"
                    labelClassName="label__grey"
                    name="bank_account.bank_id"
                    options={banks}
                  />
                </Col>
                <Col sm={2}>
                  <Field
                    id="bank_account.agency"
                    component={TextField}
                    controlClassName="form-control__white"
                    disabled={!this.state.isNewBankAccount}
                    label="Agência"
                    labelClassName="label__grey"
                    name="bank_account.agency"
                  />
                </Col>
                <Col sm={2}>
                  <Field
                    id="bank_account.account_number"
                    component={TextField}
                    controlClassName="form-control__white"
                    disabled={!this.state.isNewBankAccount}
                    label="Conta Corrente"
                    labelClassName="label__grey"
                    name="bank_account.account_number"
                  />
                </Col>
                {/* <Col sm={3} hidden>
                  <Field
                    component={SelectField}
                    controlClassName="form-control__white"
                    disabled={!this.state.isNewBankAccount}
                    label="Tipo de Conta"
                    labelClassName="label__grey"
                    name="bank_account.account_type"
                    options={accountTypes}
                  />
                </Col> */}
                <Col sm={5}>
                  <Field
                    id="bank_account.full_name"
                    component={TextField}
                    controlClassName="form-control__white"
                    disabled={!this.state.isNewBankAccount}
                    label="Nome Completo"
                    labelClassName="label__grey"
                    name="bank_account.full_name"
                  />
                </Col>
                <Col sm={4}>{this.renderPersonTypeOptions()}</Col>
                {this.state.personType === "individual" ? (
                  <Col sm={4}>
                    <Field
                      id="bank_account.cpf_or_cnpj"
                      component={TextField}
                      controlClassName="form-control__white"
                      disabled={!this.state.isNewBankAccount}
                      label="CPF"
                      labelClassName="label__grey"
                      name="bank_account.cpf_or_cnpj"
                      format={formatCPF}
                      normalize={normalizeNumericValue}
                    />
                  </Col>
                ) : (
                  <Col sm={4}>
                    <Field
                      id="bank_account.cpf_or_cnpj"
                      component={TextField}
                      controlClassName="form-control__white"
                      disabled={!this.state.isNewBankAccount}
                      label="CNPJ"
                      labelClassName="label__grey"
                      name="bank_account.cpf_or_cnpj"
                      normalize={normalizeNumericValue}
                      format={formatCNPJ}
                    />
                  </Col>
                )}
                <Row />
              </section>
            )}
        </Row>
        <input
          value={this.props.payment_method && this.props.payment_method.value}
          type="hidden"
          id="payment_method"
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  valid: isValid(ownProps.form)(state),
  formValues: getFormValues(ownProps.form)(state),
  initialValues: state.properties.selectedProperty // pull initial values from account reducer
});

const mapDispatchToProps = dispatch => ({
  changeFormField: (formName, attribute, value) =>
    dispatch(change(formName, attribute, value)),
  unregisterField: (formName, attribute) =>
    dispatch(unregisterField(formName, attribute))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceivingAccountData);
