export const receivingMethodTypes = [
  {
    value: "manual",
    icon: "/images/icon_money_bag.svg",
    label: "Manual"
  },
  {
    value: "bank_slip",
    icon: "/images/icon_boleto.svg",
    label: "Boleto"
  }
];
export const receivingMethodStatusTypes = [
  {
    value: "",
    label: "",
    tooltip: "",
    color: "gray"
  },
  {
    value: "in_review",
    label: "Em Revisão",
    tooltip: "",
    color: "orange"
  },
  {
    value: "approved",
    label: "Aprovado",
    tooltip: "",
    color: "green"
  },
  {
    value: "pending",
    label: "Pendente",
    tooltip: "",
    color: "orange"
  },
  {
    value: "not_approved",
    label: "Não aprovado",
    tooltip: "",
    color: "red"
  },
  {
    value: "missing_fields",
    label: "Campos faltantes",
    tooltip: "",
    color: "orange"
  }
];
export const banks = [
  {
    value: "",
    label: ""
  },
  {
    value: "1",
    label: "Banco do Brasil"
  },
  {
    value: "2",
    label: "Itaú"
  },
  {
    value: "3",
    label: "Santander"
  },
  {
    value: "4",
    label: "Banco Real"
  },
  {
    value: "5",
    label: "Bradesco"
  },
  {
    value: "6",
    label: "Citibank"
  },
  {
    value: "7",
    label: "Caixa Econômica Federal"
  },
  {
    value: "8",
    label: "Banco Mercantil"
  },
  {
    value: "9",
    label: "Banco Rural"
  },
  {
    value: "10",
    label: "Banco Safra"
  },
  {
    value: "11",
    label: "Banco Rendimento"
  }
];

export const furnishedTypes = [
  {
    value: "no",
    label: "Não"
  },
  {
    value: "yes",
    label: "Sim"
  },
  {
    value: "semi",
    label: "Semi"
  }
];
export const yesNoOptions = [
  {
    value: 1,
    name: "Sim"
  },
  {
    value: 0,
    name: "Não"
  }
];
export const getNumericOptions = (maxValue, addEmptyOption = true) => {
  let options = Array.from(new Array(maxValue), (val, index) => {
    return { value: index, label: index };
  });

  options.push({ value: maxValue, label: `${maxValue}+` });

  if (addEmptyOption) {
    options.unshift({ value: "", label: "" });
  }

  return options;
};

export const guaranteesList = [
  { value: "deposit", label: "Depósito Caução" },
  { value: "guarantor", label: "Fiador" },
  { value: "bond_insurance", label: "Seguro-Fiança" }
];

export const months = [
  {
    value: "",
    label: ""
  },
  {
    value: "1",
    label: "Janeiro"
  },
  {
    value: "2",
    label: "Fevereiro"
  },
  {
    value: "3",
    label: "Março"
  },
  {
    value: "4",
    label: "Abril"
  },
  {
    value: "5",
    label: "Maio"
  },
  {
    value: "6",
    label: "Junho"
  },
  {
    value: "7",
    label: "Julho"
  },
  {
    value: "8",
    label: "Agosto"
  },
  {
    value: "9",
    label: "Setembro"
  },
  {
    value: "10",
    label: "Outubro"
  },
  {
    value: "11",
    label: "Novembro"
  },
  {
    value: "12",
    label: "Dezembro"
  }
];

export const monthDays = () => {
  let options = Array.from(new Array(31), (val, index) => {
    return { value: index + 1, label: "Dia " + (index + 1) };
  });

  options.unshift({ value: "", label: "" });

  return options;
};

export const propertyTypes = [
  {
    value: "",
    label: ""
  },
  {
    value: "apartment",
    label: "Apartamento"
  },
  {
    value: "house",
    label: "Casa"
  },
  {
    value: "hangar",
    label: "Galpão"
  },
  {
    value: "kitnet",
    label: "Kitnet"
  },
  {
    value: "office",
    label: "Sala Comercial"
  },
  {
    value: "two_story_house",
    label: "Sobrado"
  },
  {
    value: "studio",
    label: "Studio"
  },
  {
    value: "other",
    label: "Outros"
  }
];

export const rentalGuaranteeTypes = [
  {
    value: "",
    label: ""
  },
  {
    value: "guarantor",
    label: "Fiador"
  },
  {
    value: "bond_insurance",
    label: "Seguro-Fiança"
  },
  {
    value: "deposit",
    label: "Caução (depósito de segurança)"
  },
  {
    value: "other",
    label: "Outro"
  },
  {
    value: "no_guarantee",
    label: "Sem Garantia"
  }
];

export const rentalTypes = [
  {
    value: "",
    label: ""
  },
  {
    value: "commercial",
    label: "Comercial"
  },
  {
    value: "residential",
    label: "Residencial"
  }
];

export const paymentForms = [
  {
    value: "",
    label: ""
  },
  {
    value: "wire_transfer",
    label: "Transferência Bancária"
  },
  {
    value: "credit_card",
    label: "Cartão de Crédito"
  },
  {
    value: "bank_slip",
    label: "Boleto"
  },
  {
    value: "cash",
    label: "Dinheiro"
  }
];

// export const chartColors = [
//   { color: 'limeGreen' },
//   { color: 'red' },
//   { color: 'orange' }
// ];

export const paymentStatusTypes = [
  {
    value: "pending",
    label: "A Receber",
    color: "orange"
  },
  {
    value: "completed",
    label: "Recebido",
    color: "limegreen"
  },
  {
    value: "overdue",
    label: "Atrasado",
    color: "red"
  },
  {
    value: "unknown",
    label: "Desconhecido",
    color: "gray"
  }
];
export const listingsStatusTypes = [
  {
    value: "",
    label: "Selecione",
    color: "gray"
  },
  {
    value: "in_review",
    label: "Em revisão",
    color: "orange",
    tooltip:
      "Seu anúncio está em validação, assim que aprovado será divulgado nos melhores portais imobiliários automaticamente!"
  },
  {
    value: "in_changes_review",
    label: "Edição em análise",
    color: "orange",
    tooltip:
      "Sua edição do anúncio está em validação, assim que aprovada a alteração será enviada aos demais portais imobiliários!"
  },
  {
    value: "approved",
    label: "Aprovado",
    color: "green"
  },
  {
    value: "not_approved",
    label: "Não aprovado",
    color: "red",
    tooltip:
      "Seu anúncio não foi aprovado. Foi enviado um Email com o motivo. Edite o anúncio com as correções necessárias e ele irá para validação novamente."
  }
];
