import React from 'react';
import { connect } from 'react-redux';
import { Col, Grid, Row } from 'react-bootstrap';
import UserDataFormSimplified from '../../_components/UserDataForm/UserDataFormSimplified';
import { userActions } from '../../_actions/userActions';

export class SignupPage extends React.Component {
  componentWillMount() {
    document.body.classList.add('guest-user');
  }

  submit = values => {
    const { dispatch } = this.props;
    dispatch(userActions.register(values));
  };

  render() {
    return (
      <Grid>
        <Row>
          <Col md={6} mdPush={3}>
            <UserDataFormSimplified
              alert={this.props.alert}
              loading={this.props.loading}
              onSubmit={this.submit}
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.registration.loading,
  };
}

export default connect(mapStateToProps)(SignupPage);
