import { authHeader } from '../_helpers/authHeader';
import config from '../_helpers/config';

export const userService = {
  login,
  facebookLogin,
  googleLogin,
  logout,
  register,
  getAll,
  getById,
  getSelf,
  update,
  updateSelf,
  changePasswordRequest,
  changePassword,
  delete: _delete
};

function login(email, password) {
  let _email = email.toLowerCase();
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email: _email, password })
  };

  return fetch(`${config.ZIMOBI_API_BASE}/auth`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.access_token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    });
}

function facebookLogin(fbAccessToken) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ access_token: fbAccessToken })
  };

  return fetch(`${config.ZIMOBI_API_BASE}/auth/facebook`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.access_token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    });
}

function googleLogin(googleTokenId) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ access_token: googleTokenId })
  };

  return fetch(`${config.ZIMOBI_API_BASE}/auth/google`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.access_token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function changePasswordRequest(email) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email })
  };

  return fetch(
    `${config.ZIMOBI_API_BASE}/users/password/reset_request`,
    requestOptions
  ).then(handleResponse);
}

function changePassword(email, password, token) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password, token })
  };

  return fetch(`${config.ZIMOBI_API_BASE}/users/password`, requestOptions).then(
    handleResponse
  );
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.ZIMOBI_API_BASE}/users`, requestOptions).then(
    handleResponse
  );
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.ZIMOBI_API_BASE}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function getSelf() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.ZIMOBI_API_BASE}/users/self`, requestOptions)
    .then(handleResponse)
    .then(response => {
      // login successful if there's a jwt token in the response
      if (response.data) {
        let localUser = JSON.parse(localStorage.getItem('user'));
        if (localUser) {
          localStorage.setItem(
            'user',
            JSON.stringify({ ...localUser, data: response.data })
          );
        }
      }
      return response;
    });
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  };

  return fetch(`${config.ZIMOBI_API_BASE}/users`, requestOptions).then(
    handleResponse
  );
}

function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  };

  return fetch(
    `${config.ZIMOBI_API_BASE}/users/${user.id}`,
    requestOptions
  ).then(handleResponse);
}

function updateSelf(user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  };

  return fetch(`${config.ZIMOBI_API_BASE}/users/self`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${config.ZIMOBI_API_BASE}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  if (response.status === 204) {
    return;
  }

  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }

      const error = data || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
