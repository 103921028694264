import React from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { change, Field, arrayPush, arrayRemove ,reduxForm} from "redux-form";
import {
  GenderRadioGroup,
  SelectField,
  TextField,
  FileUploadField,
  DropZoneField
} from "../../FormFields";
import {
  formatCPF,
  formatCNPJ,
  formatPhone
} from "../../../_helpers/reduxFormFormatters";
import { normalizeNumericValue } from "../../../_helpers/reduxFormNormalizers";

class LandlordData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      btnBaseClass: "btn-primary btn-primary__person-type",
      personType: "individual",
      showIdFileBackInput: false,
      idFileCustomButtonAddText: "Adicionar foto verso",
      idFileCustomButtonRemoveText: "Remover foto verso",
      isNewLandlord: props.landlord ? false : true,
      landlord: props.landlord
    };

    this.props.changeFormField(
      this.props.form,
      "landlord.person_type",
      this.state.personType
    );
  }

  componentDidMount() {
    const { selectedLandlord,changeFormField,form } = this.props;
    if (selectedLandlord) {
      this.setState({ isNewLandlord: false });
      changeFormField(form, "landlord", selectedLandlord);
      this.selectPersonType(
        selectedLandlord &&
          selectedLandlord.person_type &&
          selectedLandlord.person_type.value
      );
      changeFormField(
        form,
        "landlord.gender",
        selectedLandlord &&
          selectedLandlord.gender &&
          selectedLandlord.gender.value
      );
    }
  }
  selectPersonType(type) {
    this.setState({ personType: type });
    this.props.changeFormField(this.props.form, "landlord.person_type", type);
  }

  getSelectOptions() {
    let options = [];
    let selectedId =
      this.props.selectedLandlord && this.props.selectedLandlord.id;
    options.push({ value: null, label: "Cadastrar novo proprietário" });
    if (this.props.landlords && this.props.landlords.length > 0) {
      this.props.landlords.forEach(landlord =>
        options.push({
          value: landlord.id,
          selected: landlord.id === selectedId,
          label:
            (landlord.person_type && landlord.person_type.value) ===
            "individual"
              ? landlord.full_name
              : landlord.company_name
        })
      );
    }
    return options;
  }
  handleFileUploadSuccess = (file, files, identifier) => {
    this.props.changeFormField(this.props.form, identifier, files);
  };

  handleRemovedFile = (file, files, identifier) => {
    this.props.changeFormField(this.props.form, identifier, files);
  };
  onChangeAccount = e => {
    const { landlords, form, changeFormField } = this.props;
    if (landlords && landlords.length > 0) {
      const objIndex = e.target.selectedIndex - 1;
      this.setState({ isNewLandlord: true, landlord: void 0 });
      if (objIndex === -1) {
        changeFormField(form, "landlord", null);
        changeFormField(form, "landlord.id", null);
        setTimeout(() => {
          this.setState({ landlord: landlords[objIndex] });
          this.setState({ isNewLandlord: true });
        }, 300);
        this.selectPersonType(this.state.personType);
        return;
      } else {
        let selectedLandlord = landlords[objIndex];
        changeFormField(form, "landlord", selectedLandlord);
        setTimeout(() => {
          this.setState({ landlord: selectedLandlord });
          this.setState({ isNewLandlord: false });
        }, 300);
        this.selectPersonType(
          selectedLandlord &&
            selectedLandlord.person_type &&
            selectedLandlord.person_type.value
        );
        changeFormField(
          form,
          "landlord.gender",
          selectedLandlord &&
            selectedLandlord.gender &&
            selectedLandlord.gender.value
        );
      }
    }
  };

  renderIndividualFields = () => {
    return (
      <div>
        <Row>
          <Col sm={4}>
            <Field
              id="full_name"
              component={TextField}
              controlClassName="form-control__white"
              disabled={!this.state.isNewLandlord}
              label="Nome completo"
              labelClassName="label__grey"
              name="landlord.full_name"
            />
          </Col>
          <Col sm={4}>
            <GenderRadioGroup
              name="landlord.gender"
              disabled={!this.state.isNewLandlord}
            />
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <Field
              id="cpf"
              component={TextField}
              controlClassName="form-control__white"
              disabled={!this.state.isNewLandlord}
              format={formatCPF}
              label="CPF"
              labelClassName="label__grey"
              name="landlord.cpf"
              normalize={normalizeNumericValue}
              type="text"
            />
          </Col>
          <Col md={2}>
            <Field
              id="rg"
              component={TextField}
              controlClassName="form-control__white"
              disabled={!this.state.isNewLandlord}
              label="RG"
              labelClassName="label__grey"
              name="landlord.rg"
              normalize={normalizeNumericValue}
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <Field
              id="phone"
              component={TextField}
              controlClassName="form-control__white"
              disabled={!this.state.isNewLandlord}
              format={formatPhone}
              label="Telefone"
              labelClassName="label__grey"
              name="landlord.phone"
              normalize={normalizeNumericValue}
              type="text"
            />
          </Col>
          <Col md={4}>
            <Field
              id="landlord.email"
              component={TextField}
              disabled={!this.state.isNewLandlord}
              controlClassName="form-control__white"
              label="E-mail"
              labelClassName="label__grey"
              name="landlord.email"
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              label={
                <span>
                  Foto do RG - <small>Frente e Verso</small>
                </span>
              }
              showErrorBesideLabel={true}
              disabled={!this.state.isNewLandlord}
              name="landlord.rg_images_validator"
              onFileRemoved={(file, files) =>
                this.handleRemovedFile(file, files, "landlord.rg_images")
              }
              onFileAdded={(file, files) =>
                this.handleFileUploadSuccess(file, files, "landlord.rg_images")
              }
              maxFiles={2}
              images={this.state.landlord && this.state.landlord.rg_images}
              component={DropZoneField}
            />
          </Col>
          <Col md={6}>
            <Field
              disabled={!this.state.isNewLandlord}
              showErrorBesideLabel={true}
              label={<span>Foto do CPF</span>}
              name="landlord.cpf_images_validator"
              onFileRemoved={(file, files) =>
                this.handleRemovedFile(file, files, "landlord.cpf_images")
              }
              onFileAdded={(file, files) =>
                this.handleFileUploadSuccess(file, files, "landlord.cpf_images")
              }
              maxFiles={2}
              images={this.state.landlord && this.state.landlord.cpf_images}
              component={DropZoneField}
            />
          </Col>
        </Row>
      </div>
    );
  };

  renderCompanyFields = () => {
    return (
      <div>
        <Row>
          <Col sm={4}>
            <Field
              id="company_name"
              component={TextField}
              controlClassName="form-control__white"
              disabled={!this.state.isNewLandlord}
              label="Razão Social"
              labelClassName="label__grey"
              name="landlord.company_name"
              type="text"
            />
          </Col>
          <Col sm={4}>
            <Field
              id="full_name"
              component={TextField}
              controlClassName="form-control__white"
              disabled={!this.state.isNewLandlord}
              label="Nome do Responsável"
              labelClassName="label__grey"
              name="landlord.full_name"
              type="text"
            />
          </Col>
          <Col sm={4}>
            <Field
              id="cnpj"
              component={TextField}
              controlClassName="form-control__white"
              disabled={!this.state.isNewLandlord}
              label="CNPJ"
              labelClassName="label__grey"
              name="landlord.cnpj"
              normalize={normalizeNumericValue}
              format={formatCNPJ}
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Field
              id="state_inscription"
              component={TextField}
              controlClassName="form-control__white"
              disabled={!this.state.isNewLandlord}
              label="Inscrição Estadual"
              labelClassName="label__grey"
              name="landlord.state_inscription"
              type="text"
            />
          </Col>
          <Col sm={4}>
            <Field
              id="phone"
              component={TextField}
              controlClassName="form-control__white"
              disabled={!this.state.isNewLandlord}
              label="Telefone"
              labelClassName="label__grey"
              name="landlord.phone"
              format={formatPhone}
              normalize={normalizeNumericValue}
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <FileUploadField
              id="social_contract_file_url"
              component={TextField}
              controlClassName="form-control__white file_upload_input"
              disabled={!this.state.isNewLandlord}
              label="Contrato Social"
              labelClassName="label__grey"
              name="landlord.social_contract_file_url"
              type="text"
              onFileUploadFieldChange={(file, response) =>
                this.onFileUploadFieldChange(
                  file,
                  "landlord.social_contract_file_url"
                )
              }
            />
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div className="owner-panel">
        <Row>
          <Col sm={12}>
            <Button
              className={
                this.state.personType === "individual"
                  ? this.state.btnBaseClass + " active"
                  : this.state.btnBaseClass
              }
              disabled={!this.state.isNewLandlord}
              onClick={() => this.selectPersonType("individual")}
            >
              Pessoa Física
            </Button>
            <Button
              className={
                this.state.personType === "company"
                  ? this.state.btnBaseClass + " active"
                  : this.state.btnBaseClass
              }
              disabled={!this.state.isNewLandlord}
              onClick={() => this.selectPersonType("company")}
            >
              Pessoa Jurídica
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              label="Selecionar Proprietário"
              labelClassName="label__grey"
              name="landlord.id"
              options={this.getSelectOptions()}
              onChange={this.onChangeAccount}
            />
          </Col>
        </Row>
        {this.state.personType === "individual"
          ? this.renderIndividualFields()
          : this.renderCompanyFields()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  arrayPush: (formName, attribute, value) =>
    dispatch(arrayPush(formName, attribute, value)),
  changeFormField: (formName, attribute, value) =>
    dispatch(change(formName, attribute, value)),
  formArrayRemove: (formName, attribute, index) =>
    dispatch(arrayRemove(formName, attribute, index))
});
const form = reduxForm({
  destroyOnUnmount: true
})(LandlordData);
export default connect(
  null,
  mapDispatchToProps
)(form);
