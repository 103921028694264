import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Doughnut, Line } from 'react-chartjs-2';

export const Donut = ({ labels, datasets, padding }) => {
  const legendOpts = {
    display: true,
    position: 'right',
    reverse: false,
    padding: {
      top: 150
    },
    labels: {
      usePointStyle: true,
      verticalAlign: 'center'
    }
  };
  return labels && datasets ? (
    <div style={{ height: '150px' }}>
      <Doughnut
        responsive={false}
        data={{ labels: labels, datasets: datasets }}
        width={100}
        legend={legendOpts}
        height={50}
        options={{
          tooltips: {
            enabled: false,
            mode: 'single',
            callbacks: {
              label: function(tooltipItems, data) {
                return (
                  tooltipItems.yLabel + ' : ' + tooltipItems.xLabel + ' Files'
                );
              }
            }
          },
          layout: {
            padding
          },
          maintainAspectRatio: false
        }}
      />
    </div>
  ) : (
    ''
  );
};
export const PieChart = ({ pieData }) => (
  <div style={{ height: '300px' }}>
    <ResponsivePie
      data={pieData}
      margin={{
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors="nivo"
      colorBy="id"
      borderWidth={1}
      borderColor="inherit:darker(0.2)"
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#333333"
      animate={false}
      motionStiffness={90}
      motionDamping={15}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          size: 4,
          padding: 1,
          stagger: true
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          rotation: -45,
          lineWidth: 6,
          spacing: 10
        }
      ]}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#000',
          symbolSize: 18,
          symbolShape: 'circle'
        }
      ]}
    />
  </div>
);

export const LineChart = ({ chartData, className }) => {
  const legendOpts = {
    display: false,
    reverse: false,
    labels: {
      fontSize: '6'
    }
  };
  const data = canvas => {
    const ctx = canvas.getContext('2d');
    const gradientbg = ctx.createLinearGradient(0, 0, 0, 200);
    gradientbg.addColorStop(0, 'rgba(97, 199,207, 1)');
    gradientbg.addColorStop(0.8, 'rgba(97, 199,207, 0.1)');
    gradientbg.addColorStop(1, 'rgba(97, 199,207, 0)');
    const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, 'rgba(97, 199,207, 1)');
    gradientStroke.addColorStop(0.5, 'rgba(97, 199,207, 0.5)');
    const gradientbglines = ctx.createLinearGradient(0, 0, 0, 200);
    gradientbglines.addColorStop(0, '#fff');
    gradientbglines.addColorStop(0.5, '#ddd');
    gradientbglines.addColorStop(1, '#fff');
    return {
      labels: chartData.labels,
      datasets: [
        {
          label: chartData.datasets[0].label,
          data: chartData.datasets[0].data,
          backgroundColor: gradientbg,
          borderColor: gradientStroke,
          pointBackgroundColor: gradientStroke,
          pointHoverBackgroundColor: gradientStroke,
          pointHoverBorderColor: gradientStroke,
          lineTension: 0,
          borderWidth: 2,
          borderCapStyle: 'butt'
        }
      ]
    };
  };
  var timeFormat = 'MM/DD/YYYY HH:mm';
  return (
    <Line
      responsive={true}
      data={data}
      legend={legendOpts}
      options={{
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: false,
                labelString: 'Date'
              },
              ticks: {
                userCallback: function(label, index, labels) {
                  if (Math.floor(label) === label) {
                    return label;
                  }
                },
                maxTicksLimit: 5
              },
              gridLines: {
                lineWidth: 1
              }
            }
          ],
          xAxes: [
            {
              type: 'time',
              distribution: 'series',
              gridLines: {
                display: false
              },
              ticks: {
                source: 'data',
                beginAtZero: true,
                fontSize: 9,
                maxRotation: 0, // angle in degrees
                autoSkip: true,
                maxTicksLimit: 4
              },
              time: {
                parser: timeFormat,
                unit: chartData.granularity,
                tooltipFormat: 'll',
                displayFormats: {
                  week: 'MMMYY / [Sem]ww'
                }
              }
            }
          ]
        }
      }}
    />
  );
};
