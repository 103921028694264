import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Field, change, reset } from "redux-form";
import { SelectField, TextField } from "../../FormFields";
import {
  formatCEP,
  formatFiscalIndication,
  formatPropertyInscription
} from "../../../_helpers/reduxFormFormatters";
import { normalizeNumericValue } from "../../../_helpers/reduxFormNormalizers";
import { propertyTypes, rentalTypes } from "../../../_helpers/optionsArrays";
import { searchAddressFromPostalCode } from "../../../_helpers/searchAddressFromPostalCode";
import { renderAddressFormatter } from "../../../_helpers/utils";

class PropertyData extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      generatedCode: props.generatedCode,
      searchingAddress: false,
      searchAddressError: false,
      postalCode: "",
      isNewProperty: true
    };
  
    
  }

  componentDidMount() {
    if (this.props.generateCode) {
      this.generatedCode();
    }
  }
  generatedCode = () => {
    const { form, changeFormField } = this.props;
    changeFormField(form, "code", "z-" + parseInt(Math.random() * 100000, 10));
  };
  clearCodeField() {
    const { form, changeFormField } = this.props;
    changeFormField(form, "code", "");
  }
  onBlurCodeField(e) {
    setTimeout(() => !e.target.value && this.generatedCode(), 600);
  }
  handlePostalCodeInput(postalCode) {
    if (postalCode.length === 10) {
      const { form, changeFormField } = this.props;

      const onlyNums = postalCode.replace(".", "").replace("-", "");
      this.setState({ searchingAddress: true });
      changeFormField(form, "address.cep_error", null);
      searchAddressFromPostalCode(onlyNums)
        .then(address => {
          changeFormField(form, "address.street", address.logradouro);
          changeFormField(form, "address.neighbourhood", address.bairro);
          changeFormField(form, "address.city", address.localidade);
          changeFormField(form, "address.state", address.uf);
          this.setState({
            searchingAddress: false,
            searchAddressError: false
          });
        })
        .catch(error => {
          changeFormField(form, "address.cep_error", "CEP inválido");
          this.setState({
            searchingAddress: false,
            searchAddressError: true
          });
          return;
        });
    }
  }
  getSelectOptions = () => {
    let properties = this.props.properties;
    let options = [];
    options.push({ value: null, label: "Cadastrar nova propriedade" });
    if (properties && properties.length > 0) {
      properties.forEach(property => {
        if (property) {
          options.push({
            value: property.id,
            label:
              "ID:" +
              property.id +
              " - " +
              renderAddressFormatter(property.address)
          });
        }
      });
    }
    return options;
  };

  onChangeProperty = e => {
    let properties = this.props.properties;
    if (properties && properties.length > 0) {
      const objIndex = e.target.selectedIndex;
      if (objIndex === 0) {
        this.props.reset(this.props.form);
        this.setState({ isNewProperty: true });
        this.props.changeFormField(this.props.form, "property_id", null);
        return;
      } else {
        this.setState({ isNewProperty: false });
        let obj = properties[objIndex - 1];
        if (obj) {
          const fields = Object.keys(obj);
          fields.map(field => {
            this.props.changeFormField(
              this.props.form,
              field,
              properties[objIndex - 1][field]
            );
            return null;
          });
        }
      }
    }
  };
  render() {
    return (
      <div>
        <Row>
          <Col md={6} hidden={this.props.isNew}>
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              label="Selecionar Imóvel"
              labelClassName="label__grey"
              name="id"
              options={this.getSelectOptions()}
              onChange={e => this.onChangeProperty(e)}
            />
          </Col>

          <Col md={3}>
            <Field
              id="code"
              component={TextField}
              controlClassName="form-control__white"
              label="Id do imóvel"
              tooltiplabel={
                " É um código de identificação gerado automaticamente pela Zimobi."
              }
              labelClassName="label__grey"
              name="code"
              onChange={() => this.setState({ generatedCode: false })}
              onBlur={e => this.onBlurCodeField(e)}
              onFocus={() => this.clearCodeField()}
              disabled={true}
            />
          </Col>
          <Col md={3}>
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              label="Tipo de Imóvel"
              labelClassName="label__grey"
              name="property_type"
              options={propertyTypes}
              disabled={!this.state.isNewProperty}
            />
          </Col>
          <Col md={3}>
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              label="Tipo do Aluguel"
              labelClassName="label__grey"
              name="rental_type"
              options={rentalTypes}
              disabled={!this.state.isNewProperty}
            />
          </Col>
          <Col md={3}>
            <Field
              id="postal_code"
              component={TextField}
              controlClassName="form-control__white"
              format={formatCEP}
              label="CEP"
              labelClassName="label__grey"
              name="address.postal_code"
              normalize={normalizeNumericValue}
              onChange={event => {
                this.handlePostalCodeInput(event.target.value);
                this.setState({ postalCode: event.target.value });
              }}
              onKeyUp={event => this.handlePostalCodeInput(event.target.value)}
              disabled={!this.state.isNewProperty}
            />
          </Col>
          <Col md={4}>
            <Field
              id="street"
              component={TextField}
              controlClassName="form-control__white"
              label="Rua"
              labelClassName="label__grey"
              name="address.street"
              disabled={!this.state.isNewProperty}
            />
          </Col>
          <Col md={2}>
            <Field
              id="number"
              component={TextField}
              controlClassName="form-control__white"
              label="Número"
              labelClassName="label__grey"
              name="address.number"
              disabled={!this.state.isNewProperty}
            />
          </Col>
          <Col md={3}>
            <Field
              id="complement"
              component={TextField}
              controlClassName="form-control__white"
              label="Complemento"
              labelClassName="label__grey"
              name="address.complement"
              disabled={!this.state.isNewProperty}
            />
          </Col>
          <Col md={3}>
            <Field
              id="neighbourhood"
              component={TextField}
              controlClassName="form-control__white"
              label="Bairro"
              labelClassName="label__grey"
              name="address.neighbourhood"
              disabled={!this.state.isNewProperty}
            />
          </Col>
          <Col md={3}>
            <Field
              id="city"
              component={TextField}
              controlClassName="form-control__white"
              label="Cidade"
              labelClassName="label__grey"
              name="address.city"
              disabled={!this.state.isNewProperty}
            />
          </Col>
          <Col md={3}>
            <Field
              id="state"
              component={TextField}
              controlClassName="form-control__white"
              label="Estado"
              labelClassName="label__grey"
              name="address.state"
              disabled={!this.state.isNewProperty}
            />
          </Col>
          <Col md={3}>
            <Field
              id="fiscal_indication"
              component={TextField}
              controlClassName="form-control__white"
              format={formatFiscalIndication}
              label="Indicação fiscal"
              labelClassName="label__grey"
              name="fiscal_indication"
              normalize={normalizeNumericValue}
              disabled={!this.state.isNewProperty}
            />
          </Col>
          <Col md={3}>
            <Field
              id="property_inscription"
              component={TextField}
              controlClassName="form-control__white"
              format={formatPropertyInscription}
              label="Inscrição Imobiliária"
              labelClassName="label__grey"
              name="property_inscription"
              normalize={normalizeNumericValue}
              disabled={!this.state.isNewProperty}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeFormField: (formName, attribute, value) =>
    dispatch(change(formName, attribute, value)),
  reset: formName => dispatch(reset(formName))
});

export default connect(
  null,
  mapDispatchToProps
)(PropertyData);
