import React from 'react';
import { reduxForm } from 'redux-form';
import { Button, Col, Panel, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import validate from '../PropertyListingForm/Validations';
let formName;
class PropertyDataFormPanel extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.myRef = React.createRef(); // create a ref object
    this.state = {
      errors: [],
      titleBaseClass: 'panel-title__property-register'
    };
    formName = props.form + props.eventKey;
  }

  componentWillReceiveProps(prevProps) {
    setTimeout(() => {
      if (prevProps.invalid !== this.props.invalid) {
        prevProps.handleValidationChange(
          this.props.eventKey,
          this.props.invalid
        );
      }
    }, 1000);
  }

  getClassName = (isActive, isInvalid) => {
    if (isInvalid) {
      return this.state.titleBaseClass + ' invalid';
    }
    if (!isInvalid) {
      return this.state.titleBaseClass + ' completed';
    }
    return this.state.titleBaseClass;
  };
  render() {
    const {
      panel,
      body,
      eventKey,
      handleSubmit,
      // isActive,
      onBackButtonClick,
      showBackButton,
      showNextButton
      // invalid
    } = this.props;

    return (
      <div name={`panel-${eventKey}`} id={`panel-${eventKey}`}>
        <Panel
          className="panel__property-register"
          eventKey={eventKey}
          onToggle={() => false}
          expanded={true}
        >
          <Panel.Heading
            className="panel-heading__property-register"
            // onClick={() => toogleOpenPanel(eventKey)}
          >
            <Panel.Title className={this.state.titleBaseClass + ' open'}>
              <span>{this.props.title}</span>
              {/* <Image src="/images/panel_arrow.png" className="panel-arrow" /> */}
            </Panel.Title>
          </Panel.Heading>
          {/* <Panel.Collapse> */}
          <form className="form" onSubmit={handleSubmit}>
            <Panel.Body className="panel-body__property-register">
              {body}
            </Panel.Body>
            <Panel.Footer className="panel-footer__property-register">
              <Row
                style={{
                  display: panel && panel.hideButtons === true ? 'none' : ''
                }}
              >
                <Col xs={12} hidden={true}>
                  {showNextButton && (
                    <Button
                      bsStyle="primary"
                      className="btn_primary__next pull-right"
                      type="submit"
                    >
                      PRÓXIMO
                    </Button>
                  )}
                  {showBackButton && (
                    <Button
                      className="btn-primary__back pull-right"
                      onClick={onBackButtonClick}
                    >
                      VOLTAR
                    </Button>
                  )}
                </Col>
              </Row>
            </Panel.Footer>
          </form>
          {/* </Panel.Collapse> */}
        </Panel>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    state
  };
};

const form = reduxForm({
  form: formName,
  destroyOnUnmount: false,
  validate
})(PropertyDataFormPanel);

export default connect(mapStateToProps)(form);
