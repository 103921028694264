import React from 'react';
import { Link } from 'react-router-dom';
import { userActions } from '../_actions/userActions';
import { Image } from 'react-bootstrap';

class NewSideMenu extends React.Component {
  logout = () => {
    this.props.dispatch(userActions.logout());
  };

  render() {
    const menuData = this.props.menuData;
    const RenderMenuItem = ({ to, icon, label, children, isChildren }) => {
      return (
        <li
          style={{ paddingLeft: isChildren ? '6rem' : '' }}
          className={
            this.props.currentLocation &&
            to === this.props.currentLocation ?
            ' activeMenuItem' : undefined
          }
        >
          <Link to={to}>
            <div>
              <i
                className={`icon glyphicon glyphicon-${icon} bm-morph-shape`}
                style={{
                  fill: '#61c7cf',
                  top: '4px',
                  fontSize: '1.8rem',
                  marginRight: '1.3rem'
                }}
              />
              {label}
            </div>
          </Link>
        </li>
      );
    };

    return (
      <div>
        <div className="logo-wrapper selectDisable">
          <Link to={'/dashboard'}>
            <Image alt="logo" src="/images/logo_inverted.png" />
          </Link>
        </div>
        <div>
          <ul>
            {menuData
              .filter(m => !m.hidden)
              .map((menu, key) => {
                let items = [
                  <RenderMenuItem
                    key={key}
                    to={menu.to}
                    icon={menu.iconName}
                    label={menu.label}
                    children={menu.subitems}
                  />
                ];
                menu.subitems &&
                  menu.subitems.map((sub, key1) =>
                    items.push(
                      <RenderMenuItem
                        key={key1}
                        to={sub.to}
                        icon={sub.iconName}
                        label={sub.label}
                        children={sub.subitems}
                        isChildren={true}
                      />
                    )
                  );
                return items;
              })}
          </ul>
        </div>
      </div>
    );
  }
}

export default NewSideMenu;
