import { listingConstants } from "../_constants/listingConstants";
import { listingService } from "../_services/listingService";
import { alertActions } from "./alertActions";
import { userActions } from "./userActions";
import { history } from "../_helpers/history";
import { userConstants } from "../_constants/userConstants";
import { userService } from "../_services/userService";
import { propertyConstants } from "../_constants/propertyConstants";
import { propertyService } from "../_services/propertyService";
import { propertyActions } from "./propertyActions";
export { getLatLngFromAddress };
const api_key = "AIzaSyDL99UXqvOMEzOseNvDm3SBSNdhdKonWks";
function getLatLngFromAddress(address) {
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${api_key}`
  )
    .then(resp => {
      return resp.json();
    })
    .then(data => {
      if (data && data.results && data.results.length > 0) {
        var latitude = data.results[0].geometry.location.lat;
        var longitude = data.results[0].geometry.location.lng;
        return { lat: latitude, lng: longitude };
      } else {
        return { lat: 0, lng: 0 };
      }
    });
}
export const listingActions = {
  findById,
  findByPropertyId,
  findNearbyListings,
  findMore,
  registerWithUser,
  findAll,
  update,
  reviewListing,
  findAllSortingBy,
  sortByListingStatus,
  showListingConfirmationDialog,
  showExclusionConfirmationDialog,
  hideListingConfirmationDialog,
  deleteListingById,
  sendContractProcessNotification,
  sendPaymentManagementNotification,
  filterApiPropertiesById
};
function sendContractProcessNotification(propertyId) {
  return dispatch => {
    listingService
      .sendContractProcessNotification(propertyId)
      .then(response => {
        dispatch(
          alertActions.showMessageDialog(
            "Entendido!",
            "Notificando o pessoal de emissão de contratos! Em breve entraremos em contato",
            "success"
          )
        );
      })
      .catch(error => {
        dispatch(
          alertActions.showMessageDialog(
            "Erro!",
            "Erro ao solicitar pedido",
            "error"
          )
        );
      });
  };
}
function sendPaymentManagementNotification(propertyId) {
  return dispatch => {
    listingService
      .sendPaymentManagementNotification(propertyId)
      .then(response => {
        dispatch(
          alertActions.showMessageDialog(
            "Entendido!",
            "Vamos administrar seus pagamentos via boleto então, em breve entraremos em contato para acertar os detalhes",
            "success"
          )
        );
      })
      .catch(error => {
        dispatch(
          alertActions.showMessageDialog(
            "Erro!",
            "Erro ao solicitar pedido",
            "error"
          )
        );
      });
  };
}
function deleteListingById(listingId) {
  return dispatch => {
    listingService
      .removeListingById(listingId)
      .then(response => {
        dispatch(listingActions.findAll());
        dispatch(
          alertActions.showMessageDialog(
            "Pronto",
            "Seu anúncio foi deletado",
            "success"
          )
        );
      })
      .catch(error => {
        dispatch(
          alertActions.showMessageDialog(
            "Erro!",
            "Erro ao deletar anúncio " + listingId,
            "error"
          )
        );
      });
  };
}
function showExclusionConfirmationDialog(listingId) {
  return dispatch => {
    dispatch(request({ listingId }));
  };
  function request(body) {
    return {
      type: listingConstants.SHOW_LISTING_EXCLUSION_CONFIRMATION_DIALOG,
      body
    };
  }
}
function hideListingConfirmationDialog() {
  return dispatch => {
    dispatch(request({}));
  };
  function request() {
    return { type: listingConstants.HIDE_LISTING_CONFIRMATION_DIALOG };
  }
}
function showListingConfirmationDialog(listing) {
  return dispatch => {
    dispatch(request({ listing }));
  };
  function request(body) {
    return { type: listingConstants.SHOW_LISTING_CONFIRMATION_DIALOG, body };
  }
}
function update(listing) {
  return dispatch => {
    dispatch(request(listing));
    listingService.update(listing).then(
      response => {
        dispatch(success());
        history.push("/dashboard");
        dispatch(alertActions.success("Imóvel atualizado com sucesso!"));
      },
      error => {
        dispatch(failure());
        dispatch(
          alertActions.error(
            "Não foi possível atualizar o imóvel. Verifique as informações e tente novamente."
          )
        );
      }
    );
  };

  function request(listing) {
    return { type: listingConstants.LISTING_UPDATE_REQUEST, listing };
  }
  function success(listing) {
    return { type: listingConstants.LISTING_UPDATE_SUCCESS, listing };
  }
  function failure(error) {
    return { type: listingConstants.LISTING_UPDATE_ERROR, error };
  }
}
function sortByListingStatus(status, items) {
  return dispatch => {
    dispatch(request(items, status));
  };
  function request(itemsToSort, currentSortStatus) {
    return {
      type: listingConstants.LISTING_SORT_BY_STATUS,
      itemsToSort,
      currentSortStatus
    };
  }
}
function reviewListing(id, payload) {
  let body = { ...payload.listing };
  body.operation = payload.operation;
  console.debug(body);
  return dispatch => {
    dispatch(request(payload.operation));
    listingService
      .reviewListing(id, body)
      .then(response => {
        dispatch(success({ id, payload }));
        dispatch(listingActions.findById(id));
      })
      .catch(error => {
        dispatch(propertyActions.hideModals());
        dispatch(failure(error));
        dispatch(alertActions.error("Não foi possível realizar a operação"));
      });
  };

  function request() {
    return { type: listingConstants.REVIEW_PROCESS_REQUEST };
  }
  function success(body) {
    return { type: listingConstants.REVIEW_PROCESS_SUCCESS, body };
  }
  function failure(error) {
    return { type: listingConstants.REVIEW_PROCESS_ERROR, error };
  }
}
function findAllSortingBy(field, order = "asc") {
  return dispatch => {
    dispatch(request());
    listingService.findAllSortingBy(field, order).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(`Não foi possível executar a operação`));
      }
    );
  };

  function request() {
    return { type: listingConstants.LISTING_GETALL_REQUEST };
  }
  function success(response) {
    return { type: listingConstants.LISTING_GETALL_SUCCESS, response ,filtering: false};
  }
  function failure(error) {
    return { type: listingConstants.LISTING_GETALL_FAILURE, error };
  }
}
function findAll(url) {
  return dispatch => {
    dispatch(request());
    listingService.getAll(url).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(`Não foi possível executar a operação`));
      }
    );
  };

  function request() {
    return { type: listingConstants.LISTING_GETALL_REQUEST };
  }
  function success(response) {
    return { type: listingConstants.LISTING_GETALL_SUCCESS, response ,filtering: false};
  }
  function failure(error) {
    return { type: listingConstants.LISTING_GETALL_FAILURE, error };
  }
}

function filterApiPropertiesById(searchText) {
  return dispatch => {
    dispatch(request(searchText));
    listingService.searchPropertyLike("property_id", searchText).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(`Não foi possível executar a operação`));
      }
    );
  };

  function request() {
    return { type: listingConstants.LISTING_GETALL_REQUEST };
  }
  function success(response) {
    return { type: listingConstants.LISTING_GETALL_SUCCESS, response, filtering: true };
  }
  function failure(error) {
    return { type: listingConstants.LISTING_GETALL_FAILURE, error };
  }
}

function findByPropertyId(propertyId) {
  return dispatch => {
    dispatch(request(propertyId));
    listingService
      .getByPropertyId(propertyId)
      .then(response => {
        let listing = response.data.find(l => l.is_active);
        dispatch(success(listing));
        return listing;
      })
      .then(listing => {
        if (listing && listing.property) {
          const {
            street,
            number,
            neighbourhood,
            city
          } = listing.property.address;
          const addresText = `${street} ${number}, ${neighbourhood}, ${city}`;
          getLatLngFromAddress(addresText).then(latlng => {
            dispatch({
              type: listingConstants.LISTING_LATLNG_SUCCESS,
              latlng: latlng
            });
          });
        } else {
          throw new Error("Não foi possível localizar este imóvel.");
        }
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            "Não foi possível localizar detalhes do imóvel ID:" +
              propertyId +
              " contate o administrador"
          )
        );
      });
  };

  function request(listing) {
    return { type: listingConstants.LISTING_REQUEST, listing };
  }
  function success(listing) {
    return { type: listingConstants.LISTING_SUCCESS, listing };
  }
  function failure(error) {
    return { type: listingConstants.LISTING_FAILURE, error };
  }
}
function findById(listingId) {
  return dispatch => {
    dispatch(request(listingId));
    listingService.getById(listingId).then(
      response => {
        const listing = response.data;
        dispatch(success(listing));
        if (listing.property) {
          const {
            street,
            number,
            neighbourhood,
            city
          } = listing.property.address;
          const addresText = `${street} ${number}, ${neighbourhood}, ${city}`;
          getLatLngFromAddress(addresText).then(latlng => {
            dispatch({
              type: listingConstants.LISTING_LATLNG_SUCCESS,
              latlng: latlng
            });
          });
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error("Não foi possível localizar este imóvel."));
      }
    );
  };

  function request(listing) {
    return { type: listingConstants.LISTING_REQUEST, listing };
  }
  function success(listing) {
    return { type: listingConstants.LISTING_SUCCESS, listing };
  }
  function failure(error) {
    return { type: listingConstants.LISTING_FAILURE, error };
  }
}
function findNearbyListings(qnty) {
  return dispatch => {
    dispatch(request(qnty));
    listingService.getNearbyListings(qnty).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure());
        dispatch(
          alertActions.error("Não foi possível localizar imóveis próximos.")
        );
      }
    );
  };

  function request(qnty) {
    return { type: listingConstants.LISTING_NEARBY_REQUEST, qnty };
  }
  function success(listings) {
    return { type: listingConstants.LISTING_NEARBY_SUCCESS, listings };
  }
  function failure(error) {
    return { type: listingConstants.LISTING_NEARBY_FAILURE, error };
  }
}

function findMore(url) {
  return dispatch => {
    dispatch(request());
    listingService.loadMeta(url).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure());
        dispatch(
          alertActions.error("Não foi possível localizar imóveis próximos.")
        );
      }
    );
  };

  function request() {
    return { type: listingConstants.LISTING_NEARBY_REQUEST };
  }
  function success(listings) {
    return { type: listingConstants.LISTING_NEARBY_MORE_SUCCESS, listings };
  }
  function failure(error) {
    return { type: listingConstants.LISTING_NEARBY_FAILURE, error };
  }
}

function register(listing) {
  return dispatch => {
    dispatch(request(listing));

    propertyService.register(listing).then(
      response => {
        dispatch(success());
        history.push("/dashboard");
        dispatch(alertActions.success("Anúncio cadastrado com sucesso!"));
      },
      error => {
        dispatch(failure());
        dispatch(
          alertActions.error(
            "Não foi possível cadastrar o imóvel. Verifique as informações e tente novamente."
          )
        );
      }
    );
  };

  function request(property) {
    return { type: propertyConstants.REGISTER_REQUEST, property };
  }
  function success(property) {
    return { type: propertyConstants.REGISTER_SUCCESS, property };
  }
  function failure(error) {
    return { type: propertyConstants.REGISTER_FAILURE, error };
  }
}

function registerWithUser(listing) {
  return dispatch => {
    dispatch(request(listing));

    if (listing.new_user_attributes.action === "create_account") {
      userService
        .register(listing.new_user_attributes)
        .then(response => {
          return loginUserAndSaveListing(dispatch, listing);
        })
        .catch(error => {
          dispatch(failure(error));
          let errorMessage =
            "Não foi possível efetuar o seu cadastro. Verifique as informações e tente novamente.";

          if (error.email) {
            errorMessage = "Já existe um usuário cadastrado com este email.";
          }

          dispatch(alertActions.error(errorMessage));
        });
    } else {
      return loginUserAndSaveListing(dispatch, listing);
    }
  };

  function loginUserAndSaveListing(dispatch, data) {
    userService
      .login(data.new_user_attributes.email, data.new_user_attributes.password)
      .then(user => {
        dispatch({ type: userConstants.LOGIN_SUCCESS, user });
        dispatch(userActions.getSelf());
        dispatch(register(data));
        dispatch(success(data));
      })
      .catch(error => {
        dispatch(failure(error.toString()));
      });
  }

  function request(property) {
    return { type: propertyConstants.REGISTER_REQUEST, property };
  }
  function success(property) {
    return { type: propertyConstants.REGISTER_SUCCESS, property };
  }
  function failure(error) {
    return { type: propertyConstants.REGISTER_FAILURE, error };
  }
}
