import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

class PublicRoute extends React.Component {
  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        component={props =>
          this.props.loggedIn ? (
            <Component {...props} />
          ) : (
            <div>
              <Component {...props} />
            </div>
          )
        }
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.authentication.loggedIn
  };
}

export default connect(mapStateToProps)(PublicRoute);
