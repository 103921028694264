import React from "react";
import { Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { alertActions } from "../_actions/alertActions";
import { history } from "../_helpers/history";
import { PrivateRoute } from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import LoginContainer from "../_scenes/Login";
import ForgotPasswordPage from "../_scenes/Login/ForgotPasswordPage";
import ChangePasswordPage from "../_scenes/Login/ChangePasswordPage";
import SignupPage from "../_scenes/SignupPage/SignupPage";
import ProfilePage from "../_scenes/ProfilePage/ProfilePage";
import RentedPropertyNewPage from "../_scenes/Properties/New";
import RentedPropertyEditPage from "../_scenes/Properties/Edit";
import PropertyListingNewPage from "../_scenes/Listing/New";
import PublicPropertyListingNewPage from "../_scenes/PublicListing/PublicNew";
import ListingsContainer from "../_scenes/Dashboard/ListingsContainer";
import PropertyListingEditPage from "../_scenes/Listing/Edit";
import ViewPropertyListing from "../_scenes/PublicListing/ViewPropertyListing";
import BillingPageOverview from "../_scenes/Dashboard/BillingPageContainer";
import RentedProperties from "../_scenes/Dashboard/PropertiesContainer";
import NewDashboardPage from "../_scenes/Dashboard/NewDashboardPage";
// import WizardForm from "../_components/Contracts/WizardForm";
import ContractsContainer from "../_scenes/Dashboard/ContractsContainer";
import SideNavigation from "../_components/Shared/SideNavigation";
import smartlookClient from 'smartlook-client'

let previousLocation = "";
class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    history.listen((location, action) => {
      if (location.pathname !== previousLocation) {
        dispatch(alertActions.clear());
      }
      previousLocation = location.pathname;
    });
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <PublicRoute
            path="/"
            component={LoginContainer}
            exact={true}
            {...this.props}
          />
          <PublicRoute path="/login" component={LoginContainer} exact={true} />
          <PublicRoute path="/forgot-password" component={ForgotPasswordPage} />
          <PublicRoute path="/change-password" component={ChangePasswordPage} />
          <PublicRoute path="/signup" component={SignupPage} exact={true} />
          <PublicRoute
            path="/signup/listings"
            component={PublicPropertyListingNewPage}
          />
          <PublicRoute
            path="/listings/view/:propertyId"
            component={ViewPropertyListing}
            exact={true}
          />
          <SideNavigation
            currentLocation={this.props.currentLocation}
            user={this.props.user}
            profileLoading={this.props.profileLoading}
            showPayments={this.props.showPayments}
          >
            <PrivateRoute path="/dashboard" component={NewDashboardPage} />
            <PrivateRoute path="/billing" component={BillingPageOverview} />
            <PrivateRoute
              exact={true}
              path="/contracts"
              component={ContractsContainer}
            />
            {/* <PrivateRoute path="/contracts/new" component={WizardForm} /> */}
            <PrivateRoute path="/profile" component={ProfilePage} />
            <PrivateRoute
              path="/properties/new"
              component={RentedPropertyNewPage}
            />
            <PrivateRoute
              path="/properties/edit/:propertyId/:section"
              component={RentedPropertyEditPage}
            />
            <PrivateRoute
              path="/properties/edit/:propertyId"
              component={RentedPropertyEditPage}
              exact={true}
            />
            <PrivateRoute
              path="/properties"
              component={RentedProperties}
              exact={true}
            />
            <PrivateRoute
              path="/listings/new"
              component={PropertyListingNewPage}
            />
            <PrivateRoute
              path="/listings/edit/:propertyId"
              component={PropertyListingEditPage}
            />
            <PrivateRoute
              path="/listings"
              component={ListingsContainer}
              exact={true}
            />
          </SideNavigation>
        </Switch>
      </Router>
    );
  }

  componentDidMount() {
    smartlookClient.init(process.env.REACT_APP_SMARTLOOK_KEY)
  }
  componentDidUpdate() {
    if(this.props.user) {
      smartlookClient.identify(this.props.user.id, {
        name: this.props.user.full_name,
        email: this.props.user.email,
        version: process.env.REACT_APP_VERSION
      })
    }
  }
}

function mapStateToProps(state) {
  return {
    currentLocation: window.location.pathname,
    user: state.authentication.user && state.authentication.user.data,
    profileLoading: state.authentication.profileLoading,
    showPayments:
      state.payments && state.payments.items && state.payments.items.length > 0
  };
}

export default connect(mapStateToProps)(AppRouter);
