export const mapPropertyToEdit = data => {
  let newData = {
    code: data.code,
    fiscal_indication: data.fiscal_indication,
    id: data.id,
    is_data_valid: data.is_data_valid,
    number_of_bathrooms: data.number_of_bathrooms,
    number_of_parking_spots: data.number_of_parking_spots,
    number_of_rooms: data.number_of_rooms,
    number_of_suites: data.number_of_suites,
    property_inscription: data.property_inscription,
    property_type: data.property_type,
    rental_type: data.rental_type,
    status: data.status,
    usable_floor_area_in_square_meters: data.usable_floor_area_in_square_meters,
    user_id: data.user_id
  };
  newData.address_attributes = data.address;
  if (
    data.active_contract &&
    data.active_contract.payment_method !== 'manual' &&
    data.bank_account
  ) {
    if (parseInt(data.bank_account.id) > 0) {
      newData.bank_account_id = parseInt(data.bank_account.id);
    } else {
      newData.bank_account_attributes = data.bank_account;
    }
  }

  if (newData.bank_account_attributes && data.user_id) {
    newData.bank_account_attributes.user_id = data.user_id;
  }

  if (data.landlord) {
    if (parseInt(data.landlord.id) > 0) {
      newData.landlord_id = data.landlord.id;
    } else {
      if (data.landlord.full_name) {
        newData.landlord_attributes = data.landlord;
        newData.landlord_attributes.rg_images_attributes =
          data.landlord.rg_images;
        newData.landlord_attributes.cpf_images_attributes =
          data.landlord.cpf_images;
      }
    }
  }

  if (data.listing) {
    newData.listing_attributes = data.listing;
    newData.listing_attributes.images_attributes = [];
    if (data.listing.images) {
      newData.listing_attributes.images_attributes = data.listing.images;
    }
    if (data.listing && data.listing.expenses) {
      newData.listing_attributes.expenses_attributes = data.listing.expenses;
    }
  }

  if (data.active_contract) {
    newData.contracts_attributes = [data.active_contract];
    if (data.active_contract.expenses) {
      newData.contracts_attributes[0].expenses_attributes =
        data.active_contract.expenses;
    }
    if (data.active_contract.tenant) {
      let tenant_attributes = {};
      tenant_attributes.rg_images_attributes =
        data.active_contract.tenant.rg_images;
      tenant_attributes.cpf_images_attributes =
        data.active_contract.tenant.cpf_images;
      tenant_attributes.statements_images_attributes =
        data.active_contract.tenant.statements_images;
      tenant_attributes.address_images_attributes =
        data.active_contract.tenant.address_images;

      if (data.active_contract.tenant.cpf_images) {
        delete data.active_contract.tenant['cpf_images'];
      }
      if (data.active_contract.tenant.rg_images) {
        delete data.active_contract.tenant['rg_images'];
      }
      tenant_attributes = {
        ...data.active_contract.tenant,
        ...tenant_attributes
      };
      newData.contracts_attributes[0].tenant_attributes = tenant_attributes;
      if (newData.contracts_attributes[0]['tenant']) {
        delete newData.contracts_attributes[0]['tenant'];
      }
    }
  }
  Object.keys(newData).forEach(
    key => (newData[key] == null || newData[key] === '') && delete newData[key]
  );
  return newData;
};

export const mapListingToEdit = data => {
  let address_attributes = data.property && data.property.address;
  let listing_attributes = {
    rental_price_in_cents: data.rental_price_in_cents,
    condo_fee_in_cents: data.condo_fee_in_cents,
    rental_guarantee: data.rental_guarantee,
    iptu_price_in_cents: data.iptu_price_in_cents,
    deposit_amount_in_cents: data.deposit_amount_in_cents,
    title: data.title,
    furnish_type: data.furnish_type,
    guarantee_description: data.guarantee_description,
    description: data.description,
    images_attributes: data.images.map((image, index, arrayImages) => {
      image.order = index + 1;
      return image;
    }),
    total_price_in_cents: data.total_price_in_cents
  };
  if (Object.keys(listing_attributes).length === 0) {
    listing_attributes = void 0;
  }
  let property = data.property;
  const newData = {
    address_attributes,
    id: property && property.id,
    status: data.status,
    code: property && property.code,
    property_type: property && property.property_type,
    rental_type: property && property.rental_type,
    usable_floor_area_in_square_meters:
      property && property.usable_floor_area_in_square_meters,
    number_of_rooms: property && property.number_of_rooms,
    number_of_bathrooms: property && property.number_of_bathrooms,
    number_of_suites: property && property.number_of_suites,
    number_of_parking_spots: property && property.number_of_parking_spots,
    listing_attributes
  };
  Object.keys(newData).forEach(
    key => newData[key] == null && delete newData[key]
  );
  return newData;
};
