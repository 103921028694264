import React from "react";
import { reduxForm, change } from "redux-form";
import { Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { alertActions } from "../../_actions/alertActions";
var cursorFocus = function(elem) {
  var x = window.scrollX,
    y = window.scrollY;
  setTimeout(() => {
    window.scrollTo(x, y);
    elem.focus();
  }, 200);
};
export class FormSubmit extends React.Component {
  handleMySubmit = e => {
    e.preventDefault();
    let fields = document.getElementsByClassName("field-error");
    // let fields =document.querySelectorAll("field-error");
    this.props.handleSubmit();
    setTimeout(() => {
      if (fields && fields.length > 0) {
        let field = fields[0];
        if (field) {
          if (field.previousElementSibling && field.previousElementSibling.nodeName !== 'LABEL') {
            cursorFocus(field.previousElementSibling);
          }else{
            this.props.dispatch(alertActions.error(field.innerHTML));
          }
        }
      }
    }, 200);
  };
  render() {
    return (
      <form onSubmit={e => this.handleMySubmit(e)}>
        <Row>
          <Col className="text-center">
            <Button
              id="btn"
              bsStyle="primary"
              className="btn_primary__register"
              type={"submit"}
              // disabled={!this.props.canSubmit}
            >
              {this.props.loading
                ? "PROCESSANDO..."
                : this.props.isNew
                ? "CADASTRAR"
                : "SALVAR ALTERAÇÕES"}
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

const form = reduxForm({
  destroyOnUnmount: true
})(FormSubmit);
const mapDispatchToProps = dispatch => ({
  changeFormField: (formName, attribute, value) =>
    dispatch(change(formName, attribute, value))
});
const mapStateToProps = state => ({
  loading: (state.properties && state.properties.loading) || false
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form);
