import React from 'react';

export const RenderPicThumb = ({ src }) => {
  return (
    <a href={src ? src : '#'} target="blank">
      <img
        className="img-fluid d-inline"
        style={{
          margin: '2px',
          marginBottom: '15px',
          borderRadius: '10px',
          maxWidth: '110px',
          opacity: src ? 1 : 0.5,
          objectFit: 'cover',
          cursor: 'pointer'
        }}
        alt="MINI"
        width={'100%'}
        height={80}
        src={src ? src : 'https://via.placeholder.com/120x100'}
      />
    </a>
  );
};

export default RenderPicThumb;
