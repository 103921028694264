import { billingConstants } from '../_constants/billingConstants';
import { paymentConstants } from '../_constants/paymentConstants';
import { paymentService } from '../_services/paymentService';
import { alertActions } from './alertActions';
import { propertyService } from '../_services/propertyService';
import { userActions } from './userActions';
import { propertyActions } from './propertyActions';
export const billingActions = {
  showNewBoletoModal,
  showMarkAsPaidModal,
  showPropertyViewModal,
  showPropertyApprovalModal,
  showPropertyPaymentMethodApprovalModal,
  findAllPayments,
  // changeContractBillingType,
  showSharingDocumentsModal
};

// function changeContractBillingType(payment_method, id, propertyId) {
//   return dispatch => {
//     dispatch(request({ payment_method, id }));
//     paymentService.updateContract(id, payment_method).then(
//       response => {
//         dispatch(success(response.data));
//         dispatch(propertyActions.findById(propertyId));
//       },
//       error => {
//         dispatch(failure());
//         dispatch(alertActions.error(`Não foi possível atualizar o contrato`));
//       }
//     );
//   };
//   function request(body) {
//     return { type: paymentConstants.UPDATE_CONTRACT_BILLING_REQUEST, body };
//   }
//   function success(contract) {
//     return { type: paymentConstants.UPDATE_CONTRACT_BILLING_SUCCESS, contract };
//   }
//   function failure(error) {
//     return { type: paymentConstants.UPDATE_CONTRACT_BILLING_ERROR, error };
//   }
// }

function findAllPayments() {
  return dispatch => {
    dispatch(request());
    paymentService.getAll().then(
      response => {
        dispatch(success(response.data));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(`Não foi possível encontrar pagamentos`));
      }
    );
  };
  function request() {
    return { type: paymentConstants.GETALL_PAYMENT_REQUEST };
  }
  function success(payments) {
    return { type: paymentConstants.GETALL_PAYMENT_SUCCESS, payments };
  }
  function failure(error) {
    return { type: paymentConstants.GETALL_PAYMENT_FAILURE, error };
  }
}
function showPropertyViewModal(propertyId) {
  return dispatch => {
    dispatch(request({ propertyId }));
    dispatch(propertyActions.findById(propertyId));
  };
  function request(property) {
    return { type: billingConstants.SHOW_PROPERTY_VIEW_DIALOG, property };
  }
}
function showNewBoletoModal(paymentId) {
  return dispatch => {
    dispatch(request({ paymentId }));
  };
  function request(body) {
    return { type: billingConstants.SHOW_NEW_BOLETO_DIALOG, body };
  }
}

function showSharingDocumentsModal(link) {
  return dispatch => {
    dispatch(request({ link }));
  };
  function request(body) {
    return { type: billingConstants.SHOW_SHARING_DIALOG, body };
  }
}
function showMarkAsPaidModal(paymentId) {
  return dispatch => {
    dispatch(request({ paymentId }));
  };
  function request(body) {
    return { type: billingConstants.SHOW_MARK_AS_PAID_DIALOG, body };
  }
}
function showPropertyApprovalModal(propertyId) {
  return dispatch => {
    propertyService.getById(propertyId).then(
      response => {
        dispatch(success(response.data));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(`Não foi possível encontrar imóveis`));
      }
    );
  };
  function success(property) {
    return {
      type: billingConstants.PROPERTY_APPROVAL_DIALOG_SUCCESS,
      property
    };
  }
  function failure(error) {
    return { type: billingConstants.PROPERTY_APPROVAL_DIALOG_ERROR, error };
  }
}
function showPropertyPaymentMethodApprovalModal(propertyId) {
  return dispatch => {
    propertyService.getById(propertyId).then(
      response => {
        dispatch(success(response.data));
        dispatch(userActions.getUserById(response.data.user_id));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(`Não foi possível encontrar imóveis`));
      }
    );
  };
  function success(property) {
    return {
      type: billingConstants.PROPERTY_PAYMENT_APPROVAL_DIALOG_SUCCESS,
      property
    };
  }
  function failure(error) {
    return {
      type: billingConstants.PROPERTY_PAYMENT_APPROVAL_DIALOG_ERROR,
      error
    };
  }
}
