import { authHeader } from '../_helpers/authHeader';
import config from '../_helpers/config';
export const paymentService = {
  getAll,
  getSummary,
  updatePayment,
  updateTransaction,
  createTransaction,
  deleteTransaction,
  updateContract,
  requestNewBoletoNotification
};
function requestNewBoletoNotification(id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  return fetch(
    `${config.ZIMOBI_API_BASE}/payments/${id}/request_new`,
    requestOptions
  ).then(handleResponse);
}
function updateContract(id, payment_method) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ payment_method: payment_method })
  };
  return fetch(
    `${config.ZIMOBI_API_BASE}/contracts/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  return fetch(
    `${config.ZIMOBI_API_BASE}/payments?q[s]=due_date+desc&qnty=30`,
    requestOptions
  ).then(handleResponse);
}
function getSummary(month,year) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  return fetch(
    `${config.ZIMOBI_API_BASE}/payments/summary?q[year_eq]=` +
      year +
      `&q[month_eq]=` +
      (month + 1),
    requestOptions
  ).then(handleResponse);
}

function updatePayment(id, body, markPaid) {
  let has_penalty = parseInt(body.penalty_amount_in_cents, 0) > 0;
  let newBody = body;
  if (newBody.contract) {
    delete newBody['contract'];
  }
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...newBody,
      is_completed: markPaid,
      has_penalty: has_penalty
    })
  };
  return fetch(`${config.ZIMOBI_API_BASE}/payments/${id}`, requestOptions).then(
    handleResponse
  );
}
function createTransaction(id, body) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...body
    })
  };
  return fetch(
    `${config.ZIMOBI_API_BASE}/payments/${id}/transactions/`,
    requestOptions
  ).then(handleResponse);
}
function deleteTransaction(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader() },
    body: JSON.stringify({ id })
  };
  return fetch(
    `${config.ZIMOBI_API_BASE}/transactions/${id}`,
    requestOptions
  ).then(handleResponse);
}
function updateTransaction(id, body) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...body
    })
  };
  return fetch(
    `${config.ZIMOBI_API_BASE}/transactions/${id}`,
    requestOptions
  ).then(handleResponse);
}
function handleResponse(response) {
  if (response.status === 204) {
    return response;
  }
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        window.location.reload(true);
      }
      const error = data || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
