import React from "react";
import { connect } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { Button, Col, Panel, Row } from "react-bootstrap";
import { TextField, GenderRadioGroup } from "../FormFields";
import {
  formatCNPJ,
  formatCPF,
  formatPhone
} from "../../_helpers/reduxFormFormatters";
import ReactS3Uploader from "react-s3-uploader";
import { authHeader } from "../../_helpers/authHeader";
import config from "../../_helpers/config";
import { history } from "../../_helpers/history";
import validate from "./Validations";
import { normalizeNumericValue } from "../../_helpers/reduxFormNormalizers";

export class UserDataForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      btnBaseClass: "btn-primary pull-right btn-primary__person-type",
      personType: "individual",
      avatarUploading: false
    };
  }
  componentDidMount() {
    this.selectPersonType(
      (this.props.initialValues &&
        this.props.initialValues.person_type.value) ||
        "individual"
    );
    this.setState({
      avatarUrl:
        this.props.initialValues.avatar_url || "/images/avatar_placeholder.png"
    });
    this.props.dispatch(change("UserDataForm", "gender", (this.props.initialValues.gender && this.props.initialValues.gender.value)));
   
  }
  componentDidUpdate(prevProps) {
    if (prevProps.initialValues !== this.props.initialValues) {
      this.setState({
        personType: this.props.initialValues.person_type,
        avatarUrl: this.props.initialValues.avatar_url
      });
    }
  }

  selectPersonType(type) {
    this.setState({ personType: type });
    this.props.dispatch(change("UserDataForm", "person_type", type));
  }

  render() {
    const { handleSubmit, submitting, onSubmit } = this.props;
    return (
      <Panel>
        <Panel.Heading>
          <Row>
            <Col sm={4} xs={12}>
              <h1>Dados do Usuário</h1>
            </Col>
            <Col sm={8} xs={12}>
              <Button
                className={
                  this.state.personType === "company"
                    ? this.state.btnBaseClass + " active"
                    : this.state.btnBaseClass
                }
                onClick={() => this.selectPersonType("company")}
              >
                Pessoa Jurídica
              </Button>
              <Button
                className={
                  this.state.personType === "individual"
                    ? this.state.btnBaseClass + " active"
                    : this.state.btnBaseClass
                }
                onClick={() => this.selectPersonType("individual")}
              >
                Pessoa Física
              </Button>
            </Col>
          </Row>
        </Panel.Heading>
        <Panel.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col sm={2} className="text-center">
                <div
                  className="profile-picture-wrapper"
                  style={{
                    backgroundImage: `url(${this.state.avatarUrl})`
                  }}
                />
                <p
                  className="add_profile_picture_action"
                  onClick={() => this.uploadInput.click()}
                >
                  {this.state.avatarUploading
                    ? "Adicionando..."
                    : "Adicionar foto de perfil"}
                </p>
                <ReactS3Uploader
                  className={"hidden"}
                  signingUrl={`${config.ZIMOBI_API_BASE}/files/s3/sign`}
                  signingUrlMethod="GET"
                  onProgress={() => this.setState({ avatarUploading: true })}
                  //onError={this.onUploadError}
                  onFinish={response => {
                    const avatarUrl = response.signedUrl.split("?")[0];
                    this.setState({
                      avatarUploading: false,
                      avatarUrl: avatarUrl
                    });
                    this.props.dispatch(
                      change("UserDataForm", "avatar_url", avatarUrl)
                    );
                  }}
                  signingUrlHeaders={authHeader()}
                  scrubFilename={filename =>
                    filename.replace(/[^\w\d_\-.]+/gi, "")
                  }
                  ref={uploader => {
                    this.uploader = uploader;
                  }}
                  inputRef={cmp => (this.uploadInput = cmp)}
                  autoUpload
                />
              </Col>
              <Col sm={10}>
                <Row>
                  <Col xs={12}>
                    {this.state.personType === "individual" ? (
                      <h1>Dados Pessoais</h1>
                    ) : (
                      <h1>Dados da Empresa</h1>
                    )}
                  </Col>
                </Row>
                {this.state.personType === "individual" ? (
                  <Row>
                    <Col sm={6}>
                      <Field
                        id="full_name_individual"
                        component={TextField}
                        controlClassName="form-control__white"
                        label="Nome Completo"
                        labelClassName="label__grey"
                        name="full_name"
                        type="text"
                      />
                    </Col>
                    <Col sm={6}>
                      <GenderRadioGroup name="gender" />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col sm={6}>
                      <Field
                        id="company_name"
                        component={TextField}
                        controlClassName="form-control__white"
                        label="Razão Social"
                        labelClassName="label__grey"
                        name="company_name"
                        type="text"
                      />
                    </Col>
                    <Col sm={6}>
                      <Field
                        id="full_name_company"
                        component={TextField}
                        controlClassName="form-control__white"
                        label="Nome do Responsável"
                        labelClassName="label__grey"
                        name="full_name"
                        type="text"
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  {this.state.personType === "individual" ? (
                    <div>
                      <Col lg={4} sm={6}>
                        <Field
                          id="cpf"
                          component={TextField}
                          controlClassName="form-control__white"
                          label="CPF"
                          labelClassName="label__grey"
                          name="cpf"
                          normalize={normalizeNumericValue}
                          format={formatCPF}
                          type="text"
                        />
                      </Col>
                      <Col lg={4} sm={6}>
                        <Field
                          id="rg"
                          component={TextField}
                          controlClassName="form-control__white"
                          label="RG"
                          labelClassName="label__grey"
                          name="rg"
                          normalize={normalizeNumericValue}
                          type="text"
                        />
                      </Col>
                    </div>
                  ) : (
                    <div>
                      <Col lg={4} sm={6}>
                        <Field
                          id="cnpj"
                          component={TextField}
                          controlClassName="form-control__white"
                          label="CNPJ"
                          labelClassName="label__grey"
                          name="cnpj"
                          normalize={normalizeNumericValue}
                          format={formatCNPJ}
                          type="text"
                        />
                      </Col>
                      <Col lg={4} sm={6}>
                        <Field
                          id="state_inscription"
                          component={TextField}
                          controlClassName="form-control__white"
                          label="Inscrição Estadual"
                          labelClassName="label__grey"
                          name="state_inscription"
                          normalize={normalizeNumericValue}
                          type="text"
                        />
                      </Col>
                    </div>
                  )}

                  <Col lg={4} sm={6}>
                    <Field
                      id="phone"
                      component={TextField}
                      controlClassName="form-control__white"
                      label="Telefone"
                      labelClassName="label__grey"
                      name="phone"
                      normalize={normalizeNumericValue}
                      format={formatPhone}
                      type="text"
                    />
                  </Col>
                </Row>

                {this.props.hideRegisterFields ? (
                  <Row>
                    <Col sm={6}>
                      <Field
                        id="email"
                        component={TextField}
                        controlClassName="form-control__white"
                        label="Email"
                        labelClassName="label__grey"
                        name="email"
                        type="text"
                      />
                    </Col>
                  </Row>
                ) : (
                  <div>
                    <Row>
                      <Col sm={6}>
                        <Field
                          id="email"
                          component={TextField}
                          controlClassName="form-control__white"
                          label="Email"
                          labelClassName="label__grey"
                          name="email"
                          type="text"
                        />
                      </Col>
                      <Col sm={6}>
                        <Field
                          id="email_confirmation"
                          component={TextField}
                          controlClassName="form-control__white"
                          label="Confirme seu email"
                          labelClassName="label__grey"
                          name="email_confirmation"
                          type="text"
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <Field
                          id="password"
                          component={TextField}
                          controlClassName="form-control__white"
                          label="Senha"
                          labelClassName="label__grey"
                          name="password"
                          type="password"
                        />
                      </Col>
                      <Col sm={6}>
                        <Field
                          id="password_confirmation"
                          component={TextField}
                          controlClassName="form-control__white"
                          label="Confirme sua senha"
                          labelClassName="label__grey"
                          name="password_confirmation"
                          type="password"
                        />
                      </Col>
                    </Row>
                  </div>
                )}
                <br />
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12} style={{ textAlign: "center" }}>
                <Button
                  bsStyle={"default"}
                  onClick={() => history.goBack()}
                >
                  VOLTAR
                </Button>
                <Button
                  disabled={submitting}
                  bsStyle={"primary"}
                  type={"submit"}
                >
                  SALVAR
                </Button>
              </Col>
            </Row>
          </form>
        </Panel.Body>
      </Panel>
    );
  }
}

const form = reduxForm({
  form: "UserDataForm",
  validate,
  destroyOnUnmount: true
})(UserDataForm);

export default connect(state => ({
  fb_id: formValueSelector("UserDataForm")(state, "fb_id")
}))(form);
