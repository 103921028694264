import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropertyViewForm from '../Forms/PropertyViewForm';

export const PropertyViewDialog = ({
  container,
  property,
  show,
  onHide,
  handleSubmit,
  loading
}) => (
  <Modal bsSize="lg" show={show} container={container} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>
        ID do Imóvel {property.code}
        <Link
          className="primary-color-variation"
          to={'/properties/edit/' + property.id}
        >
          {' '}
          Detalhes do imóvel
        </Link>
        {'    '}
        <small> Ref Zimobi: {property.id}</small>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <PropertyViewForm
        property={property}
        loading={loading}
        handleSubmit={handleSubmit}
        onHide={onHide}
      />
    </Modal.Body>
  </Modal>
);
