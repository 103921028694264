import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { change, Field } from 'redux-form';
import { TextField } from '../../FormFields';
import { formatCEP } from '../../../_helpers/reduxFormFormatters';
import { normalizeNumericValue } from '../../../_helpers/reduxFormNormalizers';
import { searchAddressFromPostalCode } from '../../../_helpers/searchAddressFromPostalCode';

class AddressData extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      generatedCode: props.generatedCode,
      searchingAddress: false,
      searchAddressError: false,
      postalCode: ''
    };
    if (props.generatedCode) {
      this.generateCode();
    }
    props.changeFormField(props.form, 'status', 'free');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.generatedCode !== this.props.generatedCode) {
      if (!this.props.generatedCode) {
        this.setState({
          generatedCode: false
        });
      }
    }
  }

  clearCodeField() {
    if (this.state.generatedCode) {
      const { form, changeFormField } = this.props;
      changeFormField(form, 'property.code', '');
      this.setState({ generatedCode: false });
    }
  }
  generateCode = () => {
    setTimeout(() => {
      this.props.changeFormField(
        this.props.form,
        'property.code',
        'z-' + parseInt(Math.random() * 100000, 10)
      );
    }, 1500);
  };
  handlePostalCodeInput(postalCode) {
    if (postalCode.length === 10) {
      const { form, changeFormField } = this.props;

      const onlyNums = postalCode.replace('.', '').replace('-', '');
      this.setState({ searchingAddress: true });
      changeFormField(form, 'property.address.cep_error', null);
      searchAddressFromPostalCode(onlyNums)
        .then(address => {
          changeFormField(form, 'property.address.street', address.logradouro);
          changeFormField(
            form,
            'property.address.neighbourhood',
            address.bairro
          );
          changeFormField(form, 'property.address.city', address.localidade);
          changeFormField(form, 'property.address.state', address.uf);
          this.setState({ searchingAddress: false });
        })
        .catch(error => {
          changeFormField(form, 'property.address.cep_error', 'CEP inválido');
          this.setState({ searchingAddress: false });
          return;
        });
    }
  }

  render() {
    return (
      <div>
        <Row>
          <Col sm={2}>
            <Field
              id="postal_code"
              component={TextField}
              controlClassName="form-control__white"
              format={formatCEP}
              label="CEP"
              labelClassName="label__grey"
              name="property.address.postal_code"
              normalize={normalizeNumericValue}
              onChange={event => this.handlePostalCodeInput(event.target.value)}
              onKeyUp={event => this.handlePostalCodeInput(event.target.value)}
            />
          </Col>
          <Col sm={4}>
            {this.state.searchAddressError ? (
              <p className="form-note" style={{ color: 'red' }}>
                Não foi possível encontrar o seu endereço. Preencha os campos
                abaixo ou{' '}
                <u
                  onClick={() =>
                    this.handlePostalCodeInput(this.state.postalCode)
                  }
                  style={{ cursor: 'pointer' }}
                >
                  clique aqui
                </u>{' '}
                para procurar seu endereço pelo CEP novamente.
              </p>
            ) : (
              <p className="form-note">
                {this.state.searchingAddress ? (
                  'Procurando o seu endereço...'
                ) : (
                  <span>
                    <u
                      onClick={() =>
                        this.handlePostalCodeInput(this.state.postalCode)
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      Clique aqui
                    </u>{' '}
                    para buscar seu endereço pelo CEP. Se não souber o CEP, pode
                    buscar{' '}
                    <a
                      href="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      aqui.
                    </a>
                  </span>
                )}
              </p>
            )}
          </Col>
          <Col sm={3}>
            <Field
              disabled={true}
              id="code"
              component={TextField}
              controlClassName="form-control__white"
              label="Id do imóvel"
              labelClassName="label__grey"
              name="property.code"
              tooltiplabel={
                ' É um código de identificação gerado automaticamente pela Zimobi.'
              }
              onChange={e => {
                console.log(e);
                if (!e.target.value) {
                  this.generateCode();
                }
              }}
              onFocus={() => this.clearCodeField()}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Field
              id="street"
              component={TextField}
              controlClassName="form-control__white"
              label="Rua"
              labelClassName="label__grey"
              name="property.address.street"
            />
          </Col>
          <Col sm={2}>
            <Field
              id="number"
              component={TextField}
              controlClassName="form-control__white"
              label="Número"
              labelClassName="label__grey"
              name="property.address.number"
            />
          </Col>
          <Col sm={2}>
            <Field
              id="complement"
              component={TextField}
              controlClassName="form-control__white"
              label="Complemento"
              labelClassName="label__grey"
              name="property.address.complement"
            />
          </Col>
          <Col sm={4}>
            <p className="form-note">
              Você pode informar o número do bloco, apartarmento, etc.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Field
              id="neighbourhood"
              component={TextField}
              controlClassName="form-control__white"
              label="Bairro"
              labelClassName="label__grey"
              name="property.address.neighbourhood"
            />
          </Col>
          <Col sm={4}>
            <Field
              id="city"
              component={TextField}
              controlClassName="form-control__white"
              label="Cidade"
              labelClassName="label__grey"
              name="property.address.city"
            />
          </Col>
          <Col sm={4}>
            <Field
              id="state"
              component={TextField}
              controlClassName="form-control__white"
              label="Estado"
              labelClassName="label__grey"
              name="property.address.state"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeFormField: (formName, attribute, value) =>
    dispatch(change(formName, attribute, value))
});

export default connect(
  null,
  mapDispatchToProps
)(AddressData);
