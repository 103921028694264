import { validateCPF } from '../../_helpers/cpfValidator';

const validate = values => {
  const errors = {
    address: {},
    property: {},
    bank_account: {},
    active_contract: { tenant: {} },
    landlord: {},
    new_user_attributes: {}
  };

  const skipValidation =
    values.active_contract &&
    values.active_contract.payment_method === 'manual';
  const skipPropertyValidation = skipValidation;
  const skipLandlordValidation = skipValidation;
  const skipContractValidation = skipValidation;
  const skipBillingValidation = skipValidation;
  const skipTenantValidation = skipValidation;
  const isIptuSplit =
    values.active_contract &&
    values.active_contract.iptu_split_in_12_months === 'true';
  const requiredMsg = 'Campo obrigatório';

  if (!values.code) {
    errors.code = requiredMsg;
  }
  const address = values.address || {};
  if (!skipPropertyValidation) {
    if (
      !values.rental_type ||
      (values.rental_type && typeof values.rental_type !== 'string')
    ) {
      errors.rental_type = requiredMsg;
    }
    // Property
    if (
      !values.property_type ||
      (values.property_type && typeof values.property_type !== 'string')
    ) {
      errors.property_type = requiredMsg;
    }

    if (!address.street) {
      errors.address.street = requiredMsg;
    }

    if (!address.number) {
      errors.address.number = requiredMsg;
    }

    if (!address.postal_code) {
      errors.address.postal_code = requiredMsg;
    }
    if (address.cep_error) {
      errors.address.postal_code = address.cep_error;
    }
    if (!address.neighbourhood) {
      errors.address.neighbourhood = requiredMsg;
    }

    if (!address.city) {
      errors.address.city = requiredMsg;
    }

    if (!address.state) {
      errors.address.state = requiredMsg;
    }
  }
  const landlord = values.landlord;
  if (!skipLandlordValidation) {
    // Landlord

    if (landlord) {
      if (!landlord.full_name) {
        errors.landlord.full_name = requiredMsg;
      }

      if (!landlord.email) {
        errors.landlord.email = requiredMsg;
      }
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(landlord.email)) {
        errors.landlord.email = 'Insira um email válido.';
      }
      if (landlord.email && landlord.email.indexOf(' ') !== -1) {
        errors.landlord.email = 'Email não pode conter espaços!';
      }

      if (!landlord.phone) {
        errors.landlord.phone = requiredMsg;
      }

      if (!landlord.cpf && landlord.person_type === 'individual') {
        errors.landlord.cpf = requiredMsg;
      }

      if (
        landlord.cpf &&
        landlord.person_type === 'individual' &&
        !validateCPF(landlord.cpf)
      ) {
        errors.landlord.cpf = 'CPF inválido';
      }

      if (!landlord.company_name && landlord.person_type === 'company') {
        errors.landlord.company_name = requiredMsg;
      }

      if (!landlord.cnpj && landlord.person_type === 'company') {
        errors.landlord.cnpj = requiredMsg;
      }

    }
  }
  const active_contract = values.active_contract;
  if (!skipContractValidation) {
    // Contract
    if (active_contract && !active_contract.due_date_day) {
      errors.active_contract.due_date_day = requiredMsg;
    }
    if (active_contract && typeof active_contract.due_date_day === 'object') {
      errors.active_contract.due_date_day = requiredMsg;
    }
    if (active_contract && !active_contract.rental_guarantee_type) {
      errors.active_contract.rental_guarantee_type = requiredMsg;
    }
    if (
      active_contract &&
      typeof active_contract.rental_guarantee_type === 'object'
    ) {
      errors.active_contract.rental_guarantee_type = requiredMsg;
    }
    if (
      active_contract &&
      active_contract.rental_guarantee_type &&
      active_contract.rental_guarantee_type === 'deposit' &&
      !active_contract.deposit_amount_in_cents
    ) {
      errors.active_contract.deposit_amount_in_cents = requiredMsg;
    }

    if (active_contract && !active_contract.due_date_day) {
      errors.active_contract.due_date_day = requiredMsg;
    }

    if (!isIptuSplit) {
      if (active_contract && !active_contract.iptu_start_month) {
        errors.active_contract.iptu_start_month = requiredMsg;
      }
      if (active_contract && !active_contract.iptu_end_month) {
        errors.active_contract.iptu_end_month = requiredMsg;
      }
    }

    if (
      active_contract &&
      (!active_contract.rental_price_in_cents ||
        parseInt(active_contract.rental_price_in_cents) === 0)
    ) {
      errors.active_contract.rental_price_in_cents = requiredMsg;
    }

    if (
      active_contract &&
      active_contract.penalty_percentage &&
      parseFloat(active_contract.penalty_percentage) > 10.0
    ) {
      errors.active_contract.penalty_percentage =
        'A multa deve ter um valor máximo de 10%';
    }
    if (active_contract && !active_contract.penalty_percentage) {
      errors.active_contract.penalty_percentage = requiredMsg;
    }
    if (
      active_contract &&
      active_contract.interest_percentage &&
      parseFloat(active_contract.interest_percentage) > 1.0
    ) {
      errors.active_contract.interest_percentage =
        'O juros mensal deve ter um valor máximo de 1%';
    }
    if (active_contract && !active_contract.interest_percentage) {
      errors.active_contract.interest_percentage = requiredMsg;
    }
    if (
      active_contract &&
      active_contract.has_bonus_for_payment_on_time &&
      active_contract.has_bonus_for_payment_on_time === 'true' &&
      !active_contract.bonus_for_payment_on_time_in_cents
    ) {
      errors.active_contract.bonus_for_payment_on_time_in_cents = requiredMsg;
    }
  }
  const bank_account = values.bank_account || {};
  if (!skipBillingValidation) {
    // Bank Account
    if (
      bank_account.cpf_or_cnpj &&
      bank_account.person_type === 'individual' &&
      !validateCPF(bank_account.cpf_or_cnpj)
    ) {
      errors.bank_account.cpf_or_cnpj = 'CPF inválido';
    }
    if (!bank_account.full_name) {
      errors.bank_account.full_name = requiredMsg;
    }

    if (!bank_account.cpf_or_cnpj) {
      errors.bank_account.cpf_or_cnpj = requiredMsg;
    }

    if (!bank_account.bank_id) {
      errors.bank_account.bank_id = requiredMsg;
    }
    if (typeof bank_account.bank_id === 'object') {
      errors.bank_account.bank_id = requiredMsg;
    }
    if (!bank_account.agency) {
      errors.bank_account.agency = requiredMsg;
    }

    if (!bank_account.account_number) {
      errors.bank_account.account_number = requiredMsg;
    }
  }
  const tenant = values.active_contract && values.active_contract.tenant;
  if (!skipTenantValidation) {
    // Tenant

    if (tenant) {
      if (tenant && !tenant.full_name) {
        errors.active_contract.tenant.full_name = requiredMsg;
      }

      if (tenant && !tenant.email) {
        errors.active_contract.tenant.email = requiredMsg;
      }

      if (
        tenant &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(tenant.email)
      ) {
        errors.active_contract.tenant.email = 'Insira um email válido.';
      }

      if (tenant && tenant.email && tenant.email.indexOf(' ') !== -1) {
        errors.active_contract.tenant.email = 'Email não pode conter espaços!';
      }

      if (tenant && !tenant.phone) {
        errors.active_contract.tenant.phone = requiredMsg;
      }

      if (tenant && !tenant.cpf && tenant.person_type === 'individual') {
        errors.active_contract.tenant.cpf = requiredMsg;
      }

      if (
        tenant &&
        tenant.cpf &&
        tenant.person_type === 'individual' &&
        !validateCPF(tenant.cpf)
      ) {
        errors.active_contract.tenant.cpf = 'CPF inválido';
      }

      if (tenant && !tenant.company_name && tenant.person_type === 'company') {
        errors.active_contract.tenant.company_name = requiredMsg;
      }

      if (tenant && !tenant.cnpj && tenant.person_type === 'company') {
        errors.active_contract.tenant.cnpj = requiredMsg;
      }

    }
  }
  return errors;
};

export default validate;
