import { userConstants } from '../_constants/userConstants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : { loggedIn: false };

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return { ...state, loading: true, user: action.user };
    case userConstants.LOGIN_SUCCESS:
      return { ...state, loggedIn: true, loading: false, user: action.user };
    case userConstants.LOGIN_FAILURE:
      return { ...state, loading: false, loggedIn: false };
    case userConstants.GETSELF_REQUEST:
      return {
        ...state,
        profileLoading: true
      };
    case userConstants.GETSELF_SUCCESS:
      let newState = {
        ...state,
        loggedIn: true,
        profileLoading: false,
        user: { ...state.user, data: action.user }
      };
      return newState;
    case userConstants.GETSELF_FAILURE:
      return { ...state, profileLoading: false };
    case userConstants.CHANGE_PASSWORD_SOLICITATION_REQUEST:
      return { ...state, loading: true };
    case userConstants.CHANGE_PASSWORD_SOLICITATION_SUCCESS:
      return { ...state, loading: false };
    case userConstants.CHANGE_PASSWORD_SOLICITATION_FAILURE:
      return { ...state };
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return { ...state, isPasswordChanged: true, loading: false };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return { ...state, loading: false };
    case userConstants.LOGOUT:
      const { routing } = state;
      state = { routing };
      return state;
    default:
      return state;
  }
}
