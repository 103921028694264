import React from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
import BasePropertyForm from "../BasePropertyForm/BasePropertyForm";
import ContractData from "./PanelContent/ContractData";
import LandlordData from "./PanelContent/LandlordData";
import PropertyData from "./PanelContent/PropertyData";
import ReceivingAccountData from "./PanelContent/ReceivingAccountData";
import TenantData from "./PanelContent/TenantData";
import validate from "./Validations";
import { bankAccountActions } from "../../_actions/bankAccountActions";
import { landlordActions } from "../../_actions/landlordActions";

const form = "PropertyRentedForm";

export class RentedPropertyForm extends React.Component {
  componentWillMount() {
    const { getAllBankAccounts, getAllLandLords } = this.props;
    getAllBankAccounts();
    getAllLandLords();
  }
  onFileUploadFieldChange = (fieldName, fileUrl) => {
    this.props.changeFormField(form, fieldName, fileUrl);
  };
  componentDidMount() {
    const { property, changeFormField, isNew } = this.props;
    changeFormField(form, "status", "rented");
    changeFormField(form, "furnish_type", "no");
    changeFormField(form, "property_type", 'apartment');
    if (property && property.active_contract && !isNew) {
      const { active_contract } = property;
      changeFormField(form, "property_type", property.property_type && property.property_type.value);
      changeFormField(
        form,
        "rental_type",
        property.rental_type && property.rental_type.value
      );

      changeFormField(form, "id", property && property.id);
      changeFormField(
        form,
        "active_contract.payment_method",
        active_contract.payment_method.value || "manual"
      );
      changeFormField(
        form,
        "active_contract.iptu_split_in_12_months",
        String(active_contract.iptu_split_in_12_months).toLowerCase() === "true"
          ? "true"
          : "false"
      );
      changeFormField(
        form,
        "active_contract.has_bonus_for_payment_on_time",
        String(active_contract.has_bonus_for_payment_on_time).toLowerCase() ===
          "true"
          ? "true"
          : "false"
      );
    } else {
      changeFormField(form, "active_contract.iptu_split_in_12_months", "true");
      changeFormField(
        form,
        "active_contract.has_bonus_for_payment_on_time",
        "false"
      );
    }
  }

  render() {
    const {
      onDraftSubmit,
      onSubmit,
      property,
      isNew,
      payment_method
    } = this.props;

    const panels = [
      {
        body: (
          <PropertyData
            form={form}
            isNew={true}
            property={property}
            properties={this.props.listedProperties}
            generateCode={property ? false : true}
          />
        ),
        iconBaseName: "icon_house",
        title: "Dados do Imóvel",
        name: "property"
      },
      {
        body: (
          <LandlordData
            form={form}
            landlords={this.props.landlords.items}
            landlord={property && property.landlord}
            selectedLandlord={property && property.landlord}
          />
        ),
        iconBaseName: "icon_owner",
        title: "Dados do Proprietário",
        name: "owner_data"
      },
      {
        body: (
          <ContractData
            form={form}
            active_contract={property && property.active_contract}
            rental_guarantee_type={isNew ? "" : property.active_contract.rental_guarantee_type.value}
            onFileUploadFieldChange={this.onFileUploadFieldChange}
          />
        ),
        iconBaseName: "icon_contract",
        title: "Contrato e Valores",
        name: "contract"
      },
      {
        body: (
          <ReceivingAccountData
            form={form}
            bankAccounts={this.props.bankAccounts.items}
            bank_account={property && property.bank_account}
            payment_method={
              isNew
                ? payment_method
                : property &&
                  property.active_contract &&
                  property.active_contract.payment_method.value
            }
          />
        ),
        iconBaseName: "icon_bank_account",
        title: "Recebimentos",
        name: "billing"
      },
      {
        body: (
          <TenantData
            onFileUploadFieldChange={this.onFileUploadFieldChange}
            tenant={
              property && property.active_contract
                ? property.active_contract.tenant
                : undefined
            }
            form={form}
          />
        ),
        iconBaseName: "icon_tenant",
        title: "Inquilino",
        name: "tenant"
      }
    ];

    return (
      <BasePropertyForm
        form={form}
        onDraftSubmit={onDraftSubmit}
        onSubmit={onSubmit}
        panels={panels}
        initialSelectedPanel={panels
          .map(p => p.name)
          .indexOf(this.props.activeSessionName)}
        initialValues={property}
        validate={validate}
        isNew={this.props.new}
      />
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    bankAccounts: state.bankAccounts,
    landlords: state.landlords,
    listedProperties:
      state.properties.listedProperties &&
      state.properties.listedProperties.data.map(p => p.property)
  };
};
const mapDispatchToProps = dispatch => ({
  changeFormField: (formName, attribute, value) =>
    dispatch(change(formName, attribute, value)),
  getAllBankAccounts: () => dispatch(bankAccountActions.getAll()),
  getAllLandLords: () => dispatch(landlordActions.getAll())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RentedPropertyForm);
