import React from "react";
import {
  listingsStatusTypes,
  guaranteesList,
  furnishedTypes,
  receivingMethodTypes,
  receivingMethodStatusTypes,
  rentalGuaranteeTypes,
} from "./optionsArrays";
import { Glyphicon } from "react-bootstrap";
import * as moment from "moment";
import { TooltipWrapper } from "../_components/TooltipWrapper";
moment.locale("pt-br", {
  months: "Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro".split(
    "_"
  ),
  monthsShort: "Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez".split("_")
});
export function findVal(object, filters) {
  Object.keys(object).some(function(k) {
    return filters.every(key => {
      if (typeof object[key] === "object") {
        return multiFilter(object[key], filters);
      } else {
        return !!~filters[key].indexOf(object[key]);
      }
    });
  });
}

export function scrollTopSmooth() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth"
  });
}
export function scrollTop() {
  window.scroll({
    top: 0,
    left: 0
  });
}
export function multiFilter(array, filters) {
  if (typeof array === "object") {
    for (var key in filters) {
      if (array[key] === undefined || array[key] !== filters[key]) return false;
    }
    return true;
  }
  const filterKeys = Object.keys(filters);
  // filters all elements passing the criteria
  return array.filter(item => {
    // dynamically validate all filter criteria
    return filterKeys.every(key => {
      if (typeof item[key] === "object") {
        return multiFilter(item[key], filters);
      } else {
        return !!~filters[key].indexOf(item[key]);
      }
    });
  });
}
export function findById(obj, id) {
  var result;
  for (var p in obj) {
    if (obj.id === id) {
      return obj;
    } else {
      if (typeof obj[p] === "object") {
        result = findById(obj[p], id);
        if (result) {
          return result;
        }
      }
    }
  }
  return result;
}

export const toCurrenry = (value, decimals = 2) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(value || 0);

export const renderAddressFormatter = address => {
  if (!address) {
    return "";
  }
  const { street, complement, neighbourhood, number, city } = address;
  var part1 = `${street} ${number || ""}, ${complement || " "}`;
  var part2 = `${neighbourhood}, ${city}`;
  return (
    <span>
      {part1} <br /> {part2}
    </span>
  );
};

export const tenantFormatter = tenant => {
  return tenant ? tenant.full_name : "";
};
export const getGuaranteeTypeLabel = guarantee => {
  let guaranteeType = guaranteesList.find(r => r.value === guarantee);
  if (guaranteeType) {
    return guaranteeType.label;
  }
};
export const getFurnishedTypesLabel = type => {
  let furnishedType = furnishedTypes.find(r => r.value === type);
  if (furnishedType) {
    return furnishedType.label;
  } else {
    return "Não";
  }
};

export const sortByStatus = (a, b) => {
  var x = a._paymentStatus.toLowerCase();
  var y = b._paymentStatus.toLowerCase();
  if (x < y) {
    return -1;
  }
  if (x > y) {
    return 1;
  }
  return 0;
};

export const getActiveContract = property => {
  return property && property.active_contract;
};
export const zeroPad = (num, numZeros) => {
  // numZeros = numZeros ? numZeros : 6;
  // var n = Math.abs(num);
  // var zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
  // var zeroString = Math.pow(10, zeros)
  //   .toString()
  //   .substr(1);
  // if (num < 0) {
  //   zeroString = '-' + zeroString;
  // }
  return num;
  // return 'ZI' + zeroString + n;
};

export function countUnique(iterable) {
  return new Set(iterable).size;
}
export const getCurrentMonthPayments = (property, month) => {
  return property.contracts
    .find(p => p.is_active)
    .payments.find(p => moment(p.due_date).month() === month);
};
export const getPaymentStatusColor = value => {
  if (value.color) {
    return value.color;
  } else {
    return "gray";
  }
};

export const getRandomArbitrary = (min, max) => {
  return Math.random() * (max - min) + min;
};
export const getListingStatusColor = value => {
  let status = listingsStatusTypes.find(s => s.value === value);
  if (status) {
    return status.color;
  } else {
    return "gray";
  }
};
export const getBillingMethodStatusColor = property => {
  let activeContract = getActiveContract(property);
  if (!activeContract) {
    console.warn("contrato ausente");
    return;
  }
  let status = receivingMethodStatusTypes.find(
    s => s.value === activeContract.payment_method_status
  );
  if (status) {
    return status.color;
  } else {
    return "gray";
  }
};
export const adminPaymentMEthodApprovalRenderer = (value, row) => {
  let active_contract = getActiveContract(row);
  if (row.status && active_contract) {
    // let type =
    //   receivingMethodStatusTypes.find(
    //     s => s.value === active_contract.payment_method_status
    //   ) || void 0;
    let type = active_contract.payment_method_status;

    return type ? (
      <div
        style={{ whiteSpace: "nowrap" }}
        // onClick={() => {
        //   this.props.dispatch(
        //     billingActions.showPropertyPaymentMethodApprovalModal(row.id)
        //   );
        // }}
      >
        <TooltipWrapper
          id={"tooltip-" + row.id}
          tooltip={
            "Use esta opção para aprovar/rejeitar uma forma de recebimento"
          }
        >
          <Glyphicon
            glyph="cog"
            style={{
              display:
                active_contract.payment_method === "manual" ? "none" : "",
              fontSize: "2rem",
              cursor: "pointer",
              color: type.color,
              margin: "5px"
            }}
          />
        </TooltipWrapper>
      </div>
    ) : (
      ""
    );
  }
};
export const receivingPaymentMethodFormatter = (value, row) => {
  let activeContract = getActiveContract(row);
  if (!activeContract) {
    return "";
  }
  // let type = receivingMethodTypes.find(
  //   p => p.value === activeContract.payment_method
  // );
  let payment_method = activeContract.payment_method;
  return payment_method ? (
    <div>
      <img
        alt={payment_method.value}
        style={{ height: "2rem", width: "30px", marginRight: "5px" }}
        src={
          receivingMethodTypes.find(rmt => payment_method.value === rmt.value)
            .icon
        }
      />
      <span>{payment_method.label} </span>
    </div>
  ) : null;
};
export const getPaymentStatusFromProperty = (cell, property) => {
  let active_contract = getActiveContract(property);
  let status;
  if (active_contract) {
    // status = paymentStatusTypes.find(p => p.value === active_contract.payment_status);
    status = active_contract.payment_status;
  }
  if (status) {
    return (
      <div className="vertical-center" style={{ whiteSpace: "nowrap" }}>
        <span
          className={`circle dot `}
          style={{
            marginRight: "5px",
            marginBottom: "5px",
            color: status.color
          }}
        >
          {" "}
        </span>
        {status.label}
      </div>
    );
  } else {
    return cell;
  }
};
export const getPaymentStatusFromBilling = (cell, row) => {
  if (cell) {
    return (
      <div className="vertical-center" style={{ whiteSpace: "nowrap" }}>
        <span
          className={`circle dot`}
          style={{ marginRight: "5px", marginBottom: "5px", color: cell.color }}
        />
        {cell.label}
      </div>
    );
  } else {
    return "Desconhecido";
  }
};
export function getPropertiesByStatus(propertyArray, status) {
  return propertyArray ? propertyArray.filter(p => p.status === status) : [];
}

export const receivingContractStatusFormatter = (cell, row) => {
  let activeContract = getActiveContract(row);
  if (!activeContract) {
    return;
  }
  // let status = receivingMethodStatusTypes.find(
  //   p => p.value === activeContract.payment_method_status
  // );
  let status = activeContract.payment_method_status;
  if (status) {
    row.value = status.label;
    return (
      <span className="vertical-center" style={{ whiteSpace: "nowrap" }}>
        <span
          className={`circle dot`}
          style={{
            marginRight: "5px",
            marginBottom: "5px",
            color: status.color
          }}
        />
        {status.label}
      </span>
    );
  } else {
    row.value = cell;
    return cell;
  }
};
export const renderFormattedListingTooltip = (cellContent, row) => {
  let status = listingsStatusTypes.find(s => s.value === cellContent);
  if (status && row.is_data_valid) {
    return status.value !== "approved" && status.tooltip && row.is_active
      ? renderToolTipIcon(status.tooltip)
      : "";
  }
};

export const renderToolTipIcon = tooltip => {
  return (
    tooltip && (
      <div className={"question-mark-icon"}>
        {" "}
        <TooltipWrapper
          id={
            "tooltip-" +
            Math.random()
              .toString(36)
              .substring(2, 15)
          }
          tooltip={tooltip}
        >
          <img src="/images/icon_question_mark.svg" alt="tooltip" />
        </TooltipWrapper>
      </div>
    )
  );
};
const renderDotStates = (id, color, label, tip) => (
  <div key={id}>
    <span
      className={`circle dot  ${color}`}
      style={{ marginRight: "5px", marginBottom: "5px", color: color }}
    >
      {" "}
    </span>
    {label}
    <p style={{ fontSize: "12px" }}>{tip}</p>
  </div>
);

// export const renderTextStates = (id, color, description, tip) => (
//   <div key={id}>
//     <p
//       style={{
//         letterSpacing: ".2em",
//         textTransform: "uppercase",
//         fontWeight: "bold"
//       }}
//     >
//       {description}
//     </p>
//     <p style={{ fontSize: "12px" }}>{tip}</p>
//   </div>
// );

export const renderFormattedListingsStatus = (cell, row) => {
  let key = cell;
  if (key) {
    return renderDotStates(
      cell + row.id,
      key.color,
      key.label,
      key.description
    );
  }
};
const notInformedLabel = "Não informado";
export const isUserAdmin = user => {
  return user && user.data && user.data.role === "admin" ? true : false;
};

export const formToObject = form => {
  const formData = new FormData(form);
  const obj = {};
  for (let entry of formData.entries()) {
    obj[entry[0]] = entry[1];
  }
  return obj;
};
export const getCurrentPaymentStatus = property => {
  let activeContract = getActiveContract(property);
  return (
    (activeContract && activeContract.payment_status) || "missing_contract"
  );
};
// export const renderRentType = rental_type => {
//   let rentType = rentalTypes.find(r => r.value === rental_type);
//   if (rentType) {
//     return rentType.label;
//   } else {
//     return notInformedLabel;
//   }
// };
// export const renderGenre = genre => {
//   switch (genre) {
//     case 'male':
//       return 'Masculino';
//     case 'female':
//       return 'Feminino';
//     default:
//       return '';
//   }
// };

export const renderTrueNo = value => {
  switch (value) {
    case "true":
      return "Sim";
    case "no":
      return "Não";
    default:
      return "";
  }
};
// export const renderPropertyType = property_type => {
//   let propertyType = propertyTypes.find(r => r.value === property_type);
//   if (propertyType) {
//     return propertyType.label;
//   } else {
//     return notInformedLabel;
//   }
// };

export const renderGuaranteeType = guarantee => {
  let guaranteeType = rentalGuaranteeTypes.find(r => r.value === guarantee);
  if (guaranteeType) {
    return guaranteeType.label;
  } else {
    return notInformedLabel;
  }
};

export const renderRentalGuarantees = (amount_deposit, rental_guarantee) => {
  return (
    rental_guarantee &&
    rental_guarantee.length &&
    rental_guarantee.map(g => {
      return g === "deposit" ? (
        <p>
          {getGuaranteeTypeLabel(g) + " - " + toCurrenry(amount_deposit / 100)}
        </p>
      ) : (
        <p>{getGuaranteeTypeLabel(g)}</p>
      );
    })
  );
};
export function insertItem(array, item, index) {
  return [...array.slice(0, index), item, ...array.slice(index)];
}
export function removeItem(array, index) {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

// eslint-disable-next-line
Array.prototype.move = function(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};
