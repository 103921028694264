import { authHeader } from '../_helpers/authHeader';
import { history } from '../_helpers/history';
import config from '../_helpers/config';
export const propertyService = {
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  getRentedProperties,
  getFreeProperties,
  reviewBillingMethod,
  findAllSortingBy,
  searchPropertyLike
};
function searchPropertyLike(field, text) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${config.ZIMOBI_API_BASE}/properties?q[status_eq]=rented&q[${field}_eq]=${text}`,
    requestOptions
  ).then(handleResponse);
}
function findAllSortingBy(field, order) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${config.ZIMOBI_API_BASE}/properties?q[status_eq]=rented&q[s]=${field}+${order}`,
    requestOptions
  ).then(handleResponse);
}

function reviewBillingMethod(contractId, body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body)
  };
  return fetch(
    `${
      config.ZIMOBI_API_BASE
    }/contracts/${contractId}/payment_methods/bank_slip/review_process`,
    requestOptions
  ).then(response => {
    if (response.ok) {
      if (response.status === 204) {
        return response;
      } else {
        return response.json();
      }
    } else {
      throw new Error('Aconteceu um erro ao efetuar a chamada do serviço');
    }
  });
}

function getAll(url) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    url ? url : `${config.ZIMOBI_API_BASE}/properties?q[status_eq]=rented`,
    requestOptions
  ).then(handleResponse);
}
function getRentedProperties() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(
    // `${config.ZIMOBI_API_BASE}/properties?q[landlord_id_gt]=0`,
    `${config.ZIMOBI_API_BASE}/properties?q[status_eq]=rented`,
    requestOptions
  ).then(handleResponse);
}
function getFreeProperties() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(
    // `${config.ZIMOBI_API_BASE}/properties?q[landlord_id_gt]=0`,
    `${config.ZIMOBI_API_BASE}/properties?q[status_eq]=free`,
    requestOptions
  ).then(handleResponse);
}
function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${config.ZIMOBI_API_BASE}/properties/${id}`,
    requestOptions
  ).then(handleResponse);
}

function register(property) {
  console.log(property)
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(property)
  };

  return fetch(`${config.ZIMOBI_API_BASE}/properties`, requestOptions).then(
    handleResponse
  );
}

function update(property) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(property)
  };

  return fetch(
    `${config.ZIMOBI_API_BASE}/properties/${property.id}`,
    requestOptions
  ).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(
    `${config.ZIMOBI_API_BASE}/properties/${id}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  if (response.status === 204) {
    return;
  }

  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        history.push('/');
        window.location.reload(true);
      }

      const error = data || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
