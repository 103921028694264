import React from "react";
import { connect } from "react-redux";
import { Button, Col, Grid, Image, Panel, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TextField } from "../../_components/FormFields";
import { Field, reduxForm } from "redux-form";
import { userActions } from "../../_actions/userActions";

const validate = values => {
  const errors = {};

  const requiredMsg = "Campo obrigatório";

  if (!values.email) {
    errors.email = requiredMsg;
  }

  if (!values.email_confirmation) {
    errors.email_confirmation = requiredMsg;
  }

  if (values.email !== values.email_confirmation) {
    errors.email_confirmation =
      "O email e a confirmação de email estão diferentes.";
  }

  return errors;
};

export class ForgotPasswordPage extends React.Component {
  componentDidMount() {
    document.body.classList.add("guest-user");
  }

  submit = values => {
    const { dispatch } = this.props;
    dispatch(userActions.changePasswordRequest(values.email));
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Grid className="login-area">
        <Row>
          <Col sm={6} smOffset={3} xs={12}>
            <Panel>
              <Panel.Body className="panel-body__login">
                <Row>
                  <Col sm={8} smOffset={2} xs={10} xsOffset={1}>
                    <Row>
                      <Col className="text-center">
                        <Image src="/images/logo.png" alt="logo" />
                      </Col>
                    </Row>
                    <Row className="padding-top-30">
                      <Col xs={12} className="no-padding">
                        <p>
                          Por favor digite e confirme seu e-email, e enviaremos
                          um link para você alterar a sua senha
                        </p>
                      </Col>
                    </Row>
                    <form className="form" onSubmit={handleSubmit(this.submit)}>
                      <Row className="padding-top-25">
                        <Col>
                          <Field
                            id="email"
                            component={TextField}
                            controlClassName="form-control__grey"
                            label="E-mail"
                            labelClassName="label__grey"
                            name="email"
                            type="text"
                          />
                          <Field
                            id="email_confirmation"
                            component={TextField}
                            controlClassName="form-control__grey"
                            label="Confirme seu e-mail"
                            labelClassName="label__grey"
                            name="email_confirmation"
                            type="text"
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col className="text-center">
                          <Button
                            bsStyle="primary"
                            className="btn-primary__login"
                            type="submit"
                          >
                            {this.props.loading ? "VERIFICANDO..." : "ENVIAR"}
                          </Button>
                        </Col>
                        &nbsp;
                        <Col className="text-center">
                          <p className="padding-top-5">
                            <Link to="/login">Voltar</Link>
                          </p>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </Panel.Body>
            </Panel>
          </Col>
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.authentication.loading
  };
}

const form = reduxForm({
  form: "ForgotPasswordForm",
  validate
})(ForgotPasswordPage);

export default connect(mapStateToProps)(form);
