import React from "react";
import {
  Button,
  Col,
  Row,
  ToggleButtonGroup,
  ToggleButton,
  ButtonToolbar,
  ControlLabel
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  Field,
  reduxForm,
  FieldArray,
  formValueSelector,
  change
} from "redux-form";
import { formatReais } from "../../../_helpers/reduxFormFormatters";
import { normalizeNumericValue } from "../../../_helpers/reduxFormNormalizers";
import {
  toCurrenry,
  getPaymentStatusFromProperty
} from "../../../_helpers/utils";
import { TextField } from "../../../_components/FormFields";
import * as moment from "moment";

class NewBoletoForm extends React.Component {
  state = {
    btnBaseClass: "btn-primary pull-right btn-primary__person-type",
    alert: ""
  };
  constructor(props) {
    super(props);
    this.state = {
      has_penalty: props.payment.has_penalty || false
    };
  }
  componentWillReceiveProps(nexProps) {
    const selector = formValueSelector("newBoletoForm");
    if (selector(nexProps, "discounts")) {
      change(
        "_totalDiscounts",
        this.calculateTotalDiscounts(nexProps.discounts)
      );
    }
  }
  handlePenaltyChange = e => {
    this.setState({ has_penalty: e });
    this.props.change("_payment.has_penalty", e);
    this.props.change("_payment.penalty_amount_in_cents", 0);
  };

  handleTransactionRemoval = (fields, index) => {
    let item = fields.get(index);
    let transaction_type = item.transaction_type;
    if (!item.id) {
      fields.remove(index);
      if (fields.length === 0) {
        fields.push({ transaction_type, amount_in_cents: 0 });
      }
    } else {
      this.props.onTrasactionDeleteRequest({
        transactionId: item.id,
        paymentId: this.props.payment.id
      });
      fields.remove(index);
    }
  };

  renderDiscountLine = (member, index, fields) => (
    <Col md={12}>
      <Col md={3}>
        <Field
          component={TextField}
          controlClassName="form-control sm"
          label="Novo Desconto"
          labelClassName="label__grey"
          name={`${member}.amount_in_cents`}
          normalize={normalizeNumericValue}
          format={formatReais}
        />
      </Col>
      <Col md={3}>
        <Field
          component={TextField}
          controlClassName="form-control sm"
          label="Motivo"
          labelClassName="label__grey"
          name={`${member}.name`}
          type="text"
        />
      </Col>
      <Col md={2}>
        <div style={{ height: "2.2rem" }} />
        <Button
          type={"button"}
          bsStyle="default"
          onClick={() => this.handleTransactionRemoval(fields, index)}
          style={{ minWidth: "100%" }}
        >
          Remover
        </Button>
      </Col>
      <Col md={4}>
        <div style={{ height: "2.2rem" }} />
        {fields.length - 1 === index && (
          <Button
            type={"button"}
            bsStyle="secondary"
            onClick={() =>
              fields.push({ amount_in_cents: 0, transaction_type: "credit" })
            }
            style={{ minWidth: "100%" }}
          >
            Adicionar Desconto
          </Button>
        )}
      </Col>
    </Col>
  );

  renderDiscountList = ({ fields }) => {
    if (!fields.length) {
      fields.push({ amount_in_cents: 0, transaction_type: "credit" });
    }
    return <div>{fields.map(this.renderDiscountLine)}</div>;
  };

  renderAddition = (member, index, fields) => (
    <Col md={12}>
      <Col md={3}>
        <Field
          component={TextField}
          controlClassName="form-control sm"
          label="Novo Acréscimo"
          labelClassName="label__grey"
          name={`${member}.amount_in_cents`}
          normalize={normalizeNumericValue}
          format={formatReais}
        />
      </Col>
      <Col md={3}>
        <Field
          component={TextField}
          controlClassName="form-control sm"
          label="Motivo"
          labelClassName="label__grey"
          name={`${member}.name`}
          type="text"
        />
      </Col>
      <Col md={2}>
        <div style={{ height: "2.2rem" }} />
        <Button
          type={"button"}
          bsStyle="default"
          onClick={() => this.handleTransactionRemoval(fields, index)}
          style={{ minWidth: "100%" }}
        >
          Remover
        </Button>
      </Col>
      <Col md={4}>
        <div style={{ height: "2.2rem" }} />
        {fields.length - 1 === index && (
          <Button
            type={"button"}
            bsStyle="secondary"
            onClick={() =>
              fields.push({ amount_in_cents: 0, transaction_type: "debit" })
            }
            style={{ minWidth: "100%" }}
          >
            Adicionar Acréscimo
          </Button>
        )}
      </Col>
    </Col>
  );

  renderAdditionList = ({ fields }) => {
    if (!fields.length) {
      fields.push({ amount_in_cents: 0, transaction_type: "debit" });
    }
    return <div>{fields.map(this.renderAddition)}</div>;
  };
  render() {
    const {
      submitting,
      handleSubmit,
      payment,
      _penalty,
      _totalDiscounts,
      _totalPayment,
      _totalAddings
    } = this.props;

    const Col1 = ({ label, value }) => {
      return (
        <Col md={5} xs={12}>
          <Col md={4} xs={4}>
            <span>{label}</span>
          </Col>
          <Col md={8} xs={8}>
            <span>{value}</span>
          </Col>
        </Col>
      );
    };
    const Col2 = ({ label, value }) => {
      return (
        <Col md={7} xs={12}>
          <Col md={4} xs={4}>
            <span>{label}</span>
          </Col>
          <Col md={8} xs={8}>
            <span>{value}</span>
          </Col>
        </Col>
      );
    };

    const renderDueDate = field => (
      <span className="pull-right">
        {field.input.value ? field.input.value.format("L") : ""}
      </span>
    );
    const renderTotalDiscounts = value => (
      <span className="pull-right">- {toCurrenry(value, 2)}</span>
    );
    return (
      <Row>
        <form className="form" onSubmit={handleSubmit}>
          <Col md={12} className="content-table">
            <Row>
              {/*** Original Payment Details*/}
              <Col md={12}>
                <span style={{ float: "left", fontWeight: "bold" }}>
                  {getPaymentStatusFromProperty(null, payment)}
                </span>
              </Col>
              <Col1
                label={"Valor:"}
                value={toCurrenry(payment.total_amount_in_cents / 100)}
              />
              <Col2 label={"Endereço:"} value={payment._property_address} />
              <Col1
                label={"Vencimento:"}
                value={moment(payment.due_date).format("DD/MM/YY")}
              />
              <Col2
                label={"Inquilino:"}
                value={payment.contract.tenant.full_name}
              />
              <Col1
                label={"Forma pgto:"}
                value={payment.payment_method && payment.payment_method.label}
              />
            </Row>
            <Row>
              {/*** Content */}

              <Col md={12}>
                <hr />
                {/*** New Due Date */}
                <Col md={6} sm={6}>
                  <Field
                    component={TextField}
                    controlClassName="form-control sm"
                    label="Novo Vencimento"
                    labelClassName="label__grey"
                    onChange={e => {
                      let dat = moment(e.target.value);
                      if (dat) {
                        this.props.change("_payment.due_date_obj", dat);
                      }
                    }}
                    name={`_payment.due_date`}
                    type="date"
                  />
                </Col>
                <Col md={2} sm={3}>
                  <ControlLabel className={"label__grey"}>Multa</ControlLabel>
                  <ButtonToolbar>
                    <ToggleButtonGroup
                      type="radio"
                      onChange={this.handlePenaltyChange}
                      name="_payment.has_penalty"
                      value={this.state.has_penalty}
                    >
                      <ToggleButton
                        bsSize={"small"}
                        bsStyle={
                          this.state.has_penalty ? "primary-color" : "secondary"
                        }
                        value={true}
                      >
                        Sim
                      </ToggleButton>
                      <ToggleButton
                        bsSize={"small"}
                        bsStyle={
                          !this.state.has_penalty
                            ? "primary-color"
                            : "secondary"
                        }
                        value={false}
                      >
                        Não
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ButtonToolbar>
                </Col>
                <Col md={4} sm={3}>
                  <Field
                    disabled={!this.state.has_penalty}
                    component={TextField}
                    controlClassName="form-control sm"
                    label="Valor"
                    labelClassName="label__grey"
                    name={`_payment.penalty_amount_in_cents`}
                    normalize={normalizeNumericValue}
                    format={formatReais}
                    type="text"
                  />
                </Col>
              </Col>
              <FieldArray
                component={this.renderDiscountList}
                name="discounts"
              />
              <FieldArray component={this.renderAdditionList} name="addings" />
              <Col md={12}>
                {" "}
                {/*** Subtotals */}
                <hr />
                <div style={{ padding: "16px" }}>
                  <table className="table boletoform">
                    <tbody>
                      <tr className="text-muted ">
                        <td className="titles">Valor do Aluguel</td>
                        <td>
                          <span className="pull-right">
                            {toCurrenry(_totalPayment, 2)}
                          </span>
                        </td>
                      </tr>
                      <tr className="text-muted ">
                        <td className="titles">Acréscimos</td>
                        <td>
                          <span className="pull-right">
                            + {toCurrenry(_totalAddings, 2)}
                          </span>
                        </td>
                      </tr>
                      <tr className="text-muted ">
                        <td className="titles">Descontos</td>
                        <td>{renderTotalDiscounts(_totalDiscounts)}</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  <table className="table boletoform">
                    <tbody>
                      <tr style={{ fontWeight: "bold" }}>
                        <td className="titles">Total do Boleto</td>
                        <td>
                          <span className="pull-right">
                            {toCurrenry(
                              _totalAddings -
                                _totalDiscounts +
                                _totalPayment +
                                _penalty,
                              2
                            )}
                          </span>
                        </td>
                      </tr>
                      <tr style={{ fontWeight: "bold" }}>
                        <td className="titles">Data de vencimento</td>
                        <td>
                          <Field
                            name="_payment.due_date_obj"
                            component={renderDueDate}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="w-100 text-center">
                    <small>
                      O novo boleto será enviado por email em até 2 dias úteis
                    </small>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              {" "}
              {/*** Action Buttons */}
              <Col md={12} mdPush={1}>
                <Col md={5}>
                  <Button
                    bsSize={"sm"}
                    bsStyle={"secondary"}
                    className="btn-sparse btn-block "
                    onClick={this.props.onCancel}
                  >
                    CANCELAR
                  </Button>
                </Col>
                <Col md={5}>
                  <Button
                    bsSize={"sm"}
                    bsStyle={"primary"}
                    className="btn-sparse btn-block "
                    type={"submit"}
                    disabled={submitting}
                  >
                    {this.props.loading
                      ? "Processando..."
                      : "Solicitar novo boleto"}
                  </Button>
                </Col>
              </Col>
            </Row>
          </Col>
        </form>
      </Row>
    );
  }
}

NewBoletoForm = reduxForm({
  form: "newBoletoForm",
  destroyOnUnmount: true
})(NewBoletoForm);

const selector = formValueSelector("newBoletoForm");

const calculateTotalAddings = array => {
  return !array || !array.length
    ? 0
    : array
        .map(a => {
          return parseInt(a.amount_in_cents, 0);
        })
        .reduce((sum, current) => sum + current);
};
const calculateTotalDiscounts = array => {
  return !array || !array.length
    ? 0
    : array
        .map(a => {
          return parseInt(a.amount_in_cents, 0);
        })
        .reduce((sum, current) => sum + current);
};
const mapStateToProps = (state, ownProps) => {
  let _payment = ownProps.payment;
  return {
    initialValues: {
      _payment,
      addings:
        _payment.transactions &&
        _payment.transactions.filter(p => p.transaction_type === "debit"),
      discounts:
        _payment.transactions &&
        _payment.transactions.filter(p => p.transaction_type === "credit"),
      _totalDiscounts:
        calculateTotalDiscounts(
          _payment.transactions &&
            _payment.transactions.filter(p => p.transaction_type === "credit")
        ) / 100,
      _totalAddings:
        calculateTotalAddings(
          _payment.transactions &&
            _payment.transactions.filter(p => p.transaction_type === "debit")
        ) / 100
    },
    _penalty: selector(state, "_payment.has_penalty")
      ? selector(state, "_payment.penalty_amount_in_cents") / 100
      : 0,
    _totalDiscounts:
      calculateTotalDiscounts(selector(state, "discounts")) / 100,
    _totalAddings: calculateTotalAddings(selector(state, "addings")) / 100,
    _totalPayment: _payment.total_amount_in_cents / 100
  };
};

NewBoletoForm = connect(mapStateToProps)(NewBoletoForm);

export default NewBoletoForm;
