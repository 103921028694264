import React from "react";
import { Button, Col, Grid, Row, Glyphicon } from "react-bootstrap";
import { connect } from "react-redux";
import { history } from "../../_helpers/history";
import { Link } from "react-router-dom";
import SweetAlert from "sweetalert2-react";
import {
  toCurrenry,
  renderFormattedListingsStatus,
  renderFormattedListingTooltip,
  isUserAdmin
} from "../../_helpers/utils";
import { propertyActions } from "../../_actions/propertyActions";
import BootstrapTable from "react-bootstrap-table-next";
import {
  ButtonGroup,
  DropdownButton,
  MenuItem,
  ButtonToolbar,
  Breadcrumb
} from "react-bootstrap";
import { PanelWithTitle } from "../../_components/PanelWithTitle";
import LoadingMessage from "../../_components/Shared/LoadingMessage";
import { TooltipWrapper } from "../../_components/TooltipWrapper";
import { listingsStatusTypes } from "../../_helpers/optionsArrays";
import { billingActions } from "../../_actions/billingActions";
import { ListingApprovalDialog } from "./Dialogs/ListingDialogs";
import { listingActions } from "../../_actions/listingActions";
import { scrollTop, renderAddressFormatter } from "../../_helpers/utils";
import paginationFactory from "react-bootstrap-table2-paginator";
import TogglePanel from "../../_components/TogglePanel";
import { userActions } from "../../_actions/userActions";
import PageTitle from "../../_components/Shared/PageTitle";
import {
  showDeleteConfirmationDialog,
  showFoundTenantDialog,
  showPublishingStatusChangeDialog
} from "./Dialogs/AlertDialogs";
import SpinnerComponent from "../../_components/Spinner";
import SwitchBox from "../../_components/Shared/SwitchBox";
const buttonsSizes = "sm";

class PropertyListingsListingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPropertyApprovalModal: false
    };
  }
  componentDidMount() {
    scrollTop();
  }
  componentWillMount() {
    this.props.dispatch(listingActions.findAll());
  }
  handleSearchUpdated = (eventkey, searchText) => {
    const { dispatch } = this.props;
    dispatch(listingActions.filterApiPropertiesById(searchText));
  };
  handleSwitchChange = (checked, id) => {
    const { dispatch } = this.props;
    showPublishingStatusChangeDialog(dispatch, checked, id);
  };
  compare = (rowA, rowB) => {
    return this.state.sortOrder === "asc"
      ? ("" + rowA._sortingDescription).localeCompare(rowB._sortingDescription)
      : ("" + rowB._sortingDescription).localeCompare(rowA._sortingDescription);
  };
  handleTableChange = (type, { sortField, sortOrder, data }) => {
    sortOrder = this.state.sortOrder || "asc";
    this.setState(() => ({
      sortField,
      data,
      sortOrder: sortOrder === "asc" ? "desc" : "asc"
    }));

    sortField = sortField && sortField.replace(/\./g, "_");
    switch (type) {
      case "sort":
        this.props.dispatch(
          listingActions.findAllSortingBy(sortField, sortOrder)
        );
        break;
      default:
        break;
    }
  };
  renderTableRows = () => {
    let rows = [];
    this.state.data.forEach((property, index) => {
      rows.push(
        <tr key={`prop-${index}`}>
          <td>{property.id}</td>
          <td>{property.listing ? property.listing.title : "-"}</td>
          <td>
            {property.address
              ? property.address.street + " " + property.address.number
              : "-"}
          </td>
          <td>{property.address ? property.address.neighbourhood : "-"}</td>
          <td>
            {property.listing
              ? toCurrenry(property.listing.total_price_in_cents / 100)
              : "-"}
          </td>
          <td>
            <Button
              onClick={() => history.push("/listings/edit/" + property.id)}
              bsStyle="link"
            >
              Editar
            </Button>
          </td>
        </tr>
      );
    });

    return rows;
  };

  handleModalHideRequest = () => {
    this.props.dispatch(propertyActions.hideModals());
  };

  handleListingApprovalSubmission = values => {
    if (values && values.operation === "approve") {
      this.setState({
        showPropertyApprovalModal: true,
        operationValues: values
      });
    } else {
      this.props.dispatch(
        listingActions.reviewListing(
          this.props.selectedProperty.listing.id,
          values
        )
      );
    }
  };

  render() {
    const {
      user,
      propertyListLoading,
      selectedProperty,
      propertyToApproveOpen,
      currentListingChangeId,
      listingChangeLoading,
      currentSortStatus,
      listedProperties,
      selectedUser
    } = this.props;

    const columns = [
      {
        dataField: "property.code",
        text: "ID",
        hidden: false,
        sort: true
      },
      {
        dataField: "admin_action_1",
        isDummyField: true,
        hidden: !isUserAdmin(user),
        headerStyle: (colum, colIndex) => {
          return { width: "30px", textAlign: "center" };
        },
        formatter: (cellContent, row) => {
          let color = (row.status && row.status.color) || "gray";
          return (
            <div
              onClick={() => {
                this.props.dispatch(
                  userActions.getUserById(row.property.user_id)
                );
                this.props.dispatch(
                  billingActions.showPropertyApprovalModal(row.property.id)
                );
              }}
            >
              <TooltipWrapper
                id={"tooltip-" + row.id}
                tooltip={"Use esta opção para aprovar/rejeitar um anúncio"}
              >
                <Glyphicon
                  glyph="cog"
                  style={{ fontSize: "2rem", cursor: "pointer", color: color }}
                />
              </TooltipWrapper>{" "}
            </div>
          );
        }
      },
      {
        dataField: "status",
        text: "Status",
        hidden: false,
        style: (colum, colIndex) => {
          return { maxWidth: "150px" };
        },
        sort: true,
        formatter: renderFormattedListingsStatus
      },
      {
        dataField: "df",
        isDummyField: true,
        headerStyle: (colum, colIndex) => {
          return { width: "25px", textAlign: "center" };
        },
        formatter: renderFormattedListingTooltip
      },

      {
        dataField: "is_active",
        isDummyField: true,
        text: "Publicação",
        sort: true,
        formatter: (cellContent, row) => {
          if (row && row.is_data_valid) {
            return currentListingChangeId === row.id && listingChangeLoading ? (
              <SpinnerComponent />
            ) : (
              <div>
                <SwitchBox
                  disabled={
                    (row.status.value === "not_approved" &&
                      row.is_active === false) ||
                    (row.status && row.status.value === "in_review")
                  }
                  id={row.id + ""}
                  onSwitchChange={this.handleSwitchChange}
                  checked={row.is_active}
                />
                <p style={{ marginTop: "10px", fontSize: "12px" }}>
                  {row.is_active
                    ? "Seu anúncio está publicado"
                    : "Seu anúncio NÃO está publicado"}
                </p>
              </div>
            );
          } else {
            return (
              <div>
                <SwitchBox
                  disabled={true}
                  id={row.id + ""}
                  checked={row.is_active}
                />
                <p style={{ marginTop: "10px", fontSize: "12px" }}>
                  {row.is_active
                    ? "Seu anúncio está publicado"
                    : "Seu anúncio NÃO está publicado"}
                </p>
              </div>
            );
          }
        }
      },
      {
        dataField: "property.id",
        text: "Ref Zimobi",
        sort: true,
        hidden: !isUserAdmin(user)
      },
      {
        dataField: "property.address.street",
        text: "Endereço",
        sort: true,
        formatter: (cellContent, row) => {
          return renderAddressFormatter(
            (row.property && row.property.address) || ""
          );
        }
      },
      // {
      //   dataField: 'listing.events.view',
      //   text: (
      //     <Grid fluid={true} style={{ textAlign: 'center' }}>
      //       <Col md={12} xs={12}>
      //         Visualizações
      //       </Col>
      //       <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //         Passada
      //       </Col>
      //       <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //         {' '}
      //         Atual
      //       </Col>
      //     </Grid>
      //   ),
      //   formatter: (cellContent, row) => {
      //     let view =
      //       row && row.events && row.events.view ? row.events.view : [];
      //     return (
      //       <Grid fluid={true} style={{ textAlign: 'center' }}>
      //         <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //           {view && view[1] ? view[1].count : 0}
      //         </Col>
      //         <Col
      //           md={6}
      //           xs={6}
      //           style={{
      //             fontWeight: 'bold',
      //             opacity: 0.7,
      //             whiteSpace: 'nowrap'
      //           }}
      //         >
      //           <CaretIcon
      //             prev={view && view[1] ? view[1].count : 0}
      //             current={view && view[0] ? view[0].count : 0}
      //           />
      //           {view && view[0] ? view[0].count : 0}
      //         </Col>
      //       </Grid>
      //     );
      //   }
      // },

      // {
      //   dataField: 'listing.events',
      //   text: (
      //     <Grid fluid={true} style={{ textAlign: 'center' }}>
      //       <Col md={12} xs={12}>
      //         Contatos
      //       </Col>
      //       <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //         Passada
      //       </Col>
      //       <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //         {' '}
      //         Atual
      //       </Col>
      //     </Grid>
      //   ),
      //   formatter: (cellContent, row) => {
      //     let data =
      //       row && row.events && row.events.contact ? row.events.contact : [];
      //     return (
      //       <Grid fluid={true} style={{ textAlign: 'center' }}>
      //         <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //           {data && data[1] ? data[1].count : 0}
      //         </Col>
      //         <Col
      //           md={6}
      //           xs={6}
      //           style={{
      //             fontWeight: 'bold',
      //             opacity: 0.7,
      //             whiteSpace: 'nowrap'
      //           }}
      //         >
      //           {' '}
      //           <CaretIcon
      //             prev={data && data[1] ? data[1].count : 0}
      //             current={data && data[0] ? data[0].count : 0}
      //           />
      //           {data && data[0] ? data[0].count : 0}
      //         </Col>
      //       </Grid>
      //     );
      //   }
      // },
      // {
      //   dataField: 'listing.events.visit_completed',
      //   text: (
      //     <Grid fluid={true} style={{ textAlign: 'center' }}>
      //       <Col md={12} xs={12}>
      //         Visitas Realizadas
      //       </Col>
      //       <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //         Passada
      //       </Col>
      //       <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //         {' '}
      //         Atual
      //       </Col>
      //     </Grid>
      //   ),
      //   formatter: (cellContent, row) => {
      //     let data =
      //       row && row && row.events && row.events.visit_scheduled
      //         ? row.events.visit_scheduled
      //         : [];
      //     return (
      //       <Grid fluid={true} style={{ textAlign: 'center' }}>
      //         <Col md={6} xs={6} style={{ fontWeight: 'normal', opacity: 0.7 }}>
      //           {data && data[1] ? data[1].count : 0}
      //         </Col>
      //         <Col
      //           md={6}
      //           xs={6}
      //           style={{
      //             fontWeight: 'bold',
      //             opacity: 0.7,
      //             whiteSpace: 'nowrap'
      //           }}
      //         >
      //           {' '}
      //           <CaretIcon
      //             prev={data && data[1] ? data[1].count : 0}
      //             current={data && data[0] ? data[0].count : 0}
      //           />
      //           {data && data[0] ? data[0].count : 0}
      //         </Col>
      //       </Grid>
      //     );
      //   }
      // },
      {
        dataField: "df2",
        isDummyField: true,
        text: "Ações",
        formatter: (cellContent, row) => {
          return (
            <div style={{ display: "flex" }}>
              <Button
                bsSize={buttonsSizes}
                bsStyle={"primary"}
                onClick={() =>
                  showFoundTenantDialog(this.props.dispatch, row.property.id)
                }
              >
                ENCONTREI MEU INQUILINO
              </Button>
              <Link to={"/listings/edit/" + row.id}>
                <Button bsSize={buttonsSizes} bsStyle={"info"}>
                  Editar
                </Button>
              </Link>
              {"        "}
              {row.property && (
                <Link
                  to={"/listings/view/" + row.property.id}
                  target="_blank"
                  style={{
                    display:
                      row && row && row.is_data_valid && row.is_active
                        ? ""
                        : "none"
                  }}
                >
                  <Button bsSize={buttonsSizes} bsStyle={"info"}>
                    Ver anúncio
                  </Button>
                </Link>
              )}
              <Button
                bsSize={buttonsSizes}
                bsStyle={"default"}
                onClick={() =>
                  showDeleteConfirmationDialog(this.props.dispatch, row.id)
                }
              >
                Excluir anúncio
              </Button>
            </div>
          );
        }
      }
    ];

    const ListingsTableList = ({ data, onTableChange, meta }) => {
      if (!meta) {
        return null;
      }
      const {
        current_page,
        page_size,
        total_count,
        next_page,
        previous_page
      } = meta;

      const pagination = paginationFactory({
        custom: true,
        page: current_page, // Specify the current page. It's necessary when remote is enabled
        sizePerPage: page_size, // Specify the size per page. It's necessary when remote is enabled
        totalSize: total_count, // Total data size. It's necessary when remote is enabled
        showTotal: true, // display pagination information
        firstPageText: "Primeira", // the text of first page button
        prePageText: "Anterior", // the text of previous page button
        nextPageText: "Próxima", // the text of next page button
        lastPageText: "Última", // the text of last page button
        hideSizePerPage: true, // hide the size per page dropdown
        hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
        onPageChange: (page, size) => {
          let jumping = Math.abs(page - current_page) > 1;
          let baseUrl = next_page || previous_page;
          if (jumping) {
            let url = baseUrl.replace(/page=[\d].*&/, "page=" + page + "&");
            this.props.dispatch(listingActions.findAll(url));
          } else {
            this.props.dispatch(
              listingActions.findAll(
                page > current_page ? next_page : previous_page
              )
            );
          }
        }, // callback function when page was changing
        onSizePerPageChange: (sizePerPage, page) => {}, // callback function when page size was changing
        paginationTotalRenderer: (from, to, size) => {
          return (
            <span>
              mostrando {from}-{to} de {size} entradas
            </span>
          );
        } // custom the pagination total
      });
      return (
        <div style={{ overflowX: "auto" }}>
          <BootstrapTable
            id="listing_table"
            key={"listing_table"}
            striped
            remote={{ pagination: true, filter: false, sort: true }}
            pagination={pagination}
            bordered={false}
            onTableChange={onTableChange}
            noDataIndication="Sem dados"
            keyField="id"
            data={data}
            columns={columns}
            headerClasses={"content-table"}
            rowClasses={"content-table"}
          />
        </div>
      );
    };
    const PropertyToolbar = ({ currentSortStatus }) => {
      let tmpList = listedProperties
        ? [...new Set(listedProperties.data.map(a => a.status))]
        : [""];
      tmpList = tmpList.map(b =>
        listingsStatusTypes.find(st => st.value === b)
      );
      let type =
        listingsStatusTypes.find(st => st.value === currentSortStatus) ||
        listingsStatusTypes[0];
      return (
        <div style={{ marginBottom: "3rem" }}>
          <ButtonToolbar>
            <span style={{ float: "left", marginLeft: "5px" }}>
              Organizar por: &nbsp;&nbsp;
            </span>
            <ButtonGroup>
              <DropdownButton
                style={{ minWidth: "200px", marginRight: "1rem" }}
                bsSize={"xsmall"}
                title={type.label}
                id="bg-nested-dropdown"
                onSelect={(evtKey, evt) => {
                  this.props.dispatch(
                    propertyActions.filterPropertiesWithFeatures({})
                  );
                  this.props.dispatch(
                    listingActions.sortByListingStatus(
                      evt.target.attributes.eventkey.value,
                      listedProperties.data
                    )
                  );
                }}
              >
                {listingsStatusTypes.map((item, index) => {
                  return (
                    tmpList.map(t => t.value).indexOf(item.value) !== -1 && (
                      <MenuItem
                        key={index}
                        eventkey={item.value}
                        active={currentSortStatus === item.value}
                      >
                        {item.label}
                      </MenuItem>
                    )
                  );
                })}
              </DropdownButton>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
      );
    };
    let panels = [
      {
        thinBody: true,
        body: (
          <div>
            <PageTitle>Anúncios</PageTitle>
            <Link to={"/listings/new"}>
              <Button bsStyle={"primary"}>CRIAR NOVO ANÚNCIO</Button>
            </Link>
          </div>
        )
      },

      {
        thinBody: true,
        body: (
          <Breadcrumb>
            <Breadcrumb.Item href="/dashboard">Meus Imóveis</Breadcrumb.Item>
            <Breadcrumb.Item active href="/listings">
              Anúncios
            </Breadcrumb.Item>
          </Breadcrumb>
        )
      },

      {
        thinBody: true,
        hidden: true,
        body: <PropertyToolbar currentSortStatus={currentSortStatus} />
      },
      {
        thinBody: true,
        hidden: !this.props.shouldShowEmptyMessage,
        body: (
          <Col
            md={6}
            mdPush={3}
            style={{ marginTop: "10rem" }}
            className=" animated fadeInDown"
          >
            <PanelWithTitle
              iconPath={"/images/icon_ad_listing.svg"}
              to={"/listings/new"}
              callToAction={"Criar Anúncio"}
              callToActionSize={"small"}
              title={"Cadastrar Anúncio"}
              body={"Anunciamos seu imóvel nos melhores portais gratuitamente"}
            />
          </Col>
        )
      },
      {
        thinBody: false,
        hidden: this.props.shouldShowEmptyMessage,
        hasSearch: isUserAdmin(user),
        title: "Lista de anúncios",
        body: (
          <div name="listingsWrapper">
            {propertyListLoading ? (
              <LoadingMessage />
            ) : (
              <ListingsTableList
                data={listedProperties.data}
                meta={listedProperties.meta}
                onTableChange={this.handleTableChange}
              />
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        {selectedProperty && (
          <ListingApprovalDialog
            onHide={this.handleModalHideRequest}
            onSubmit={this.handleListingApprovalSubmission}
            container={this}
            user={selectedUser}
            property={selectedProperty}
            open={propertyToApproveOpen}
          />
        )}
        <Grid fluid={true}>
          <Row>
            <Col md={12} className="main animated fadeInDown">
              <Row>
                {panels.map((panel, index) => {
                  return (
                    !panel.hidden && (
                      <TogglePanel
                        key={index}
                        body={panel.body}
                        eventkey={index}
                        form={this.props.form}
                        handleValidationChange={
                          this.handlePanelValidationChange
                        }
                        initialValues={panel.initialValues}
                        onBackButtonClick={this.goToPreviousPanel}
                        onSubmit={this.goToNextPanel}
                        showNextButton
                        title={panel.title}
                        toogleOpenPanel={() => this.toogleOpenPanel(index)}
                        validate={this.props.validate}
                        size={panel.colSize}
                        thinBody={panel.thinBody}
                        toogleable={panel.toogleable}
                        hasSearch={panel.hasSearch}
                        onSearchChanged={this.handleSearchUpdated}
                        customComponent={panel.customComponent}
                      />
                    )
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Grid>
        <SweetAlert
          show={this.state.showPropertyApprovalModal}
          title="Confirmação"
          text={`Tem certeza que deseja aprovar o anúncio ${selectedProperty &&
            selectedProperty.code} `}
          type="warning"
          showCancelButton
          onConfirm={() => {
            this.props.dispatch(
              listingActions.reviewListing(
                this.props.selectedProperty.listing.id,
                this.state.operationValues
              )
            );
            this.setState({ showPropertyApprovalModal: false });
          }}
          onCancel={() => {
            this.setState({
              showPropertyApprovalModal: false,
              operationValues: []
            });
          }}
          onClose={() => console.debug("close")} // eslint-disable-line no-console
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { properties } = state;
  return {
    user: state.authentication && state.authentication.user,
    selectedUser: state.registration && state.registration.selectedUser,
    currentListingChangeId: properties.currentListingChangeId,
    listingChangeLoading: properties.listingChangeLoading,
    propertyListLoading: properties.propertyListLoading,
    filtering: properties.filtering,
    listedProperties: properties.listedProperties || {},
    shouldShowEmptyMessage: properties.shouldShowEmptyMessage,
    currentDateSelected: properties.currentDateSummary,
    selectedProperty: properties.selectedProperty,
    currentSortStatus: properties.currentSortStatus,
    propertyToApproveOpen: properties.propertyToApproveOpen
  };
};
export default connect(mapStateToProps)(PropertyListingsListingPage);
