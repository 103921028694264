import { bankAccountConstants } from '../_constants/bankAccountConstants';

export default function bankAccounts(state = {}, action) {
  switch (action.type) {
    case bankAccountConstants.GETALL_REQUEST:
      return { ...state, loading: true };
    case bankAccountConstants.GETALL_SUCCESS:
      return { ...state, items: action.bankAccounts };
    case bankAccountConstants.GETALL_FAILURE:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
