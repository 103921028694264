import React from "react";
import { Col, Row, Glyphicon } from "react-bootstrap";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import {
  receivingContractStatusFormatter,
  toCurrenry,
  getActiveContract
} from "../../../_helpers/utils";
import * as moment from "moment";
import { formatCPF, formatPhone } from "../../../_helpers/reduxFormFormatters";
import SpinnerComponent from "../../../_components/Spinner";
import SwitchBox from "../../../_components/Shared/SwitchBox";
class PropertyViewForm extends React.Component {
  handleSwitchChange = (checked, id) => {
    this.props.handleSubmit({
      id,
      payment_method: checked ? "bank_slip" : "manual"
    });
  };

  render() {
    const { property, loading } = this.props;
    const { listing, address } = property;
    const activeContract = getActiveContract(property);
    const tenant = activeContract && activeContract.tenant;
    const Line = ({ label, value }) => {
      return value ? (
        <div>
          <small>{label}</small>
          <h5>{value}</h5>
        </div>
      ) : (
        ""
      );
    };
    const Header = props => {
      return (
        <div
          className="title"
          style={{ textTransform: "uppercase", width: "100%" }}
        >
          {props.children}
          {props.action && (
            <Link to={props.to} className="header-icon">
              <Glyphicon glyph={props.icon} />
            </Link>
          )}
          <hr style={{ marginTop: "5px" }} />
        </div>
      );
    };

    return (
      <form className="form">
        <Row className="content-table">
          <Col md={12}>
            {tenant ? (
              <Col md={4}>
                <Col>
                  <img
                    alt="prop type im"
                    style={{ objectFit: "cover" }}
                    height="150"
                    width="100%"
                    src={
                      listing && listing.images && listing.images.length > 1
                        ? listing.images[0].url
                        : "/images/property_image_placeholder.png"
                    }
                  />
                </Col>
                <Col md={12}>
                  <Line
                    label={"Status da Forma de Cobrança"}
                    value={receivingContractStatusFormatter(null, property)}
                  />
                </Col>
                <Col md={12} hidden>
                  <Line
                    label={"Cobrança por boleto?"}
                    value={
                      <div className="text-center">
                        {property && loading ? (
                          <SpinnerComponent />
                        ) : (
                          <SwitchBox
                            disabled={false}
                            id={activeContract.id + ""}
                            onSwitchChange={this.handleSwitchChange}
                            checked={
                              activeContract.payment_method.value ===
                              "bank_slip"
                            }
                          />
                        )}
                      </div>
                    }
                  />
                </Col>
              </Col>
            ) : (
              "Contrato não encontrado, clique em 'detalhes do imóvel' para visualizar o cadastro"
            )}

            <Col md={4}>
              {address && (
                <section>
                  <Header
                    action={true}
                    to={"/properties/edit/" + property.id + "/property"}
                    icon={"pencil"}
                  >
                    Dados do imóvel{" "}
                  </Header>
                  <Line
                    label={"Endereço do imóvel"}
                    value={`${address.street || ""}, ${address.number ||
                      ""} ${address.complement || ""}`}
                  />
                  <Line label={"CEP"} value={`${address.postal_code || ""}`} />
                </section>
              )}
              {tenant &&
                (tenant.full_name ||
                  tenant.cpf ||
                  tenant.phone ||
                  tenant.email) && (
                  <section>
                    <Header
                      action={true}
                      to={"/properties/edit/" + property.id + "/tenant"}
                      icon={"pencil"}
                    >
                      Dados dos Inquilino{" "}
                    </Header>
                    <div>
                      <Line
                        label={"Inquilino"}
                        value={tenant.full_name || ""}
                      />
                      <Line label={"CPF"} value={formatCPF(tenant.cpf) || ""} />
                      <Line label={"E-mail"} value={tenant.email || ""} />
                      <Line
                        label={"Telefone"}
                        value={formatPhone(tenant.phone) || ""}
                      />
                    </div>
                  </section>
                )}
            </Col>
            <Col md={4}>
              {activeContract && (
                <section>
                  <Header
                    action={true}
                    to={"/properties/edit/" + property.id + "/contract"}
                    icon={"pencil"}
                  >
                    Contratos e Valores{" "}
                  </Header>
                  <div>
                    <Line
                      label={"Dia de Vencimento"}
                      value={activeContract.due_date_day}
                    />
                    <Line
                      label={"Data final do contrato"}
                      value={
                        isNaN(moment(activeContract.start_date))
                          ? ""
                          : moment(activeContract.start_date)
                              .add(activeContract.duration_in_months, "month")
                              .format("DD/MM/YYYY")
                      }
                    />
                    <Line
                      label={"Garantia do aluguel"}
                      value={activeContract.rental_guarantee_type.label}
                    />
                    <Line
                      label={"Valor do aluguel"}
                      value={toCurrenry(
                        activeContract.rental_price_in_cents / 100
                      )}
                    />
                    <Line
                      label={"Valor do condomínio"}
                      value={toCurrenry(
                        activeContract.condo_fee_in_cents / 100
                      )}
                    />
                    <Line
                      label={"Valor do IPTU"}
                      value={toCurrenry(
                        activeContract.iptu_price_in_cents / 100
                      )}
                    />
                    <Line label={"Documentação Inquilino"} value={" "} />
                    <ul>
                      {activeContract.contract_file_url && (
                        <li>
                          <a
                            target="blank"
                            href={activeContract.contract_file_url}
                          >
                            <Glyphicon glyph="file" />
                            Contrato
                          </a>
                        </li>
                      )}
                      {activeContract.inspection_file_url && (
                        <li>
                          <a
                            target="blank"
                            href={activeContract.inspection_file_url}
                          >
                            <Glyphicon glyph="file" />
                            Laudo Vistoria
                          </a>
                        </li>
                      )}

                      {tenant && tenant.social_contract_file_url && (
                        <li>
                          <a
                            target="blank"
                            href={tenant.social_contract_file_url}
                          >
                            <Glyphicon glyph="file" />
                            Contrato Social
                          </a>
                        </li>
                      )}
                      {tenant &&
                        tenant.rg_images &&
                        tenant.rg_images.map((item, index) => {
                          return (
                            <li>
                              <a target="blank" href={item.url}>
                                <Glyphicon glyph="file" />
                                Imagem RG {index + 1}
                              </a>
                            </li>
                          );
                        })}
                      {tenant &&
                        tenant.cpf_images &&
                        tenant.cpf_images.map((item, index) => {
                          return (
                            <li>
                              <a target="blank" href={item.url}>
                                <Glyphicon glyph="file" />
                                Imagem CPF {index + 1}
                              </a>
                            </li>
                          );
                        })}
                      {tenant &&
                        tenant.address_images &&
                        tenant.address_images.map((item, index) => {
                          return (
                            <li>
                              <a target="blank" href={item.url}>
                                <Glyphicon glyph="file" />
                                Comprovante Endereço {index + 1}
                              </a>
                            </li>
                          );
                        })}
                      {tenant &&
                        tenant.statements_images &&
                        tenant.statements_images.map((item, index) => {
                          return (
                            <li>
                              <a target="blank" href={item.url}>
                                <Glyphicon glyph="file" />
                                Comprovante {index + 1}
                              </a>
                            </li>
                          );
                        })}
                    </ul>

                    <Line label={"Documentação Proprietário"} value={" "} />

                    <ul>
                      {property.landlord &&
                        property.landlord.rg_images &&
                        property.landlord.rg_images.map((item, index) => {
                          return (
                            <li>
                              <a target="blank" href={item.url}>
                                <Glyphicon glyph="file" />
                                Imagem RG {index + 1}
                              </a>
                            </li>
                          );
                        })}
                      {property.landlord &&
                        property.landlord.cpf_images &&
                        property.landlord.cpf_images.map((item, index) => {
                          return (
                            <li>
                              <a target="blank" href={item.url}>
                                <Glyphicon glyph="file" />
                                Imagem CPF {index + 1}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </section>
              )}
            </Col>
          </Col>
        </Row>
      </form>
    );
  }
}

const form = reduxForm({
  form: "propertyViewDialog",
  destroyOnUnmount: true,
  initialValues: {}
})(PropertyViewForm);

export default connect()(form);
