import React from 'react';
import { Col, Row, ControlLabel } from 'react-bootstrap';

export const RenderRow = ({ label, value, colLabelSize, colValSize }) => (
  <Row>
    <Col md={colLabelSize || 5} xs={5}>
      <ControlLabel className="label__dark_blue">{label}</ControlLabel>
    </Col>
    <Col md={colValSize || 7} xs={7}>
      <p>{value}</p>
    </Col>
  </Row>
);

export default RenderRow;
