import { propertyConstants } from "../_constants/propertyConstants";
import { paymentConstants } from "../_constants/paymentConstants";
import { listingConstants } from "../_constants/listingConstants";
import * as moment from "moment";
import { billingConstants } from "../_constants/billingConstants";
import { listingsStatusTypes } from "../_helpers/optionsArrays";
require("string_score");

export default function properties(
  state = {
    currentListingChangeId: 0,
    boletoModalOpen: false,
    markAsPaidModalOpen: false,
    currentVisualizationMode: 2,
    currentSortStatus: void 0,
    currentDateSummary: void 0
  },
  action
) {
  switch (action.type) {
    case listingConstants.REVIEW_PROCESS_REQUEST:
      return { ...state, loading: true };
    case listingConstants.REVIEW_PROCESS_SUCCESS:
      return { ...state, loading: false, selectedProperty: action.payload };
    case listingConstants.REVIEW_PROCESS_ERROR:
      return { ...state, loading: false };
    case listingConstants.LISTING_GETALL_REQUEST:
      return {
        ...state,
        propertyListLoading: true,
        shouldShowEmptyMessage: false
      };
    case listingConstants.LISTING_GETALL_SUCCESS:
      return {
        ...state,
        propertyListLoading: false,
        filtering: action.filtering,
        listedProperties: action.response,
        shouldShowEmptyMessage:
          (!action.filtering &&
            action.response &&
            action.response.data &&
            action.response.data.length === 0) ||
          false
      };
    case listingConstants.LISTING_GETALL_FAILURE:
      return { ...state, propertyListLoading: false, filtering: false };
    case propertyConstants.PROPERTIES_SORT_BY_STATUS:
      // let statuses2 = //paymentStatusTypes.map(l => l.value);
      let items = action.itemsToSort;
      // statuses2.move(statuses2.indexOf(action.currentSortStatus), 0);
      // items.sort((first, second) => {
      //   let cA = getActiveContract(first);
      //   let cB = getActiveContract(second);
      //   if (cA && cB) {
      //     return (
      //       statuses2.indexOf(cA.payment_status) -
      //       statuses2.indexOf(cB.payment_status)
      //     );
      //   } else {
      //     return -1;
      //   }
      // });
      return {
        ...state,
        currentSortStatus: action.currentSortStatus,
        loading: false,
        rentedProperties: { ...state.rentedProperties, data: items }
      };

    case listingConstants.LISTING_SORT_BY_STATUS:
      let statuses = listingsStatusTypes.map(l => l.value);
      statuses.move(statuses.indexOf(action.currentSortStatus), 0);
      let litems = action.itemsToSort.sort((a, b) => {
        return statuses.indexOf(a.status) - statuses.indexOf(b.status);
      });
      return {
        ...state,
        currentSortStatus: action.currentSortStatus,
        loading: false,
        listedProperties: { ...state.listedProperties, data: litems }
      };

    case listingConstants.LISTING_REQUEST:
      return { ...state, loading: true, selectedListing: void 0 };
    case listingConstants.LISTING_SUCCESS:
      if (state.listedProperties && state.listedProperties.data) {
        let index2 = state.listedProperties.data
          .map(x => x.id)
          .indexOf(action.listing.id);
        let newListedProperties = [
          ...state.listedProperties.data.slice(0, index2),
          action.listing,
          ...state.listedProperties.data.slice(index2 + 1)
        ];
        return {
          ...state,
          currentListing: void 0,
          loading: false,
          selectedListing: action.listing,
          listedProperties: {
            ...state.listedProperties,
            data: newListedProperties
          }
        };
      } else {
        return {
          ...state,
          loading: false,
          selectedListing: action.listing
        };
      }

    case listingConstants.LISTING_FAILURE:
      return { ...state, loading: false };
    case listingConstants.LISTING_NEARBY_REQUEST:
      return { ...state, loadingNearby: true };
    case listingConstants.LISTING_NEARBY_SUCCESS:
      return {
        ...state,
        loadingNearby: false,
        similarListings: action.listings
      };
    case listingConstants.LISTING_NEARBY_MORE_SUCCESS:
      let oldData = state.similarListings.data;
      let newData = action.listings.data;
      let meta = action.listings.meta;
      let newList = [...oldData, ...newData];
      return {
        ...state,
        loadingNearby: false,
        similarListings: { data: newList, meta }
      };
    case listingConstants.LISTING_LATLNG_SUCCESS:
      let enhancedListingWithLatLng = state.selectedListing;
      enhancedListingWithLatLng.latlng = action.latlng;
      return {
        ...state,
        selectedListing: enhancedListingWithLatLng
      };
    case listingConstants.LISTING_NEARBY_FAILURE:
      return { ...state, loadingNearby: false };
    case propertyConstants.REGISTER_REQUEST:
      return { ...state, loading: true };
    case propertyConstants.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedProperty: void 0,
        selectedListing: void 0,
        currentListing: void 0
      };
    case propertyConstants.GET_PROPERTY_SUCCESS:
      delete action.property["contracts"];

      if (state.rentedProperties) {
        let indexSuccessProperty = state.rentedProperties.data
          .map(p => p.id)
          .indexOf(action.property.id);
        let newProperties = [
          ...state.rentedProperties.data.slice(0, indexSuccessProperty),
          action.property,
          ...state.rentedProperties.data.slice(indexSuccessProperty + 1)
        ];
        return {
          ...state,
          loading: false,
          selectedProperty: action.property,
          rentedProperties: { ...state.rentedProperties, data: newProperties }
        };
      } else {
        return {
          ...state,
          loading: false,
          selectedProperty: action.property
        };
      }

    case propertyConstants.REGISTER_FAILURE:
      return { ...state, loading: false };
    case propertyConstants.PROPERTIES_GETALL_REQUEST:
      return { ...state, loadingProperties: true };
    case propertyConstants.PROPERTIES_GETALL_SUCCESS:
      let contractList = action.properties.data
        .filter(p => p.active_contract)
        .map(property => {
          return property.active_contract;
        });
      let enhancedProperties = {
        ...action.properties,
        data: action.properties.data.map(property => {
          delete property["contracts"];
          return property;
        })
      };
      let enhancedPayments = [];
      return {
        ...state,
        contracts: contractList,
        payments: Object.values([].concat.apply([], enhancedPayments)),
        rentedProperties: enhancedProperties,
        // analyticsTableData: calculateAnalytics(action.properties.data),
        loadingProperties: false
      };
    case propertyConstants.GETALL_PROPERTY_LISTINGS:
      return { ...state, listedProperties: action.listings, loading: true };
    case propertyConstants.PROPERTIES_GETALL_FAILURE:
      return { ...state, loadingProperties: false };
    case propertyConstants.GET_TO_EDIT_REQUEST:
      return { ...state, loading: true };
    case propertyConstants.GET_TO_EDIT_SUCCESS:
      return { ...state, propertyToEdit: action.payload, loading: false };
    case propertyConstants.GET_TO_EDIT_FAILURE:
      return { ...state, loading: false };
    case propertyConstants.UPDATE_MODALS:
      return { ...state, ...action.body };
    case paymentConstants.CREATE_TRANSACTION_REQUEST:
      return { ...state, transactionLoading: true };
    case paymentConstants.UPDATE_TRANSACTION_REQUEST:
      return { ...state, transactionLoading: true };
    case paymentConstants.CREATE_TRANSACTION_SUCCESS:
      return { ...state, transactionLoading: false };
    case paymentConstants.UPDATE_TRANSACTION_SUCCESS:
      return { ...state, transactionLoading: false };
    case listingConstants.LISTING_CHANGE_REQUEST:
      return {
        ...state,
        listingChangeLoading: true,
        currentListingChangeId: parseInt(action.id)
      };
    case listingConstants.LISTING_CHANGE_SUCCESS:
      let index = state.listedProperties.data
        .map(function(x) {
          return x.id;
        })
        .indexOf(action.listing.id);
      return {
        ...state,
        listedProperties: {
          ...state.listedProperties,
          data: [
            ...state.listedProperties.data.slice(0, index),
            action.listing,
            ...state.listedProperties.data.slice(index + 1)
          ]
        },
        listingChangeLoading: false,
        currentListingChangeId: action.listing.id
      };
    case listingConstants.LISTING_CHANGE_ERROR:
      return { ...state, listingChangeLoading: false };

    case propertyConstants.HIDE_MODALS:
      return {
        ...state,
        boletoModalOpen: false,
        markAsPaidModalOpen: false,
        propertyViewModalOpen: false,
        propertyToApproveOpen: false,
        propertyPaymentToApproveOpen: false,
        listingExclusionConfirmationDialogOpen: false,
        listingConfirmationDialogOpen: false
      };
    case propertyConstants.CHANGE_VIEW_MODE:
      return {
        ...state,
        currentVisualizationMode: action.currentVisualizationMode
      };
    case paymentConstants.UPDATE_CONTRACT_BILLING_REQUEST:
      return {
        ...state,
        billingChangeInProgress: true
      };
    case paymentConstants.UPDATE_CONTRACT_BILLING_ERROR:
      return {
        ...state,
        billingChangeInProgress: false
      };
    case paymentConstants.UPDATE_CONTRACT_BILLING_SUCCESS:
      // let newContracts = state.selectedProperty.contracts.map(contract => {
      //   if (action.contract.id === contract.id) {
      //     return Object.assign({}, action.contract, { valid: true });
      //   } else {
      //     return contract;
      //   }
      // });
      let newSelected = {
        ...state.selectedProperty,
        active_contract: action.contract
      };

      return {
        ...state,
        selectedProperty: newSelected,
        billingChangeInProgress: false
      };
    case billingConstants.SHOW_PROPERTY_VIEW_DIALOG:
      return {
        ...state,
        propertyViewModalOpen: true
      };
    case listingConstants.SHOW_LISTING_CONFIRMATION_DIALOG:
      return {
        ...state,
        listingConfirmationDialogOpen: true,
        currentListing: action.body.listing
      };
    case listingConstants.HIDE_LISTING_CONFIRMATION_DIALOG:
      return {
        ...state,
        listingConfirmationDialogOpen: false,
        currentListing: void 0
      };
    case listingConstants.SHOW_LISTING_EXCLUSION_CONFIRMATION_DIALOG:
      return {
        ...state,
        listingExclusionConfirmationDialogOpen: true,
        selectedListing: { id: action.body.listingId }
      };
    case billingConstants.PROPERTY_APPROVAL_DIALOG_SUCCESS:
      return {
        ...state,
        propertyToApproveOpen: true,
        selectedProperty: action.property
      };
    case billingConstants.PROPERTY_PAYMENT_APPROVAL_DIALOG_SUCCESS:
      return {
        ...state,
        propertyPaymentToApproveOpen: true,
        selectedProperty: action.property
      };
    case paymentConstants.GET_BILLING_SUMMARY_REQUEST:
      return { ...state, currentDateSummary: action.currentDateSummary };
    case paymentConstants.GET_SUMMARY_FAILURE:
      return { ...state };
    case paymentConstants.GET_SUMMARY_SUCCESS:
      return { ...state, summaryData: action.summaryData };
    case propertyConstants.ANALYTICS_GRANULARITY_CHANGE:
      let analyticsGranularity = action.analyticsGranularity;
      return {
        ...state,
        analyticsGranularity,
        analyticsTableData: calculateAnalytics(
          state.properties,
          analyticsGranularity.granularity
        )
      };
    case propertyConstants.FILTER_RENTED_PROPERTIES:
      let filter = action.filters;
      removeEmpty(filter);
      if (Object.keys(filter).length) {
        let filtered = state.rentedProperties.data.filter(item => {
          let result = false;
          for (var key in filter) {
            let obj = findObjectByKeyVal(item, key, filter[key], false);
            result = obj !== null;
          }
          return result;
        });
        return {
          ...state,
          filteredProperties: { ...state.rentedProperties, data: filtered },
          filteringProperties: true
        };
      } else {
        return {
          ...state,
          filteredProperties: { ...state.filteredProperties, data: [] },
          filteringProperties: false
        };
      }
    case propertyConstants.FILTER_PROPERTY_BY_TEXT:
      return {
        ...state,
        filteringProperties: true,
        loadingProperties: true
      };
    default:
      return state;
  }
}
var findObjectByKeyVal = function(obj, key, val, exactMatch = false) {
  if (!obj || typeof obj === "string") {
    return null;
  }
  if (exactMatch) {
    if (obj[key] === val) {
      return obj;
    }
  } else {
    if (obj[key] && !!~obj[key].indexOf(val)) {
      return obj;
    }
  }

  for (var i in obj) {
    if (obj.hasOwnProperty(i)) {
      var found = findObjectByKeyVal(obj[i], key, val);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

function sortByDateAsc(lhs, rhs) {
  return lhs.date > rhs.date ? 1 : lhs.date < rhs.date ? -1 : 0;
}
const removeEmpty = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
    else if (obj[key] == null || obj[key] === "") delete obj[key];
  });
};

function getDataFromEventArray(eventArray, groupReducer) {
  let _temp = [];
  _temp = Object.values(Object.assign({}, eventArray.reduce(groupReducer, {})));
  _temp = _temp.slice(Math.max(_temp.length - 5, 0));
  return Object.values(
    Object.assign(
      {},
      _temp.map(e => {
        return { x: e.date.format(timeFormat), y: e.count };
      })
    )
  );
}
var timeFormat = "MM/DD/YYYY HH:mm";

function calculateAnalytics(properties, granularity) {
  granularity = granularity ? granularity : "week";
  let analyticsTableData = [];
  let viewEvents = [];
  let contactEvents = [];
  let listingEvents = [];
  let visitScheduledEvents = [];
  let visitCompletedEvents = [];
  let labels = [];
  let previousView = 0;
  let currentView = 0;

  let currentListings = 0;
  let previousListings = 0;
  let dataListings = [];

  let dataView = [];
  let dataContact = [];
  let dataVisitScheduled = [];
  let dataVisitCompleted = [];

  let previousContact = 0;
  let currentContact = 0;

  let currentVisitCompleted = 0;
  let previousVisitCompleted = 0;

  let currentVisitScheduled = 0;
  let previousVisitScheduled = 0;

  let maxWeek = -1;
  let maxYear = -1;
  let weekDayReport = 4;

  const getTimeMetric = entry => {
    if (entry.week_of_year > maxWeek) {
      maxWeek = entry.week_of_year;
    }
    if (entry.year > maxYear) {
      maxYear = entry.year;
    }
    entry.date = moment()
      .weekday(weekDayReport)
      .year(entry.year)
      .week(entry.week_of_year)
      .startOf("day");
    return entry;
  };

  const flatAndSortByDate = array => {
    return Object.values([].concat.apply([], array)).sort(sortByDateAsc);
  };

  properties &&
    properties.forEach(property => {
      const { listing } = property;
      if (listing) {
        const {
          visit_completed,
          visit_scheduled,
          contact,
          view,
          listings_number
        } = listing.events;
        //Acumulators
        if (view) {
          viewEvents = [...viewEvents, view.map(getTimeMetric)];
        }
        if (listings_number) {
          listingEvents = [
            ...listingEvents,
            listings_number.map(getTimeMetric)
          ];
        }
        if (contact) {
          contactEvents = [...contactEvents, contact.map(getTimeMetric)];
        }
        if (visit_scheduled) {
          visitScheduledEvents = [
            ...visitScheduledEvents,
            visit_scheduled.map(getTimeMetric)
          ];
        }
        if (visit_completed) {
          visitCompletedEvents = [
            ...visitCompletedEvents,
            visit_completed.map(getTimeMetric)
          ];
        }
      }
    });
  //Flat arrays, sort values by date, get value objects
  viewEvents = flatAndSortByDate(viewEvents);
  listingEvents = flatAndSortByDate(listingEvents);
  contactEvents = flatAndSortByDate(contactEvents);
  visitScheduledEvents = flatAndSortByDate(visitScheduledEvents);
  visitCompletedEvents = flatAndSortByDate(visitCompletedEvents);

  switch (granularity) {
    case "week":
      currentContact = calculateWeekAmount(contactEvents, 0);
      previousContact = calculateWeekAmount(contactEvents, -1);
      dataContact = getDataFromEventArray(contactEvents, groupEventsByWeek);

      currentListings = calculateWeekAmount(listingEvents, 0);
      previousListings = calculateWeekAmount(listingEvents, -1);
      dataListings = getDataFromEventArray(listingEvents, groupEventsByWeek);

      currentVisitCompleted = calculateWeekAmount(visitCompletedEvents, 0);
      previousVisitCompleted = calculateWeekAmount(visitCompletedEvents, -1);
      dataVisitCompleted = getDataFromEventArray(
        visitCompletedEvents,
        groupEventsByWeek
      );

      currentVisitScheduled = calculateWeekAmount(visitScheduledEvents, 0);
      previousVisitScheduled = calculateWeekAmount(visitScheduledEvents, -1);
      dataVisitScheduled = getDataFromEventArray(
        visitScheduledEvents,
        groupEventsByWeek
      );

      currentView = calculateWeekAmount(viewEvents, 0);
      previousView = calculateWeekAmount(viewEvents, -1);
      dataView = getDataFromEventArray(viewEvents, groupEventsByWeek);

      break;
    case "month":
      currentView = calculateMonthAmount(viewEvents, 0);
      previousView = calculateMonthAmount(viewEvents, -1);
      dataView = getDataFromEventArray(viewEvents, groupEventsByMonth);

      currentListings = calculateMonthAmount(listingEvents, 0);
      previousListings = calculateMonthAmount(listingEvents, -1);
      dataListings = getDataFromEventArray(listingEvents, groupEventsByMonth);

      currentContact = calculateMonthAmount(contactEvents, 0);
      previousContact = calculateMonthAmount(contactEvents, -1);
      dataContact = getDataFromEventArray(contactEvents, groupEventsByMonth);

      currentVisitCompleted = calculateMonthAmount(visitCompletedEvents, 0);
      previousVisitCompleted = calculateMonthAmount(visitCompletedEvents, -1);
      dataVisitCompleted = getDataFromEventArray(
        visitCompletedEvents,
        groupEventsByMonth
      );
      currentVisitScheduled = calculateMonthAmount(visitScheduledEvents, 0);
      previousVisitScheduled = calculateMonthAmount(visitScheduledEvents, -1);
      dataVisitScheduled = getDataFromEventArray(
        visitScheduledEvents,
        groupEventsByMonth
      );

      break;
    default:
      break;
  }

  analyticsTableData = {
    lastUpdate: moment()
      .weekday(4)
      .year(maxYear < 0 ? moment().year() : maxYear)
      .week(maxWeek)
      .startOf("day"),
    data: [
      {
        label: "Anúncios",
        metricName: "listings_number",
        previous: previousListings,
        current: currentListings,
        labels: labels,
        values: dataListings,
        granularity: granularity
      },
      {
        label: "Visualizações",
        metricName: "view",
        previous: previousView,
        current: currentView,
        labels: labels,
        values: dataView,
        granularity: granularity
      },
      {
        label: "Contatos",
        metricName: "contact",
        previous: previousContact,
        current: currentContact,
        labels: labels,
        values: dataContact,
        granularity: granularity
      },
      {
        label: "Visitas Agendadas",
        metricName: "visit_scheduled",
        previous: previousVisitScheduled,
        current: currentVisitScheduled,
        labels: labels,
        values: dataVisitScheduled,
        granularity: granularity
      },
      {
        label: "Visitas Realizadas",
        metricName: "visit_completed",
        previous: previousVisitCompleted,
        current: currentVisitCompleted,
        labels: labels,
        values: dataVisitCompleted,
        granularity: granularity
      }
    ]
  };
  return analyticsTableData;
}

const groupEventsByWeek = (r, e) => {
  var key = e.year + "|" + e.week_of_year;
  if (!r[key]) {
    r[key] = Object.assign({}, e);
  } else {
    r[key].count += e.count;
  }
  return r;
};
const groupEventsByMonth = (r, e) => {
  var key =
    moment()
      .year(e.year)
      .week(e.week_of_year)
      .startOf("day")
      .month() +
    "|" +
    e.year;
  if (!r[key]) {
    r[key] = Object.assign({}, e);
  } else {
    r[key].count += e.count;
  }
  return r;
};

function calculateWeekAmount(eventArray, offset) {
  let current = 0;
  eventArray.forEach(v =>
    v.date.week() === moment().week() + offset ? (current += v.count) : 0
  );
  return current;
}
function calculateMonthAmount(eventArray, offset) {
  let current = 0;
  eventArray.forEach(v =>
    v.date.month() === moment().month() + offset ? (current += v.count) : 0
  );
  return current;
}
