import { propertyConstants } from '../_constants/propertyConstants';
import { renderAddressFormatter, removeItem } from '../_helpers/utils';
import { billingConstants } from '../_constants/billingConstants';
import { paymentConstants } from '../_constants/paymentConstants';

require('string_score');
export default function payments(state = {}, action) {
  switch (action.type) {
    case paymentConstants.GETALL_PAYMENT_SUCCESS:
      let enhancedPayments2 = [];
      action.payments.forEach(payment => {
        let enhancedPayment = payment;
        enhancedPayment._property_address = renderAddressFormatter(
          payment.contract.property && payment.contract.property.address
        );
        enhancedPayments2.push(enhancedPayment);
      });
      return {
        loading: false,
        items: Object.values([].concat.apply([], enhancedPayments2))
      };
    case paymentConstants.GETALL_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case billingConstants.SHOW_NEW_BOLETO_DIALOG:
      let paymentToGenerateBoleto = state.items.find(x => {
        return x.id === action.body.paymentId;
      });
      return {
        ...state,
        boletoModalOpen: true,
        selectedPayment: paymentToGenerateBoleto
      };
    case paymentConstants.DELETE_TRANSACTION_SUCCESS:
      let currentPaymentId = action.body.paymentId;
      let currentTransactionId = action.body.transactionId;
      let newPaymentsArray = state.items;
      let indexOfPayment = newPaymentsArray
        .map(p => p.id)
        .indexOf(currentPaymentId);
      let indexOfTransaction = newPaymentsArray[indexOfPayment].transactions
        .map(t => t.id)
        .indexOf(currentTransactionId);
      let newTransactionArray = removeItem(
        newPaymentsArray[indexOfPayment].transactions,
        indexOfTransaction
      );
      newPaymentsArray[indexOfPayment].transactions = newTransactionArray;
      return { ...state, transactionLoading: false, items: newPaymentsArray };
    case propertyConstants.HIDE_MODALS:
      return {
        ...state,
        boletoModalOpen: false,
        markAsPaidModalOpen: false,
        propertyViewModalOpen: false,
        propertyToApproveOpen: false,
        propertyPaymentToApproveOpen: false
      };
    case paymentConstants.UPDATE_PAYMENT_REQUEST:
      return state;
    case paymentConstants.UPDATE_PAYMENT_SUCCESS:
      let indexPayment = state.items.map(x => x.id).indexOf(action.payment.id);
      let newPayments = [
        ...state.items.slice(0, indexPayment),
        action.payment,
        ...state.items.slice(indexPayment + 1)
      ];
      return {
        ...state,
        items: newPayments,
        selectedPayment: null
      };
    case paymentConstants.UPDATE_PAYMENT_ERROR:
      return state;
    case billingConstants.SHOW_MARK_AS_PAID_DIALOG:
      let paymentToMarkAsPaid = state.items.find(x => {
        return x.id === action.body.paymentId;
      });
      return {
        ...state,
        markAsPaidModalOpen: true,
        selectedPayment: paymentToMarkAsPaid
      };
    default:
      return state;
  }
}
