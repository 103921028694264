import React from 'react';
import Switch from 'react-switch';

class SwitchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: props.checked ? props.checked : false };
  }

  handleChange = checked => {
    this.setState({ checked: checked });
    this.props.onSwitchChange(checked, this.props.id);
  };

  render() {
    const { id } = this.props;
    return (
      <Switch
        onChange={this.handleChange}
        height={22}
        checked={this.state.checked}
        className="react-switch"
        id={'switch-' + id}
        onColor="#2DF256"
        {...this.props}
      />
    );
  }
}
export default SwitchBox;
