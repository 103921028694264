import React from "react";
import { connect } from "react-redux";
import { Button, Col, ControlLabel, FormGroup, Row } from "react-bootstrap";
import {
  arrayRemove,
  change,
  Field,
  FieldArray,
  getFormValues
} from "redux-form";
import { SelectField, TextField } from "../../FormFields";
import { formatReais } from "../../../_helpers/reduxFormFormatters";
import { normalizeNumericValue } from "../../../_helpers/reduxFormNormalizers";
import {
  months,
  monthDays,
  rentalGuaranteeTypes
} from "../../../_helpers/optionsArrays";
import { calculateTotalRentPriceInCents } from "../../PropertyListingForm/PanelContent/business/propertyCalculations";

class ContractData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otherExpenses: []
    };
    props.changeFormField(props.form, "active_contract.is_active", true);
  }

  componentDidMount() {
    this.handleRentalGuaranteeChange(this.props.rental_guarantee_type);
  }

  setMonetaryAmountInLocalState(changedKey, changedValue) {
    const intValue = parseInt(changedValue.replace(/[^\d]/g, ""), 10);

    this.setState(
      {
        [changedKey]: Number.isInteger(intValue) ? intValue : 0
      },
      () => this.calculateMonthlyTotal()
    );
  }

  calculateTotalRecurringExpenses() {
    return this.state.otherExpenses.reduce((prevVal, expense) => {
      return prevVal + expense.value;
    }, 0);
  }

  calculateMonthlyTotal() {
    const monthyTotal =
      this.state.rentalPriceInCents +
      this.state.condoFeeInCents +
      this.state.iptuPriceInCents +
      (this.calculateTotalRecurringExpenses() || 0);

    this.props.changeFormField(
      this.props.form,
      "active_contract.total_price_in_cents",
      monthyTotal
    );
  }

  removeExpense = index => {
    this.props.formArrayRemove(
      this.props.form,
      "active_contract.expenses",
      index
    );
  };

  renderExpenses = ({ fields, meta: { error } }) => (
    <section>
      <FormGroup className="contract-panel-expenses">
        <ControlLabel className={"label__grey"}>
          Outras despesas recorrentes
          {/* <small>
        (Insira quaisquer outras despesas como reparos, taxas de limpeza e
        etc.)
      </small> */}
        </ControlLabel>
        <Button
          bsStyle="secondary"
          onClick={() => {
            fields.push({});
            this.setState({
              otherExpenses: [
                ...this.state.otherExpenses,
                { value: 0, is_recurring: false }
              ]
            });
          }}
        >
          Adicionar despesa recorrente
        </Button>
      </FormGroup>
      <FormGroup className="contract-panel-expenses">
        {fields.map((expense, index) => (
          <Row key={index}>
            <Col md={3}>
              <Field
                component={TextField}
                controlClassName="form-control__white"
                name={`active_contract.expenses[${index}].name`}
                placeholder="Descrição"
                showLabel={false}
              />
            </Col>
            <Col md={3}>
              <Field
                component={TextField}
                controlClassName="form-control__white"
                name={`active_contract.expenses[${index}].amount_in_cents`}
                normalize={normalizeNumericValue}
                format={formatReais}
                placeholder="Valor"
                showLabel={false}
                onChange={event => {
                  let otherExpenses = this.state.otherExpenses;
                  otherExpenses[index].value = parseInt(
                    event.target.value.replace(/[^\d]/g, ""),
                    10
                  );
                  this.setState({ otherExpenses }, () =>
                    this.calculateMonthlyTotal()
                  );
                }}
              />
            </Col>
            <Col md={3}>
              <Button
                bsStyle="secondary"
                onClick={() => {
                  fields.remove(index);
                  // this.removeExpense(index);
                  let otherExpenses = this.state.otherExpenses;
                  otherExpenses.splice(index, 1);
                  this.setState({ otherExpenses }, () =>
                    this.calculateMonthlyTotal()
                  );
                }}
              >
                Remover despesa
              </Button>
            </Col>
          </Row>
        ))}
      </FormGroup>
    </section>
  );

  handleIptuRadioChange = e => {
    if (e.target.value === "true") {
      this.props.changeFormField(
        this.props.form,
        "active_contract.iptu_start_month",
        null
      );
      this.props.changeFormField(
        this.props.form,
        "active_contract.iptu_end_month",
        null
      );
    }
  };

  handleRentalGuaranteeChange = value => {
    this.props.changeFormField(
      this.props.form,
      "active_contract.rental_guarantee_type",
      value
    );
    switch (value) {
      case "deposit":
        this.setState({
          showDepositAmountField: true,
          showInsuranceFields: false,
          showGuaranteeDescriptionField: false
        });
        break;
      case "other":
        this.setState({
          showDepositAmountField: false,
          showInsuranceFields: false,
          showGuaranteeDescriptionField: true
        });
        break;
      case "bond_insurance":
        this.setState({
          showDepositAmountField: false,
          showInsuranceFields: true,
          showGuaranteeDescriptionField: true
        });
        break;
      case "guarantor":
        this.setState({
          showDepositAmountField: false,
          showInsuranceFields: false,
          showGuaranteeDescriptionField: true
        });
        break;
      default:
        this.setState({
          showInsuranceFields: false,
          showDepositAmountField: false,
          showGuaranteeDescriptionField: false
        });
        break;
    }
  };

  renderBonusPaymentOnTime = () => {
    return (
      <FormGroup>
        <ControlLabel className="label__grey">
          Bonificação por pagamento em dia
        </ControlLabel>
        <Row>
          <Col md={12}>
            <Col md={6}>
              <label className="radio-option">
                <Field
                  component="input"
                  name="active_contract.has_bonus_for_payment_on_time"
                  type="radio"
                  value="true"
                  // onChange={e => this.handleBonusRadioChange(e.target.value)}
                />
                Sim
              </label>
            </Col>
            <Col md={6}>
              <label className="radio-option">
                <Field
                  component="input"
                  name="active_contract.has_bonus_for_payment_on_time"
                  type="radio"
                  value="false"
                  // onChange={e => this.handleBonusRadioChange(e.target.value)}
                />
                Não
              </label>
            </Col>{" "}
          </Col>
        </Row>
      </FormGroup>
    );
  };
  renderYesNoOption = (label, name) => {
    return (
      <FormGroup>
        <ControlLabel className="label__grey">{label}</ControlLabel>
        <div>
          <label className="radio-option">
            <Field component="input" name={name} type="radio" value="true" />
            Sim
          </label>
          <label className="radio-option">
            <Field component="input" name={name} type="radio" value="false" />
            Não
          </label>
        </div>
      </FormGroup>
    );
  };
  renderSplitIPTUIn12Months = () => {
    return (
      <FormGroup>
        <ControlLabel className="label__grey">Cobrar iptu em 12X?</ControlLabel>
        <Row>
          <Col md={12} className="radio-group">
            <div>
              <label className="radio-option">
                <Field
                  component="input"
                  name="active_contract.iptu_split_in_12_months"
                  type="radio"
                  value="true"
                  onChange={this.handleIptuRadioChange}
                />
                Sim
              </label>
            </div>
            <div>
              <label className="radio-option">
                <Field
                  component="input"
                  name="active_contract.iptu_split_in_12_months"
                  type="radio"
                  value="false"
                  onChange={this.handleIptuRadioChange}
                />
                Não
              </label>
            </div>
          </Col>
        </Row>
      </FormGroup>
    );
  };

  render() {
    const iptu_split_in_12_months =
      this.props.values &&
      this.props.values.active_contract &&
      this.props.values.active_contract.iptu_split_in_12_months;

    const has_bonus_for_payment_on_time =
      this.props.values &&
      this.props.values.active_contract &&
      this.props.values.active_contract.has_bonus_for_payment_on_time;
    return (
      <div>
        <Row>
          <Col lg={4} sm={6}>
            <Field
              id="start_date"
              type={"date"}
              component={TextField}
              controlClassName="form-control__white"
              label="Início do Contrato"
              labelClassName="label__grey"
              name="active_contract.start_date"
              placeholder="dd/mm/aaaa"
              // format={formatDate}
            />
          </Col>
          <Col lg={4} sm={6}>
            <Field
              id="duration_in_months"
              component={TextField}
              controlClassName="form-control__white"
              label="Duração do Contrato (meses)"
              labelClassName="label__grey"
              name="active_contract.duration_in_months"
            />
          </Col>
          <Col lg={4} sm={6}>
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              label="Dia de Vencimento Mensal"
              labelClassName="label__grey"
              name="active_contract.due_date_day"
              options={monthDays()}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              label="Garantia de Aluguel"
              labelClassName="label__grey"
              name="active_contract.rental_guarantee_type"
              onChange={e => this.handleRentalGuaranteeChange(e.target.value)}
              options={rentalGuaranteeTypes}
            />
          </Col>
          {this.state.showDepositAmountField && (
            <Col sm={4}>
              <Field
                id="deposit_amount_in_cents"
                component={TextField}
                controlClassName="form-control__white"
                label="Valor da Caução"
                labelClassName="label__grey"
                name="active_contract.deposit_amount_in_cents"
                normalize={normalizeNumericValue}
                format={formatReais}
              />
            </Col>
          )}
          {this.state.showGuaranteeDescriptionField && (
            <Col sm={8}>
              <Field
                id="rental_guarantee_description"
                component={TextField}
                controlClassName="form-control__white"
                label="Descrição da Garantia"
                labelClassName="label__grey"
                name="active_contract.rental_guarantee_description"
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={12}>
            <h4 className="title" style={{ textTransform: "uppercase" }}>
              {" "}
              Valores
            </h4>
            <hr />
          </Col>
        </Row>
        {/* <Row>
          <Col lg={9} sm={12}>
            <FileUploadField
              id="inspection_file_url"
              component={TextField}
              controlClassName="form-control__white file_upload_input"
              label="Laudo de Vistoria"
              labelClassName="label__grey"
              name="active_contract.inspection_file_url"
              type="text"
              onFileUploadFieldChange={this.props.onFileUploadFieldChange}
            />
          </Col>
        </Row> */}
        <Row>
          <Col md={3}>
            <Field
              id="rental_price_in_cents"
              component={TextField}
              controlClassName="form-control__white"
              label="Aluguel"
              labelClassName="label__grey"
              name="active_contract.rental_price_in_cents"
              normalize={normalizeNumericValue}
              format={formatReais}
              onChange={e => {
                this.setMonetaryAmountInLocalState(
                  "rentalPriceInCents",
                  e.target.value
                );
              }}
            />
          </Col>
          <Col md={3}>
            <Field
              id="condo_fee_in_cents"
              component={TextField}
              controlClassName="form-control__white"
              label="Condomínio"
              labelClassName="label__grey"
              name="active_contract.condo_fee_in_cents"
              normalize={normalizeNumericValue}
              format={formatReais}
              onChange={e =>
                this.setMonetaryAmountInLocalState(
                  "condoFeeInCents",
                  e.target.value
                )
              }
            />
          </Col>
          <Col md={3}>{this.renderSplitIPTUIn12Months()}</Col>
          <Col md={3}>
            <Field
              id="iptu_price_in_cents"
              component={TextField}
              controlClassName="form-control__white"
              label={
                <span>
                  IPTU (<small>Mensal</small>)
                </span>
              }
              labelClassName="label__grey"
              name="active_contract.iptu_price_in_cents"
              normalize={normalizeNumericValue}
              format={formatReais}
              onChange={e =>
                this.setMonetaryAmountInLocalState(
                  "iptuPriceInCents",
                  e.target.value
                )
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Field
              component={SelectField}
              options={months}
              controlClassName="form-control__white"
              disabled={iptu_split_in_12_months === "true"}
              label={
                <span>
                  IPTU (<small>Mês Inicial</small>)
                </span>
              }
              labelClassName="label__grey"
              name="active_contract.iptu_start_month"
            />
          </Col>
          <Col sm={4}>
            <Field
              component={SelectField}
              controlClassName="form-control__white"
              disabled={iptu_split_in_12_months === "true"}
              options={months}
              label={
                <span>
                  IPTU (<small>Mês Final</small>)
                </span>
              }
              labelClassName="label__grey"
              name="active_contract.iptu_end_month"
            />
          </Col>
        </Row>
        <Row>
          {/* <Col sm={4}>
            <Field
              component={TextField}
              controlClassName="form-control__white"
              label="Detalhes da garantia"
              labelClassName="label__grey"
              name="active_contract.rental_guarantee_description"
            />
          </Col> */}
          {/* <Col sm={4}>
            <Field
              id="notes"
              component="textarea"
              rows={2}
              className="form-control contract-notes"
              name="active_contract.notes"
              placeholder="Escreva aqui outras particularidades do contrato de aluguel que não foram pedidas em itens anteriores."
              type="textarea"
            />
          </Col> */}
        </Row>

        <Row>
          <Col md={12}>
            <FieldArray
              name="active_contract.expenses"
              component={this.renderExpenses}
            />
          </Col>
          <Col md={4}>{this.renderBonusPaymentOnTime()}</Col>
          <Col md={4}>
            <Field
              id="bonus_payment_on_time_in_cents"
              component={TextField}
              controlClassName="form-control__white"
              label={"Valor Bonificação"}
              labelClassName="label__grey"
              name="active_contract.bonus_for_payment_on_time_in_cents"
              normalize={normalizeNumericValue}
              format={formatReais}
              disabled={has_bonus_for_payment_on_time === "false"}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col sm={3}>
            <Field
              id="penalty_percentage"
              component={TextField}
              controlClassName="form-control__white"
              label="Multa (%)"
              tooltiplabel={' A multa deve ter um valor máximo de 10%'}
              labelClassName="label__grey"
              name="active_contract.penalty_percentage"
              normalize={normalizeFloatValue}
            />
          </Col>
          <Col sm={3}>
            <Field
              id="interest_percentage"
              component={TextField}
              controlClassName="form-control__white"
              label="Juros Mensal (%)"
              tooltiplabel={' O juros mensal deve ter um valor máximo de 1%'}
              labelClassName="label__grey"
              name="active_contract.interest_percentage"
              normalize={normalizeFloatValue}
            />
          </Col>
         
        </Row> */}
        <Row>
          <Col lg={3} sm={6}>
            <ControlLabel
              className="label__grey"
              style={{ marginBottom: "2rem", fontSize: "2rem" }}
            >
              Valor Total:{" "}
              <span className="bluevalues">
                {formatReais(
                  (this.props.values &&
                    this.props.values.active_contract &&
                    this.props.values.active_contract.total_price_in_cents) ||
                    0
                )}
              </span>
            </ControlLabel>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let values = getFormValues(props.form)(state);
  if (values.active_contract) {
    values.active_contract.total_price_in_cents = calculateTotalRentPriceInCents(
      values.active_contract.expenses,
      values.active_contract.rental_price_in_cents,
      values.active_contract.condo_fee_in_cents,
      values.active_contract.iptu_price_in_cents
    );
  }
  return {
    values,
    initialValues: state.properties.selectedProperty // pull initial values from account reducer
  };
};
const mapDispatchToProps = dispatch => ({
  changeFormField: (formName, attribute, value) =>
    dispatch(change(formName, attribute, value)),
  formArrayRemove: (formName, attribute, index) =>
    dispatch(arrayRemove(formName, attribute, index))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractData);
