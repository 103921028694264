import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Grid, Image, Panel, Row } from 'react-bootstrap';
import { TextField } from '../../_components/FormFields';
import { Field, reduxForm } from 'redux-form';
import { userActions } from '../../_actions/userActions';
import qs from 'stringquery';
import { history } from '../../_helpers/history';

const validate = values => {
  const errors = {};

  const requiredMsg = 'Campo obrigatório';

  if (!values.password) {
    errors.password = requiredMsg;
  } else if (values.password.length < 6) {
    errors.password = 'A senha deve ter pelo menos 6 caracteres.';
  }

  if (!values.password_confirmation) {
    errors.password_confirmation = requiredMsg;
  }

  if (values.password !== values.password_confirmation) {
    errors.password_confirmation =
      'A senha e a confirmação de senha estão diferentes.';
  }

  return errors;
};

export class ChangePasswordPage extends React.Component {
  componentDidMount() {
    document.body.classList.add('guest-user');
  }

  submit = values => {
    const { dispatch } = this.props;
    const queries = qs(this.props.location.search);

    dispatch(
      userActions.changePassword(queries.email, values.password, queries.token)
    );
  };

  renderBody(handleSubmit) {
    if (!this.props.isPasswordChanged) {
      return (
        <form className="form" onSubmit={handleSubmit(this.submit)}>
          <Row className="padding-top-30">
            <Col xs={12} className="no-padding">
              <p>Por favor digite e confirme sua nova senha</p>
            </Col>
          </Row>
          <Row className="padding-top-25">
            <Col>
              <Field
                id="password"
                component={TextField}
                controlClassName="form-control__white"
                label="Senha"
                labelClassName="label__grey"
                name="password"
                type="password"
              />
            </Col>
            <Col>
              <Field
                id="password_confirmation"
                component={TextField}
                controlClassName="form-control__white"
                label="Confirme sua senha"
                labelClassName="label__grey"
                name="password_confirmation"
                type="password"
              />
            </Col>
          </Row>

          <Row>
            <Col className="text-center">
              <Button bsStyle="primary" className="btn-primary__login" type="submit">
                {this.props.loading ? 'VERIFICANDO...' : 'ALTERAR'}
              </Button>
            </Col>
          </Row>
        </form>
      );
    } else {
      return (
        <div>
          <Row className="padding-top-60">
            <Col className="text-center">
              <Image src="/images/change_password_success.png" />
            </Col>
          </Row>
          <Row className="padding-top-25">
            <Col>
              <p>Senha alterada com sucesso.</p>
            </Col>
          </Row>
          <Row className="padding-top-30">
            <Col className="text-center">
              <Button
                bsStyle="primary"
                className="btn-primary__login"
                onClick={() => history.push('/dashboard')}
              >
                ACESSAR
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Grid className="login-area">
        <Row>
          <Col sm={6} smOffset={3} xs={12}>
            <Panel>
              <Panel.Body className="panel-body__login">
                <Row>
                  <Col sm={8} smOffset={2} xs={10} xsOffset={1}>
                    <Row>
                      <Col className="text-center">
                        <Image src="/images/logo.png" alt="logo" />
                      </Col>
                      {this.renderBody(handleSubmit)}
                    </Row>
                  </Col>
                </Row>
              </Panel.Body>
            </Panel>
          </Col>
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.authentication.loading,
    isPasswordChanged: state.authentication.isPasswordChanged
  };
}

const form = reduxForm({
  form: 'ChangePassordForm',
  validate
})(ChangePasswordPage);

export default connect(mapStateToProps)(form);
