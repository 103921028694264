import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Col, Row, Button } from "react-bootstrap";
import { TextAreaField } from "../../../_components/FormFields";
import { Field } from "redux-form";
import validate from "./Validations";
import {
  formatCEP,
  formatCPF,
  formatPhone
} from "../../../_helpers/reduxFormFormatters";
import {
  toCurrenry,
  getGuaranteeTypeLabel,
  receivingPaymentMethodFormatter,
  receivingContractStatusFormatter,
  getActiveContract
} from "../../../_helpers/utils";
import { rentalTypes, propertyTypes } from "../../../_helpers/optionsArrays";
import { SectionTitle } from "../../../_components/FormSectionTitle";
import RenderRow from "../../../_components/RenderRow";
const notInformedLabel = "Não informado";
const formName = "ApprovalPaymentMethodForm";
class ApprovalPaymentMethodForm extends React.Component {
  renderRentType = rental_type => {
    let rentType = rentalTypes.find(r => r.value === rental_type);
    if (rentType) {
      return rentType.label;
    } else {
      return notInformedLabel;
    }
  };

  renderPropertyType = property_type => {
    let propertyType = propertyTypes.find(r => r.value === property_type);
    if (propertyType) {
      return propertyType.label;
    } else {
      return notInformedLabel;
    }
  };

  renderRentalGuarantees = (amount_deposit, rental_guarantee) => {
    return (
      rental_guarantee &&
      rental_guarantee.length &&
      rental_guarantee.map(g => {
        return g === "deposit" ? (
          <p>
            {getGuaranteeTypeLabel(g) +
              " - " +
              toCurrenry(amount_deposit / 100)}
          </p>
        ) : (
          <p>{getGuaranteeTypeLabel(g)}</p>
        );
      })
    );
  };
  render() {
    const { bank_account, active_contract } = this.props.property;

    const { handleSubmit, property, user } = this.props;

    return (
      <Row>
        <form className="form" onSubmit={handleSubmit}>
          <Col md={12}>
            <Col md={6}>
              <SectionTitle label="Dados Usuário" />
              <RenderRow label="Usuário" value={user.full_name} />
              <RenderRow label="Email" value={user.email} />
              <RenderRow label="Contato" value={user.phone} />
              <RenderRow label="Empresa" value={user.company_name} />
            </Col>
            <Col md={6}>
              <SectionTitle label="Dados Proprietário" />
              <RenderRow
                label="Nome"
                value={property.landlord && property.landlord.full_name}
              />
              <RenderRow
                label="CPF"
                value={property.landlord && formatCPF(property.landlord.cpf)}
              />
              <RenderRow
                label="Telefone"
                value={
                  property.landlord && formatPhone(property.landlord.phone)
                }
              />
              <RenderRow
                label="Email"
                value={property.landlord && property.landlord.email}
              />
            </Col>
            <Col md={6}>
              <SectionTitle label="Dados Imóvel" />
              <RenderRow
                label="Tipo Imóvel"
                value={this.renderPropertyType(property.property_type)}
              />
              <RenderRow
                label="Tipo Aluguel"
                value={this.renderRentType(property.rental_type)}
              />
              <RenderRow
                label="CEP"
                value={
                  property.address && formatCEP(property.address.postal_code)
                }
              />
              <RenderRow
                label="Rua"
                value={property.address && property.address.street}
              />
              <RenderRow
                label="Número"
                value={property.address && property.address.number}
              />
              <RenderRow
                label="Bairro"
                value={property.address && property.address.neighbourhood}
              />
              <RenderRow
                label="Cidade"
                value={property.address && property.address.city}
              />
              <RenderRow
                label="Estado"
                value={property.address && property.address.state}
              />
            </Col>
            <Col md={6}>
              <SectionTitle label="Contrato e Valores" />
              <RenderRow
                label="Dia Vencimento"
                value={active_contract.due_date_day}
              />
              <RenderRow
                label="Valor aluguel"
                value={toCurrenry(
                  active_contract.rental_price_in_cents / 100 || 0
                )}
              />
              <RenderRow
                label="Condomínio"
                value={toCurrenry(
                  active_contract.condo_fee_in_cents / 100 || 0
                )}
              />
              <RenderRow
                label="IPTU"
                value={toCurrenry(
                  active_contract.iptu_price_in_cents / 100 || 0
                )}
              />
              <RenderRow
                label="IPTU em 12x"
                value={active_contract.iptu_split_in_12_months ? "Sim" : "Não"}
              />
              {!active_contract.iptu_split_in_12_months && (
                <div>
                  {" "}
                  <RenderRow
                    label="Mês Inicial"
                    value={active_contract.iptu_start_month}
                  />
                  <RenderRow
                    label="Mês Final"
                    value={active_contract.iptu_end_month}
                  />
                </div>
              )}
              <RenderRow
                label="Outras Desp."
                value={
                  active_contract.expenses &&
                  toCurrenry(
                    active_contract.expenses
                      .map(e => parseInt(e.amount_in_cents))
                      .reduce((acc, item) => acc + item, 0) / 100
                  )
                }
              />
              <RenderRow
                label="Total"
                value={toCurrenry(
                  active_contract.total_price_in_cents / 100 || 0
                )}
              />
              <RenderRow
                label="Cobrança"
                value={receivingPaymentMethodFormatter(
                  property.payment_method,
                  property
                )}
              />
              <RenderRow
                label="Status Cobrança"
                value={receivingContractStatusFormatter(
                  property.active_contract.payment_method_status,
                  property
                )}
              />
              <RenderRow
                label="Garantias"
                value={this.renderRentalGuarantees(
                  active_contract.deposit_amount_in_cents,
                  active_contract.rental_guarantee
                )}
              />
              <RenderRow
                label="Observação"
                value={
                  active_contract.guarantee_description || notInformedLabel
                }
              />
            </Col>
            <Col md={6}>
              <SectionTitle label="Recebimento" />
              <RenderRow
                label="Favorecido"
                value={bank_account && bank_account.full_name}
              />
              <RenderRow
                label="CPF ou CNPJ"
                value={bank_account && bank_account.cpf_or_cnpj}
              />
              <RenderRow
                label="Conta"
                value={
                  bank_account &&
                  bank_account.bank &&
                  `${bank_account.bank.name} Ag: ${bank_account.agency} Cta: ${
                    bank_account.account_number
                  }`
                }
              />
              <RenderRow
                label="Tipo Pessoa"
                value={
                  bank_account &&
                  bank_account.person_type &&
                  bank_account.person_type.label
                }
              />
              <RenderRow
                label="Tipo Conta"
                value={
                  bank_account &&
                  bank_account.account_type &&
                  bank_account.account_type.label
                }
              />
            </Col>

            <Col md={6}>
              <SectionTitle label="Dados Inquilino" />
              <RenderRow
                label="Nome"
                value={property.active_contract.tenant.full_name}
              />
              <RenderRow
                label="CPF"
                value={formatCPF(property.active_contract.tenant.cpf)}
              />
              <RenderRow
                label="Telefone"
                value={formatPhone(property.active_contract.tenant.phone)}
              />
              <RenderRow
                label="Email"
                value={property.active_contract.tenant.email}
              />
            </Col>

            <Col md={12} className="text-center">
              <Field
                component={TextAreaField}
                controlClassName="form-control__white listing-description"
                label="Notas de Revisão"
                labelClassName="label__grey"
                name="payment_method_notes"
                required={true}
              />
              <Col md={6} xs={6}>
                <Button
                  bsStyle={"primary"}
                  style={{ fontWeight: "bold" }}
                  className="btn-invalid "
                  type="submit"
                  onClick={handleSubmit(values => {
                    this.props.onSubmit({
                      notes: values.payment_method_notes,
                      operation: "reprove"
                    });
                  })}
                >
                  REPROVAR
                </Button>
              </Col>
              <Col md={6} xs={6} style={{ marginBottom: "3rem" }}>
                <Button
                  bsStyle={"primary"}
                  style={{ fontWeight: "bold" }}
                  className="btn-valid "
                  type="submit"
                  onClick={handleSubmit(values => {
                    this.props.onSubmit({
                      notes: values.payment_method_notes,
                      operation: "approve"
                    });
                  })}
                >
                  APROVAR
                </Button>
              </Col>
            </Col>
          </Col>
        </form>
      </Row>
    );
  }
}

ApprovalPaymentMethodForm = reduxForm({
  form: formName,
  destroyOnUnmount: true,
  validate
})(ApprovalPaymentMethodForm);

ApprovalPaymentMethodForm = connect((state, ownProps) => ({
  initialValues: {
    payment_method_notes: getActiveContract(ownProps.property)
      .payment_method_notes
  }
}))(ApprovalPaymentMethodForm);
export default ApprovalPaymentMethodForm;
