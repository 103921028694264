export const listingConstants = {
  LISTING_REQUEST: 'LISTING_REQUEST',
  LISTING_SUCCESS: 'LISTING_SUCCESS',
  LISTING_NEARBY_MORE_SUCCESS: 'LISTING_NEARBY_MORE_SUCCESS',
  LISTING_FAILURE: 'LISTING_FAILURE',
  LISTING_NEARBY_REQUEST: 'LISTING_NEARBY_REQUEST',
  LISTING_NEARBY_SUCCESS: 'LISTING_NEARBY_SUCCESS',
  LISTING_NEARBY_FAILURE: 'LISTING_NEARBY_FAILURE',

  LISTING_LATLNG_REQUEST: 'LISTING_LATLNG_REQUEST',
  LISTING_LATLNG_SUCCESS: 'LISTING_LATLNG_SUCCESS',
  LISTING_LATLNG_FAILURE: 'LISTING_LATLNG_FAILURE',

  LISTING_GETALL_REQUEST: 'LISTING_GETALL_REQUEST',
  LISTING_GETALL_SUCCESS: 'LISTING_GETALL_SUCCESS',
  LISTING_GETALL_FAILURE: 'LISTING_GETALL_FAILURE',

  REVIEW_PROCESS_REQUEST: 'REVIEW_PROCESS_REQUEST',
  REVIEW_PROCESS_SUCCESS: 'REVIEW_PROCESS_SUCCESS',
  REVIEW_PROCESS_ERROR: 'REVIEW_PROCESS_ERROR',

  LISTING_CHANGE_REQUEST: 'LISTING_CHANGE_REQUEST',
  LISTING_CHANGE_SUCCESS: 'LISTING_CHANGE_SUCCESS',
  LISTING_CHANGE_ERROR: 'LISTING_CHANGE_ERROR',

  LISTING_UPDATE_REQUEST: 'LISTING_UPDATE_REQUEST',
  LISTING_UPDATE_SUCCESS: 'LISTING_UPDATE_SUCCESS',
  LISTING_UPDATE_ERROR: 'LISTING_UPDATE_ERROR',

  LISTING_SORT_BY_STATUS: 'LISTING_SORT_BY_STATUS',

  SHOW_LISTING_CONFIRMATION_DIALOG: 'SHOW_LISTING_CONFIRMATION_DIALOG',
  SHOW_LISTING_EXCLUSION_CONFIRMATION_DIALOG:
    'SHOW_LISTING_EXCLUSION_CONFIRMATION_DIALOG',
  HIDE_LISTING_CONFIRMATION_DIALOG: 'HIDE_LISTING_CONFIRMATION_DIALOG'
};
