import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row } from 'react-bootstrap';
import { destroy, getFormValues, isValid } from 'redux-form';
// import FormSteps from './FormSteps';
import FormPanel from './FormPanel';
import FormSubmit from './FormSubmit';
//import { history } from '../../_helpers/history';

export class BasePropertyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previousPanel: -1,
      completedPanels: [],
      invalidPanels: []
    };
  }

  componentWillMount(nextProps) {
    const completedPanels = Array.from(Array(this.props.panels.length).keys());
    this.setState({ ...this.state, completedPanels });
  }
  moveToSelectedPanel = () => {
    let actPanel = 'panel-' + this.state.activePanel;
    let div = document.getElementById(actPanel);
    if (div) {
      setTimeout(() => {
        const y = div.getBoundingClientRect().top + window.scrollY;
        window.scroll({
          top: y - 90,
          behavior: 'smooth'
        });
      }, 300);
    }
  };
  componentDidMount() {
    const { initialSelectedPanel } = this.props;
    this.setState(
      { activePanel: initialSelectedPanel >= 0 ? initialSelectedPanel : 0 },
      this.moveToSelectedPanel
    );
  }
  componentWillUnmount() {
    this.props.dispatch(destroy(this.props.form));
  }

  canSubmit = () => {
    return this.state.completedPanels.length === this.props.panels.length;
  };
  handlePanelValidationChange = (eventKey, invalid) => {
    if (invalid) {
      this.setState({
        completedPanels: this.state.completedPanels.filter(
          element => element !== eventKey
        ),
        invalidPanels: [...new Set(this.state.invalidPanels.concat(eventKey))]
      });
      // this.props.arrayPush(this.props.form,'completedPanels',eventKey)
    } else {
      // Mark panel as incomplete and invalid
      this.setState({
        completedPanels: [
          ...new Set(this.state.completedPanels.concat(eventKey))
        ],
        invalidPanels: this.state.invalidPanels.filter(
          element => element !== eventKey
        )
      });
    }
  };
  goToNextPanel = () => {
    let nextPanel = this.state.activePanel + 1;
    this.setState({ activePanel: nextPanel }, this.moveToSelectedPanel);
  };
  setActivePanel = key => {
    this.setState({ activePanel: key }, this.moveToSelectedPanel);
  };
  goToPreviousPanel = () => {
    this.setState(
      { activePanel: this.state.activePanel - 1 },
      this.moveToSelectedPanel
    );
  };

  isPanelActive = eventKey => {
    return eventKey === this.state.activePanel;
  };

  isPanelCompleted = eventKey => {
    return (
      this.state.completedPanels &&
      this.state.completedPanels.includes(eventKey)
    );
  };

  isPanelInvalid = eventKey => {
    return this.state.invalidPanels.includes(eventKey);
  };

  toogleOpenPanel = key => {
    this.setState({ activePanel: key }, this.moveToSelectedPanel);
  };

  renderPanels() {
    let panels = [];
    this.props.panels.forEach((panel, index) => {
      panels.push(
        <FormPanel
          panel={panel}
          body={panel.body}
          eventKey={index}
          form={this.props.form}
          handleValidationChange={this.handlePanelValidationChange}
          initialValues={this.props.initialValues}
          isActive={this.isPanelActive(index)}
          isCompleted={this.isPanelCompleted(index)}
          isInvalid={this.isPanelInvalid(index)}
          key={`panel-${index}`}
          onBackButtonClick={this.goToPreviousPanel}
          onSubmit={this.goToNextPanel}
          showBackButton={index === 0 ? false : true}
          showNextButton
          title={panel.title}
          toogleOpenPanel={() => this.toogleOpenPanel(index)}
          validate={this.props.validate}
        />
      );
    });

    return panels;
  }
  handleMySubmit = e => {
    // if (!this.props.formTouched) {
    //   // Form hasn't even been touched
    //   return;
    // }
    // if(isValid(this.props.form) && isPristine(this.props.form)(this)){
    //   // history.goBack()
    // }
    // if (isValid(this.props.form) && isDirty(this.props.form)) {
    //   // Don't allow submit if any field is in an invalid state
    //   this.props.onSubmit(e)
    // }
    this.props.onSubmit(e);
  };
  render() {
    const { form, validate } = this.props;

    return (
      <Grid className="property-register-page " fluid>
        <Row>
          {this.renderPanels()}
          <FormSubmit
            canSubmit={this.canSubmit()}
            form={form}
            isNew={this.props.isNew}
            onSubmit={this.handleMySubmit}
            validate={validate}
          />
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    valid: isValid(ownProps.form)(state),
    values: getFormValues(ownProps.form)(state),
  };
};

export default connect(mapStateToProps)(BasePropertyForm);
