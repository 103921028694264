import React from 'react';
import { Col, Row } from 'react-bootstrap';

class CheckboxGroup extends React.Component {
  checkboxGroup() {
    let {
      options,
      input,
      meta: { touched, error }
    } = this.props;

    var hasError = false;
    if (error !== undefined) {
      hasError = true;
    }

    let cbGroup = options.map((option, index) => {
      return (
        <Col sm={3} key={index}>
          <div className="checkbox checkbox-group-option">
            <label>
              <input
                type="checkbox"
                name={`${input.name}[${index}]`}
                value={option.value}
                checked={input.value.indexOf(option.value) !== -1}
                onChange={event => {
                  const newValue = [...input.value];
                  if (event.target.checked) {
                    newValue.push(option.value);
                  } else {
                    newValue.splice(newValue.indexOf(option.value), 1);
                  }

                  return input.onChange(newValue);
                }}
              />
              {option.label}
            </label>
          </div>
        </Col>
      );
    });

    return (
      <Row>
        <Col sm={12}>{cbGroup}</Col>
        <Col sm={12}>
          {hasError && touched ? <p className="field-error">{error}</p> : null}
        </Col>
      </Row>
    );
  }

  render() {
    return this.checkboxGroup();
  }
}

export default CheckboxGroup;
