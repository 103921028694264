import React from "react";
import DropzoneComponent from "react-dropzone-component";
import Dragula from "react-dragula";
import config from "../_helpers/config";
import { authHeader } from "../_helpers/authHeader";

export default class PhotosDropzone extends React.Component {
  componentWillUnmount() {
    if (this.dropzone) {
      this.dropzone.options.autoQueue = false;
      this.dropzone.options.autoProcessQueue = false;
      this.dropzone.options.enqueueForUpload = false;
    }
  }
  constructor(props) {
    super(props);
    this.header = authHeader();

    this.dropZoneConfig = {
      acceptedFiles: "image/*,.pdf",
      addRemoveLinks: true,
      dictCancelUpload: "Cancelar upload",
      dictDefaultMessage: "Clique ou arraste para adicionar fotos",
      dictMaxFilesExceeded:
        "Você pode inserir no máximo {{maxFiles}} nesta secção.",
      dictRemoveFile: "Remover",
      dictCancelUploadConfirmation:
        "Você tem certeza que deseja cancelar esse upload?",
      headers: { Authorization: authHeader().Authorization },
      method: "put",
      maxFiles: props.maxFiles ? props.maxFiles : 20,
      parallelUploads: 1,
      timeout: 180000,
      autoProcessQueue: true
    };
    this.componentConfig = {
      iconFiletypes: [".jpg", ".png", ".gif"],
      postUrl: `${config.ZIMOBI_API_BASE}/files/s3/upload`
    };
    this.addedfile = file => {
      if (this.dropzone.options.enqueueForUpload) {
        this.dropzone.processFile(file);
        if (props.onUploadProgress) {
          props.onUploadProgress("progress");
        }
      }
    };
    this.complete = file => {};
    this.success = (file, response) => {
      file.url = response.fileUrl;
      var a = document.createElement("a");
      a.setAttribute("href", file.url);
      a.setAttribute("target", "_blank");
      a.innerHTML = "Abrir";
      file.previewTemplate.appendChild(a);
      if (file.dataURL) {
        delete file["dataURL"];
      }
      if (file.xhr) {
        delete file["xhr"];
      }
      var ext = file.url.split(".").pop();
      if (ext.toLowerCase() === "pdf") {
        this.dropzone.emit("thumbnail", file, "/images/pdf_icon.png");
      } else if (ext.toLowerCase().indexOf("doc") !== -1) {
        this.dropzone.emit("thumbnail", file, "/images/word_icon.png");
      } else if (ext.toLowerCase().indexOf("xls") !== -1) {
        this.dropzone.emit("thumbnail", file, "/images/excel_icon.png");
      } else {
        this.dropzone.emit("thumbnail", file, file.url);
      }
      if (this.dropzone.options.autoQueue) {
        props.onFileAdded(
          file,
          this.dropzone.files
            .filter(f => f.status === "success")
            .map(file => {
              return {
                accepted: file.accepted,
                height: file.height,
                lastModified: file.lastModified,
                name: file.name,
                processing: file.processing,
                size: file.size,
                status: file.status,
                type: file.type,
                upload: file.upload,
                url: file.url,
                width: file.width
              };
            })
        );
      }
    };

    this.removedfile = file => {
      props.onFileRemoved(
        file,
        this.dropzone.files.filter(f => f.status === "success")
      );
    };

    this.onQueueComplete = file => {
      if (this.props.onUploadDone) {
        props.onUploadDone("completed");
      }
    };
    this.maxfilesexceeded = file => {
      this.emit(
        "error",
        file,
        this.options.dictMaxFilesExceeded.replace(
          "{{maxFiles}}",
          this.options.maxFiles
        )
      );
    };

    this.dropzone = null;
  }

  preload = images => {
    images &&
      images.forEach((image, index) => {
        // Create the mock file:
        var mockFile = {
          url: image.url,
          accepted: true,
          name:
            image.url &&
            image.url.substring(
              image.url.lastIndexOf("/") + 1,
              image.url.length
            ),
          status: "success",
          upload: {
            uuid: `added-${index}`
          },
          size: 12345
        };
        this.dropzone.files.push(mockFile);
        // Call the default addedfile event handler
        this.dropzone.emit("addedfile", mockFile);
        var ext = (mockFile.url && mockFile.url.split(".").pop()) || "";
        if (ext.toLowerCase() === "pdf") {
          this.dropzone.emit("thumbnail", mockFile, "/images/pdf_icon.png");
        } else if (ext.toLowerCase().indexOf("doc") !== -1) {
          this.dropzone.emit("thumbnail", mockFile, "/images/word_icon.png");
        } else if (ext.toLowerCase().indexOf("xls") !== -1) {
          this.dropzone.emit("thumbnail", mockFile, "/images/excel_icon.png");
        } else {
          this.dropzone.emit("thumbnail", mockFile, mockFile.url);
        }
        // And optionally show the thumbnail of the file:
        // this.dropzone.emit("thumbnail", mockFile, mockFile.url);
        // Or if the file on your server is not yet in the right
        // size, you can let Dropzone download and resize it
        // callback and crossOrigin are optional.
        this.dropzone.createThumbnailFromUrl(mockFile, mockFile.url);
        this.dropzone.emit("success", mockFile, mockFile.url);
        // Make sure that there is no progress bar, etc...
        this.dropzone.emit("complete", mockFile);
        // If you use the maxFiles option, make sure you adjust it to the
      });
  };

  componentDidMount() {
    var items = document.querySelectorAll(".dz-message");
    items.forEach(element => {
      element.innerHTML = '<img  height="83" src="/images/image_icon2.png"/> ';
    });

    const container = document.querySelector(".filepicker.dropzone");
    const options = {
      mirrorContainer: container,
      direction: "horizontal",
      invalid: el => {
        return el.classList.contains("dz-default");
      }
    };
    let drake = Dragula([container], options);
    drake.on("drop", (el, target, source, sibling) => {
      var files = this.dropzone.files;
      const nodes = Array.prototype.slice.call(target.children);
      let to = nodes.indexOf(el) - 1;
      let from = files
        .map(f => f.url)
        .indexOf(el.querySelector(".dz-image img").src);
      // console.log("TO", to);
      // console.log("FROM", from);

      this.move = (from, to) => {
        this.dropzone.files.splice(
          to,
          0,
          this.dropzone.files.splice(from, 1)[0]
        );
        return this.dropzone.files;
      };
      this.dropzone.files = this.move(from, to);
      this.props.onFileAdded(
        null,
        this.dropzone.files.filter(f => f.status === "success")
      );
      // console.log(this.dropzone.files);
    });
  }
  render() {
    const config = this.componentConfig;
    const djsConfig = this.dropZoneConfig;
    const eventHandlers = {
      init: dz => {
        this.dropzone = dz;

        this.preload(this.props.images);
        this.dropzone.files.forEach(f => {
          var a = document.createElement("a");
          a.setAttribute("href", f.url);
          a.setAttribute("target", "_blank");
          a.innerHTML = "Abrir";
          f.previewTemplate.appendChild(a);
        });
      },
      drop: this.callbackArray,
      addedfile: this.addedfile,
      complete: this.complete,
      success: this.success,
      removedfile: this.removedfile,
      queuecomplete: this.onQueueComplete
    };

    return (
      <div className="photos-dropzone">
        <DropzoneComponent
          config={config}
          djsConfig={djsConfig}
          eventHandlers={eventHandlers}
        />
      </div>
    );
  }
}
