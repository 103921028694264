import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { TextField } from "../../../_components/FormFields";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

const validate = values => {
  const errors = {};

  const requiredMsg = "Campo obrigatório";

  if (!values.email) {
    errors.email = requiredMsg;
  }

  if (!values.password) {
    errors.password = requiredMsg;
  }

  return errors;
};

export let LoginForm = ({ handleSubmit }, props) => {
  return (
    <form method="post" className="form" onSubmit={handleSubmit}>
      <Row className="padding-top-25">
        <Col>
          <Field
            {...props}
            autoFocus
            id="email"
            component={TextField}
            controlClassName="form-control__grey"
            label="E-mail"
            labelClassName="label__grey"
            name="email"
            type="text"
          />
          <Field
            {...props}
            id="password"
            component={TextField}
            controlClassName="form-control__grey"
            label="Senha"
            autoComplete="off"
            labelClassName="label__grey"
            name="password"
            type="password"
          />
        </Col>
      </Row>

      <Row>
        <Col className="text-center">
          <Button
            bsStyle="primary"
            className="btn-primary__login"
            type="submit"
          >
            ENTRAR
          </Button>
          <p className="padding-top-5">
            <Link to="/forgot-password">Esqueceu sua senha?</Link>
          </p>
        </Col>
      </Row>
    </form>
  );
};

LoginForm = reduxForm({
  form: "LoginForm",
  destroyOnUnmount: true,
  validate
})(LoginForm);

export default LoginForm;
