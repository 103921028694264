export const propertyConstants = {
  //ENTITY_OPS_STATUS
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',

  PROPERTIES_GETALL_REQUEST: 'PROPERTIES_GETALL_REQUEST',
  PROPERTIES_GETALL_SUCCESS: 'PROPERTIES_GETALL_SUCCESS',
  PROPERTIES_GETALL_FAILURE: 'PROPERTIES_GETALL_FAILURE',

  GET_TO_EDIT_REQUEST: 'GET_TO_EDIT_REQUEST',
  GET_TO_EDIT_SUCCESS: 'GET_TO_EDIT_SUCCESS',
  GET_TO_EDIT_FAILURE: 'GET_TO_EDIT_FAILURE',

  DELETE_REQUEST: 'PROPERTIES_DELETE_REQUEST',
  DELETE_SUCCESS: 'PROPERTIES_DELETE_SUCCESS',
  DELETE_FAILURE: 'PROPERTIES_DELETE_FAILURE',

  GET_PROPERTY_REQUEST: 'GET_PROPERTY_REQUEST',
  GET_PROPERTY_SUCCESS: 'GET_PROPERTY_SUCCESS',
  GET_PROPERTY_FAILURE: 'GET_PROPERTY_FAILURE',

  GETALL_PROPERTY_LISTINGS: 'GETALL_PROPERTY_LISTINGS',

  HIDE_MODALS: 'HIDE_MODALS',
  UPDATE_MODALS: 'UPDATE_MODALS',
  CHANGE_VIEW_MODE: 'CHANGE_VIEW_MODE',
  FILTER_RENTED_PROPERTIES: 'FILTER_RENTED_PROPERTIES',
  FILTER_PROPERTY_BY_TEXT: 'FILTER_PROPERTY_BY_TEXT',
  ANALYTICS_GRANULARITY_CHANGE: 'ANALYTICS_GRANULARITY_CHANGE',
  PROPERTIES_SORT_BY_STATUS: 'PROPERTIES_SORT_BY_STATUS',

  REVIEW_BILLING_REQUEST: 'REVIEW_BILLING_REQUEST',
  REVIEW_BILLING_SUCCESS: 'REVIEW_BILLING_SUCCESS',
  REVIEW_BILLING_ERROR: 'REVIEW_BILLING_ERROR'
};
