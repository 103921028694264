import { authHeader } from '../_helpers/authHeader';
import config from '../_helpers/config';

export const listingService = {
  register,
  getAll,
  getById,
  update,
  getNearbyListings,
  loadMeta,
  getByPropertyId,
  enableListing,
  disableListing,
  reviewListing,
  findAllSortingBy,
  removeListingById,
  sendContractProcessNotification,
  sendPaymentManagementNotification,
  searchPropertyLike
};
function searchPropertyLike(field, text) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${config.ZIMOBI_API_BASE}/listings?q[${field}_eq]=${text}`,
    requestOptions
  ).then(handleResponse);
}
function sendPaymentManagementNotification(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    // body: JSON.stringify({ id })
  };
  return fetch(
    `${config.ZIMOBI_API_BASE}/properties/${id}/request_new_payment`,
    requestOptions
  ).then(response => {
    if (response.ok) {
      return {message: "Solicitação de gestão de pagamentos efetuada com sucesso"};
    } else {
      throw new Error('Erro ao processar');
    }
  });
}
function sendContractProcessNotification(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    // body: JSON.stringify({ id })
  };
  return fetch(
    `${config.ZIMOBI_API_BASE}/properties/${id}/request_new_contract`,
    requestOptions
  ).then(response => {
    if (response.ok) {
      return {message: "Solicitação de envio de contratos efetuada com sucesso"};
    } else {
      throw new Error('Erro ao processar');
    }
  });
}
function reviewListing(id, body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body)
  };
  return fetch(
    `${config.ZIMOBI_API_BASE}/listings/${id}/review_process`,
    requestOptions
  ).then(response => {
    if (response.ok) {
      if (response.status === 204) {
        return response;
      } else {
        return response.json();
      }
    } else {
      throw new Error('Aconteceu um erro ao efetuar a chamada do serviço');
    }
  });
}
function enableListing(id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ is_active: true })
  };
  return fetch(`${config.ZIMOBI_API_BASE}/listings/${id}`, requestOptions).then(
    response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong');
      }
    }
  );
}
function disableListing(id) {
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify({ is_active: false }),
    headers: authHeader()
  };
  return fetch(`${config.ZIMOBI_API_BASE}/listings/${id}`, requestOptions).then(
    response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong');
      }
    }
  );
}
function getAll(url) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    url ? url : `${config.ZIMOBI_API_BASE}/listings`,
    requestOptions
  ).then(handleResponse);
}

function findAllSortingBy(field, order) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${config.ZIMOBI_API_BASE}/listings?q[s]=${field}+${order}&qnty=20`,
    requestOptions
  ).then(handleResponse);
}
function removeListingById(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };
  return fetch(`${config.ZIMOBI_API_BASE}/listings/${id}`, requestOptions).then(
    response => {
      if (response.ok) {
        return {message: "Anúncio removido com sucesso"};
      } else {
        throw new Error('Something went wrong');
      }
    }
  );
}
function getById(id) {
  const requestOptions = {
    method: 'GET'
  };
  return fetch(`${config.ZIMOBI_API_BASE}/listings/${id}`, requestOptions).then(
    response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong');
      }
    }
  );
}
function getByPropertyId(id) {
  const requestOptions = {
    method: 'GET'
  };
  return fetch(
    `${config.ZIMOBI_API_BASE}/listings?q[property_id_eq]=${id}`,
    requestOptions
  ).then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Something went wrong');
    }
  });
}
function getNearbyListings(qnty) {
  const requestOptions = {
    method: 'GET'
  };
  return fetch(
    `${config.ZIMOBI_API_BASE}/listings/?qnty=${qnty}`,
    requestOptions
  ).then(resp => resp.json());
}
function loadMeta(url) {
  const requestOptions = {
    method: 'GET'
  };
  return fetch(url, requestOptions).then(resp => resp.json());
}
function register(listing) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(listing)
  };

  return fetch(`${config.ZIMOBI_API_BASE}/listings`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong');
      }
    })
    .catch(error => {
      console.error(error);
    });
}

function update(listing) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(listing)
  };

  return fetch(
    `${config.ZIMOBI_API_BASE}/listings/${listing.id}`,
    requestOptions
  )
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong');
      }
    })
    .catch(error => {
      console.error(error);
    });
}
function handleResponse(response) {
  if (response.status === 204) {
    return;
  }

  return response.json().then(data => {
    if (!response.ok) {
      const error = data || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
