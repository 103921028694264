import React from 'react';
import { Gmaps, Marker } from 'react-gmaps';

const params = { v: '3.exp', key: 'AIzaSyDL99UXqvOMEzOseNvDm3SBSNdhdKonWks' };

class App extends React.Component {
  onMapCreated(map) {
    map.setOptions({
      disableDefaultUI: false
    });
  }

  onDragEnd(e) {
    console.debug('onDragEnd', e);
  }

  onCloseClick() {
    console.debug('onCloseClick');
  }

  onClick(e) {
    console.debug('onClick', e);
  }

  render() {
    return (
      <Gmaps
        width={'100%'}
        height={'60vh'}
        lat={this.props.latlng.lat}
        lng={this.props.latlng.lng}
        zoom={16}
        loadingMessage={'Carregando'}
        params={params}
        onMapCreated={this.onMapCreated}
      >
        {/* <InfoWindow
          lat={this.props.latlng.lat}
          lng={this.props.latlng.lng}
          content={this.props.address}
          onCloseClick={this.onCloseClick} /> */}
        <Marker
          lat={this.props.latlng.lat}
          lng={this.props.latlng.lng}
          draggable={false}
          onDragEnd={this.onDragEnd}
        />

        {/* <Circle
          lat={coords.lat}
          lng={coords.lng}
          radius={500}
          onClick={this.onClick} /> */}
      </Gmaps>
    );
  }
}

export default App;
