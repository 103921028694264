import React from "react";
import { Col, Panel, Glyphicon } from "react-bootstrap";
import { SearchBox } from "./SearchBox";
let callSearch;
class TogglePanel extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { ...props.initialValues, open: true, searchValue: "" };
    this.timeout = null;
  }
  handleCriteriaChange = event => {
    clearTimeout(callSearch);
    callSearch = setTimeout(this.sendToParent, 1000);
    this.setState({ searchValue: event.target.value });
  };
  onClearSearch = event => {
    clearTimeout(callSearch);
    callSearch = setTimeout(this.sendToParent, 100);
    this.setState({ searchValue: "" });
  };
  sendToParent = () => {
    this.props.onSearchChanged(this.props.eventkey, this.state.searchValue);
  };
  render() {
    const {
      body,
      eventkey,
      handleSubmit,
      title,
      toogleable,
      hasSearch,
      size,
      thinBody,
      customComponent,
      title2
    } = this.props;

    return !thinBody && body ? (
      <Col md={size ? size : 12} className=" animated fadeInDown">
        <Panel
          eventKey={eventkey}
          onToggle={() => this.setState({ open: !this.state.open })}
          defaultExpanded={true}
        >
          <div>
            <Col
              xsHidden={true}
              smHidden={false}
              mdHidden={false}
              lgHidden={false}
            >
              <Panel.Heading>
                {toogleable && (
                  <Panel.Toggle componentClass="a">
                    <div
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        width: "88%",
                        height: "40px",
                        position: "absolute"
                      }}
                    />
                  </Panel.Toggle>
                )}
                <Panel.Title toggle={toogleable}>
                  <span
                    className="title"
                    style={{ display: "contents", whiteSpace: "nowrap" }}
                  >
                    {title}
                    {toogleable && (
                      <Glyphicon
                        style={{ float: "right" }}
                        glyph={
                          this.state.open
                            ? "glyphicon glyphicon-chevron-up"
                            : "glyphicon glyphicon-chevron-down"
                        }
                      />
                    )}
                    <small style={{ marginLeft: title2 ? "3rem" : "0px" }}>
                      {title2}
                    </small>
                  </span>
                  {hasSearch && (
                      <SearchBox
                        style={{ position: 'relative', width: '40%' }}
                        onSearchChanged={this.handleCriteriaChange}
                        value={this.state.searchValue}
                        onClearSearch={this.onClearSearch}
                      />
                    )}
                  {title && this.state.open ? <hr /> : ""}
                  {customComponent && (
                    <div style={{ position: "relative", float: "right" }}>
                      {customComponent}
                    </div>
                  )}
                 
                </Panel.Title>
              </Panel.Heading>
            </Col>
            <Col
              xsHidden={false}
              smHidden={true}
              mdHidden={true}
              lgHidden={true}
              style={{ display: "grid" }}
            >
              <Panel.Heading>
                {toogleable && (
                  <Panel.Toggle componentClass="a">
                    <div
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        width: "88%",
                        height: "40px",
                        position: "absolute"
                      }}
                    />
                  </Panel.Toggle>
                )}
                <Panel.Title toggle={toogleable}>
                  <h4 className="title" style={{ whiteSpace: "nowrap" }}>
                    {title}
                    {toogleable && (
                      <Glyphicon
                        style={{ float: "right" }}
                        glyph={
                          this.state.open
                            ? "glyphicon glyphicon-chevron-up"
                            : "glyphicon glyphicon-chevron-down"
                        }
                      />
                    )}
                    <small style={{ marginLeft: title2 ? "3rem" : "0px" }}>
                      {title2}
                    </small>
                  </h4>
                  {this.state.open && <hr />}
                </Panel.Title>
              </Panel.Heading>
              {body && customComponent && (
                <span style={{ textAlign: "center", marginRight: "2rem" }}>
                  {customComponent}
                </span>
              )}
            </Col>
          </div>
          <Panel.Collapse>
            <form className="form" onSubmit={handleSubmit}>
              <Panel.Body>{body}</Panel.Body>
            </form>
          </Panel.Collapse>
        </Panel>
      </Col>
    ) : (
      <Col md={size ? size : 12}>{body}</Col>
    );
  }
}

export default TogglePanel;
