import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../_helpers/history';
import { mapListingToEdit } from '../../_helpers/mappers';
import PropertyListingForm from '../../_components/PropertyListingForm/PropertyListingForm';
import { propertyActions } from '../../_actions/propertyActions';
import { listingActions } from '../../_actions/listingActions';
import { Breadcrumb } from 'react-bootstrap';
import PageTitle from '../../_components/Shared/PageTitle';
class PropertyListingEditPage extends React.Component {
  constructor(props) {
    super(props);
    const { propertyId } = props.match.params;
    if (propertyId) {
      props.findListing(propertyId);
    } else {
      history.push('/dashboard');
    }
  }
  submit = values => {
    this.props.updateProperty(mapListingToEdit(values));
  };

  render() {
    return (
      <div>
        {this.props.selectedListing && (
          <div>
            <PageTitle>Edição Anúncio</PageTitle>
            <Breadcrumb>
              <Breadcrumb.Item href="/dashboard">Meus Imóveis</Breadcrumb.Item>
              <Breadcrumb.Item href="/listings">Anúncios</Breadcrumb.Item>
              <Breadcrumb.Item active>Edição Anúncio</Breadcrumb.Item>
            </Breadcrumb>
            <PropertyListingForm
              onSubmit={this.submit}
              selectedListing={this.props.selectedListing}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateProperty: values => dispatch(propertyActions.update(values)),
  findListing: id => dispatch(listingActions.findById(id))
});
const mapStateToProps = state => ({
  selectedListing: state.properties.selectedListing
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyListingEditPage);
