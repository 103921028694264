import { validateCPF } from '../../_helpers/cpfValidator';

const validate = values => {
  const errors = {};

  const requiredMsg = 'Campo obrigatório';

  if (!values.full_name) {
    errors.full_name = requiredMsg;
  }

  if (!values.phone) {
    errors.phone = requiredMsg;
  }

  if (!values.company_name && values.person_type === 'company') {
    errors.company_name = requiredMsg;
  }

  if (
    values.cpf &&
    values.person_type === 'individual' &&
    !validateCPF(values.cpf)
  ) {
    errors.cpf = 'CPF inválido';
  }

  if (!values.cnpj && values.person_type === 'company') {
    errors.cnpj = requiredMsg;
  }

  if (!values.email) {
    errors.email = requiredMsg;
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Insira um email válido.';
  }

  if (!values.email_confirmation) {
    errors.email_confirmation = requiredMsg;
  }

  if (values.email !== values.email_confirmation) {
    errors.email_confirmation =
      'O email e a confirmação de email estão diferentes.';
  }

  if (!values.password) {
    errors.password = requiredMsg;
  } else if (values.password.length < 6) {
    errors.password = 'A senha deve ter pelo menos 6 caracteres.';
  }

  if (!values.password_confirmation) {
    errors.password_confirmation = requiredMsg;
  }

  if (values.password !== values.password_confirmation) {
    errors.password_confirmation =
      'A senha e a confirmação de senha estão diferentes.';
  }

  if (values.email && values.email.indexOf(' ') !== -1) {
    errors.email = 'Email não pode conter espaços!';
  }
  return errors;
};

export default validate;
