import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Image } from 'react-bootstrap';
const EmptyMessageBlock = ({
  title,
  subtitle,
  callToActionLabel,
  linkTo,
  imagePath,
  bsStyle,
  callToActionSize
}) => (
  <div
    style={{
      position: 'relative',
      textAlign: 'center',
      height: 'auto',
      margin: '1rem',
      padding: '1rem'
    }}
  >
    <Image
      style={{ maxHeight: '60px', height: '60px', fill: '#61c7cf' }}
      src={imagePath}
    />
    {title && <h4>{title}</h4>}
    {subtitle && <h5>{subtitle}</h5>}
    {linkTo && (
      <Link to={linkTo}>
        <Button
          className="button-with-padding"
          bsSize={callToActionSize ? callToActionSize : 'small'}
          bsStyle={bsStyle ? bsStyle : 'primary'}
        >
          {callToActionLabel}
        </Button>
      </Link>
    )}
  </div>
);

export default EmptyMessageBlock;
