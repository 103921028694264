import { userConstants } from '../_constants/userConstants';

export default function registration(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { ...state, loading: true };
    case userConstants.REGISTER_SUCCESS:
      return { ...state, loading: false };
    case userConstants.REGISTER_FAILURE:
      return { ...state, loading: false };
    case userConstants.GET_USER_SUCCESS:
      return { ...state, loading: false, selectedUser: action.user };
    case userConstants.GET_USER_REQUEST:
      return { ...state, loading: false, selectedUser: void 0 };
    default:
      return state;
  }
}
