import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import ListingConfirmationForm from '../Forms/ListingConfirmationForm';

export const ListingConfirmationModalWrapper = ({
  container,
  listing,
  show,
  onSubmit,
  onHide,
  onCancel,
  loading
}) => (
  <Modal bsSize="lg" show={show} container={container} onHide={onHide}>
    <Modal.Header
      closeButton
      style={{
        borderLeft: '10px solid',
        borderLeftColor: '#5ec7ce',
        borderTopLeftRadius: '5px'
      }}
    >
      <Modal.Title
        style={{
          color: '#5ec7ce'
        }}
      >
        Resumo do anúncio - {listing.id}
        <small> Ref Zimobi: {listing.property.code}</small>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ListingConfirmationForm
        listing={listing}
        loading={loading}
        onHide={onHide}
      />
    </Modal.Body>
    <Modal.Footer>
      <Button
        bsStyle="secondary"
        className=" btn-secondary__cancel"
        onClick={onCancel}
      >
        Editar Anúncio
      </Button>
      <Button bsStyle="primary" onClick={onSubmit}>
        ANUNCIAR MEU IMÓVEL
      </Button>
    </Modal.Footer>
  </Modal>
);
