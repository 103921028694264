import React from "react";
import { connect } from "react-redux";
import PropertyListingForm from "../../_components/PropertyListingForm/PropertyListingForm";
import { listingActions } from "../../_actions/listingActions";

export class PublicPropertyListingNewPage extends React.Component {
  submit = values => {
    this.props.saveListing(values);
  };
  render() {
    return (
      <div>
        <PropertyListingForm
          onSubmit={this.submit}
          showListingOwnerPanel
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveListing: values => dispatch(listingActions.registerWithUser(values))
});

export default connect(
  null,
  mapDispatchToProps
)(PublicPropertyListingNewPage);
