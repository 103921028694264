import React from "react";
import { connect } from "react-redux";
import BasePropertyForm from "../BasePropertyForm/BasePropertyForm";
import AddressData from "./PanelContent/AddressData";
import ListingData from "./PanelContent/ListingData";
import ListingOwnerData from "./PanelContent/ListingOwnerData";
import { change, reset } from "redux-form";
import PropertyDetails from "./PanelContent/PropertyDetails";
import validate from "./Validations";
const form = "PropertyListingForm";

export class PropertyListingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { initialPanel: this.props.user.role === "admin" ? 3 : 0 };
    this.props.changeFormField(form, "furnish_type", "no");
    this.props.changeFormField(form, "status", "free");
    if (props.new) {
      reset(props.form);
    }
  }

  shouldRenderApprovalPanel = () => {
    return (
      this.props.property &&
      !this.props.property.listing.is_data_valid &&
      this.props.user.role === "admin"
    );
  };
  render() {
    const {
      selectedListing,
      onDraftSubmit,
      onSubmit,
      showListingOwnerPanel
    } = this.props;

    let panels = [
      {
        body: (
          <AddressData
            form={form}
            generatedCode={this.props.new ? true : false}
          />
        ),
        iconBaseName: "icon_house",
        title: "Endereço",
        hidden: false
      },
      {
        body: <PropertyDetails form={form} listing={selectedListing} />,
        iconBaseName: "icon_owner",
        title: "Detalhes",
        hidden: false
      },
      {
        body: <ListingData form={form} listing={selectedListing} />,
        iconBaseName: "icon_contract",
        title: "Anúncio",
        hidden: false
      },
      {
        body: (
          <ListingOwnerData
            form={form}
            selectedAction={
              selectedListing &&
              selectedListing.new_user_attributes &&
              selectedListing.new_user_attributes.action
                ? selectedListing.new_user_attributes.action
                : null
            }
          />
        ),
        iconBaseName: "icon_tenant",
        title: "Anunciante",
        hidden: !showListingOwnerPanel
      }
    ];

    return (
      <BasePropertyForm
        form={form}
        onDraftSubmit={onDraftSubmit}
        onSubmit={onSubmit}
        panels={panels.filter(p => !p.hidden)}
        initialValues={selectedListing}
        validate={validate}
        isNew={this.props.new}
      />
    );
  }
}
const mapDispatchToProps = dispatch => ({
  changeFormField: (formName, attribute, value) =>
    dispatch(change(formName, attribute, value))
});
const mapStateToProps = (state, ownProps) => ({
  user: state.authentication.user.data
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyListingForm);
