import React from "react";
import { connect } from "react-redux";
import Header from "../../_components/Header";
import { listingActions } from "../../_actions/listingActions";
import ImgsViewer from "react-images";
import SimpleMap from "./SimpleMap";
import LoadingMessage from "../../_components/Shared/LoadingMessage";
import {
  Col,
  Button,
  Modal,
  Grid,
  Image,
  Panel,
  Row,
  Carousel as PropertySlider
} from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ScheduleVisitForm from "../../_components/PropertyListingForm/ListingModals/ScheduleVisitForm";
import { saveDoubt, saveSchedule } from "./spreedsheetservice";
import { toCurrenry } from "../../_helpers/utils";
import * as moment from "moment";
import * as utils from "../../_helpers/utils";
import { SimplePanel } from "../../_components/SimplePanel";
let itensPerLine = 4;

class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyToShow: null,
      slideIndex: 0,
      contactModalOpen: false,
      showMissingIdError: false,
      viewerIsOpen: false,
      currentImage: 1
    };
  }
  componentDidMount() {
    utils.scrollTop();
    const { dispatch, match } = this.props;
    const { propertyId } = match.params;
    if (propertyId) {
      dispatch(listingActions.findByPropertyId(propertyId));
    } else {
      this.setState({ showMissingIdError: true });
    }
  }
  loadMoreSimilar = url => {
    const { dispatch } = this.props;
    dispatch(listingActions.findMore(url));
  };
  gotoPrevious = () => {
    let _currentImage = this.state.currentImage - 1;
    this.setState({ currentImage: _currentImage });
  };
  gotoNext = () => {
    let _currentImage = this.state.currentImage + 1;
    this.setState({ currentImage: _currentImage });
  };
  closeViewer = () => {
    this.setState({ viewerIsOpen: false, currentImage: 0 });
  };

  handleScheduleVisitFormSubmission = e => {
    e.preventDefault();
    saveSchedule({
      created: moment().format("DD/MM/YYYY HH:mm:ss"),
      type: "visit",
      ...this.props.scheduleForm.values.schedule_form_attributes,
      propertyId: this.props.match.params.propertyId
    });
    this.handleHide();
  };
  handleDoubtFormSubmission = e => {
    e.preventDefault();
    saveDoubt({
      created: moment().format("DD/MM/YYYY HH:mm:ss"),
      type: "doubt",
      ...this.props.doubtForm.values.doubt_form_attributes,
      propertyId: this.props.match.params.propertyId
    });
    this.handleHide();
  };
  handleListingSelection = id => {
    this.props.dispatch(listingActions.findByPropertyId(id));
    window.scroll(0, 0);
  };
  handlePropertyImageClick = imgSelected => {
    this.setState({ currentImage: imgSelected, viewerIsOpen: true });
  };
  handleHide = () => {
    this.setState({ doubtModalOpen: false, contactModalOpen: false });
  };
  render() {
    const {
      listingLatLng,
      selectedListing,
      similarListings,
      loading
    } = this.props;
    const PropertyAddress = props => {
      const { property } = props.listing;
      const {
        street,
        complement,
        number,
        neighbourhood,
        city,
        state
      } = property.address;
      const addresText = `${street}, ${number || ""}${
        complement ? ", " + complement : ""
      }, ${neighbourhood} - ${city}/${state}
      `;
      // const addresText = `${street}, ${number || ""}, ${neighbourhood},\n ${city}/${state}${
      //   complement ? ", " + complement : ""
      // }`;
      return (
        <h4>
          {addresText}
          &nbsp;
          <span className="badge">
            {getPropertyTypeDisplayText(property.property_type)}
          </span>
        </h4>
      );
    };
    const getGuaranteDisplayText = (value, label, deposit_amount_in_cents) => {
      switch (value) {
        case "deposit":
          return ` ${label} ${
            deposit_amount_in_cents > 0
              ? toCurrenry(deposit_amount_in_cents / 100)
              : ""
          }`;
        default:
          return ` ${label}`;
      }
    };
    const getPropertyTypeDisplayText = type => {
      switch (type) {
        case "apartment":
          return " Apartamento";
        case "house":
          return " Casa";
        case "hangar":
          return " Galpão";
        case "kitnet":
          return " Kitnet";
        case "office":
          return " Sala Comercial";
        case "two_story_house":
          return " Sobrado";
        case "studio":
          return " Studio";
        case "other":
          return " Outro";
        default:
          return "";
      }
    };

    const PropertyFinancials = props => {
      const {
        rental_price_in_cents,
        condo_fee_in_cents,
        iptu_price_in_cents,
        total_price_in_cents,
        rental_guarantee,
        deposit_amount_in_cents
      } = props.listing;
      return props.listing ? (
        <div className={props.float ? "" : ""}>
          <Panel>
            <Panel.Title>
              <h3 className="text-center titles">Valores</h3>
            </Panel.Title>

            <Panel.Body>
              <table className="table">
                <tbody>
                  <tr className="text-muted ">
                    <td className="titles">Aluguel</td>
                    <td>
                      <span className="pull-right">
                        {toCurrenry(rental_price_in_cents / 100)}
                      </span>
                    </td>
                  </tr>
                  <tr className="text-muted ">
                    <td className="titles">Condomínio</td>
                    <td>
                      <span className="pull-right">
                        {toCurrenry(condo_fee_in_cents / 100)}
                      </span>
                    </td>
                  </tr>
                  <tr className="text-muted ">
                    <td className="titles">IPTU</td>
                    <td>
                      <span className="pull-right">
                        {toCurrenry(iptu_price_in_cents / 100)}
                      </span>
                    </td>
                  </tr>
                  <tr style={{ fontWeight: "bold", fontSize: "1.8rem" }}>
                    <td className="titles">Total</td>
                    <td>
                      <span className="pull-right">
                        {toCurrenry(total_price_in_cents / 100)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              {rental_guarantee && rental_guarantee.length > 0 ? (
                <div>
                  <h4 className="text-center titles">Garantias</h4>
                  <div className="guarantee">
                    {rental_guarantee.map((guarantee, key) => {
                      // alert(deposit_amount_in_cents)
                      return (
                        <p key={key} style={{ paddingRight: "10px" }}>
                          <Image
                            className="img-valign blueimg"
                            width={"16px"}
                            src="/images/icon_garantees.png"
                          />
                          {getGuaranteDisplayText(
                            guarantee.value,
                            guarantee.label,
                            deposit_amount_in_cents
                          )}
                        </p>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
              <br />
              <div className="text-nowrap text-center col-centered ">
                <Button
                  bsStyle="primary"
                  className="btn-sparse btn-block "
                  onClick={() => {
                    window.scroll(0, 0);
                    this.setState({ contactModalOpen: true });
                  }}
                >
                  AGENDAR VISITA
                </Button>
                <p />
              </div>
            </Panel.Body>
          </Panel>
        </div>
      ) : (
        ""
      );
    };
    const FeatureItem = props => {
      let label =
        !props.plural || parseInt(props.value, 0) === 1
          ? props.label
          : props.label + "s";
      return (
        <Col
          md={2}
          sm={2}
          xs={4}
          className="text-nowrap text-center col-centered "
        >
          <div className="property-feature-item">
            {props.image}
            <span>{props.value ? props.value : 0}</span>
            {label}
            {props.suffix ? " " + props.suffix : ""}
          </div>
        </Col>
      );
    };

    const PropertyFeatures = props => {
      const iconSize = 40;
      const {
        number_of_rooms,
        usable_floor_area_in_square_meters,
        number_of_parking_spots,
        number_of_bathrooms,
        number_of_suites
      } = props.listing.property;
      const { furnish_type } = props.listing;
      return (
        <Panel style={{ zIndex: "0" }}>
          <Panel.Body className="text-center">
            <Row>
              <FeatureItem
                value={number_of_rooms}
                image={
                  <Image
                    width="auto"
                    height={iconSize}
                    src="/images/icon_bedroom.svg"
                  />
                }
                label={"Quarto"}
                plural={true}
              />
              <FeatureItem
                value={number_of_suites}
                image={
                  <Image
                    width="auto"
                    height={iconSize}
                    src="/images/icon_suites.svg"
                  />
                }
                label={"Suíte"}
                plural={true}
              />
              <FeatureItem
                value={number_of_bathrooms}
                image={
                  <Image
                    width="auto"
                    height={iconSize}
                    src="/images/icon_bathroom.svg"
                  />
                }
                label={"Banheiro"}
                plural={true}
              />
              <FeatureItem
                value={number_of_parking_spots}
                image={
                  <Image
                    width="auto"
                    height={iconSize}
                    src="/images/icon_parking_slot2.svg"
                  />
                }
                label={"Vaga"}
                plural={true}
              />
              <FeatureItem
                value={usable_floor_area_in_square_meters}
                image={
                  <Image
                    width="auto"
                    height={iconSize}
                    src="/images/icon_area.svg"
                  />
                }
                label={"Area"}
                plural={false}
                suffix={"m²"}
              />
              <FeatureItem
                value={furnish_type.label}
                image={
                  <Image
                    width="auto"
                    height={iconSize}
                    src="/images/icon_furniture.svg"
                  />
                }
                label={"Mobiliado"}
                plural={false}
              />
            </Row>
            <br />
            <PropertyAddress listing={props.listing} />
          </Panel.Body>
        </Panel>
      );
    };
    const PropertyImages = props => {
      const { images } = props.listing;
      let imageArray = [];
      images.forEach(img => {
        imageArray.push({ src: img.url });
      });
      return images ? (
        <div>
          <Col
            xsHidden={false}
            smHidden={false}
            mdHidden={true}
            lgHidden={true}
            className=""
          >
            <ImgsViewer
              backdropClosesModal={true}
              currentImage={props.currentImage}
              images={imageArray}
              isOpen={props.viewerIsOpen}
              onClickPrev={this.gotoPrevious}
              onClickNext={this.gotoNext}
              onClose={this.closeViewer}
            />
            <Carousel
              onClickItem={this.handlePropertyImageClick}
              className="fullwidth-carousel"
              showThumbs={false}
              emulateTouch
              showArrows={false}
              dynamicHeight={false}
              statusFormatter={(current, total) => {
                return `${current} de ${total}`;
              }}
            >
              {images.map((img, index) => {
                return (
                  <div key={index}>
                    <img
                      alt="img representation of the listing property"
                      src={img.url}
                      className="listing-img"
                    />
                    <p className="legend">Legend 1</p>
                  </div>
                );
              })}
            </Carousel>
          </Col>
          <Col
            xsHidden={true}
            smHidden={true}
            mdHidden={false}
            lgHidden={false}
            className=""
          >
            <Carousel
              onClickItem={this.handlePropertyImageClick}
              className="col-carousel"
              showThumbs={false}
              centerMode
              centerSlidePercentage={33.3}
              autoPlay={true}
              statusFormatter={(current, total) => `${current} de ${total}`}
            >
              {images.map((img, index) => {
                return (
                  <div key={index}>
                    <img
                      alt="img  of the listing property"
                      src={img.url}
                      className="listing-img"
                    />
                    <p className="legend">Legend 1</p>
                  </div>
                );
              })}
            </Carousel>
          </Col>
        </div>
      ) : (
        ""
      );
    };

    const PropertyDescription = props => {
      const { title, description } = props.listing;
      return (
        <Panel>
          <Panel.Body>
            <div className="listing-line-content">
              <h2 className="title"> {title}</h2>
              <h4 className="description">{description}</h4>
            </div>
          </Panel.Body>
        </Panel>
      );
    };
    const PropertyLocation = props => {
      return (
        <Panel>
          <Panel.Title>
            <h3 className="text-center titles">Localização</h3>
          </Panel.Title>
          <Panel.Body>
            {props.latlng ? (
              <SimpleMap latlng={props.latlng} />
            ) : (
              <LoadingMessage title="Carregando" />
            )}
          </Panel.Body>
        </Panel>
      );
    };

    const PropertyListingCard = props => {
      const { id, images, title, rental_price_in_cents } = props.listing;
      return (
        <Col md={props.items ? 12 / props.items : 4}>
          <div
            className="property-listing-card grow"
            onClick={() => this.handleListingSelection(id)}
          >
            <PropertySlider indicators={false} controls={false}>
              {images.map(image => {
                return (
                  <PropertySlider.Item key={image.url}>
                    <img
                      alt="smaller properties"
                      className="small-listing-img"
                      src={image.url}
                    />
                  </PropertySlider.Item>
                );
              })}
            </PropertySlider>
            <div className="body">
              <span className="title">{title}</span>
              <br />
              <span className="price">
                {toCurrenry(rental_price_in_cents / 100)}
              </span>
              <small className="description">
                <PropertyAddress listing={props.listing} />
              </small>
            </div>
          </div>
        </Col>
      );
    };
    const SimilarPropertiesNearby = props => {
      return (
        <Panel>
          <h3 className="text-center listing-line-title titles">
            Anúncios similares
          </h3>
          <Panel.Body>
            {props.listings.data.map((listing, key) => {
              return (
                <PropertyListingCard
                  key={listing.property.id}
                  listing={listing}
                  items={itensPerLine}
                />
              );
            })}
          </Panel.Body>
          <div className="text-center">
            <Button
              bsStyle="primary"
              bsSize="lg"
              onClick={() =>
                this.loadMoreSimilar(props.listings.meta.next_page)
              }
              className="btn-sparse"
            >
              Mais Anúncios
            </Button>
            <br />
            <br />
            <br />
          </div>
        </Panel>
      );
    };
    const ScheudleVisitModal = (
      <Modal
        show={this.state.contactModalOpen}
        container={this}
        onHide={this.handleHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>Agendar uma visita</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ScheduleVisitForm
            handleSubmit={this.handleScheduleVisitFormSubmission}
            onCancel={this.handleHide}
          />
        </Modal.Body>
      </Modal>
    );
    return (
      <div style={{ color: "#9c9c9c" }}>
        {ScheudleVisitModal}
        {loading ? (
          <SimplePanel>
            <LoadingMessage title="Carregando" />
          </SimplePanel>
        ) : (
          <Row>
            {selectedListing && (
              <div>
                <Header />
                <Grid fluid className=" nopadding">
                  <Row className="first-row-with-header row-no-padding">
                    <PropertyImages
                      listing={selectedListing}
                      viewerIsOpen={this.state.viewerIsOpen}
                      currentImage={this.state.currentImage}
                    />
                  </Row>
                </Grid>
                <Grid fluid={false}>
                  <Row style={{ marginTop: "-20px" }}>
                    <Col md={8}>
                      {selectedListing.property && (
                        <PropertyFeatures listing={selectedListing} />
                      )}
                      <div>
                        <Col
                          xsHidden={false}
                          smHidden={false}
                          mdHidden={true}
                          lgHidden={true}
                        >
                          <PropertyFinancials
                            listing={selectedListing}
                            float={false}
                          />
                        </Col>
                        <PropertyDescription
                          className="listing-line-item"
                          listing={selectedListing}
                        />
                      </div>

                      {listingLatLng && (
                        <PropertyLocation
                          className="listing-line-item"
                          latlng={listingLatLng}
                        />
                      )}
                    </Col>
                    <Col
                      md={4}
                      xsHidden={true}
                      smHidden={true}
                      mdHidden={false}
                      lgHidden={false}
                      className="sticky"
                    >
                      <PropertyFinancials
                        float={true}
                        listing={selectedListing}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      {similarListings ? (
                        <SimilarPropertiesNearby
                          className="listing-line-item"
                          listings={similarListings}
                        />
                      ) : (
                        <div>
                          <br />
                          <br />
                          <br />
                        </div>
                      )}
                    </Col>
                    <Col
                      xs={12}
                      xsHidden={false}
                      smHidden={false}
                      mdHidden={true}
                      lgHidden={true}
                    >
                      <div className="footer">
                        <Col xs={4}>
                          <div>
                            <span className="text-center amount">
                              {toCurrenry(
                                selectedListing.rental_price_in_cents / 100
                              )}
                            </span>
                            <br />
                            <small>Aluguel</small>
                          </div>
                        </Col>
                        <Col xs={8}>
                          <Button
                            bsStyle="primary"
                            bsSize="sm"
                            className="btn-sparse pull-right"
                            onClick={() =>
                              this.setState({ contactModalOpen: true })
                            }
                          >
                            Agendar
                          </Button>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </div>
            )}
          </Row>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { ScheduleVisitForm, DoubtForm } = state.form;
  const { selectedListing, similarListings, loading } = state.properties;
  const listingLatLng = selectedListing ? selectedListing.latlng : null;
  return {
    loading,
    scheduleForm: ScheduleVisitForm,
    doubtForm: DoubtForm,
    selectedListing,
    similarListings,
    listingLatLng
  };
}

export default connect(mapStateToProps)(SignupPage);
