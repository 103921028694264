import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Col, Row } from 'react-bootstrap';
import { TextField, TextAreaField } from '../../FormFields';
import { formatCPF, formatPhone } from '../../../_helpers/reduxFormFormatters';
import validate from './Validations';
import { normalizeNumericValue } from '../../../_helpers/reduxFormNormalizers';
class ScheduleVisitForm extends React.Component {
  state = {
    btnBaseClass: 'btn-primary pull-right btn-primary__person-type',
    alert: ''
  };

  render() {
    const {
      handleSubmit,
      invalid,
      pristine,
      submitting,
      onCancel
    } = this.props;
    return (
      <form className="form" onSubmit={handleSubmit}>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={8}>
                <Field
                  autoFocus
                  id="schedule_form_attributes.full_name"
                  component={TextField}
                  controlClassName="form-control__white"
                  label="Nome Completo"
                  labelClassName="label__grey"
                  name="schedule_form_attributes.full_name"
                  type="text"
                />
              </Col>
              <Col md={4}>
                <Field
                  id="schedule_form_attributes.mobile"
                  component={TextField}
                  controlClassName="form-control__white"
                  label="Celular"
                  labelClassName="label__grey"
                  name="schedule_form_attributes.mobile"
                  normalize={normalizeNumericValue}
                  format={formatPhone}
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  id="schedule_form_attributes.email"
                  component={TextField}
                  controlClassName="form-control__white"
                  label="Email"
                  labelClassName="label__grey"
                  name="schedule_form_attributes.email"
                  type="text"
                />
              </Col>
              <Col md={6}>
                <Field
                  id="schedule_form_attributes.cpf"
                  component={TextField}
                  controlClassName="form-control__white"
                  label="CPF"
                  labelClassName="label__grey"
                  name="schedule_form_attributes.cpf"
                  normalize={normalizeNumericValue}
                  format={formatCPF}
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Field
                  id="schedule_form_attributes.availability"
                  component={TextAreaField}
                  controlClassName="form-control__white listing-description"
                  label="Quais dias e horários você tem disponibilidade?"
                  labelClassName="label__grey"
                  name="schedule_form_attributes.availability"
                  type="textarea"
                  rows={3}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Button
                  bsStyle="secondary"
                  className="btn-sparse btn-block"
                  onClick={onCancel}
                >
                  CANCELAR
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  bsStyle="primary"
                  disabled={invalid || pristine || submitting}
                  className="btn-sparse btn-block "
                  type={'submit'}
                >
                  {this.props.loading ? 'ENVIANDO...' : 'ENVIAR'}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    );
  }
}

const form = reduxForm({
  form: 'ScheduleVisitForm',
  validate,
  destroyOnUnmount: true
})(ScheduleVisitForm);

export default connect(state => ({}))(form);
