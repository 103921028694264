import React from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Grid, Button, Row, Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import TogglePanel from "../../_components/TogglePanel";
import { Link } from "react-router-dom";
import { propertyActions } from "../../_actions/propertyActions";
import {
  toCurrenry,
  renderAddressFormatter,
  receivingPaymentMethodFormatter,
  isUserAdmin,
  receivingContractStatusFormatter,
  tenantFormatter,
  getPaymentStatusFromProperty,
  adminPaymentMEthodApprovalRenderer
} from "../../_helpers/utils";
import BootstrapTable from "react-bootstrap-table-next";
import { rentedPropertyActions } from "../../_actions/rentedPropertyActions";
import { billingActions } from "../../_actions/billingActions";
import { reset } from "redux-form";
import LoadingMessage from "../../_components/Shared/LoadingMessage";
import { PanelWithTitle } from "../../_components/PanelWithTitle";
import { SimplePanel } from "../../_components/SimplePanel";
import { PropertyViewDialog } from "./Dialogs/PropertyViewDialog";
import { PaymentMethodApprovalDialog } from "./Dialogs/PaymentMethodApprovalDialog";
import PageTitle from "../../_components/Shared/PageTitle";
const buttonsSizes = "sm";
class RentedPropertiesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      rentedProperties: props.rentedProperties,
      sortOrder: "desc"
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.rentedProperties) {
      this.setState({
        rentedProperties: nextProps.rentedProperties
      });
    }
    if (
      sessionStorage.getItem("currentVisualizationMode") !==
      this.state.currentVisualizationMode
    ) {
      this.setState({
        currentVisualizationMode: parseInt(nextProps.currentVisualizationMode)
      });
      sessionStorage.setItem(
        "currentVisualizationMode",
        parseInt(nextProps.currentVisualizationMode)
      );
    }
  }
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(propertyActions.findAll());
    dispatch(propertyActions.filterPropertiesWithFeatures({}));
  }
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(reset("PopupFilterForm"));
    dispatch(propertyActions.filterPropertiesWithFeatures({}));
  }
  // handlePropertiesTableChange = (type, newState) => {
  //   setTimeout(() => {
  //     const { data, sortField } = newState;
  //     let result;
  //     if (this.state._sortOrderProperties === "asc") {
  //       result = data.sort((a, b) => {
  //         if (a[sortField] > b[sortField]) {
  //           return 1;
  //         } else if (b[sortField] > a[sortField]) {
  //           return -1;
  //         }
  //         return 0;
  //       });
  //     } else {
  //       result = data.sort((a, b) => {
  //         if (a[sortField] > b[sortField]) {
  //           return -1;
  //         } else if (b[sortField] > a[sortField]) {
  //           return 1;
  //         }
  //         return 0;
  //       });
  //     }

  //     this.setState(() => ({
  //       rentedProperties: result,
  //       _sortOrderProperties:
  //         this.state._sortOrderProperties === "asc" ? "desc" : "asc"
  //     }));
  //   }, 200);
  //   alert(this.state._sortOrderProperties);
  // };
  handleBoardAction = (eventCode, id) => {
    switch (eventCode) {
      case "new_boleto_modal":
        this.props.dispatch(
          rentedPropertyActions.updateModals({ boletoModalOpen: true })
        );
        break;
      case "mark_paid_modal":
        this.props.dispatch(
          rentedPropertyActions.updateModals({ markAsPaidModalOpen: true })
        );
        break;
      default:
        break;
    }
  };
  handleMonthSummaryChange = e => {
    console.debug(e.target.attributes.eventkey.value);
  };
  handleToggle = () => {
    this.setState({ show: !this.state.show });
  };
  handleModalHideRequest = () => {
    this.props.dispatch(rentedPropertyActions.hideModals());
  };
  // handlePropertyBillingStateChange = values => {
  //   const { dispatch, selectedProperty } = this.props;
  //   dispatch(
  //     billingActions.changeContractBillingType(
  //       values.payment_method,
  //       values.id,
  //       selectedProperty.id
  //     )
  //   );
  // };
  handleTableChange = (type, { sortField, sortOrder, data }) => {
    sortOrder = this.state.sortOrder || "asc";

    sortField = sortField && sortField.replace(/\./g, "_");
    switch (type) {
      case "sort":
        this.props.dispatch(
          propertyActions.findAllSortingBy(sortField, sortOrder)
        );
        this.setState(() => ({
          sortField,
          data,
          sortOrder: sortOrder === "asc" ? "desc" : "asc"
        }));
        break;
      default:
        break;
    }
  };
  applyFilters = values => {
    let filter = values;
    this.props.dispatch(propertyActions.filterPropertiesWithFeatures(filter));
  };
  handleBillingReviewProcess = values => {
    let activeContract = this.props.selectedProperty.contracts.find(
      c => c.is_active
    );
    let activeContractId = activeContract && activeContract.id;
    if (activeContractId) {
      this.props.dispatch(
        propertyActions.reviewBillingMethod(
          activeContractId,
          values,
          this.props.selectedProperty.id
        )
      );
    }
  };
  render() {
    const { user } = this.props;

    const propertyTableColumns = [
      {
        dataField: "code",
        text: "ID",
        sort: true
      },
      {
        dataField: "active_contract.payment_status",
        text: "Status Pagamento",
        sort: false,
        formatter: getPaymentStatusFromProperty
      },
      {
        isDummyField: true,
        dataField: "active_contract.payment_method",
        text: "Forma Cobrança",
        sort: false,
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc")
            return ("" + rowA.value).localeCompare(rowB.value);
          else return ("" + rowB.value).localeCompare(rowA.value);
        },
        formatter: receivingPaymentMethodFormatter
      },
      {
        isDummyField: true,
        hidden: !isUserAdmin(user),
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            this.props.dispatch(
              billingActions.showPropertyPaymentMethodApprovalModal(row.id)
            );
          }
        },
        formatter: adminPaymentMEthodApprovalRenderer
      },
      {
        dataField: "active_contract.payment_method_status",
        text: "Status Forma Cobrança",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "25px", textAlign: "center" };
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") {
            console.log(rowA.value, rowB.value);
            return ("" + rowA.value).localeCompare(rowB.value);
          } else {
            console.log(rowB.value, rowA.value);
            return ("" + rowB.value).localeCompare(rowA.value);
          }
        },
        formatter: receivingContractStatusFormatter
      },

      {
        dataField: "id",
        text: "Ref Zimobi",
        sort: true,
        hidden: user && user.data && user.data.role === "admin" ? false : true
      },
      {
        dataField: "address.street",
        sort: true,
        text: "Endereço",
        formatter: (value, row) => {
          return row && renderAddressFormatter(row.address);
        }
      },
      {
        dataField: "active_contract.tenant",
        text: "Inquilino",
        sort: false,
        formatter: (value, row) => {
          return tenantFormatter(value);
        }
      },
      {
        dataField: "active_contract.due_date_day",
        text: "Dia Vencimento",
        sort: true
      },
      {
        dataField: "active_contract.total_price_in_cents",
        text: "Valor Total",
        sort: true,
        formatter: (value, row) => {
          return toCurrenry(value ? value / 100 : 0);
        }
      },
      {
        dataField: "df4",
        isDummyField: true,
        formatter: (cellContent, row) => {
          return (
            <Button
              bsSize={buttonsSizes}
              bsStyle={"info"}
              onClick={() =>
                this.props.dispatch(
                  billingActions.showPropertyViewModal(row.id)
                )
              }
            >
              Detalhes
            </Button>
          );
        }
      }
    ];
    const {
      currentVisualizationMode,
      selectedProperty,
      selectedUser,
      propertyViewModalOpen,
      propertyPaymentToApproveOpen,
      filteringProperties,
      filteredProperties
    } = this.props;
    const { rentedProperties } = this.state;

    // const RenderPropertyCard = ({ property }) => {
    //   const { code, id, address, _activeContract, listing } = property;
    //   return (
    //     <div
    //       className="panel"
    //       style={{ minWidth: '250px', maxWidth: '300px', marginRight: '3rem' }}
    //     >
    //       <img
    //         alt="prop type im"
    //         style={{ objectFit: 'cover' }}
    //         height="150"
    //         width="100%"
    //         src={
    //           listing && listing.images && listing.images.length > 1
    //             ? listing.images[0].url
    //             : 'https://via.placeholder.com/150'
    //         }
    //       />
    //       <h4 className="primary-color-variation">
    //         <Link to={'/properties/edit/' + id}>{zeroPad(code, 8)}</Link>
    //       </h4>

    //       <hr />
    //       {_activeContract ? (
    //         <div className="property-card content-table">
    //           <h5>
    //             {_activeContract.tenant && _activeContract.tenant.full_name}{' '}
    //           </h5>
    //           <h6>{renderAddressFormatter(address)} </h6>
    //           <hr />
    //           <table className="w-100">
    //             <tbody>
    //               <tr>
    //                 <td style={{ paddingRight: '1rem' }}>Valor:</td>
    //                 <td style={{ textAlign: 'end' }}>
    //                   {toCurrenry(_activeContract.total_price_in_cents / 100)}
    //                 </td>
    //               </tr>
    //               <tr>
    //                 <td style={{ paddingRight: '1rem' }}>Vencimento:</td>
    //                 <td style={{ textAlign: 'end' }}>
    //                   {_activeContract.due_date_day}
    //                 </td>
    //               </tr>
    //               <tr>
    //                 <td style={{ paddingRight: '1rem' }}>
    //                   Status Forma Cobrança:
    //                 </td>
    //                 <td style={{ textAlign: 'end', float: 'right' }}>
    //                   {receivingContractStatusFormatter(null, property)}
    //                 </td>
    //               </tr>
    //               <tr>
    //                 <td style={{ paddingRight: '1rem' }}>Forma Cobrança:</td>
    //                 <td style={{ textAlign: 'end', float: 'right' }}>
    //                   {receivingPaymentMethodFormatter(property, property)}
    //                 </td>
    //               </tr>
    //             </tbody>
    //           </table>
    //         </div>
    //       ) : (
    //         <Col md={12} className="property-card content-table">
    //           Contrato não encontrado...
    //         </Col>
    //       )}
    //       <div
    //         className="w-100 text-center"
    //         style={{ display: _activeContract ? 'inherit' : 'none' }}
    //       >
    //         <Button
    //           bsSize={'small'}
    //           bsStyle={'info'}
    //           onClick={() =>
    //             this.props.dispatch(billingActions.showPropertyViewModal(id))
    //           }
    //         >
    //           Detalhes
    //         </Button>
    //       </div>
    //     </div>
    //   );
    // };

    // const PropertyToolbar = ({
    //   currentVisualizationMode,
    //   currentSortStatus
    // }) => {
    //   // let type = paymentStatusTypes.find(p => p.value === currentSortStatus);
    //   let title = p.label || 'Escolha';
    //   let availableStatus = [
    //     ...new Set(
    //       rentedProperties &&
    //         rentedProperties.data.map(p => {
    //           let activeContract = getActiveContract(p);
    //           if (activeContract) {
    //             return activeContract.payment_status.label;
    //           } else {
    //             return null;
    //           }
    //         })
    //     )
    //   ].filter(function(el) {
    //     return el != null;
    //   });

    //   return (
    //     <div style={{ marginBottom: '3rem' }}>
    //       <ButtonToolbar>
    //         <span style={{ float: 'left', marginLeft: '5px' }}>
    //           Organizar por: &nbsp;&nbsp;
    //         </span>
    //         <ButtonGroup>
    //           {' '}
    //           <DropdownButton
    //             style={{ minWidth: '200px', marginRight: '1rem' }}
    //             bsSize={'xsmall'}
    //             title={title}
    //             id="bg-nested-dropdown"
    //             onSelect={(evtKey, evt) => {
    //               this.props.dispatch(
    //                 propertyActions.filterPropertiesWithFeatures({})
    //               );
    //               this.props.dispatch(
    //                 propertyActions.sortByPaymentStatus(
    //                   evt.target.attributes.eventkey.value,
    //                   rentedProperties.data
    //                 )
    //               );
    //             }}
    //           >
    //             {availableStatus.map((item, index) => {
    //               return (
    //                 <MenuItem
    //                   eventkey={item}
    //                   active={currentSortStatus === item}
    //                 >
    //                   {paymentStatusTypes.find(p => p.value === item).label}
    //                 </MenuItem>
    //               );
    //             })}
    //           </DropdownButton>
    //         </ButtonGroup>
    //         <Overlay
    //           show={this.state.show}
    //           onHide={() => this.setState({ show: false })}
    //           placement="bottom"
    //           rootClose
    //           container={this}
    //           target={() => ReactDOM.findDOMNode(this.target)}
    //         >
    //           <PopupFilterForm onSubmit={this.applyFilters} />
    //         </Overlay>

    //         <ButtonGroup>
    //           <span style={{ float: 'left' }}>Filtro: &nbsp;&nbsp;</span>
    //           <DropdownButton
    //             style={{ minWidth: '200px' }}
    //             bsSize={'xsmall'}
    //             title={'Escolha seu filtro'}
    //             id="bg-nested-dropdown"
    //             onClick={this.handleToggle}
    //             ref={button => {
    //               this.target = button;
    //             }}
    //           />
    //         </ButtonGroup>
    //         <div className="pull-right">
    //           <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
    //             {/* <ToggleButton
    //               bsSize={'xs'}
    //               bsStyle={currentVisualizationMode === 1 ? 'info' : 'default'}
    //               value={1}
    //               onClick={() =>
    //                 this.props.dispatch(
    //                   rentedPropertyActions.changeVisualizationMode(1)
    //                 )
    //               }
    //             >
    //               {' '}
    //               <Glyphicon glyph="th-large" /> Miniaturas
    //             </ToggleButton> */}
    //             <ToggleButton
    //               disabled
    //               bsSize={'xs'}
    //               bsStyle={currentVisualizationMode === 2 ? 'info' : 'default'}
    //               value={2}
    //               onClick={() =>
    //                 this.props.dispatch(
    //                   rentedPropertyActions.changeVisualizationMode(2)
    //                 )
    //               }
    //             >
    //               <Glyphicon glyph="menu-hamburger" /> Lista
    //             </ToggleButton>
    //           </ToggleButtonGroup>
    //           <Button
    //             disabled
    //             bsSize={'xs'}
    //             bsStyle="primary"
    //             className="btn-primary-color"
    //           >
    //             Exportar Relatório
    //           </Button>
    //         </div>
    //       </ButtonToolbar>
    //     </div>
    //   );
    // };

    const PropertyTableList = ({ columns, items, onTableChange, meta }) => {
      const {
        current_page,
        page_size,
        total_count,
        next_page,
        previous_page
      } = meta;
      const pagination = paginationFactory({
        custom: true,
        page: current_page, // Specify the current page. It's necessary when remote is enabled
        sizePerPage: page_size, // Specify the size per page. It's necessary when remote is enabled
        totalSize: total_count, // Total data size. It's necessary when remote is enabled
        // pageStartIndex: 1, // first page will be 0, default is 1
        // paginationSize: 10, // the pagination bar size, default is 5
        showTotal: true, // display pagination information
        firstPageText: "Primeira", // the text of first page button
        prePageText: "Anterior", // the text of previous page button
        nextPageText: "Próxima", // the text of next page button
        lastPageText: "Última", // the text of last page button
        hideSizePerPage: true, // hide the size per page dropdown
        hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
        onPageChange: (page, size) => {
          let jumping = Math.abs(page - current_page) > 1;
          let baseUrl = next_page || previous_page;
          if (jumping) {
            let url = baseUrl.replace(/page=[\d].*&/, "page=" + page + "&");
            this.props.dispatch(propertyActions.findAll(url));
          } else {
            this.props.dispatch(
              propertyActions.findAll(
                page > current_page ? next_page : previous_page
              )
            );
          }
        }, // callback function when page was changing
        onSizePerPageChange: (sizePerPage, page) => {}, // callback function when page size was changing
        paginationTotalRenderer: (from, to, size) => {
          return (
            <span>
              mostrando {from}-{to} de {size} entradas
            </span>
          );
        } // custom the pagination total
      });

      return (
        <div style={{ overflowX: "auto" }}>
          <BootstrapTable
            id="properties_table"
            key={"properties_table"}
            striped
            remote={{ pagination: true, filter: false, sort: false }}
            pagination={pagination}
            bordered={false}
            onTableChange={onTableChange}
            noDataIndication="Sem dados"
            keyField="id"
            data={items}
            columns={columns}
            headerClasses={"content-table"}
            rowClasses={"content-table"}
          />
        </div>
      );
    };

    let panels = [
      {
        thinBody: true,
        body: (
          <div>
            <PageTitle>Imóveis Alugados</PageTitle>
            <Link to={"/properties/new"}>
              <Button bsSize={"medium"} bsStyle={"primary"}>
                CRIAR NOVO IMÓVEL
              </Button>
            </Link>
          </div>
        )
      },

      {
        thinBody: true,
        body: (
          <Breadcrumb>
            <Breadcrumb.Item href="/dashboard">Meus Imóveis</Breadcrumb.Item>
            <Breadcrumb.Item active href="/properties">
              Imóveis Alugados
            </Breadcrumb.Item>
          </Breadcrumb>
        )
      },
      {
        hidden: !(
          rentedProperties &&
          rentedProperties.data &&
          rentedProperties.data.length === 0 &&
          !filteringProperties
        ),
        thinBody: true,
        body: (
          <Col
            md={6}
            mdPush={3}
            className=" animated fadeInDown"
            style={{ marginTop: "10rem" }}
          >
            <PanelWithTitle
              bsStyle={"info"}
              iconPath={"/images/icon_houses.svg"}
              to={"/properties/new"}
              callToAction={"Cadastrar Imóvel"}
              callToActionSize={"small"}
              title={"Cadastrar Imóvel Alugado"}
              body={"Escolha entre receber manualmente ou receber por boleto bancário."}
            />
          </Col>
        )
      },
      // {
      // hidden:
      //   !filteringProperties &&
      //   rentedProperties &&
      //   rentedProperties.data &&
      //   rentedProperties.data.length === 0,
      // hidden: true,
      // thinBody: true,
      // body: (
      //   <PropertyToolbar
      //     currentSortStatus={currentSortStatus}
      //     currentVisualizationMode={currentVisualizationMode}
      //   />
      // )
      // },
      // {
      //   thinBody: true,
      //   hidden: !filteringProperties && properties && properties.length === 0,
      //   body: properties && currentVisualizationMode === 1 && (
      //     <div style={{ display: 'flex', overflow: 'auto' }}>
      //       {properties.map((property, index) => {
      //         return <RenderPropertyCard key={index} property={property} />;
      //       })}
      //     </div>
      //   )
      // },
      {
        thinBody: false,
        hidden:
          rentedProperties &&
          rentedProperties.data &&
          rentedProperties.data.length === 0,
        body: rentedProperties && currentVisualizationMode === 2 && (
          <PropertyTableList
            columns={propertyTableColumns}
            items={
              filteringProperties
                ? filteredProperties.data
                : rentedProperties.data
            }
            meta={rentedProperties.meta}
            onTableChange={this.handleTableChange}
          />
        )
      }
    ];
    return (
      <div>
        {/* <Header isAuthenticated /> */}
        {selectedProperty && (
          <PropertyViewDialog
            onHide={this.handleModalHideRequest}
            container={this}
            // handleSubmit={this.handlePropertyBillingStateChange}
            property={selectedProperty}
            loadingProperties={this.props.billingChangeInProgress}
            show={propertyViewModalOpen}
          />
        )}
        {selectedProperty && selectedUser && (
          <PaymentMethodApprovalDialog
            onHide={this.handleModalHideRequest}
            onSubmit={this.handleBillingReviewProcess}
            container={this}
            property={selectedProperty}
            user={selectedUser}
            open={propertyPaymentToApproveOpen}
          />
        )}
        <Grid fluid={true}>
          {/* <SideNav /> */}
          <Row>
            <Col md={12} className="main animated fadeInDown">
              <Row>
                {this.props.loadingProperties ? (
                  <SimplePanel>
                    <LoadingMessage />
                  </SimplePanel>
                ) : (
                  panels.map((panel, index) => {
                    return (
                      !panel.hidden && (
                        <TogglePanel
                          key={index}
                          body={panel.body}
                          eventkey={index}
                          form={this.props.form}
                          handleValidationChange={
                            this.handlePanelValidationChange
                          }
                          initialValues={panel.initialValues}
                          onBackButtonClick={this.goToPreviousPanel}
                          onSubmit={this.goToNextPanel}
                          showNextButton
                          title={panel.title}
                          toogleOpenPanel={() => this.toogleOpenPanel(index)}
                          validate={this.props.validate}
                          size={panel.colSize}
                          thinBody={panel.thinBody}
                          toogleable={panel.toogleable}
                          hasSearch={panel.hasSearch}
                          onSearchChanged={this.handleSearchUpdated}
                          customComponent={panel.customComponent}
                        />
                      )
                    );
                  })
                )}
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
    selectedUser: state.registration.selectedUser,
    loadingProperties: state.properties.loadingProperties,
    selectedProperty: state.properties.selectedProperty,
    rentedProperties: state.properties.rentedProperties,
    propertyViewModalOpen: state.properties.propertyViewModalOpen,
    propertyPaymentToApproveOpen: state.properties.propertyPaymentToApproveOpen,
    currentVisualizationMode: state.properties.currentVisualizationMode,
    currentSortStatus: state.properties.currentSortStatus,
    filteringProperties: state.properties.filteringProperties,
    filteredProperties: state.properties.filteredProperties,
    billingChangeInProgress: state.properties.billingChangeInProgress
  };
};
export default connect(mapStateToProps)(RentedPropertiesPage);
