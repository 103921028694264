import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button, Col, Row } from "react-bootstrap";
import {
  TextField,
  TextAreaField,
  SelectField
} from "../../../_components/FormFields";
import { formatReais } from "../../../_helpers/reduxFormFormatters";
import { normalizeNumericValue } from "../../../_helpers/reduxFormNormalizers";
import { paymentForms } from "../../../_helpers/optionsArrays";
import {
  getPaymentStatusFromProperty,
  toCurrenry
} from "../../../_helpers/utils";
import * as moment from "moment";

const required = value =>
  value && typeof value !== "object" ? undefined : "Campo Obrigatório";
const minValue = min => value =>
  value && value <= min ? `Valor deve ser maior que R$ ${min}` : undefined;
const minValue0 = minValue(0);
class MarkAsPaidForm extends React.Component {
  render() {
    const { handleSubmit, payment, valid } = this.props;
    return (
      payment && (
        <form className="form" onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <Col md={12}>
                <div
                  style={{
                    display: "flex",
                    lineHeight: "initial",
                    fontWeight: "bold"
                  }}
                >
                  <span>{getPaymentStatusFromProperty(null, payment)}</span>
                </div>
                <br />
              </Col>
              <Col md={4}>
                <table className="content-table">
                  <tr>
                    <td style={{ paddingRight: "1rem" }}>Valor:</td>
                    <td>{toCurrenry(payment.total_amount_in_cents / 100)}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: "1rem" }}>Vencimento:</td>
                    <td>{moment(payment.due_date).format("DD/MM/YY")}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: "1rem" }}>Forma Pgto:</td>
                    <td>
                      {payment.payment_method && payment.payment_method.label}
                    </td>
                  </tr>
                </table>
              </Col>
              <Col md={8}>
                <table className="content-table">
                  <tr>
                    <td style={{ paddingRight: "1rem" }}>Endereço:</td>
                    <td>{payment._property_address}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: "1rem" }}>Inquilino:</td>
                    <td>{payment.contract.tenant.full_name}</td>
                  </tr>
                </table>
              </Col>
              <Col md={12}>
                <hr />
                <Row>
                  <Col md={6}>
                    <Field
                      component={TextField}
                      controlClassName="form-control__gray"
                      label="Data Pagamento"
                      validate={[required]}
                      labelClassName="label__grey"
                      name="payment.due_date"
                      type="date"
                    />
                  </Col>
                  <Col md={6}>
                    <Field
                      component={TextField}
                      controlClassName="form-control__gray"
                      label="Valor pagamento"
                      validate={[required, minValue0]}
                      labelClassName="label__grey"
                      name="payment.total_amount_in_cents"
                      normalize={normalizeNumericValue}
                      format={formatReais}
                    />
                  </Col>
                  <Col md={12}>
                    <Field
                      component={SelectField}
                      controlClassName="form-control__gray"
                      label="Forma de Pagamento"
                      validate={[required]}
                      labelClassName="label__grey"
                      name="payment.payment_method"
                      options={paymentForms}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Field
                      component={TextAreaField}
                      controlClassName="form-control__gray listing-description"
                      label="Observações"
                      labelClassName="label__grey"
                      name="payment.notes"
                      type="textarea"
                      rows={3}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button
                      bsStyle={"primary"}
                      style={{ float: `right` }}
                      type={"submit"}
                      disabled={!valid}
                    >
                      {this.props.loading
                        ? "Processando..."
                        : "Marcar como Recebido"}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        </form>
      )
    );
  }
}

MarkAsPaidForm = reduxForm({
  form: "markAsPaidForm",
  destroyOnUnmount: true
})(MarkAsPaidForm);

export default connect(null)(MarkAsPaidForm);
