const spreasheetId = '1CVu69xUYfhgPqUq1p6XMKRv9w1wVjiDp-Vx9DAd4lbc';
let token = null;
let token_url =
  'https://cors.io/?https://inquilino.zimobi.com.br/get_token.php?token=e2870553342fb89c05279daf812999e3';
const saveDoubt = row => {
  let bdy = { values: [Object.values(row)] };
  let options1 = {
    method: 'GET'
  };
  if (!token) {
    fetch(`${token_url}`, options1)
      .then(resp => {
        return resp.json();
      })
      .then(json => {
        let options = {
          method: 'POST',
          body: JSON.stringify(bdy)
        };
        fetch(
          `https://sheets.googleapis.com/v4/spreadsheets/${spreasheetId}/values/Duvidas!A2%3AAB2:append?valueInputOption=USER_ENTERED&alt=json&access_token=${
            json.access_token
          }`,
          options
        ).then(resp => {
          console.debug(resp);
        });
      });
  }
};
const saveSchedule = row => {
  let bdy = { values: [Object.values(row)] };
  let options1 = {
    method: 'GET'
  };
  if (!token) {
    fetch(`${token_url}`, options1)
      .then(resp => {
        return resp.json();
      })
      .then(json => {
        let options = {
          method: 'POST',
          body: JSON.stringify(bdy)
        };
        fetch(
          `https://sheets.googleapis.com/v4/spreadsheets/${spreasheetId}/values/Agendamentos!A2%3AAB2:append?valueInputOption=USER_ENTERED&alt=json&access_token=${
            json.access_token
          }`,
          options
        ).then(resp => {
          console.debug(resp);
        });
      });
  }
};
module.exports = { saveDoubt, saveSchedule };
