import React from "react";
import { Col, Row, ControlLabel } from "react-bootstrap";
import styled from "styled-components";
import {
  toCurrenry,
  renderAddressFormatter,
  // renderRentType,
  renderRentalGuarantees
} from "../../_helpers/utils";
import { formatCEP } from "../../_helpers/reduxFormFormatters";
import RenderRow from "../RenderRow";
import RenderPicThumb from "../RenderPicThumb";
export class ListingConfirmationForm extends React.Component {
  render() {
    const { listing } = this.props;
    const Paragraph = styled.p``;
    const Section = styled.div`
      margin-bottom: 2rem;
    `;

    const Header = props => (
      <Row>
        <Col md={12}>
          <ControlLabel className="label__dark_blue">
            {props.children}
          </ControlLabel>{" "}
          <small>{props.subtitle}</small>
        </Col>
      </Row>
    );
    return (
      <Row>
        <Col md={12}>
          <Paragraph>Seu anúncio está quase pronto.</Paragraph>
          <Paragraph>
            Confirme as informações ou volte ao cadastro para editar algum
            detalhe.
          </Paragraph>
          <Paragraph>
            Assim que terminar, o anúncio será validado em até 2 dias úteis.
            Caso aprovado será espalhado pela internet automaticamente.
          </Paragraph>
        </Col>
        <Col md={12}>
          <hr />
        </Col>
        <Col md={6}>
          <Section>
            <Header>Título do anúncio</Header>
            <Paragraph>{listing.title}</Paragraph>
          </Section>
          <Section>
            <Header>Descrição do anúncio</Header>
            <Paragraph>{listing.description}</Paragraph>
          </Section>
          {listing && (
            <Section>
              <RenderRow
                label={"CEP"}
                value={formatCEP(listing.property.address.postal_code)}
              />
              <RenderRow
                label={"ENDEREÇO"}
                value={renderAddressFormatter(listing.property.address)}
              />
              <RenderRow
                label={"VALOR DO ALUGUEL"}
                value={toCurrenry(listing.rental_price_in_cents / 100)}
              />
              <RenderRow
                label={"CONDOMÍNIO"}
                value={toCurrenry(listing.condo_fee_in_cents / 100)}
              />
              <RenderRow
                label={"IPTU"}
                value={toCurrenry(listing.iptu_price_in_cents / 100)}
              />
              <RenderRow
                label={"TOTAL"}
                value={toCurrenry(listing.total_price_in_cents / 100)}
              />
              <RenderRow
                label={"TIPO DE IMÓVEL"}
                value={listing.property.property_type.label}
              />
              <RenderRow
                label={"TIPO DE ALUGUEL"}
                value={listing.property.rental_type.label}
              />
              <RenderRow
                label={"ÁREA"}
                value={
                  ((listing.property &&
                    listing.property.usable_floor_area_in_square_meters) ||
                    0) + "m²"
                }
              />
              <RenderRow
                label={"QUARTOS"}
                value={
                  (listing.property && listing.property.number_of_rooms) || 0
                }
              />
              <RenderRow
                label={"BANHEIROS"}
                value={
                  (listing.property && listing.property.number_of_bathrooms) ||
                  0
                }
              />
              <RenderRow
                label={"SUITES"}
                value={
                  (listing.property && listing.property.number_of_suites) || 0
                }
              />
              <RenderRow
                label={"VAGAS DE GARAGEM"}
                value={
                  (listing.property &&
                    listing.property.number_of_parking_spots) ||
                  0
                }
              />
            </Section>
          )}
        </Col>
        <Col md={6}>
          <Section>
            <Header>Fotos do Anúncio</Header>
            {listing.images.map((i, index) => {
              return <RenderPicThumb src={i.url} key={index} />;
            })}
          </Section>
          <RenderRow
            label={"GARANTIAS"}
            colLabelSize={3}
            value={renderRentalGuarantees(
              listing.deposit_amount_in_cents,
              listing.rental_guarantee
            )}
          />
          {listing.guarantee_description && (
            <RenderRow
              colLabelSize={3}
              label={"OBSERVAÇÃO"}
              value={listing.guarantee_description}
            />
          )}
        </Col>
      </Row>
    );
  }
}

export default ListingConfirmationForm;
