export const formatCEP = value => {
  if (!value) {
    return '';
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2)}`;
  }

  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}-${onlyNums.slice(
    5,
    8
  )}`.slice(0, 10);
};

export const formatCPF = value => {
  if (!value) {
    return '';
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3)}`;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6
    )}`;
  }

  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
    6,
    9
  )}-${onlyNums.slice(9)}`.slice(0, 14);
};

export const formatRG = value => {
  if (!value) {
    return '';
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 1) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 1)}.${onlyNums.slice(1)}`;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 1)}.${onlyNums.slice(1, 4)}.${onlyNums.slice(
      4
    )}`;
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 1)}.${onlyNums.slice(1, 4)}.${onlyNums.slice(
      4,
      7
    )}-${onlyNums.slice(7)}`;
  }

  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
    5,
    8
  )}-${onlyNums.slice(8)}`.slice(0, 12);
};

export const formatPhone = value => {
  if (!value) {
    return '';
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2)}`;
  }
  if (onlyNums.length <= 10) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}-${onlyNums.slice(
      6
    )}`;
  }

  return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(
    7
  )}`.slice(0, 15);
};

export const formatDate = value => {
  if (!value) {
    return '';
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
  }

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
    4
  )}`.slice(0, 10);
};

export const formatCNPJ = value => {
  if (!value) {
    return '';
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2)}`;
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5
    )}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8)}`;
  }

  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
    5,
    8
  )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12)}`.slice(0, 18);
};

export const formatReais = value => {
  if (!value) {
    value = 0;
  }

  let onlyNums = null;
  if (typeof value === 'string') {
    onlyNums = parseFloat(value.replace(/[^\d]/g, ''));
  } else {
    onlyNums = value;
  }

  if (isNaN(onlyNums)) onlyNums = 0;

  onlyNums = onlyNums / 100;

  return (
    'R$ ' +
    onlyNums.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  );
};

export const formatFiscalIndication = value => {
  if (!value) {
    return '';
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2)}`;
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 5)}-${onlyNums.slice(
      5
    )}`;
  }
  if (onlyNums.length <= 11) {
    return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 5)}-${onlyNums.slice(
      5,
      8
    )}.${onlyNums.slice(8)}`;
  }

  return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 5)}-${onlyNums.slice(
    5,
    8
  )}.${onlyNums.slice(8, 11)}-${onlyNums.slice(11, 12)}`.slice(0, 16);
};

export const formatPropertyInscription = value => {
  if (!value) {
    return '';
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 3) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2)}`;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 3)}.${onlyNums.slice(
      3
    )}`;
  }
  if (onlyNums.length <= 11) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 3)}.${onlyNums.slice(
      3,
      7
    )}.${onlyNums.slice(7)}`;
  }
  if (onlyNums.length <= 13) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 3)}.${onlyNums.slice(
      3,
      7
    )}.${onlyNums.slice(7, 11)}.${onlyNums.slice(11)}`;
  }

  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 3)}.${onlyNums.slice(
    3,
    7
  )}.${onlyNums.slice(7, 11)}.${onlyNums.slice(11, 13)}-${onlyNums.slice(
    13,
    14
  )}`.slice(0, 19);
};
