export const calculateTotalRentPriceInCents = (
  expenses,
  rental_price_in_cents,
  condo_fee_in_cents,
  iptu_price_in_cents
) => {
  let expense = calculateTotalRecurringExpenses(expenses);
  let rental = parseInt(rental_price_in_cents) || 0;
  let condo = parseInt(condo_fee_in_cents) || 0;
  let iptu = parseInt(iptu_price_in_cents) || 0;

  return expense + rental + condo + iptu;
};

export const calculateTotalRecurringExpenses = expenses => {
  if (!expenses || (expenses && expenses.length === 0)) {
    return 0;
  } else {
    return expenses
      .map(a => a.amount_in_cents)
      .reduce((total, amount) => parseInt(total) + parseInt(amount || 0), 0);
  }
};
