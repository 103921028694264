import React from 'react';
import {
  Image,
  Navbar,
  Nav,
  NavDropdown,
  MenuItem,
  Glyphicon
} from 'react-bootstrap';
import SpinnerComponent from './Spinner';

export class NewToolBar extends React.Component {
  render() {
    const { user, onToggle, showToggleButton,profileLoading } = this.props;
    return (
      <Navbar
        style={{
          backgroundColor: 'white',
          position: 'sticky',
          top: '0',
          width: '100%',
          zIndex: '1'
        }}
        fluid
      >
        {showToggleButton && (
          <Navbar.Brand href="#home" onClick={onToggle}>
          {' '}
            <Glyphicon glyph={'glyphicon glyphicon-menu-hamburger'} />
          </Navbar.Brand>
        )}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav pullRight>
            <React.Fragment>{user && !profileLoading? (
              <NavDropdown
                eventKey={1}
                style={{
                  backgroundColor: 'white'
                }}
                title={
                  <div className="pull-right">
                    <span className="logged-user-name">
                      {user.full_name + '   '}
                    </span>
                    <Image
                      style={{ height: '60px', width: '60px' }}
                      circle
                      src={user.avatar_url}
                    />
                  </div>
                }
                id="basic-nav-dropdown"
              >
                <MenuItem
                  className="toolbar-menu-item"
                  eventkey={3.1}
                  onClick={this.props.onProfileRequest}
                >
                  Perfil
                </MenuItem>
                <MenuItem divider />
                <MenuItem
                  className="toolbar-menu-item"
                  eventkey={3.2}
                  onClick={this.props.onExitRequest}
                >
                  Sair (Desconectar)
                </MenuItem>
              </NavDropdown>
            ) : (
              <div className="text-center" style={{height: '100px',display:'table'}}>
                  <SpinnerComponent   style={{fontSize: '24px', display:'table-cell', verticalAlign:'middle'}}   />
              </div>
            )}
            </React.Fragment>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NewToolBar;
